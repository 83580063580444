import React, { Component } from 'react'
import NewReport from './NewReport'
export default class NewTouristsReportCount extends Component {
    /*static propTypes = {
         prop: PropTypes
     }*/
    constructor(props) {
        super(props);
        this.state = {

        }
    };



    render() {
        return (
            <React.Fragment>
                <NewReport></NewReport>
            </React.Fragment>
        )
    }
}
