import React, { Component } from 'react'
import { Row, Col } from "reactstrap";
import Swal from 'sweetalert2'
import { withTranslation } from 'react-i18next';
class RangeTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_period: { value: 30, isSearch: false, selected_period_start: '', selected_period_end: '' }
        }
    };

    submitHandle(value) {
        switch (value) {
            case "date":
                if (this.state.selected_period.value === 'custom') {
                    this.props.callback(value, this.state.selected_period)
                }
                else {
                    this.props.callback(value, this.state.selected_period)
                }
                break;

            default:
                return
        }
    }

    componentDidMount() {
        this.props.callback('date', this.state.selected_period)
    }

    render() {
        return (
            <Row className="mb-3">
                <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>
                    <span style={{ fontSize: 16 }}>{this.props.t('Select time period')} : </span>
                </Col>
                <Col xl={10} lg={10} md={10} sm={12} style={{ display: 'inline-flex', overflow: 'auto' }}>
                    <button className={this.state.selected_period.value === 1 ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'}
                        style={{ whiteSpace: 'nowrap', textAlign: 'center' }}
                        onClick={() => {
                            let obj = this.state.selected_period
                            obj.value = 1
                            obj.selected_period_start = ''
                            obj.selected_period_end = ''
                            obj.isSearch = false
                            this.setState({
                                selected_period: obj,
                                // fetch_report: true
                            }, () => {
                                this.submitHandle('date')
                            })
                        }}>
                        {this.props.t('Today')}
                    </button>
                    <button className={this.state.selected_period.value === 7 ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'}
                        style={{ marginLeft: 20, whiteSpace: 'nowrap', textAlign: 'center' }}
                        onClick={() => {
                            let obj = this.state.selected_period
                            obj.value = 7
                            obj.selected_period_start = ''
                            obj.selected_period_end = ''
                            obj.isSearch = false
                            this.setState({
                                selected_period: obj,
                                // fetch_report: true
                            }, () => {
                                this.submitHandle('date')
                            })
                        }}>
                        {this.props.t('7 days ago')}
                    </button>
                    <button className={this.state.selected_period.value === 30 ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'}
                        style={{ marginLeft: 20, whiteSpace: 'nowrap', textAlign: 'center' }}
                        onClick={() => {
                            let obj = this.state.selected_period
                            obj.value = 30
                            obj.selected_period_start = ''
                            obj.selected_period_end = ''
                            obj.isSearch = false
                            this.setState({
                                selected_period: obj,
                                // fetch_report: true
                            }, () => {
                                this.submitHandle('date')
                            })
                        }}>
                        {this.props.t('30 days ago')}
                    </button>
                    <button className={this.state.selected_period.value === 'custom' ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'}
                        style={{ marginLeft: 20, whiteSpace: 'nowrap', textAlign: 'center' }}
                        onClick={() => {
                            let obj = this.state.selected_period
                            obj.isSearch = !obj.isSearch
                            this.setState({
                                selected_period: obj,
                                // fetch_report: true
                            }, () => {
                                this.submitHandle('date')
                            })
                        }}>
                        {this.props.t('Choose your own time')}
                    </button>

                </Col>
                <Col xl={12} lg={12} md={12} sm={12}></Col>
                {this.state.selected_period.isSearch === true ?
                    <>
                        <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>
                        </Col>
                        <Col xl={10} lg={10} md={10} sm={12} >
                            <Row>
                                <Col xl={4} lg={4} md={4} sm={12}>
                                    <Row>
                                        <Col xl={4} lg={4} md={4} sm={4} style={{ alignSelf: 'center', marginTop: 15 }}> <span > {this.props.t('Start date')} : </span></Col>
                                        <Col xl={8} lg={8} md={8} sm={8}><input type="date" value={this.state.selected_period_start} onChange={(e) => {
                                            let obj = this.state.selected_period
                                            obj.selected_period_start = e.target.value
                                            this.setState({
                                                selected_period: obj
                                            })
                                        }} placeholder="เริ่มต้น" className="form-control" style={{ marginTop: 15 }}></input></Col>


                                    </Row>

                                </Col>
                                <Col xl={4} lg={4} md={4} sm={12}>
                                    <Row>
                                        <Col xl={4} lg={4} md={4} sm={4} style={{ alignSelf: 'center', marginTop: 15 }}> <span > {this.props.t('End date')} : </span></Col>
                                        <Col xl={8} lg={8} md={8} sm={8}><input type="date" value={this.state.selected_period_end} min={this.state.selected_period.selected_period_start} onChange={(e) => {
                                            let obj = this.state.selected_period
                                            obj.selected_period_end = e.target.value
                                            this.setState({
                                                selected_period: obj
                                            })
                                        }} placeholder="สิ้นสุด" className="form-control" style={{ marginTop: 15 }}></input></Col>


                                    </Row>
                                </Col>
                                <Col xl={2} lg={2} md={2} sm={12}>
                                    {/* <label style={{ color: 'rgba(255,0,0,0.0)' }}>ปุ่ม ซ่อนสีมันซะ :</label><br></br> */}
                                    <button style={{ width: '100%', marginTop: 15 }} className='btn btn-primary '
                                        onClick={() => {
                                            if (this.state.selected_period.selected_period_start === "" || this.state.selected_period.selected_period_end === "") {
                                                Swal.fire(
                                                    'ล้มเหลว !',
                                                    'กรุณาเลือกวันเริ่มต้นและสิ้นสุดของรายงานที่ต้องการค้นหา',
                                                    'error'
                                                )
                                            }
                                            else {
                                                let obj = this.state.selected_period
                                                obj.value = 'custom'
                                                obj.isSearch = false
                                                this.setState({
                                                    selected_period: obj
                                                }, () => {
                                                    this.submitHandle('date')
                                                })
                                            }
                                        }}>
                                        {this.props.t('Search')}
                                    </button>
                                </Col>
                            </Row>

                        </Col>
                    </>

                    :
                    <>
                        {this.state.selected_period.value === 'custom' && this.state.selected_period.selected_period_start !== '' && this.state.selected_period.selected_period_end !== '' ?
                            <>
                                <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}></Col>
                                <Col className="mt-10" xl={10} lg={10} md={10} sm={12} style={{ alignSelf: 'center' }}>
                                    <Row>
                                        <Col xl={3} lg={3} md={3} sm={12}>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={5} style={{ alignSelf: 'center', marginTop: 15 }}> <span > {this.props.t('Start date')} : </span></Col>
                                                <Col xl={7} lg={7} md={7} sm={7} style={{ marginTop: 15 }}>
                                                    <span>{this.state.selected_period.selected_period_start}</span>
                                                </Col>


                                            </Row>

                                        </Col>
                                        <Col xl={3} lg={3} md={3} sm={12} style={{ alignSelf: 'center' }}>
                                            <Row>
                                                <Col xl={5} lg={5} md={5} sm={5} style={{ alignSelf: 'center', marginTop: 15 }}> <span > {this.props.t('End date')} : </span></Col>
                                                <Col xl={7} lg={7} md={7} sm={7} style={{ marginTop: 15 }}>
                                                    <span>{this.state.selected_period.selected_period_end}</span>
                                                </Col>


                                            </Row>
                                        </Col>

                                    </Row>
                                </Col>
                            </>

                            :
                            null}

                    </>

                }

            </Row>
        )
    }
}
export default (withTranslation()(RangeTab))