import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Col, Row, CardImg, CardImgOverlay } from "reactstrap";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// import { useRef } from "react";
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import './swiper.scss';
// import check from '../../../assets/images/icon/check.svg';
// import choss from "../../../assets/images/icon/c.png";


SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
export default class Cardfocus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showimage: {
                index: [],
                image: '/assets/images/icon/c.png'
            }
        };
    }

    filter_array(id) {
        let value = this.state.showimage.index.filter((e) => e === id)
        return (value)
    }

    substring(value) {
        let length = value.length
        let values = value.slice(0, length - 13)
        return (values)
    }

    render() {


        const { data } = this.props;
        return (


            <Swiper
                breakpoints={{
                    // when window width is >= 640px
                    200: {
                        width: 500,
                        slidesPerView: 1,

                    },
                    // when window width is >= 640px
                    640: {
                        width: 640,
                        slidesPerView: 1,

                    },
                    // when window width is >= 768px
                    768: {
                        width: 768,
                        slidesPerView: 2,
                    },
                    960: {
                        width: 820,
                        slidesPerView: 2,
                    },
                }}
                id="main"
                width="480"
                loop={true}
                navigation
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}
                spaceBetween={5}
                slidesPerView={'auto'}
                onClick={(swiper, event) => {
                    // console.log(swiper.clickedSlide.innerText)
                    if (swiper.clickedSlide === undefined) {
                        console.log('clickedSlide undefined')
                        return
                    }
                    else {
                        let data = this.state.showimage.index
                        if (swiper.clickedSlide.innerText.trim() === 'ร้านอาหาร') {
                            let arr = this.filter_array(0)
                            if (arr.length < 1) {
                                this.props.cardSelected('ร้านอาหาร')
                                data.push(0);
                                this.setState({

                                    showimage: {
                                        ...this.state.showimage,
                                        index: data
                                    }
                                }, () => {
                                    console.log(this.state.showimage)
                                })
                            }
                            return

                        }
                        if (swiper.clickedSlide.innerText.trim() === 'นันทนาการและการพักผ่อน') {
                            let arr = this.filter_array(1)
                            if (arr.length < 1) {
                                this.props.cardSelected('แหล่งท่องเที่ยวเชิงนันทนาการและการพักผ่อน')
                                data.push(1);
                                this.setState({

                                    showimage: {
                                        ...this.state.showimage,
                                        index: data
                                    }
                                }, () => {
                                    console.log(this.state.showimage)
                                })
                                return
                            }
                        }

                        if (swiper.clickedSlide.innerText.trim() === 'ท่องเที่ยวในธรรมชาติ') {
                            this.props.cardSelected('แหล่งท่องเที่ยวในธรรมชาติ')
                            data.push(2);
                            this.setState({

                                showimage: {
                                    ...this.state.showimage,
                                    index: data
                                }
                            })
                            return
                        }
                        if (swiper.clickedSlide.innerText.trim() === 'ท่องเที่ยวผจญภัย') {
                            this.props.cardSelected('แหล่งท่องเที่ยวผจญภัย')
                            data.push(3);
                            this.setState({

                                showimage: {
                                    ...this.state.showimage,
                                    index: data
                                }
                            })
                            return
                        }
                        if (swiper.clickedSlide.innerText.trim() === 'ท่องเที่ยวศาสนาและวัฒนธรรม') {
                            this.props.cardSelected('แหล่งท่องเที่ยวศาสนาและวัฒนธรรม')
                            data.push(4);
                            this.setState({

                                showimage: {
                                    ...this.state.showimage,
                                    index: data
                                }
                            })
                            return
                        }
                        if (swiper.clickedSlide.innerText.trim() === 'ที่พัก') {
                            this.props.cardSelected('ที่พัก')
                            data.push(5);
                            this.setState({

                                showimage: {
                                    ...this.state.showimage,
                                    index: data
                                }
                            })
                            return
                        }

                        if (swiper.clickedSlide.innerText.trim() === 'ร้านค้าของฝาก') {
                            this.props.cardSelected('ร้านค้าของฝาก')
                            data.push(6);
                            this.setState({

                                showimage: {
                                    ...this.state.showimage,
                                    index: data
                                }
                            })
                        }
                        else {
                            let value = this.substring(swiper.clickedSlide.innerText)
                            console.log(value)
                            if (value === "ร้านอาหาร") {
                                let datas = data.indexOf(0)
                                console.log(datas)
                                data.splice(datas, 1)
                                console.log(data)
                                this.setState({

                                    showimage: {
                                        ...this.state.showimage,
                                        index: data
                                    }
                                })
                            }
                            if (value === "นันทนาการและการพักผ่อน") {
                                let datas = data.indexOf(1)
                                console.log(datas)
                                data.splice(datas, 1)
                                console.log(data)
                                this.setState({


                                    showimage: {
                                        ...this.state.showimage,
                                        index: data
                                    }
                                })
                            }
                            if (value === "ท่องเที่ยวในธรรมชาติ") {
                                let datas = data.indexOf(2)
                                console.log(datas)
                                data.splice(datas, 1)
                                console.log(data)
                                this.setState({

                                    showimage: {
                                        ...this.state.showimage,
                                        index: data
                                    }
                                })
                            }
                            if (value === "ท่องเที่ยวผจญภัย") {
                                let datas = data.indexOf(3)
                                console.log(datas)
                                data.splice(datas, 1)
                                console.log(data)
                                this.setState({

                                    showimage: {
                                        ...this.state.showimage,
                                        index: data
                                    }
                                })
                            }
                            if (value === "ท่องเที่ยวศาสนาและวัฒนธรรม") {
                                let datas = data.indexOf(4)
                                console.log(datas)
                                data.splice(datas, 1)
                                console.log(data)
                                this.setState({

                                    showimage: {
                                        ...this.state.showimage,
                                        index: data
                                    }
                                })
                            }
                            if (value === "ที่พัก") {
                                let datas = data.indexOf(5)
                                console.log(datas)
                                data.splice(datas, 1)
                                console.log(data)
                                this.setState({

                                    showimage: {
                                        ...this.state.showimage,
                                        index: data
                                    }
                                })
                            }
                            if (value === "ร้านค้าของฝาก") {
                                let datas = data.indexOf(6)
                                console.log(datas)
                                data.splice(datas, 1)
                                console.log(data)
                                this.setState({

                                    showimage: {
                                        ...this.state.showimage,
                                        index: data
                                    }
                                })
                            }

                        }
                    }

                }}>
                {data.map((Item, index) =>
                    <SwiperSlide key={index} style={{ marginTop: '6rem' }}>
                        <Row style={{ alignSelf: 'center', justifyContent: 'center' }}>
                            <label className="card-radio-label mb-2">
                                <input type="radio" name="currency" id="buycurrencyoption1" className="card-radio-input" />
                                <Col className="card-radio" key={index} style={{ width: '18rem', height: '21rem', borderRadius: 10, borderWidth: 5 }}>
                                    <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
                                        <CardImg top className="img-fluid" src={Item.src} style={{ width: '10rem', height: '11rem' }} />
                                    </div>
                                    <div>
                                        <CardImgOverlay >
                                            {this.state.showimage.index.map((e, i) => {
                                                if (e === index) {
                                                    return (
                                                        <div style={{ paddingTop: 53 }}>
                                                            <img key={i} src={this.state.showimage.image} alt="" style={{ width: '90%', }} />
                                                        </div>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        null
                                                    )
                                                }
                                            })}
                                        </CardImgOverlay>
                                    </div>
                                    <div style={{ textAlign: "center" }}  >
                                        <h4 style={{ color: "black", fontWeight: 'bold' }}>{Item.title}</h4>
                                    </div>
                                </Col>
                            </label>
                        </Row>
                    </SwiperSlide>
                )
                }
            </Swiper>
        );
    }
}

