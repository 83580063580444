import React, { Component } from 'react';
// import Select from "react-select";
// import SimpleBar from "simplebar-react";
// import Moment from 'react-moment';
import QRCode from "react-qr-code";
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
} from "reactstrap";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import DataGrid, {
    Column,
    Pager, Paging,
} from 'devextreme-react/data-grid';
import Lottie from "react-lottie";
import FadeIn from "react-fade-in";
import * as loadingData from "../men-component/loading.json";
import { API_ENDPOINT } from "../../../assets/api";
import moment from 'moment'
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { Link } from "react-router-dom";
import CountriesData from '../men-component/countries.json';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { withTranslation } from 'react-i18next';
import '../Joborder.css';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};
const LoadingContainer = props => <div>Loading...</div>;
// const optionGroup = [
//     {
//         label: "Picnic",
//         options: [
//             { label: "Mustard", value: "Mustard" },
//             { label: "Ketchup", value: "Ketchup" },
//             { label: "Relish", value: "Relish" }
//         ]
//     },
//     {
//         label: "Camping",
//         options: [
//             { label: "Tent", value: "Tent" },
//             { label: "Flashlight", value: "Flashlight" },
//             { label: "Toilet Paper", value: "Toilet Paper" }
//         ]
//     }
// ];
// const optionGroup1 = [
//     {
//         label: "Picnic",
//         options: [
//             { label: "Mustard", value: "Mustard" },
//             { label: "Ketchup", value: "Ketchup" },
//             { label: "Relish", value: "Relish" }
//         ]
//     },
//     {
//         label: "Camping",
//         options: [
//             { label: "Tent", value: "Tent" },
//             { label: "Flashlight", value: "Flashlight" },
//             { label: "Toilet Paper", value: "Toilet Paper" }
//         ]
//     }
// ];
// const optionGroup2 = [
//     {
//         label: "Picnic",
//         options: [
//             { label: "Mustard", value: "Mustard" },
//             { label: "Ketchup", value: "Ketchup" },
//             { label: "Relish", value: "Relish" }
//         ]
//     },
//     {
//         label: "Camping",
//         options: [
//             { label: "Tent", value: "Tent" },
//             { label: "Flashlight", value: "Flashlight" },
//             { label: "Toilet Paper", value: "Toilet Paper" }
//         ]
//     }
// ];

class JoborderProgress extends Component {
    constructor(props) {
        var today = new Date(),
            date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        var time = new Date(),
            time = time.getHours() + ':' + time.getMinutes() + ':' + time.getSeconds();
        super(props)
        this.state = {
            insurerName: [],
            policy_pdfs: [],
            joborder_code: "",
            currentTime: time,
            currentDate: date,
            selectedDate: null,
            selectedTime: null,
            statuscheckin: null,
            activeMarker: {},
            selectedPlace: {},
            showingInfoWindow: false,
            joborder_id: -1,
            fetch_joborder: true,
            joborder: {},
            test: 0,
            tourists: [],
            change_requests: [],
            notofications: [
                { date: "15 Mar", desc: "If several languages coalesce of the resulting." },
                { date: "14 Mar", desc: "New common language will be more simple and regular than the existing." },
                { date: "13 Mar", desc: "It will seem like simplified English as a skeptical Cambridge." },
                { date: "13 Mar", desc: "To achieve this, it would be necessary." },
                { date: "12 Mar", desc: "Cum sociis natoque penatibus et magnis dis." },
                { date: "11 Mar", desc: "New common language will be more simple and regular than the existing." },
                { date: "10 Mar", desc: "It will seem like simplified English as a skeptical Cambridge." },
                { date: "9 Mar", desc: "To achieve this, it would be necessary." },
            ],
            map: {},
            policysets: []
        }
        this.handleselectedDate = this.handleselectedDate.bind(this);
        this.handleselectedTime = this.handleselectedTime.bind(this);
        this.handlestatuscheckin = this.handlestatuscheckin.bind(this);

    }
    handleselectedDate = selectedDate => {
        this.setState({ selectedDate });
    };
    handleselectedTime = selectedTime => {
        this.setState({ selectedTime });
    };
    handlestatuscheckin = statuscheckin => {
        this.setState({ statuscheckin });
    };

    async fetch_joborder_progress() {
        const joborder_code = new URLSearchParams(this.props.location.search).get("joborder_code")
        console.log("joborder_code", joborder_code)
        var auth = JSON.parse(await localStorage.getItem('authToken'))
        var lang = 'th'
        var response = await fetch(API_ENDPOINT + 'api/v2/web/joborder/progress', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: auth.data.type + ' ' + auth.data.token,
            },
            body: JSON.stringify({
                joborder_code: joborder_code,
                lang: lang
            })
        });
        var responseJson = await response.json();
        console.log("------------", responseJson);

        await this.setState({
            joborder_code: joborder_code,
            joborder: responseJson.joborder,
            fetch_joborder: false
        })

        function getCountryNameByCode(countryCode) {
            const countryName = CountriesData[countryCode];
            return countryName ? countryName : "ไม่พบข้อมูล";
        }

        var tourists = [];
        var no = 1;
        for (let tourist of this.state.joborder.tourists) {
            const countryName = getCountryNameByCode(tourist.origincountry);
            tourists.push({
                no: no,
                passport: tourist.passport,
                fname: tourist.fname,
                lname: tourist.lname,
                origincountry: countryName, // ใช้ countryName แทน tourist.origincountry
            });
            no += 1;
            // console.log("-----------", countryName);
        }

        if (Array.isArray(this.state.joborder.change_requests)) {
            // ทำงานกับ this.state.joborder.change_requests ได้ที่นี่
            var change_requests = []
            for (let change_request of this.state.joborder.change_requests) {
                console.log("---+-++-+-+", this.state.joborder.change_requests);
                change_requests.push({
                    date: change_request.date === null ? '-' : moment(new Date(change_request.date)).format('DD-MM-YYYY'),
                    time: change_request.time,
                    images: change_request.images,
                    remark: change_request.remark
                })
            }
        } else {
            console.error('this.state.joborder.change_requests ไม่ใช่ array หรือไม่ถูกต้อง');
        }

        await this.setState({
            policysets: responseJson.joborder.policysets,
            policy_pdfs: responseJson.joborder.policy_pdfs,
            tourists: tourists,
            change_requests: change_requests
        })
    }

    async getInsurer() {
        var auth = JSON.parse(await localStorage.getItem('authToken'))
        const response_insurer = await fetch(API_ENDPOINT + 'api/v2/web/insurer', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: auth.data.type + ' ' + auth.data.token,
            },
        });

        const result = await response_insurer.json();
        console.log("response_insurer", result)
        this.setState({
            insurerName: result.data
        })

        // console.log("insurerName", insurerName)
    }

    onMarkerClick = (props, marker, e) => {
        console.log(props)
        console.log(marker)
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });
    };

    render_marker() {
        setTimeout(
            () => {
                return (
                    <Marker
                        title={'The marker`s title will appear as a tooltip.'}
                        name={'SOMA'}
                        position={{
                            lat: 13.9656153,
                            lng: 100.5839537
                        }}
                    >
                        <InfoWindow
                            visible={true}
                            position={{
                                lat: 13.9656153,
                                lng: 100.5839537
                            }}
                        >
                            <div>
                                <p>Click on the map or drag the marker to select location where the incident occurred</p>
                            </div>
                        </InfoWindow>
                    </Marker>
                )
            },
            1000
        );
    }
    test() {
        this.setState({
            test: this.state.test + 1
        })
    }

    centerHandle(places, last_checkin_index) {
        if (this.state.selectedPlace.latitude !== undefined && this.state.selectedPlace.longitude !== undefined) {
            return { lat: this.state.selectedPlace.latitude, lng: this.state.selectedPlace.longitude }
        }
        if (places.length > 0 && places[last_checkin_index].latitude !== undefined && places[last_checkin_index].longitude !== undefined) {

            return { lat: parseFloat(places[last_checkin_index].latitude), lng: parseFloat(places[last_checkin_index].longitude) }
        }
        else {
            return { lat: 13.736717, lng: 100.523186 }
        }
    }

    _mapLoaded(mapProps, map) {

        this.setState({
            map: mapProps
        })
    }

    componentDidMount() {
        // if (this.props.location.joborder_id == null) {
        //     this.props.history.push('/Joborder')
        // }       
        this.setState({
            joborder_id: this.props.location.joborder_id == null ? 271 : this.props.location.joborder_id
        }, async () => {
            await this.fetch_joborder_progress()
            await this.getInsurer()
        })
    }

    render() {
        // const { selectedDate } = this.state;
        // const { selectedTime } = this.state;
        // const { statuscheckin } = this.state;
        let last_checkin_index = 0
        console.log("this.state.policysets", this.state.policysets)
        if (this.state.fetch_joborder || this.props.google == null) {
            return (
                <React.Fragment>
                    <Row >
                        <Col>
                            <div style={{ marginTop: 200 }} className="text-center">
                                <FadeIn>
                                    <div style={{ display: "flex", justifyContent: 'center' }}>
                                        <Card>
                                            <CardBody>
                                                <Lottie options={defaultOptions} height={300} width={300} />
                                                <div>
                                                    <h4 style={{ color: 'black', padding: 30 }}>กำลังเตรียมข้อมูล กรุณารอสักครู่</h4>
                                                </div>
                                            </CardBody>
                                        </Card>

                                    </div>
                                </FadeIn>
                            </div>
                        </Col>
                    </Row>
                </React.Fragment>
            )
        }
        var places = []
        var temperature_check = []
        var places_arrive = 0

        for (let k = 0; k < this.state.joborder.tourists.length; k++) {
            temperature_check.push({ name: this.state.joborder.tourists[k].fname + ' ' + this.state.joborder.tourists[k].lname, day: [] })
            for (let j = 0; j < this.state.joborder.days.length; j++) {
                temperature_check[k].day.push({
                    status: 0,
                    remark: 'ยังไม่ระบุ'
                })
                for (let l = 0; l < this.state.joborder.days[j].tourists_temperature.length; l++) {
                    if (this.state.joborder.tourists[k].id === this.state.joborder.days[j].tourists_temperature[l].joborder_tourist_id) {
                        temperature_check[k].day[j].status = this.state.joborder.days[j].tourists_temperature[l].temperaturestatus
                        temperature_check[k].day[j].remark = this.state.joborder.days[j].tourists_temperature[l].remark
                    }
                }
            }
        }
        console.log(temperature_check)
        for (var day of this.state.joborder.days) {

            for (var daypart of day.day_details) {
                let daypart_name = ""
                switch (daypart.daypart) {
                    case 1:
                        daypart_name = "ช่วงเช้า"
                        break;
                    case 2:
                        daypart_name = "ช่วงบ่าย"
                        break;
                    case 3:
                        daypart_name = "ช่วงเย็น"
                        break;
                    default:
                        break;
                }
                let checkin_status = ""
                if (daypart.checkindate != null && daypart.checkoutdate != null) {
                    checkin_status = 2
                    places_arrive++
                } else if (daypart.checkindate != null && daypart.checkoutdate == null) {
                    checkin_status = 1
                    places_arrive++
                } else {
                    checkin_status = 0
                }
                let dayno = Number(day.dayno) - 1
                // console.log(daypart)

                daypart.place = {
                    ...daypart.place,
                    day: moment(new Date(this.state.joborder.joborder_start_date)).add("days", dayno).format('DD-MM-YYYY'),
                    dayno: day.dayno,
                    daypart: daypart_name,
                    place_no: places.length + 1,
                    place_id: daypart.place_id,
                    checkindate: daypart.checkindate === null ? '-' : moment(new Date(daypart.checkindate)).format('DD-MM-YYYY , h:mm:ss a'),
                    checkoutdate: daypart.checkoutdate === null ? '-' : moment(new Date(daypart.checkoutdate)).format('DD-MM-YYYY , h:mm:ss a'),
                    checkin_status: checkin_status,
                    remark: daypart.remark === null ? '-' : daypart.remark,
                    status: daypart.status
                }
                places.push(daypart.place)
            }
        }

        for (let i = 0; i < places.length; i++) {
            if (typeof places[i + 1] != 'undefined') {
                if (places[i + 1].checkin_status == 0) {
                    last_checkin_index = i
                    break
                }
            }
        }
        console.log(last_checkin_index)
        // const settings = {
        //     dots: true,
        //     infinite: true,
        //     speed: 500,
        //     slidesToShow: 2,
        //     slidesToScroll: 2,
        //     adaptiveHeight: true
        // };
        console.log(this.state.selectedPlace.latitude)
        console.log(this.state.tourists)
        console.log(this.state.change_requests)

        return (
            <React.Fragment>
                <div className="page-content">
                    <div className="container-fluid">
                        <Breadcrumbs
                            title="Progress"
                            breadcrumbItem={this.props.t("มุมมองข้อมูลใบสั่งงาน")}
                        />
                        <div className="row">
                            <div className="col-12 col-lg-4">
                                <Card>
                                    <CardBody style={{ height: 'auto' }}>
                                        <div className="row">
                                            <div className="col-8">
                                                <div className="text-black ">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <h4 className="text-black" style={{ color: 'black' }}>สถานะ Joborder</h4>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <font className="text-black" style={{ fontSize: 16, fontWeight: 'bold', color: '#224AAC', letterSpacing: 1 }}>{this.state.joborder_code}</font>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <font style={{ fontSize: 16 }}> ล่าสุด {this.state.currentDate} เวลา {this.state.currentTime} น.</font>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <Button onClick={() => {
                                                            const joborderCode = this.state.joborder.code;
                                                            window.open(`https://drjotest.melivecode.com/joborder/paper/${joborderCode}`);
                                                        }} style={{ width: '40%', backgroundColor: '#22AD00', borderColor: '#22AD00', marginLeft: '12px', fontSize: 14 }}>ปริ้นใบสั่งงาน</Button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                {/* {"https://drjotest.melivecode.com/joborder/paper/" + this.state.joborder_code} */}
                                                <div style={{ height: "auto", margin: "0 auto", maxWidth: 120, width: "100%" }}>
                                                    <QRCode
                                                        size={256}
                                                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                        value={"https://drjotest.melivecode.com/joborder/paper/" + this.state.joborder_code}
                                                        viewBox={`0 0 256 256`}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col-12 col-lg-4">
                                <Card>
                                    <CardBody style={{ height: 150 }}>
                                        {/* <div className="row">
                                            <div className="col-10">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <b style={{ color: 'black' }}>สถานที่ท่องเที่ยวทั้งหมด</b>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <font>สถานะวันนี้</font>  <font color="green" > ไปแล้ว </font> <font color="green"> {places_arrive} </font> <font color="red" > ยังไม่ไป </font> <font color="red"> {places.length - places_arrive}</font>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h4 style={{ color: 'black' }}>สถานที่ท่องเที่ยวทั้งหมด {places.length} สถานที่</h4>
                                                    </div>
                                                </div>
                                                <div className="row mt-2" style={{ textAlign: 'center' }}>
                                                    <div className="col-4" >
                                                        <span style={{ fontSize: 24 }}> {places.filter(item => item.checkin_status === 2).length}</span>
                                                    </div>
                                                    <div className="col-4">
                                                        <span style={{ fontSize: 24 }}>   {places.filter(item => item.checkin_status === 1).length}</span>
                                                    </div>
                                                    <div className="col-4">
                                                        <span style={{ fontSize: 24 }}> {places.filter(item => item.status === -1).length}</span>
                                                    </div>
                                                </div>
                                                <div className="row mt-2" style={{ textAlign: 'center' }}>
                                                    <div className="col-4">
                                                        <i className="fa fa-circle" aria-hidden="true" style={{ paddingRight: 5, color: '#85ff00' }}></i>
                                                        <font style={{ fontSize: 16 }}>เช็คเอาท์</font>
                                                    </div>
                                                    <div className="col-4">
                                                        <i className="fa fa-circle" aria-hidden="true" style={{ paddingRight: 5, color: '#ffba00' }}></i>
                                                        <font style={{ fontSize: 16 }}>เช็คอิน</font>
                                                    </div>
                                                    <div className="col-4">
                                                        <i className="fa fa-circle" aria-hidden="true" style={{ paddingRight: 5, color: '#ff0005' }}></i>
                                                        <font style={{ fontSize: 16 }}>ยกเลิก</font>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col-12 col-lg-4">
                                {/* <Card>
                                    <CardBody style={{ minHeight: 104 }}>
                                        {
                                            parseInt(new Date().getTime() / 1000) >= parseInt(new Date(this.state.joborder.joborder_start_date).getTime() / 1000) && parseInt(new Date().getTime() / 1000) <= parseInt(new Date(this.state.joborder.joborder_stop_date).getTime() / 1000) ?
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <b style={{ color: 'black' }}>นักท่องเที่ยวทั้งหมดในแผนการท่องเที่ยว</b>
                                                            </div>
                                                        </div>
                                                        <div className="row" style={{ textAlign: 'center' }}>
                                                            <div className="col-6" >
                                                                <span style={{ fontSize: 16 }}> 0</span>
                                                            </div>
                                                            <div className="col-6">
                                                                <span style={{ fontSize: 16 }}>   0</span>
                                                            </div>
                                                        </div>
                                                        <div className="row" style={{ textAlign: 'center' }}>
                                                            <div className="col-6">
                                                                <i className="fa fa-circle" aria-hidden="true" style={{ paddingRight: 5, color: '#85ff00' }}></i>
                                                                ผ่าน
                                                            </div>
                                                            <div className="col-6">
                                                                <i className="fa fa-circle" aria-hidden="true" style={{ paddingRight: 5, color: '#ff0005' }}></i>
                                                                ไม่ผ่าน
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <b>นักท่องเที่ยวทั้งหมดในแผนการท่องเที่ยว</b>
                                                            </div>
                                                        </div>
                                                        <br />
                                                        <div className="row">

                                                            <div className="col-12">
                                                                <h6> ไม่อยู่ในช่วงเวลาทำงาน  </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </CardBody>
                                </Card> */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-8">
                                <div className="row">
                                    <div className="col-12">
                                        <Card style={{ height: 500 }}>
                                            <CardBody>
                                                <Map
                                                    // ref={m => {
                                                    //    console.log(m)
                                                    //   }}
                                                    className="map"
                                                    google={this.props.google}
                                                    onClick={this.onMapClicked}

                                                    zoom={13}
                                                    // style={{ width: "96%", height: "92%" }}
                                                    // initialCenter={{
                                                    //     lat: this.state.selectedPlace.latitude!== undefined ? this.state.selectedPlace.latitude: places[last_checkin_index].latitude,
                                                    //     lng: this.state.selectedPlace.longitude!== undefined ? this.state.selectedPlace.longitude: places[last_checkin_index].longitude
                                                    // }}
                                                    center={this.centerHandle(places, last_checkin_index)}
                                                    onReady={(mapProps, map) => this._mapLoaded(mapProps, map)}
                                                >
                                                    {/* <Marker
                                                        title={'The marker`s title will appear as a tooltip.'}
                                                        name={'SOMA'}
                                                        position={{
                                                            lat: 13.9656153,
                                                            lng: 100.5839537
                                                        }}
                                                    >
                                                        <InfoWindow
                                                            visible={true}
                                                            position={{
                                                                lat: 13.9656153,
                                                                lng: 100.5839537
                                                            }}
                                                        >
                                                            <div>
                                                                <p>Click on the map or drag the marker to select location where the incident occurred</p>
                                                            </div>
                                                        </InfoWindow>
                                                    </Marker> */}
                                                    {places.map((element, i) => {
                                                        let fillColor = '#ff0005'
                                                        if (element.checkin_status === 1) {
                                                            fillColor = '#ffba00'
                                                        }
                                                        if (element.checkin_status === 2) {
                                                            fillColor = '#85ff00'
                                                        }
                                                        if (element.checkin_status === 0 && element.status === null) {
                                                            fillColor = '#BFBFBF'
                                                        }
                                                        let iconSVG = {
                                                            path: `M13.04,41.77c-0.11-1.29-0.35-3.2-0.99-5.42c-0.91-3.17-4.74-9.54-5.49-10.79c-3.64-6.1-5.46-9.21-5.45-12.07
                                                            c0.03-4.57,2.77-7.72,3.21-8.22c0.52-0.58,4.12-4.47,9.8-4.17c4.73,0.24,7.67,3.23,8.45,4.07c0.47,0.51,3.22,3.61,3.31,8.11
                                                            c0.06,3.01-1.89,6.26-5.78,12.77c-0.18,0.3-4.15,6.95-5.1,10.26c-0.64,2.24-0.89,4.17-1,5.48C13.68,41.78,13.36,41.78,13.04,41.77z
                                                            `,
                                                            fillColor: fillColor,
                                                            fillOpacity: 1,
                                                            strokeColor: '#ffffff',
                                                            strokeWeight: 1,
                                                            anchor: { x: 14, y: 43 },
                                                            labelOrigin: { x: 13.5, y: 15 }
                                                        }
                                                        return (
                                                            <Marker
                                                                key={i}
                                                                // title={'The marker`s title will appear as a tooltip.'}
                                                                // name={'SOMA'}
                                                                position={{
                                                                    lat: element.latitude,
                                                                    lng: element.longitude
                                                                }}
                                                                label={
                                                                    {
                                                                        text: (i + 1).toString(),
                                                                        color: 'black',
                                                                        fontSize: '15px',
                                                                        fontWeight: 'bold',
                                                                    }
                                                                }
                                                                // icon= {{
                                                                //     url: url
                                                                //   }}
                                                                icon={iconSVG}
                                                            >
                                                            </Marker>
                                                        )
                                                    })}
                                                </Map>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-xs-12">
                                <Card style={{ height: 500 }}>
                                    <CardBody>
                                        <div className="row">
                                            <div className="col-12 mb-2">
                                                <h4 style={{ color: 'black' }}>ข้อมูลใบสั่งงาน</h4>
                                            </div>
                                            <div className="col-12 mb-2">
                                                <font style={{ fontSize: 16, color: '#224AAC' }}>ชื่อผู้ประกอบธุรกิจนำเที่ยว : </font> <font style={{ fontSize: 16 }}>{this.state.joborder.agency.name}</font>
                                            </div>
                                            <div className="col-12 mb-2">
                                                <font style={{ fontSize: 16, color: '#224AAC' }}>เลขที่ใบอนุญาต : </font> <font style={{ fontSize: 16 }}>{this.state.joborder.agency.license}</font>
                                            </div>
                                            <div className="col-12">
                                                <font style={{ fontSize: 16, color: '#224AAC' }}>ติดต่อ : </font> <font style={{ fontSize: 16 }}>{this.state.joborder.agency.mobile}</font>
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row">
                                            <div className="col-12 mb-2">
                                                <font style={{ fontSize: 16, color: '#224AAC' }}>ใบสั่งงานเลขที่ : </font> <font style={{ fontSize: 16 }}>{this.state.joborder.code}</font>
                                            </div>
                                            <div className="col-12 mb-2">
                                                <font style={{ fontSize: 16, color: '#224AAC' }}>ระยะเวลา : </font> <font style={{ fontSize: 16 }}>{this.state.joborder.days.length} วัน </font>
                                            </div>
                                            <div className="col-12 mb-2">
                                                <font style={{ fontSize: 16, color: '#224AAC' }}>ประเภททัวร์ : </font> <font style={{ fontSize: 16 }}>N/A</font>
                                            </div>
                                            <div className="col-12 mb-2">
                                                <font style={{ fontSize: 16, color: '#224AAC' }}>นักท่องเที่ยว :  </font> <font style={{ fontSize: 16 }}>{this.state.joborder.tourists.length}  คน</font>
                                            </div>
                                            <div className="col-12 mb-2">
                                                <font style={{ fontSize: 16, color: '#224AAC' }}>วันที่เริ่ม : </font> <font style={{ fontSize: 16 }}>{moment(new Date(this.state.joborder.joborder_start_date)).format('YYYY-MM-DD HH:MM:SS')} น.</font>
                                            </div>
                                            <div className="col-12 mb-2">
                                                <font style={{ fontSize: 16, color: '#224AAC' }}>วันที่จบ : </font> <font style={{ fontSize: 16 }}>{moment(new Date(this.state.joborder.joborder_stop_date)).format('YYYY-MM-DD HH:MM:SS')} น.</font>
                                            </div>
                                        </div>
                                        {/* <div className="row">
                                            <div className="col-6">
                                                <button onClick={() => { this.test() }} className="btn btn-block btn-danger" style={{ backgroundColor: '#BE0000', borderColor: '#BE0000' }}><i className="fas fa-square fa-lg"></i> ระงับการท่องเที่ยว</button>
                                            </div>
                                            <div className="col-6">
                                                <button className="btn btn-block btn-success" style={{ backgroundColor: '#22AC4A', borderColor: '#22AC4A' }}><i className="fas fa-play fa-lg"></i> ดำเนินการต่อ</button>
                                            </div>
                                        </div> */}
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-xs-12">
                                <Card >
                                    <CardBody>
                                        <div className="row">
                                            <div className="col-12">
                                                <h4 style={{ color: 'black' }}>สถานะการทำงานมัคคุเทศก์</h4>
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="row">
                                            {this.state.joborder.guides.map((element, i) => {
                                                return (
                                                    <Col sm={12} xs={12} lg={4} md={4} xl={4} style={{ paddingBottom: 10 }}>
                                                        <div key={i} className="col-12">
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <font style={{ fontSize: 16 }}>สถานะล่าสุด</font>
                                                                    <font className="ml-2">
                                                                        {element.accepted === 1 ? <font style={{ fontSize: 16 }} color="green">(ตอบรับงานแล้ว)</font> : ''}
                                                                        {element.accepted === 0 ? <font style={{ fontSize: 16 }} color="orange">(รอการตอบรับ)</font> : ''}
                                                                        {element.accepted === -1 ? <font style={{ fontSize: 16 }} color="red">(ปฏิเสธการรับงาน)</font> : ''}
                                                                    </font>
                                                                </div>
                                                                {/* <div className="col-6 text-right">
                                                                    <font>
                                                                        {element.accepted === 1 ? <font style={{ fontSize: 16 }} color="green">ตอบรับงานแล้ว</font> : ''}
                                                                        {element.accepted === 0 ? <font style={{ fontSize: 16 }} color="orange">รอการตอบรับ</font> : ''}
                                                                        {element.accepted === -1 ? <font style={{ fontSize: 16 }} color="red">ปฏิเสธการรับงาน</font> : ''}
                                                                    </font>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                        <div className="col-12" >
                                                            <div className="row">
                                                                <div className="row col-12">
                                                                    <div className="col-6"><font style={{ color: '#224AAC', fontSize: 16 }}>ชื่อ:</font> <font style={{ fontSize: 16 }}>{element.fname}</font>  </div>
                                                                    <div className="col-6"><font style={{ color: '#224AAC', fontSize: 16 }}>นามสกุล: {element.lname}</font> </div>
                                                                </div>
                                                                <div className="row col-12">
                                                                    <div className="col-6"><font style={{ color: '#224AAC', fontSize: 16 }}>ใบอนุญาต:</font> <font style={{ fontSize: 16 }}>{element.guide_license}</font></div>
                                                                    <div className="col-6"><font style={{ color: '#224AAC', fontSize: 16 }}>โทรศัพท์:</font> <font style={{ fontSize: 16 }}>{element.mobile}</font></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                )
                                            })}
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col-12">
                                <Card>
                                    <CardBody style={{ padding: 25 }}>
                                        <h4 className="mb-2" style={{ color: 'black' }}>สถานที่ท่องเที่ยวในแผนการท่องเที่ยว</h4>
                                        {/* <Row style={{ paddingBottom: 10 }}>
                                                    <Col xl={3} lg={3} md={6} sm={12} xs={12}>
                                                        <FormGroup className="select2-container">
                                                            <Label>วันที่ต้องการ :</Label>
                                                            <Select
                                                                value={selectedDate}
                                                                onChange={this.handleselectedDate}
                                                                // options={optionGroup}
                                                                classNamePrefix="select2-selection"
                                                            />

                                                        </FormGroup>
                                                    </Col>
                                                    <Col xl={3} lg={3} md={6} sm={12} xs={12}>
                                                        <FormGroup className="select2-container">
                                                            <Label>วันที่ต้องการ :</Label>
                                                            <Select
                                                                value={selectedTime}
                                                                onChange={this.handleselectedTime}
                                                                // options={optionGroup1}
                                                                classNamePrefix="select2-selection"
                                                            />

                                                        </FormGroup>
                                                    </Col>
                                                    <Col xl={4} lg={3} md={6} sm={12} xs={12}>
                                                        <FormGroup className="select2-container">
                                                            <Label>สถานนะเช็คสถานะการทำงานมัคคุเทศก์ :</Label>
                                                            <Select
                                                                value={statuscheckin}
                                                                onChange={this.handlestatuscheckin}
                                                                // options={optionGroup2}
                                                                classNamePrefix="select2-selection"
                                                            />

                                                        </FormGroup>
                                                    </Col>
                                                </Row> */}
                                        <DataGrid
                                            dataSource={places}
                                            allowColumnReordering={true}
                                            allowColumnResizing={true}
                                            showBorders={true}
                                            onRowClick={(e) => {
                                                this.setState({
                                                    selectedPlace: e.data
                                                }, () => {
                                                    console.log(this.state.selectedPlace)
                                                })
                                            }}
                                        >
                                            <Paging defaultPageSize={10} />
                                            <Pager
                                                showPageSizeSelector={true}
                                                allowedPageSizes={[5, 10, 20]}
                                                showInfo={true} />
                                            <Column
                                                caption="ลำดับ"
                                                dataField="place_no"
                                                width={50}
                                                defaultSortOrder={'asc'}
                                            />
                                            <Column
                                                caption="ชื่อสถานที่"
                                                dataField="name"
                                                width={200}
                                            />
                                            <Column
                                                caption="วันที่"
                                                dataField="day"
                                                width={100}
                                            />
                                            <Column
                                                caption="ลำดับวัน"
                                                dataField="dayno"
                                                width={50}
                                            />
                                            <Column
                                                caption="ช่วงวัน"
                                                dataField="daypart"
                                                width={80}
                                            />
                                            <Column
                                                caption="เวลาเช็คอิน"
                                                dataField="checkindate"
                                            />
                                            <Column
                                                caption="เวลาเช็คเอ้าท์"
                                                dataField="checkoutdate"
                                            />
                                            <Column
                                                caption="สถานะ"
                                                dataField="checkin_status"
                                                cellRender={(data) => {
                                                    // var output = ""
                                                    if (data.data.status === -1) {
                                                        return <div>
                                                            <font color="red"> ยกเลิก </font>
                                                        </div>
                                                    }
                                                    if (data.data.checkin_status === 2) {
                                                        return <div>
                                                            <font color="green"> เช็คเอาท์ </font>
                                                        </div>
                                                    }
                                                    if (data.data.checkin_status === 1) {
                                                        return <div>
                                                            <font color="orange"> เช็คอิน </font>
                                                        </div>
                                                    }
                                                    if (data.data.checkin_status === 0) {
                                                        return <div>
                                                            <font color="gray"> ยังไม่ดำเนินการ </font>
                                                        </div>
                                                    }
                                                }}
                                            />
                                            <Column
                                                caption="หมายเหตุ"
                                                dataField="remark"
                                            />
                                        </DataGrid>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-xs-12">
                                <Card>
                                    <CardBody style={{ padding: 25 }}>
                                        <h4 style={{ color: 'black' }}>รายชื่อนักท่องเที่ยวทั้งหมด</h4>
                                        <DataGrid
                                            dataSource={this.state.tourists}
                                            allowColumnReordering={true}
                                            allowColumnResizing={true}
                                            showBorders={true}
                                        >
                                            <Paging defaultPageSize={50} />
                                            <Pager
                                                showPageSizeSelector={true}
                                                allowedPageSizes={[5, 10, 20]}
                                                showInfo={true} />
                                            <Column
                                                caption="ลำดับ"
                                                dataField="no"
                                            />
                                            <Column
                                                caption="Passport"
                                                dataField="passport"
                                            />
                                            <Column
                                                caption="ชื่อ"
                                                dataField="fname"
                                            />
                                            <Column
                                                caption="นามสกุล"
                                                dataField="lname"
                                            />
                                            <Column
                                                caption="ประเทศ"
                                                dataField="origincountry"
                                            />


                                        </DataGrid>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-xs-12">
                                <Card>
                                    <CardBody style={{ padding: 25 }}>
                                        <h4 style={{ color: 'black' }}>การเปลี่ยนแปลงข้อมูล</h4>
                                        {this.state.change_requests ? ( // ตรวจสอบว่า this.state.change_requests มีค่าหรือไม่
                                            <DataGrid
                                                dataSource={this.state.change_requests}
                                                allowColumnReordering={true}
                                                allowColumnResizing={true}
                                                showBorders={true}
                                            >
                                                <Paging defaultPageSize={50} />
                                                <Pager
                                                    showPageSizeSelector={true}
                                                    allowedPageSizes={[5, 10, 20]}
                                                    showInfo={true}
                                                />
                                                <Column
                                                    caption="วันที่"
                                                    dataField="date"
                                                />
                                                <Column
                                                    caption="เวลา"
                                                    dataField="time"
                                                />
                                                <Column
                                                    caption="รูปภาพ"
                                                    cellRender={(data) => {
                                                        const changeRequest = this.state.joborder.change_requests[data.rowIndex];
                                                        const imageUrls = changeRequest.images.split(';');
                                                        const basePath = 'https://drjotest-api.melivecode.com/';
                                                        // console.log("ข้อมูลทั้งหมด", imageUrls);
                                                        // ใช้ filter เพื่อกรองรูปภาพที่ไม่ว่าง
                                                        const validImages = imageUrls.filter((imageUrl) => imageUrl !== "");

                                                        const images = validImages.map((imageUrl, index) => (
                                                            <img
                                                                key={index}
                                                                src={basePath.concat(imageUrl)}
                                                                alt={`${index}`}
                                                                style={{ width: '50px', height: 'auto', marginRight: '5px' }}
                                                            />
                                                        ));
                                                        // console.log("ข้อมูลที่มีรูป", validImages);
                                                        return <div>{images}</div>;
                                                    }}
                                                />
                                                <Column
                                                    caption="remark"
                                                    dataField="remark"
                                                />
                                            </DataGrid>
                                        ) : (
                                            <p style={{ fontSize: 16 }}>ไม่มีข้อมูลการเปลี่ยนแปลง</p>
                                        )}
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col-lg-12 col-xs-12">
                                <Card>
                                    <CardBody style={{ padding: 25 }}>
                                        <h4 style={{ color: 'black' }}>รายการตรวจวัดอุณหภูมินักท่องเที่ยวทั้งหมด</h4>
                                        <Row style={{ overflowX: 'visible' }}>
                                            <Col style={{ borderRightStyle: 'solid', borderRightColor: 'gray', borderRightWidth: 2, minWidth: 200 }}>
                                                <font style={{ fontSize: 16 }}>รายชื่อ</font>
                                            </Col>
                                            {temperature_check.map((e, i) => {
                                                if (i > 0) return <></>
                                                return (
                                                    <>
                                                        {e.day.map((e1, j) => {
                                                            return (
                                                                <Col style={{ borderRightStyle: e.day.length === j + 1 ? null : 'solid', borderRightColor: 'gray', borderRightWidth: 2 }}>
                                                                    <font style={{ fontSize: 16 }}>วันที่ {j + 1}</font>
                                                                </Col>
                                                            )
                                                        })}
                                                    </>)
                                            })}
                                        </Row>
                                        {temperature_check.map((e, i) => {
                                            return (
                                                <Row style={{ overflowX: 'visible' }}>
                                                    <Col style={{ borderRightStyle: 'solid', borderRightColor: 'gray', borderRightWidth: 2, minWidth: 200 }}>
                                                        <font style={{ fontSize: 16 }}>{e.name}</font>
                                                    </Col>
                                                    {e.day.map((e1, j) => {
                                                        return (
                                                            <Col style={{ borderRightStyle: e.day.length === j + 1 ? null : 'solid', borderRightColor: 'gray', borderRightWidth: 2 }}>
                                                                {(e1.status === 0 && e1.remark === 'ยังไม่ระบุ') && <span style={{ fontSize: 16 }}>ยังไม่ระบุ</span>}
                                                                {e1.status === 1 && <span style={{ color: 'green', fontSize: 16 }}>ปกติ</span>}
                                                                {(e1.status === 0 && e1.remark !== 'ยังไม่ระบุ') && <span style={{ color: 'red', fontSize: 16 }}>ผิดปกติ ({e1.remark})</span>}
                                                            </Col>
                                                        )
                                                    })}
                                                </Row>
                                            )
                                        })}
                                        {/* <DataGrid
                                            dataSource={temperature_check}
                                            allowColumnReordering={true}
                                            allowColumnResizing={true}
                                            showBorders={true}
                                        >
                                            <Paging defaultPageSize={50} />
                                            <Pager
                                                showPageSizeSelector={true}
                                                allowedPageSizes={[5, 10, 20]}
                                                showInfo={true} />
                                                {temperature_check.map((e,i)=>{
                                                    console.log(e)
                                                    return(
                                                        <Column
                                                        caption='Name'
                                                        cellRender={this.columnRender}
                                                    />
                                                    )
                                                })}
                                            <Column
                                                caption="ลำดับ"
                                                dataField="no"
                                            />
                                            <Column
                                                caption="Passport"
                                                dataField="passport"
                                            />
                                            <Column
                                                caption="ชื่อ"
                                                dataField="fname"
                                            />
                                            <Column
                                                caption="นามสกุล"
                                                dataField="lname"
                                            />
                                            <Column
                                                caption="ประเทศ"
                                                dataField="origincountry"
                                            />
                                        </DataGrid> */}
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                        {this.state.policysets === undefined || this.state.policysets.length === 0 ? (
                            <div className="row">
                                <div className="col-lg-6 col-xs-12">
                                    <Card>
                                        <CardBody style={{ padding: 25 }}>
                                            <h4 style={{ color: 'black' }}>รายชื่อกรมธรรม์ประกันอุบัติเหตุ</h4>
                                            <p style={{ fontSize: 16 }}>ไม่มีผู้รับกรมธรรม์ประกันอุบัติเหตุ</p>
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className="col-lg-6 col-xs-12">
                                    <Card>
                                        <CardBody style={{ padding: 25 }}>
                                            <h4 style={{ color: 'black' }}>เอกสารอัพโหลดข้อมูลกรมธรรม์</h4>
                                            <p style={{ fontSize: 16 }}>ไม่มีข้อมูลเอกสารกรมธรรม์</p>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                        ) :
                            <div className="row">
                                <div className="col-lg-6 col-xs-12">
                                    <Card>
                                        <CardBody style={{ padding: 25 }}>
                                            <h4 style={{ color: 'black' }}>รายชื่อกรมธรรม์ประกันอุบัติเหตุ</h4>
                                            {this.state.policysets.map((e, i) => {
                                                const displayInsurer = this.state.insurerName.find((insurer) => insurer.id === e.insurer_id);
                                                return (
                                                    <>
                                                        <div key={i}>
                                                            <h5 className="mt-2">กรมธรรม์อุบัติเหตุ ชุดที่ {e.policy_set_sequence_number}</h5>
                                                            <li style={{ fontSize: 16 }}>
                                                                ชื่อบริษัทผู้รับประกันภัย : {displayInsurer ? displayInsurer.name : 'Insurer not found'}
                                                            </li>
                                                            <li style={{ fontSize: 16 }}>
                                                                วัน/เดือน/ปีที่นำส่งรายชื่อผู้เอาประกันภัยให้แก่ผู้รับประกันภัย : {moment(e.delivery_date).format('DD-MM-YYYY')}
                                                            </li>
                                                            <li style={{ fontSize: 16 }}>
                                                                จำนวนผู้เอาประกัน : {e.tourists.length} คน
                                                            </li>
                                                            <li style={{ fontSize: 16 }}>
                                                                มีระยะเวลาประกัน เริ่มต้นตั้งแต่วันที่ : {moment(e.policy_start_date).format('DD-MM-YYYY')} สิ้นสุดวันที่ : {moment(e.policy_end_date).format('DD-MM-YYYY')}
                                                            </li>
                                                        </div>
                                                        <hr />
                                                    </>
                                                );
                                            })}
                                        </CardBody>
                                    </Card>
                                </div>
                                {this.state.policy_pdfs === null || this.state.policy_pdfs.length === 0 ? (
                                    <div className="col-lg-6 col-xs-12">
                                        <Card>
                                            <CardBody style={{ padding: 25 }}>
                                                <h4 style={{ color: 'black' }}>เอกสารอัพโหลดข้อมูลกรมธรรม์</h4>
                                                <p style={{ fontSize: 16 }}>ไม่มีข้อมูลเอกสารกรมธรรม์</p>
                                            </CardBody>
                                        </Card>
                                    </div>
                                ) :
                                    <div className="col-lg-6 col-xs-12">
                                        <Card>
                                            <CardBody style={{ padding: 25 }}>
                                                <h4 style={{ color: 'black' }}>เอกสารอัพโหลดข้อมูลกรมธรรม์</h4>
                                                {this.state.policy_pdfs.map((path, i) => {
                                                    // console.log("path", path)
                                                    return (
                                                        <div className="row align-items-center mt-3" style={{ paddingLeft: 13 }} key={i}>
                                                            <h5>กรมธรรม์อุบัติเหตุชุดที่ {i + 1}</h5>
                                                            <button className="btn btn-primary ml-3" style={{ fontSize: 14 }}
                                                                onClick={() => window.open(`https://drjotest.melivecode.com/upload${path}`, '_blank')}>ดูข้อมูล</button>
                                                        </div>
                                                    );
                                                })}
                                            </CardBody>
                                        </Card>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
                {/* <Card>
                                    <CardBody style={{ padding: 25 }}>
                                        <h6>บันทึกกิจกรรมทั้งหมดในการท่องเที่ยว</h6>
                                        <DataGrid
                                            dataSource={[{
                                                id: 1,
                                                action: 'เข้าสู่สถานที่',
                                                message: 'วัดอะไรสักที่',
                                                remark: ''

                                            }]}
                                            allowColumnReordering={true}
                                            allowColumnResizing={true}
                                            columnAutoWidth={true}
                                            showBorders={true}
                                        >
                                            <Column
                                                caption="ลำดับ"
                                                dataField="id"
                                            />
                                            <Column
                                                caption="ชื่อสถานที่"
                                                dataField="action"
                                            />
                                            <Column
                                                caption="ข้อความ"
                                                dataField="message"
                                            />
                                            <Column
                                                caption="หมายเหตุ"
                                                dataField="remark"
                                            />
                                        </DataGrid>
                                    </CardBody>
                                </Card> */}
            </React.Fragment >
        );
    }
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyD0AIarEA25PY-NvHNsZDAVqM3qa8krHfs",
    libraries: ["visualization"],
    LoadingContainer: LoadingContainer,
})(withTranslation()(JoborderProgress))
