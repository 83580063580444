import React, { Component } from "react";
import { Button } from 'reactstrap';
import { Link } from "react-router-dom";
import '../../pages/Introduction/intro.css'
//i18n
import { withTranslation } from 'react-i18next';
import './intro.css'
class BodyIntro extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content-intro">
                    <div className="contain-body">
                        <div className="content-intro">
                            <div className="content-row">
                                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="sign-in">
                                        <p className="font-style-20-bold">ลงชื่อเข้าใช้สำหรับ <span style={{ color: '#224aac' }}>ผู้ประกอบการท่องเที่ยว / เจ้าหน้าที่กรมการท่องเที่ยว</span></p>
                                        <Link to={{ pathname: "/login" }}>
                                            <Button className="style-button">เข้าสู่ระบบ</Button>
                                        </Link>
                                    </div>
                                    <div className="call-center-phone">
                                        <p>Call center : 021065315</p>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="manual">
                                        <div className="manual-pdf">
                                            <img src={'/assets/images/others/PDF.png'} alt={'pdf'} />
                                            <h6 style={{ fontSize: 12, marginTop: 10 }}>ดาวน์โหลดคู่มือ</h6>
                                            <a href={'/assets/images/manual/01_manual_tour.pdf'}><h6 style={{ fontSize: 12, color: '#224aac' }}>ผุ้ประกอบการ</h6></a>
                                        </div>
                                        <div className="video-mb">
                                            <p className="font-style-18-bold">วิดีโอคู่มือ <span style={{ color: '#224aac' }}>ผู้ประกอบการ</span></p>
                                            <video className="video-style" controls poster={'/assets/images/others/manual_tour.png'}>
                                                <source src={'/assets/images/video/01_video_tour.mp4'} type="video/mp4" />
                                            </video>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="hr-intro"></hr>
                            <div className="content-row">
                                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="download-guide">
                                        <p className="font-style-20-bold">ดาวน์โหลดแอปสำหรับ <span style={{ color: '#224aac' }}>มัคคุเทศก์</span></p>
                                        <div className="qr-guide">
                                            {/* <div className="style-qr-code"> */}
                                            <img src={'/assets/images/others/joborder-google.png'} alt={'qr-google'} />
                                            <img src={'/assets/images/others/joborder-appstore.png'} alt={'qr-appstore'} />
                                            {/* </div> */}
                                            {/* <div className="download">
                                                <p className="font-style-18-bold">Download Mobile App</p>
                                                <p className="font-style-30-bold">Scan QR Now</p>
                                                <div className="img-store">
                                                    <img style={{ marginRight: 10 }} src={'/assets/images/others/apple_store.png'} alt={'apple_store'} />
                                                    <img src={'/assets/images/others/play_store.png'} alt={'play_store'} />
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="manual">
                                        <div className="manual-pdf">
                                            <img src={'/assets/images/others/PDF.png'} alt={'pdf'} />
                                            <h6 style={{ fontSize: 12, marginTop: 10 }}>ดาวน์โหลดคู่มือ</h6>
                                            <a href={'/assets/images/manual/02_manual_guide.pdf'}><h6 style={{ fontSize: 12, color: '#224aac' }}>มัคคุเทศก์</h6></a>
                                        </div>
                                        <div className="video-mb">
                                            <p className="font-style-18-bold">วิดีโอคู่มือ <span style={{ color: '#224aac' }}>มัคคุเทศก์</span></p>
                                            <video className="video-style" controls poster={'/assets/images/others/manual_guide.png'}>
                                                <source src={'/assets/images/video/02_video_guide.mp4'} type="video/mp4" />
                                            </video>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="hr-intro"></hr>
                            <div className="content-row-no-br">
                                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="download-police">
                                        <p className="font-style-20-bold">ดาวน์โหลดแอปสำหรับ <span style={{ color: '#224aac' }}>พนักงานเจ้าหน้าที่</span></p>
                                        <div className="qr-police">
                                            <img src={'/assets/images/others/verify-google.png'} alt={'qr-police'} />
                                            <img src={'/assets/images/others/verify-ios.png'} alt={'qr-police'} />
                                            {/* <div className="download">
                                                <p className="font-style-18-bold">Download Mobile App</p>
                                                <p className="font-style-30-bold">Scan QR Now</p>
                                                <div className="img-store">
                                                    <img style={{ marginRight: 10 }} src={'/assets/images/others/apple_store.png'} alt={'apple_store'} />
                                                    <img src={'/assets/images/others/play_store.png'} alt={'play_store'} />
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="manual">
                                        <div className="manual-pdf">
                                            <img src={'/assets/images/others/PDF.png'} alt={'pdf'} />
                                            <h6 style={{ fontSize: 12, marginTop: 10 }}>ดาวน์โหลดคู่มือ</h6>
                                            <a href={'/assets/images/manual/03_manual_police.pdf'}><h6 style={{ fontSize: 12, color: '#224aac' }}>พนักงานเจ้าหน้าที่</h6></a>
                                        </div>
                                        <div className="video-mb">
                                            <p className="font-style-18-bold">วิดีโอคู่มือ <span style={{ color: '#224aac' }}>พนักงานเจ้าหน้าที่</span></p>
                                            <video className="video-style" controls poster={'/assets/images/others/manual_police.png'}>
                                                <source src={'/assets/images/video/03_video_police.mp4'} type="video/mp4" />
                                            </video>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <aside className="left-sidebar">
                            <img src={'/assets/images/others/background-left.png'} alt={'background-left'} />
                        </aside>
                        <aside className="right-sidebar">
                            <img src={'/assets/images/others/background-right.png'} alt={'background-right'} />
                        </aside>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}

export default (withTranslation()(BodyIntro));
