import React, { Component } from 'react'
import { Row, Col } from "reactstrap";
// import Swal from 'sweetalert2'
import { withTranslation } from 'react-i18next';
class TypeTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: props.type === 'data-relationship' ? 'country-attraction' : 'country'
        }
    };

    submitHandle(value) {
        this.props.callback('filter', value)
    }

    componentDidMount() {
        this.props.callback('filter', this.state.filter)
    }

    render() {
        if (this.props.type === 'tourists-report') {
            return (
                <Row className="mb-3" >
                    <Col xl={2} lg={2} md={2} sm={2} style={{ display: 'inline-flex', alignSelf: 'center' }}>
                        <span style={{ fontSize: 16 }}>{this.props.t('Choose format')} : </span>
                    </Col>
                    <Col xl={10} lg={10} md={10} sm={10} style={{ display: 'inline-flex', overflow: 'auto' }}>
                        <button className={this.state.filter === 'country' ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'}
                            style={{ whiteSpace: 'nowrap', textAlign: 'center' }}
                            onClick={() => {
                                this.setState({
                                    filter: 'country',
                                }, () => {
                                    this.submitHandle('country')
                                })
                            }}>
                            {this.props.t('Country')}
                        </button>
                        <button style={{ marginLeft: 20, whiteSpace: 'nowrap', textAlign: 'center' }} className={this.state.filter === 'attraction' ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'}
                            onClick={() => {
                                this.setState({
                                    filter: 'attraction',
                                }, () => {
                                    this.submitHandle('attraction')
                                })
                            }}>
                            {this.props.t('Attractions')}
                        </button>
                        <button style={{ marginLeft: 20, whiteSpace: 'nowrap', textAlign: 'center' }} className={this.state.filter === 'accommodation' ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'}
                            onClick={() => {
                                this.setState({
                                    filter: 'accommodation',
                                }, () => {
                                    this.submitHandle('accommodation')
                                })
                            }}>
                            {this.props.t('Hotel or Accommodation')}
                        </button>
                        {/* <button style={{ marginLeft: 20, whiteSpace: 'nowrap', textAlign: 'center' }} className={this.state.filter === 'guide' ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'}
                            onClick={() => {
                                this.setState({
                                    filter: 'guide',
                                }, () => {
                                    this.submitHandle('guide')
                                })
                            }}>
                            {this.props.t('Guide')}
                        </button>
                        <button style={{ marginLeft: 20, whiteSpace: 'nowrap', textAlign: 'center' }} className={this.state.filter === 'agency' ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'}
                            onClick={() => {
                                this.setState({
                                    filter: 'agency',
                                }, () => {
                                    this.submitHandle('agency')
                                })
                            }}>
                            {this.props.t('Tour operator')}
                        </button> */}
                    </Col>
                </Row>
            )
        }
        if (this.props.type === 'tourism-trends') {
            return (
                <Row className="mb-3" >
                    <Col xl={2} lg={2} md={2} sm={2} style={{ display: 'inline-flex', alignSelf: 'center' }}>
                        <span style={{ fontSize: 16 }}>{this.props.t('Choose format')} : </span>
                    </Col>
                    <Col xl={10} lg={10} md={10} sm={10} style={{ display: 'inline-flex', overflow: 'auto' }}>
                        <button className={this.state.filter === 'country' ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'}
                            style={{ whiteSpace: 'nowrap', textAlign: 'center' }}
                            onClick={() => {
                                this.setState({
                                    filter: 'country',
                                }, () => {
                                    this.submitHandle('country')
                                })
                            }}>
                            {this.props.t('Country')}
                        </button>
                        <button style={{ marginLeft: 20, whiteSpace: 'nowrap', textAlign: 'center' }} className={this.state.filter === 'attraction' ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'}
                            onClick={() => {
                                this.setState({
                                    filter: 'attraction',
                                }, () => {
                                    this.submitHandle('attraction')
                                })
                            }}>
                            {this.props.t('Attractions')}
                        </button>
                        <button style={{ marginLeft: 20, whiteSpace: 'nowrap', textAlign: 'center' }} className={this.state.filter === 'accommodation' ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'}
                            onClick={() => {
                                this.setState({
                                    filter: 'accommodation',
                                }, () => {
                                    this.submitHandle('accommodation')
                                })
                            }}>
                            {this.props.t('Hotel or Accommodation')}
                        </button>
                    </Col>
                </Row>
            )
        }
        if (this.props.type === 'data-relationship') {
            return (
                <Row className="mb-3" >
                    <Col xl={2} lg={2} md={2} sm={2} style={{ display: 'inline-flex', alignSelf: 'center' }}>
                        <span style={{ fontSize: 16 }}>{this.props.t('Choose format')} : </span>
                    </Col>
                    <Col xl={10} lg={10} md={10} sm={10} style={{ display: 'inline-flex', overflow: 'auto' }}>
                        <button className={this.state.filter === 'country-attraction' ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'}
                            style={{ whiteSpace: 'nowrap', textAlign: 'center' }}
                            onClick={() => {
                                this.setState({
                                    filter: 'country-attraction',
                                }, () => {
                                    this.submitHandle('country-attraction')
                                })
                            }}>
                            {this.props.t('Country - Attractions')}
                        </button>
                        <button style={{ marginLeft: 20, whiteSpace: 'nowrap', textAlign: 'center' }} className={this.state.filter === 'attraction-country' ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'}
                            onClick={() => {
                                this.setState({
                                    filter: 'attraction-country',
                                }, () => {
                                    this.submitHandle('attraction-country')
                                })
                            }}>
                            {this.props.t('Attractions - Country')}
                        </button>
                        <button style={{ marginLeft: 20, whiteSpace: 'nowrap', textAlign: 'center' }} className={this.state.filter === 'country-accommodation' ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'}
                            onClick={() => {
                                this.setState({
                                    filter: 'country-accommodation',
                                }, () => {
                                    this.submitHandle('country-accommodation')
                                })
                            }}>
                            {this.props.t('Country - Hotel/Accommodation')}
                        </button>
                        <button style={{ marginLeft: 20, whiteSpace: 'nowrap', textAlign: 'center' }} className={this.state.filter === 'accommodation-country' ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'}
                            onClick={() => {
                                this.setState({
                                    filter: 'accommodation-country',
                                }, () => {
                                    this.submitHandle('accommodation-country')
                                })
                            }}>
                            {this.props.t('Hotel/Accommodation - Country')}
                        </button>
                    </Col>
                </Row>
            )
        }
        else {
            return (
                null
            )
        }
    }
}
export default (withTranslation()(TypeTab))