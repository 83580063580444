import React, { Component } from 'react';

import {
  Alert,
} from "reactstrap";



// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// actions
import { loginUser, apiError } from '../../store/actions';




// import images
// import profile from "../../assets/images/profile-img.png";
// import logo from "../../assets/images/logo.svg";
// import login1 from "../../assets/images/icon/login.png"
// import imglogin from "../../assets/images/small/bgloginnew.png"
// import logincard from "../../assets/images/icon/login-card.svg"
// import imgkrom from "../../assets/images/small/logokrom.png"
// import imgcard from "../../assets/images/small/Logins.svg"
// import thai from "../../assets/images/icon/thai.svg"
// import engGray from "../../assets/images/icon/eng-gray.svg"


import "../Authentication/Login.scss"
import { withTranslation } from 'react-i18next';
import ConsentPopup from "./ConsentPopup.js"




class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isConsentPopupOpen: false,
      formValues: {},
      isLoggedIn: false, // New login status flag
    };

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleConsentConfirm = this.handleConsentConfirm.bind(this);
    this.handleConsentCancel = this.handleConsentCancel.bind(this);

  }

  // handleValidSubmit
  handleValidSubmit(event, values) {
    // Show the consent popup when the form is submitted
    this.setState({ isConsentPopupOpen: true, formValues: values });
  }




  handleConsentConfirm() {
    // Close the consent popup and proceed with login
    this.setState({ isConsentPopupOpen: false });
    this.props.loginUser(this.state.formValues, this.props.history);
    console.log("this.state.formValues", this.state.formValues);
    console.log("application_id", this.props.history.application_id);
  }

  handleConsentCancel = () => {
    // Close the consent popup without proceeding with login
    this.setState({ isConsentPopupOpen: false, hasDisagreed: true });
  };



  componentDidMount() {
    this.props.apiError("");
  }

  render() {
    return (
      <React.Fragment>
        <div className="login-page">
          <div className="style-login">
            <div className="box-flex-1">
              <div className="box-logo">
                <img
                  className="logo-img"
                  src={"/assets/images/icon/login.png"}
                  alt=""
                />
              </div>
              <div>
                <button
                  onClick={() => {
                    return (window.location = "/joborder_search");
                  }}
                  className="btn btn-primary waves-effect waves-light bt-style-joborder"
                >
                  <span className="normal-text-18 cl-gray">
                    ค้นหาใบสั่งงานมัคคุเทศก์
                    <img
                      src={"/assets/images/icon/arrow-right.svg"}
                      style={{ marginLeft: 10 }}
                      alt=""
                    />
                  </span>
                </button>
              </div>
            </div>
            <div className="box-flex-2">
              <div className="card-login">
                <span className="card-text">{this.props.t("เข้าสู่ระบบ")}</span>
                <div className="style-card-login">
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={this.handleValidSubmit}
                  >
                    {this.props.error && this.props.error ? (
                      <Alert color="danger">{this.props.error}</Alert>
                    ) : null}
                    <div className="form-group normal-text">
                      <AvField
                        name="username"
                        label="ชื่อผู้ใช้งาน"
                        value=""
                        className="form-control"
                        placeholder="กรอกชื่อผู้ใช้งาน"
                        type="text"
                        required
                      />
                    </div>
                    <div className="form-group normal-text">
                      <AvField
                        name="password"
                        label="รหัสผ่าน"
                        value=""
                        type="password"
                        required
                        placeholder="กรอกรหัสผ่าน"
                      />
                    </div>
                    <div className="row" style={{ marginBottom: "1rem" }}>
                      <div className="col-lg-6 col-6">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customControlInline"
                          />
                          <label
                            className="custom-control-label normal-text"
                            htmlFor="customControlInline"
                          >
                            จดจำฉัน
                          </label>
                        </div>
                      </div>
                      <div
                        className="col-lg-6 col-6"
                        style={{ textAlign: "right" }}
                      >
                        <Link
                          to="/forgot-password"
                          className="normal-text"
                          style={{ color: "#224AAC" }}
                        >
                          {" "}
                          ลืมรหัสผ่าน?
                        </Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <label className="normal-text">
                          วิธีการลงชื่อเข้าระบบ
                        </label>
                        <label>
                          - Username เป็นเลขใบอนุญาต <br></br>เช่น 52/99999 ให้
                          login ด้วย 52/99999
                        </label>
                        <label>
                          - Password เป็นเลขนิติบุคคลหรือเลขบัตรประชาชน{" "}
                          <br></br> เช่น 0105559999999
                        </label>
                      </div>
                    </div>
                    <div className="">
                      <button
                        className="btn btn-primary btn-block waves-effect waves-light"
                        style={{ marginTop: 40 }}
                        type="submit"
                        onClick={() =>
                          this.setState({ isConsentPopupOpen: true })
                        }
                      >
                        <span className="normal-text-18">เข้าสู่ระบบ</span>
                      </button>
                    </div>
                    {/* <div className="row" style={{ display: 'flex', justifyContent: 'center', marginTop: 40, textAlign: 'center' }}>
                                            <label className="normal-text text-align" style={{ color: 'black' }}>
                                                <img className="icon-thai" src={'/assets/images/icon/thai.svg'} alt=""></img>
                                                <span className="normal-text">ไทย</span> /
                                                <img className="icon-eng-gray" style={{ marginLeft: 5 }} src={'/assets/images/icon/eng-gray.svg'} alt=""></img>
                                                <span className="normal-text">Eng</span></label>
                                        </div> */}
                    {/* <div className="row" style={{ justifyContent: 'center', marginTop: 16, textAlign: 'center' }}>
                                            <label className="normal-text text-align">{this.props.t('หากคุณยังไม่ได้เป็นสมาชิก')} <span className="normal-text" style={{ color: '#FFBF00', cursor: 'pointer' }} ><Link to={"register"}>{this.props.t('สมัครสมาชิก')}</Link></span> <span className="normal-text">{this.props.t('ได้ที่นี่')}</span></label>
                                        </div> */}
                  </AvForm>
                </div>
              </div>
            </div>
            {/* <button className="btn btn-primary waves-effect waves-light bt-style-joborder">
                            <span className="normal-text-18 cl-gray">
                                ค้นหา Joborder
                                <img src={"/assets/images/icon/arrow-right.svg"} style={{ marginLeft: 10 }} alt="" /></span>
                        </button> */}
          </div>

          <ConsentPopup
            isOpen={this.state.isConsentPopupOpen}
            onConfirm={this.handleConsentConfirm}
            onCancel={this.handleConsentCancel}
          />

        </div>
        {/* <Container >
                        <div className="justify-content-center row">

                            <Col md={12} xs={12} lg={6} xl={7} style={{ alignSelf: 'center', marginTop: 110 }}>
                                <div style={{ justifyContent: 'center' }}>
                                    <img src={login1} alt="" width="500px" />
                                </div>

                            </Col>
                            <Col md={6} xd={6} lg={6} xl={5} style={{ marginTop: 130 }}>
                                <Card className="overflow-hidden">
                                    <div style={{ backgroundColor: '#224AAC' }}>
                                        <Row>
                                            <Col className="col-8">
                                                <div className="text-primary p-4">
                                                    <h3 style={{ color: 'white' }}>ยินดีต้อนรับ</h3>

                                                </div>
                                            </Col>
                                            <Col className="align-self-end">
                                                <img src={logincard} alt="" className="img-fluid" />
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-0">
                                        <div>
                                            <Link to="/">
                                                <div className="avatar-md profile-user-wid mb-4">
                                                    <span className="avatar-title rounded-circle bg-light">
                                                        <img src={logo} alt="" className="rounded-circle" height="34" />
                                                    </span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="p-2">

                                            <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>
                                                {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                                                <div className="form-group">
                                                    <AvField name="username" label="Username" value="" className="form-control" placeholder="Enter Username" type="text" required />
                                                </div>
                                                <div className="form-group">
                                                    <AvField name="password" label="Password" value="" type="password" required placeholder="Enter Password" />
                                                </div>
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="customControlInline" />
                                                    <label className="custom-control-label" htmlFor="customControlInline">Remember me</label>
                                                </div>
                                                <div className="text-right">
                                                    <Link to="/forgot-password" style={{ color: '#224AAC' }}> Forgot your password?</Link>
                                                </div>
                                                <div className="mt-3">
                                                    <button className="btn btn-primary btn-block waves-effect waves-light" type="submit">Log In</button>
                                                </div>
                                                <div className="mt-3">
                                                                <Link to="register" className="font-weight-medium text-primary"> <button className="btn btn-warning btn-block waves-effect waves-light" >Sig up</button> </Link>
                                                            </div>
                                                <div className="mt-3">
                                                    <Link to="Regis" className="font-weight-medium text-primary"> <button className="btn btn-warning btn-block waves-effect waves-light" >Register</button> </Link>
                                                </div>
                                            </AvForm>
                                        </div>
                                    </CardBody>
                                </Card>                               
                            </Col>

                        </div>
                    </Container> */}
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  const { error } = state.Login;
  return { error };
}

export default withRouter(connect(mapStatetoProps, { loginUser, apiError })(withTranslation()(Login)));

