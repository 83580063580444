import React, { Component } from 'react'
import { API_ENDPOINT } from "../../assets/api";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Row, Col, Card, CardBody, Button, Badge, Label, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import '../../assets/scss/custom.scss';
import moment from 'moment'
import Swal from 'sweetalert2'
import DataGrid, {
  Column,
  SearchPanel,
  FilterRow,
  HeaderFilter,
  GroupPanel,
  Pager, Paging,
  Scrolling,
  Export
} from 'devextreme-react/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Link } from "react-router-dom";
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import * as loadingData from "./men-component/loading.json";
import Lottie from "react-lottie";
import FadeIn from "react-fade-in";
import './Joborder.css'
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingData.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};
const allowedPageSizes = [5, 10];

class Joborder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      joborder_fetch: true,
      isAnnouncementModalOpen: false, // เพิ่ม state สำหรับเปิด/ปิด Modal
      announcementData: "",
      selectedGuideData: {
        fullname: "",
        license: "",
        code: ""
      },
      Checkbox_guides: 0,
      accepted: {},
      checkboxState: {},
      guidesData: []
    }
    this.auth = JSON.parse(localStorage.getItem('authToken'))
    this.getJoborder = this.getJoborder.bind(this);
    this.Operation = this.Operation.bind(this);
    this.code = React.createRef()
    this.guide_license = React.createRef()
    this.passport_no = React.createRef()
    this.agency_license = React.createRef()
    this.inbound_date = React.createRef()
    this.outbound_date = React.createRef()
    this.tourist_origincountry = React.createRef()
    this.place_name = React.createRef()
    this.onExporting = this.onExporting.bind(this);
  }
  setSelectedGuideData(fullname, guide_id, license, code) {
    //  console.log("sssssssssssssss",guide_id);
    this.setState(prevState => ({
      selectedGuideData: {
        fullname: fullname,
        guide_id: guide_id,
        license: license,
        code: code
      },
      guidesData: [...prevState.guidesData, { fullname, guide_id, license, code }] // เพิ่มข้อมูลของ guide เข้าไปในอาร์เรย์      
    }), () => {
      // console.log('Selected Guide Data:', this.state.selectedGuideData);   
    });
  }
  showAnnouncementModal(selectedData, Checkbox_guides) {
    this.setState({
      isAnnouncementModalOpen: true,
      selectedGuideData: selectedData,
      Checkbox_guides: Checkbox_guides,
    });
  }
  async summitAnnouncementModal() {
    // สร้างอ็อบเจกต์ accept_job จาก state
    const accept_job = {
      joborder_code: this.state.selectedGuideData.code,
      guides_id: Object.keys(this.state.accepted).join(','),
      accepted: Object.values(this.state.accepted).reduce((acc, value) => acc + value, 0),
    };

    var auth = JSON.parse(await localStorage.getItem('authToken'));
    // ทำการส่งข้อมูลไปยัง API
    fetch('https://drjotest-api.melivecode.com/api/v2/web/guides/accept_job', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: auth.data.type + ' ' + auth.data.token,
      },
      body: JSON.stringify(this.state.accept_job),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Response from API accept_job :', data);

        // ตรวจสอบ status จาก API
        if (data.status === 'success') {
          Swal.fire(
            'ยืนยันการรับงานเรียบร้อย',
            'คุณได้รับงานเรียบร้อยแล้ว',
            'success'
          ).then(() => {
            this.setState({
              isAnnouncementModalOpen: false,
            });
          });
        } else {
          Swal.fire(
            'ล้มเหลว !',
            'เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
            'error'
          );
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  closeAnnouncementModal() {
    this.setState({
      isAnnouncementModalOpen: false,
    });
  }
  // handleCheckboxChange(guide) {
  //   this.setState((prevState) => {
  //     const updatedAccepted = { ...prevState.accepted };

  //     updatedAccepted[guide.guideId] = !prevState.accepted[guide.guideId] ? 1 : 0;

  //     // สร้างอ็อบเจกต์ accept_job และกำหนดค่าตามที่ต้องการ
  //     const accept_job = {
  //       joborder_code: guide.code,
  //       guides_id: Object.keys(updatedAccepted).join(','),
  //       accepted: Object.values(updatedAccepted).reduce((acc, value) => acc + value, 0),
  //     };

  //     return {
  //       accepted: updatedAccepted,
  //       accept_job: accept_job, // เพิ่มการกำหนดค่า accept_job ใน setState
  //     };
  //   }, () => {
  //     // แสดงผลใน console.log ดังที่คุณระบุ
  //     console.log("accept_job", this.state.accept_job);
  //   });
  // }
  handleCheckboxChange(guide) {
    this.setState((prevState) => {
      const updatedAccepted = { ...prevState.accepted };

      // กำหนดค่า accepted เป็น 1 เสมอเมื่อมี Checkbox ถูกติ๊ก
      const isChecked = !prevState.accepted[guide.guideId];
      updatedAccepted[guide.guideId] = isChecked ? 1 : 0;

      // ตรวจสอบว่ามี Checkbox ถูกติ๊กเครื่องหมายอย่างน้อย 1 หรือไม่
      const hasAtLeastOneChecked = Object.values(updatedAccepted).some(value => value === 1);

      // สร้างอ็อบเจกต์ accept_job 
      const accept_job = {
        joborder_code: guide.code,
        guides_id: Object.keys(updatedAccepted).join(','),
        accepted: hasAtLeastOneChecked ? 1 : 0,
      };

      return {
        accepted: updatedAccepted,
        accept_job: accept_job,
      };
    }, () => {
      // console.log("accept_job", this.state.accept_job);
    });
  }

  componentDidMount() {
    this.getJoborder();
  }
  guide_status_msg(data) {
    switch (data.data.guide_status_msg) {
      case 'ready': return (<Badge className={"font-size-16 badge-soft-success"} color={'success'} pill>พร้อมทำงาน</Badge>)
      case 'pending': return (<Badge className={"font-size-16 badge-soft-warning"} color={'warning'} pill>รอการตอบรับ</Badge>)
      // case 3: return (<Badge className={"font-size-12 badge-soft-danger"} color={'danger'} pill>ลบ</Badge>)
      default:
        return null;
    }
  }
  start_date_render(data) {
    const date_formatted = moment(new Date(data.data.inbound_date)).format('DD-MM-YYYY')
    if (date_formatted === '01-01-1970') {
      return (<div>-</div>)
    } else {
      return (<div>{date_formatted}</div>)
    }
  }
  stop_date_render(data) {
    const date_formatted = moment(new Date(data.data.outbound_date)).format('DD-MM-YYYY')
    if (date_formatted === '01-01-1970') {
      return (<div>-</div>)
    } else {
      return (<div>{date_formatted}</div>)
    }
  }
  Operation(data) {
    return (
      <div style={{ alignSelf: 'center', textAlign: 'center' }}>
        <i onClick={(e) => {
          e.preventDefault();
          window.location.href = '/Joborderaddjb?joborder_code=' + data.data.code;
        }} style={{ color: 'gray', cursor: 'pointer' }} className="mdi mdi-pencil font-size-18"></i>
        {/* <i onClick={() => {
                    this.props.history.push({
                        pathname: '/joborder/progress',
                        joborder_id: data.data.id,
                    })
                }} style={{ color: 'gray', cursor: 'pointer' }} className="mdi mdi-eye font-size-18"></i> */}
        {data.data.status !== 3 ?
          <i
            style={{ color: 'gray', cursor: 'pointer' }}
            className="mdi mdi-delete font-size-18"
            onClick={() => { this.joborder_delete(data.data.code) }}
          />
          // <i onClick={() => { this.set_delete_users(data.data.id) }} style={{ color: 'gray', cursor: 'pointer' }} className="mdi mdi-delete font-size-18"></i>
          :
          ''
        }
      </div>
    )
  }
  async getJoborder(pageSize, pageIndex) {
    const skip = pageSize * pageIndex;
    var url = API_ENDPOINT + `api/v2/web/joborder/get?take=${pageSize}&skip=${skip}`;
    if (this.code.current.value !== "")
      url += "&joborder_code=" + this.code.current.value;
    if (this.guide_license.current.value !== "")
      url += "&guide_license=" + this.guide_license.current.value;
    if (this.passport_no.current.value !== "")
      url += "&passport_no=" + this.passport_no.current.value;
    if (this.agency_license.current.value !== "")
      url += "&agency_license=" + this.agency_license.current.value;
    if (this.inbound_date.current.value !== "")
      url += "&inbound_date=" + this.inbound_date.current.value;
    if (this.outbound_date.current.value !== "")
      url += "&outbound_date=" + this.outbound_date.current.value;
    if (this.tourist_origincountry.current.value !== "")
      url +=
        "&tourist_origincountry=" + this.tourist_origincountry.current.value;
    if (this.place_name.current.value !== "")
      url += "&place_name=" + this.place_name.current.value;
    var user = JSON.parse(localStorage.getItem("authUser"));
    if (this.auth.users.is_admin !== 1 || user.agency_id)
      url += "&agency_id=" + user.agency_id;
    var response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.auth.data.type + " " + this.auth.data.token,
      },
    });
    var responseJson = await response.json();
    console.log("responseJson", responseJson);
    const allGuidesData = [];
    responseJson.forEach((item) => {
      const guidesData = item.guides;
      for (const guideId in guidesData) {
        // console.log("asdasdddddddddd",guideId);
        if (guidesData.hasOwnProperty(guideId)) {
          const guideInfo = guidesData[guideId];
          const { fullname, license, guide_id } = guideInfo;
          allGuidesData.push({ fullname, guide_id, license, code: item.code });
          this.setSelectedGuideData(fullname, guide_id, license, item.code);
        }
      }
    });
    this.setState({
      Orders: responseJson,
      joborder_fetch: false,
      guidesData: allGuidesData,
    });
  }
  async joborder_delete(code) {
    Swal.fire({
      title: 'ยืนยันหรือไม่ ?',
      text: "ต้องการลบ joborder หรือไม่",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'ยกเลิก',
      confirmButtonText: 'ยืนยัน!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        let obj = {
          joborder_code: code
        }
        var response = await fetch(API_ENDPOINT + 'api/v2/web/joborder/delete', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: this.auth.data.type + ' ' + this.auth.data.token,
          },
          body: JSON.stringify(obj)

        });
        var responseJson = await response.json();
        if (responseJson.status === 'success') {
          Swal.fire(
            'สำเร็จ !',
            'ลบสำเร็จ',
            'success'
          ).then(async () => {
            window.location.reload()
          })

        } else {
          Swal.fire(
            'ล้มเหลว !',
            'ไม่สำเร็จ',
            'error'
          )
          return
        }
      }
    })

  }

  onExporting(e) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      autoFilterEnabled: true
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
      });
    });
    e.cancel = true;
  }


  // announcement_status(data) {
  //   if (data.data.is_announcement === true) {
  //     if (data.data.guides && Object.keys(data.data.guides).length > 0) {
  //       const guideIds = Object.keys(data.data.guides);
  //       const guidesInfo = guideIds.map((guideId) => {
  //         const guideInfo = data.data.guides[guideId];
  //         return {
  //           fullname: guideInfo.fullname,
  //           license: guideInfo.license,
  //           code: data.data.code,
  //           guideId: guideInfo.guide_id,
  //         };
  //       });

  //       const selectedData = {
  //         guideInfoList: guidesInfo,
  //       };
  //       return (
  //         <Badge
  //           color="success"
  //           className="font-size-16 badge-soft-success rounded-pill"
  //           style={{ cursor: "pointer" }}
  //           onClick={() => this.showAnnouncementModal(selectedData, 1)}
  //         >
  //           ประกาศหา
  //           <span className="notification-badge">N</span>
  //         </Badge>
  //       );
  //     } else {
  //       const selectedData = {
  //         fullname: "ยังไม่ได้เลือกมัคคุเทศก์",
  //         license: "ยังไม่ได้เลือกมัคคุเทศก์",
  //       };
  //       return (
  //         <Badge
  //           color="success"
  //           className="font-size-16 badge-soft-success rounded-pill"
  //           style={{ cursor: "pointer" }}
  //           onClick={() => this.showAnnouncementModal(selectedData, 0)}
  //         >
  //           ประกาศหา
  //         </Badge>
  //       );
  //     }
  //   } else {
  //     return null;
  //   }
  // }




  renderMyCommand(cellData) {
    // console.log("cellData", cellData)    
    return (
      <>
        {
          cellData.data.is_announcement ? (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <a href={"/joborder/progress?joborder_code=" + cellData.data.code} target="_blank" rel="noopener noreferrer">
                <Button type="button" color="primary" className="btn-sm btn-rounded" >
                  View Details
                </Button>
              </a>
            </div>
          ) : <div>
            <a href={"/joborder/progress?joborder_code=" + cellData.data.code} target="_blank" rel="noopener noreferrer">
              <Button type="button" color="primary" className="btn-sm btn-rounded" >
                View Details
              </Button>
            </a>
          </div >
        }

      </>
    )
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Row>
              <Col xs="12">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Breadcrumbs
                    title="Job Order"
                    breadcrumbItem={this.props.t("Joborder")}
                  />
                  <Link
                    to={{
                      pathname: "/Trip",
                    }}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light"
                      style={{
                        width: "100%",
                        backgroundColor: "#224AAC",
                        borderColor: "#224AAC",
                      }}
                    >
                      <i className="fas fa-plus-circle mr-3"></i>
                      <span className="font-style-16">
                        {this.props.t("Add New Joborder")}
                      </span>
                    </button>
                  </Link>
                </div>
              </Col>
            </Row>
            {/*Card Search*/}
            <Card className="card-shadow" style={{ marginTop: "1.5rem" }}>
              <CardBody>
                <Row className="mb-4">
                  <Col xl={3} lg={6} md={6} sm={12} xs={12}>
                    <FormGroup className="mt-3 mb-0">
                      <Label>{this.props.t("Job order code")} :</Label>
                      <Input
                        type="text"
                        innerRef={this.code}
                        id="code"
                        className="form-control"
                        placeholder={this.props.t(
                          "Joborder reference number"
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col xl={3} lg={6} md={6} sm={12} xs={12}>
                    <FormGroup className="mt-3 mb-0">
                      <Label>
                        {this.props.t("Guide license number")} :
                      </Label>
                      <Input
                        type="text"
                        innerRef={this.guide_license}
                        id="guide_license"
                        className="form-control"
                        placeholder={this.props.t("Guide license number")}
                      />
                    </FormGroup>
                  </Col>
                  <Col xl={3} lg={6} md={6} sm={12} xs={12}>
                    <FormGroup className="mt-3 mb-0">
                      <Label>{this.props.t("Tourist passport")} :</Label>
                      <Input
                        type="text"
                        innerRef={this.passport_no}
                        id="passport_no"
                        className="form-control"
                        placeholder={this.props.t("Tourist passport")}
                      />
                    </FormGroup>
                  </Col>
                  <Col xl={3} lg={6} md={6} sm={12} xs={12}>
                    <FormGroup className="mt-3 mb-0">
                      <Label>
                        {this.props.t("Tourist origin country")} :
                      </Label>
                      <Input
                        type="text"
                        innerRef={this.tourist_origincountry}
                        id="tourist_origincountry"
                        className="form-control"
                        placeholder={this.props.t("Tourist origin country")}
                      />
                    </FormGroup>
                  </Col>
                  <Col xl={3} lg={6} md={6} sm={12} xs={12}>
                    <FormGroup className="mt-3 mb-0">
                      <Label>{this.props.t("Place name")} :</Label>
                      <Input
                        type="text"
                        innerRef={this.place_name}
                        id="place_name"
                        className="form-control"
                        placeholder={this.props.t("Place name")}
                      />
                    </FormGroup>
                  </Col>
                  <Col xl={3} lg={6} md={6} sm={12} xs={12}>
                    <FormGroup className="mt-3 mb-0">
                      <Label>
                        {this.props.t("Tourism business license")} :
                      </Label>
                      <Input
                        type="text"
                        innerRef={this.agency_license}
                        id="agency_license"
                        className="form-control"
                        placeholder={this.props.t(
                          "Tourism business license"
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col xl={3} lg={6} md={6} sm={12} xs={12}>
                    <FormGroup className="mt-3 mb-0">
                      <Label>
                        {this.props.t("Date of entry into the country")} :
                      </Label>
                      <Input
                        type="date"
                        innerRef={this.inbound_date}
                        className="form-control"
                        id="joborder_start"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl={3} lg={6} md={6} sm={12} xs={12}>
                    <FormGroup className="mt-3 mb-0">
                      <Label>{this.props.t("Date of departure")} :</Label>
                      <Input
                        type="date"
                        innerRef={this.outbound_date}
                        className="form-control"
                        id="joborder_start"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={{ justifyContent: "flex-end" }}>
                  <Col xl={2} lg={3} md={6} sm={12} xs={12}>
                    <FormGroup className="mb-0">
                      <button
                        className="mt-1r btn btn-primary waves-effect waves-light"
                        onClick={() => {
                          this.code.current.value = "";
                          this.guide_license.current.value = "";
                          this.passport_no.current.value = "";
                          this.agency_license.current.value = "";
                          this.inbound_date.current.value = "";
                          this.outbound_date.current.value = "";
                          this.tourist_origincountry.current.value = "";
                          this.place_name.current.value = "";
                        }}
                        style={{
                          width: "100%",
                          background: "transparent",
                          borderColor: "#c2c2c2",
                        }}
                      >
                        <span className="font-style-16 cl-gray">
                          {this.props.t("Clear")}
                        </span>
                      </button>
                    </FormGroup>
                  </Col>
                  <Col xl={2} lg={3} md={6} sm={12} xs={12}>
                    <FormGroup className="mb-0">
                      <button
                        className="btn btn-primary waves-effect waves-light"
                        onClick={() => {
                          this.setState(
                            {
                              joborder_fetch: true,
                            },
                            () => {
                              this.getJoborder();
                            }
                          );
                        }}
                        style={{
                          width: "100%",
                          background: "transparent",
                          borderColor: "#2249AC",
                        }}
                      >
                        <img
                          className=""
                          src={"/assets/images/icon/search.svg"}
                          alt=""
                          style={{ marginRight: "0.5rem" }}
                        ></img>
                        <span className="font-style-16 cl-blue">
                          {this.props.t("Search")}
                        </span>
                      </button>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            {/*Card Data*/}
            <Card className="card-shadow" style={{ marginTop: "1.5rem" }}>
              <CardBody>
                <Row>
                  <Col lg={12}>
                    {this.state.joborder_fetch === true ? (
                      <div>
                        <div
                          style={{ marginTop: 10 }}
                          className="text-center"
                        >
                          <FadeIn>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Card>
                                <CardBody>
                                  <Lottie
                                    options={defaultOptions}
                                    height={300}
                                    width={300}
                                  />
                                  <div>
                                    <h4
                                      style={{
                                        color: "black",
                                        padding: 30,
                                      }}
                                    >
                                      กำลังเตรียมข้อมูล กรุณารอสักครู่
                                    </h4>
                                  </div>
                                </CardBody>
                              </Card>
                            </div>
                          </FadeIn>
                        </div>
                      </div>
                    ) : (
                      <DataGrid
                        onExporting={this.onExporting}
                        dataSource={this.state.Orders}
                        showBorders={true}
                        showColumnLines={true}
                        rowAlternationEnabled={true}
                        selection={{ mode: "single" }}
                        columnMinWidth={50}
                        allowColumnResizing={true}
                        remoteOperations={true} // เปิดใช้งาน remote operations
                        // ตั้งค่า callback ในการดึงข้อมูลหน้าต่อไป
                        onPageSizeChange={(e) => {
                          // เรียกใช้ getJoborder โดยใช้ pageSize ใหม่
                          this.getJoborder(
                            e.component.pageSize(),
                            e.component.pageIndex()
                          ).then((data) => {
                            e.component.option("dataSource", data);
                          });
                        }}
                        onPageIndexChange={(e) => {
                          // เรียกใช้ getJoborder โดยใช้ pageIndex ใหม่
                          this.getJoborder(
                            e.component.pageSize(),
                            e.component.pageIndex()
                          ).then((data) => {
                            e.component.option("dataSource", data);
                          });
                        }}
                      >
                        <Pager
                          allowedPageSizes={allowedPageSizes}
                          showPageSizeSelector={true}
                          showNavigationButtons={true}
                        />
                        <Scrolling
                          columnRenderingMode="standard"
                          renderAsync={false}
                        />
                        <Paging defaultPageSize={10} />
                        <GroupPanel visible={true} />
                        <FilterRow visible={this.state.showFilterRow} applyFilter={this.state.currentFilter} />
                        <HeaderFilter visible={this.state.showHeaderFilter} />
                        <SearchPanel visible={true} width={240} placeholder="Search..." />
                        {/* <Column dataField="id"
                                                        defaultSortOrder={'desc'}
                                                        width={50} /> */}

                        <Column caption={"Joborder Code"} dataField="code" />
                        <Column caption={"Inbound Date"} dataField="inbound_date" cellRender={this.start_date_render} />
                        <Column caption={"Outbound Date"} dataField="outbound_date" cellRender={this.stop_date_render} />
                        <Column caption={"Agency License"} dataField="license" />
                        <Column caption={"Agency Name"} dataField="agencyName" />
                        {/* <Column
                          caption={"Announcement"}
                          dataField="is_announcement"
                          type="buttons"
                          cellRender={(data) => {
                            console.log("data-------------------",data);
                            if (data.data.is_announcement === true) {
                              if (data.data.guides && Object.keys(data.data.guides).length > 0) {
                                const guideIds = Object.keys(data.data.guides);
                                const guidesInfo = guideIds.map((guideId) => {
                                  const guideInfo = data.data.guides[guideId];
                                  return {
                                    fullname: guideInfo.fullname,
                                    license: guideInfo.license,
                                    code: data.data.code,
                                    guideId: guideInfo.guide_id,
                                  };
                                });

                                const selectedData = {
                                  guideInfoList: guidesInfo, // Pass the list of guide info to the modal
                                };
                                return (
                                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                  <Badge
                                    color="success"
                                    className="font-size-16 badge-soft-success rounded-pill"
                                    style={{ cursor: "pointer",marginLeft: "18px" }}
                                    onClick={() => this.showAnnouncementModal(selectedData, 1)} // ส่งค่า Checkbox_guides เป็น 1
                                  >
                                    ประกาศหา                                    
                                  </Badge>
                                  <span className="notification-badge">N</span> 
                                  </div>
                                );
                              } else {
                                const selectedData = {
                                  fullname: "ยังไม่ได้เลือกมัคคุเทศก์",
                                  license: "ยังไม่ได้เลือกมัคคุเทศก์",
                                };
                                return (
                                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                  <Badge
                                    color="success"
                                    className="font-size-16 badge-soft-success rounded-pill"
                                    style={{ cursor: "pointer" ,marginLeft: "18px" }}
                                    onClick={() => this.showAnnouncementModal(selectedData, 0)} // ส่งค่า Checkbox_guides เป็น 0
                                  >
                                    ประกาศหา             
                                  </Badge>
                                  <span className="notification-badge">N</span>
                                </div>

                                );
                              }
                            } else {
                              return null;
                            }
                          }}
                        /> */}
                        <Column
                          caption={"Announcement"}
                          dataField="is_announcement"
                          type="buttons"
                          cellRender={(data) => {
                            // console.log("data-------------------", data);
                            if (data.data.is_announcement === true) {
                              if (data.data.guides && Object.keys(data.data.guides).length > 0) {
                                const guideIds = Object.keys(data.data.guides);
                                const guidesInfo = guideIds.map((guideId) => {
                                  const guideInfo = data.data.guides[guideId];
                                  return {
                                    fullname: guideInfo.fullname,
                                    license: guideInfo.license,
                                    code: data.data.code,
                                    guideId: guideInfo.guide_id,
                                  };
                                });

                                const selectedData = {
                                  guideInfoList: guidesInfo, // Pass the list of guide info to the modal
                                };
                                if (data.data.is_announcement_new === true) {
                                  return (
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                      <Badge
                                        color="success"
                                        className="font-size-16 badge-soft-success rounded-pill"
                                        style={{ cursor: "pointer", marginLeft: "18px" }}
                                        onClick={() => this.showAnnouncementModal(selectedData, 1)} // ส่งค่า Checkbox_guides เป็น 1
                                      >
                                        ประกาศหา
                                      </Badge>
                                      <span className="notification-badge">N</span>
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                      <Badge
                                        color="success"
                                        className="font-size-16 badge-soft-success rounded-pill"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => this.showAnnouncementModal(selectedData, 0)} // ส่งค่า Checkbox_guides เป็น 0
                                      >
                                        ประกาศหา
                                      </Badge>
                                    </div>
                                  );
                                }
                              } else {
                                const selectedData = {
                                  fullname: "ยังไม่ได้เลือกมัคคุเทศก์",
                                  license: "ยังไม่ได้เลือกมัคคุเทศก์",
                                };
                                if (data.data.is_announcement_new === true) {
                                  return (
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                      <Badge
                                        color="success"
                                        className="font-size-16 badge-soft-success rounded-pill"
                                        style={{ cursor: "pointer", marginLeft: "18px" }}
                                        onClick={() => this.showAnnouncementModal(selectedData, 1)} // ส่งค่า Checkbox_guides เป็น 1
                                      >
                                        ประกาศหา
                                      </Badge>
                                      <span className="notification-badge">N</span>
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                      <Badge
                                        color="success"
                                        className="font-size-16 badge-soft-success rounded-pill"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => this.showAnnouncementModal(selectedData, 0)} // ส่งค่า Checkbox_guides เป็น 0
                                      >
                                        ประกาศหา
                                      </Badge>
                                    </div>
                                  );
                                }
                              }
                            } else {
                              return null;
                            }
                          }}
                        />

                        <Column dataField="Job Preparation status" type="buttons" cellRender={this.guide_status_msg} />
                        <Column dataField="Detail" type="buttons" cellRender={this.renderMyCommand} />
                        <Column dataField="Operation" type="buttons" cellRender={this.Operation} />
                        <Export
                          enabled={true}
                          allowExportSelectedData={true}
                        />
                      </DataGrid>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </div>
        {/* <Modal
          isOpen={this.state.isAnnouncementModalOpen}
          toggle={() => this.closeAnnouncementModal()}
        >
          <ModalHeader toggle={() => this.closeAnnouncementModal()}>
            Announcement
          </ModalHeader>
          <ModalBody>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {this.state.Checkbox_guides === 1 && (
                <>
                  {this.state.selectedGuideData.guideInfoList.map(guide => (
                    <div key={guide.fullname} style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p>
                        Full Name: {guide.fullname}&nbsp;&nbsp;&nbsp;
                        License: {guide.license}
                      </p>
                      <label>
                      <input
                    type="checkbox"
                    checked={this.state.accepted[guide.guideId] ? true : false}
                    onChange={() => this.handleCheckboxChange(guide)}
                  />
                              </label>
                    </div>
                  ))}
                </>
              )}
              {this.state.Checkbox_guides === 0 && (        
                      <p>
                        Full Name: {this.state.selectedGuideData.fullname}&nbsp;&nbsp;&nbsp;
                        License: {this.state.selectedGuideData.license}
                      </p>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.summitAnnouncementModal()}>
              ยืนยันการรับงาน
            </Button>
            <Button color="secondary" onClick={() => this.closeAnnouncementModal()}>
              Close
            </Button>
          </ModalFooter>
        </Modal> */}

        <Modal
          isOpen={this.state.isAnnouncementModalOpen}
          toggle={() => this.closeAnnouncementModal()}
        >
          <ModalHeader toggle={() => this.closeAnnouncementModal()}>
            <h4>Announcement</h4>
          </ModalHeader>
          <ModalBody>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {this.state.Checkbox_guides === 1 && (
                <>
                  {this.state.selectedGuideData.guideInfoList.map(guide => (
                    <div key={guide.fullname} style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <h5>
                        Full Name: {guide.fullname}&nbsp;&nbsp;&nbsp;
                        License: {guide.license}
                      </h5>
                      <label>
                        <input
                          type="checkbox"
                          checked={this.state.accepted[guide.guideId] ? true : false}
                          onChange={() => this.handleCheckboxChange(guide)}
                        />
                      </label>
                    </div>
                  ))}
                </>
              )}
              {this.state.Checkbox_guides === 0 && this.state.selectedGuideData.fullname === "ยังไม่ได้เลือกมัคคุเทศก์" && (
                <h5>
                  ยังไม่มีมัคคุเทศก์สมัครเข้ามา
                </h5>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.summitAnnouncementModal()}>
              ยืนยันการรับงาน
            </Button>
            <Button color="secondary" onClick={() => this.closeAnnouncementModal()}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  return {
    ...state.Layout,
    login: state.Login
  };
};
export default connect(mapStatetoProps, {})((withTranslation()(Joborder))
);