import React, { Component } from 'react'
import { Row, Col } from "reactstrap";
import moment from 'moment-timezone';
import { withTranslation } from 'react-i18next';

class SelectDateTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_period: { value: 'custom', selected_period_start: '', selected_period_end: '' },
            view: 'month'
        }
        console.log("props type", props)
    };

    submitHandle(value) {
        switch (value) {
            case "view":
                this.props.callback(value, this.state.view)
                break;

            default:
                return
        }
    }

    componentDidMount() {
        let startOfMonth = moment().startOf('year').format('YYYY-MM-DD');
        let endOfMonth = moment().endOf('month').format('YYYY-MM-DD');
        let obj = this.state.selected_period
        obj.selected_period_start = startOfMonth;
        obj.selected_period_end = endOfMonth;
        this.setState({
            selected_period: obj
        }, () => {
            this.props.callback('view', this.state.view)
            this.props.callback('date', this.state.selected_period)
        })
    }

    render() {
        if (this.props.type === 'dashboard-executive') {
            return (
                <>
                    <Row className="mb-3">
                        <Col xl={1} lg={1} md={1} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>
                            <span style={{ fontSize: 16 }}>{this.props.t('ค้นหา')} : </span>
                        </Col>
                        <Col xl={5} lg={5} md={5} sm={12}>
                            <Row>
                                <Col xl={5} lg={5} md={5} sm={12}>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={12}>
                                            <span style={{ fontSize: 16 }}>{this.props.t('Start date')} :</span>
                                        </Col>
                                        <Col xl={10} lg={10} md={10} sm={12}><input type="date" value={this.state.selected_period.selected_period_start} onChange={(e) => {
                                            let obj = this.state.selected_period
                                            obj.selected_period_start = e.target.value
                                            this.setState({
                                                selected_period: obj
                                            }, () => {
                                                console.log("เริ่มต้น", this.state.selected_period)
                                                this.props.callback('date', this.state.selected_period)
                                            })
                                        }} placeholder="เริ่มต้น " className="form-control" ></input></Col>
                                    </Row>
                                </Col>
                                <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>-</Col>
                                <Col xl={5} lg={5} md={5} sm={12}>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={12}>
                                            <span style={{ fontSize: 16 }}>{this.props.t('End date')} :</span>
                                        </Col>
                                        <Col xl={9} lg={9} md={9} sm={9}><input type="date" value={this.state.selected_period.selected_period_end} onChange={(e) => {
                                            let obj = this.state.selected_period
                                            obj.selected_period_end = e.target.value
                                            this.setState({
                                                selected_period: obj
                                            }, () => {
                                                console.log("สิ้นสุด ", this.state.selected_period)
                                                this.props.callback('date', this.state.selected_period)
                                            })
                                        }} placeholder="สิ้นสุด" className="form-control" ></input></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl={3} lg={3} md={3} sm={12} style={{ display: 'inline-flex', alignSelf: 'center', justifyContent: 'end' }}>
                            <span style={{ fontSize: 16 }}>{this.props.t('Display of month and year graphs')} : </span>
                        </Col>
                        <Col xl={3} lg={3} md={3} sm={3} style={{ display: 'inline-flex', alignSelf: 'center' }}>
                            <button className={this.state.view === 'month' ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'}
                                style={{ whiteSpace: 'nowrap', textAlign: 'center' }}
                                onClick={() => {
                                    this.setState({
                                        view: 'month',
                                    }, () => {
                                        console.log('เลือกเดือน', this.state.view)
                                        this.submitHandle('view')
                                    })
                                }}>
                                {this.props.t('Month')}
                            </button>
                            <button className={this.state.view === 'year' ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'}
                                style={{ marginLeft: 20, whiteSpace: 'nowrap', textAlign: 'center' }}
                                onClick={() => {
                                    this.setState({
                                        view: 'year',
                                    }, () => {
                                        console.log('เลือกปี', this.state.view)
                                        this.submitHandle('view')
                                    })
                                }}>
                                {this.props.t('Year')}
                            </button>
                        </Col>
                    </Row>
                </>
            )
        }
        if (this.props.type === 'new-tourists-report-count') {
            return (
                <>
                    <Row className="mb-3">
                        <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>
                            <span style={{ fontSize: 16 }}>{this.props.t('The number of issuing job orders')} : </span>
                        </Col>
                        <Col xl={5} lg={5} md={5} sm={12}>
                            <Row>
                                <Col xl={5} lg={5} md={5} sm={12}>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={12}>
                                            <span style={{ fontSize: 16 }}>{this.props.t('Start date')} :</span>
                                        </Col>
                                        <Col xl={10} lg={10} md={10} sm={12}><input type="date" value={this.state.selected_period.selected_period_start} onChange={(e) => {
                                            let obj = this.state.selected_period
                                            obj.selected_period_start = e.target.value
                                            this.setState({
                                                selected_period: obj
                                            }, () => {
                                                console.log("เริ่มต้น", this.state.selected_period)
                                                this.props.callback('date', this.state.selected_period)
                                            })
                                        }} placeholder="เริ่มต้น " className="form-control" ></input></Col>


                                    </Row>

                                </Col>
                                <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>-</Col>
                                <Col xl={5} lg={5} md={5} sm={12}>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={12}>
                                            <span style={{ fontSize: 16 }}>{this.props.t('End date')} :</span>
                                        </Col>
                                        <Col xl={9} lg={9} md={9} sm={9}><input type="date" value={this.state.selected_period.selected_period_end} onChange={(e) => {
                                            let obj = this.state.selected_period
                                            obj.selected_period_end = e.target.value
                                            this.setState({
                                                selected_period: obj
                                            }, () => {
                                                console.log("สิ้นสุด ", this.state.selected_period)
                                                this.props.callback('date', this.state.selected_period)
                                            })
                                        }} placeholder="สิ้นสุด" className="form-control" ></input></Col>
                                    </Row>
                                </Col>

                            </Row>

                        </Col>
                        <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>
                            <span style={{ fontSize: 16 }}>{this.props.t('Display of month and year graphs')} : </span>
                        </Col>
                        <Col xl={3} lg={3} md={3} sm={3} style={{ display: 'inline-flex', alignSelf: 'center' }}>
                            <button className={this.state.view === 'month' ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'}
                                style={{ whiteSpace: 'nowrap', textAlign: 'center' }}
                                onClick={() => {
                                    this.setState({
                                        view: 'month',
                                    }, () => {
                                        console.log('เลือกเดือน', this.state.view)
                                        this.submitHandle('view')
                                    })
                                }}>
                                {this.props.t('Month')}
                            </button>
                            <button className={this.state.view === 'year' ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'}
                                style={{ marginLeft: 20, whiteSpace: 'nowrap', textAlign: 'center' }}
                                onClick={() => {
                                    this.setState({
                                        view: 'year',
                                    }, () => {
                                        console.log('เลือกปี', this.state.view)
                                        this.submitHandle('view')
                                    })
                                }}>
                                {this.props.t('Year')}
                            </button>
                        </Col>
                    </Row>
                </>
            )
        }
        if (this.props.type === 'report-attraction-count') {
            return (
                <>
                    <Row className="mb-3">
                        <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>
                            <span style={{ fontSize: 16 }}>{this.props.t('Attraction statistics')} : </span>
                        </Col>
                        <Col xl={5} lg={5} md={5} sm={12}>
                            <Row>
                                <Col xl={5} lg={5} md={5} sm={12}>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={12}>
                                            <span style={{ fontSize: 16 }}>{this.props.t('Start date')} :</span>
                                        </Col>
                                        <Col xl={10} lg={10} md={10} sm={12}><input type="date" value={this.state.selected_period.selected_period_start} onChange={(e) => {
                                            let obj = this.state.selected_period
                                            obj.selected_period_start = e.target.value
                                            this.setState({
                                                selected_period: obj
                                            }, () => {
                                                console.log("เริ่มต้น", this.state.selected_period)
                                                this.props.callback('date', this.state.selected_period)
                                            })
                                        }} placeholder="เริ่มต้น " className="form-control" ></input></Col>


                                    </Row>

                                </Col>
                                <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>-</Col>
                                <Col xl={5} lg={5} md={5} sm={12}>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={12}>
                                            <span style={{ fontSize: 16 }}>{this.props.t('End date')} :</span>
                                        </Col>
                                        <Col xl={9} lg={9} md={9} sm={9}><input type="date" value={this.state.selected_period.selected_period_end} onChange={(e) => {
                                            let obj = this.state.selected_period
                                            obj.selected_period_end = e.target.value
                                            this.setState({
                                                selected_period: obj
                                            }, () => {
                                                console.log("สิ้นสุด ", this.state.selected_period)
                                                this.props.callback('date', this.state.selected_period)
                                            })
                                        }} placeholder="สิ้นสุด" className="form-control" ></input></Col>
                                    </Row>
                                </Col>

                            </Row>

                        </Col>
                        <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>
                            <span style={{ fontSize: 16 }}>{this.props.t('Display of month and year graphs')} : </span>
                        </Col>
                        <Col xl={3} lg={3} md={3} sm={3} style={{ display: 'inline-flex', alignSelf: 'center' }}>
                            <button className={this.state.view === 'month' ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'}
                                style={{ whiteSpace: 'nowrap', textAlign: 'center' }}
                                onClick={() => {
                                    this.setState({
                                        view: 'month',
                                    }, () => {
                                        console.log('เลือกเดือน', this.state.view)
                                        this.submitHandle('view')
                                    })
                                }}>
                                {this.props.t('Month')}
                            </button>
                            <button className={this.state.view === 'year' ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'}
                                style={{ marginLeft: 20, whiteSpace: 'nowrap', textAlign: 'center' }}
                                onClick={() => {
                                    this.setState({
                                        view: 'year',
                                    }, () => {
                                        console.log('เลือกปี', this.state.view)
                                        this.submitHandle('view')
                                    })
                                }}>
                                {this.props.t('Year')}
                            </button>
                        </Col>
                    </Row>
                </>
            )
        }
        if (this.props.type === 'new-tourism-trends') {
            return (
                <>
                    <Row className="mb-3">
                        <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>
                            <span style={{ fontSize: 16 }}>{this.props.t('Tourism Trends')} : </span>
                        </Col>
                        <Col xl={5} lg={5} md={5} sm={12}>
                            <Row>
                                <Col xl={5} lg={5} md={5} sm={12}>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={12}>
                                            <span style={{ fontSize: 16 }}>{this.props.t('Start date')} :</span>
                                        </Col>
                                        <Col xl={10} lg={10} md={10} sm={12}><input type="date" value={this.state.selected_period.selected_period_start} onChange={(e) => {
                                            let obj = this.state.selected_period
                                            obj.selected_period_start = e.target.value
                                            this.setState({
                                                selected_period: obj
                                            }, () => {
                                                console.log("เริ่มต้น", this.state.selected_period)
                                                this.props.callback('date', this.state.selected_period)
                                            })
                                        }} placeholder="เริ่มต้น " className="form-control" ></input></Col>


                                    </Row>

                                </Col>
                                <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>-</Col>
                                <Col xl={5} lg={5} md={5} sm={12}>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={12}>
                                            <span style={{ fontSize: 16 }}>{this.props.t('End date')} :</span>
                                        </Col>
                                        <Col xl={9} lg={9} md={9} sm={9}><input type="date" value={this.state.selected_period.selected_period_end} onChange={(e) => {
                                            let obj = this.state.selected_period
                                            obj.selected_period_end = e.target.value
                                            this.setState({
                                                selected_period: obj
                                            }, () => {
                                                console.log("สิ้นสุด ", this.state.selected_period)
                                                this.props.callback('date', this.state.selected_period)
                                            })
                                        }} placeholder="สิ้นสุด" className="form-control" ></input></Col>
                                    </Row>
                                </Col>

                            </Row>

                        </Col>
                        <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>
                            <span style={{ fontSize: 16 }}>{this.props.t('Display of month and year graphs')} : </span>
                        </Col>
                        <Col xl={3} lg={3} md={3} sm={3} style={{ display: 'inline-flex', alignSelf: 'center' }}>
                            <button className={this.state.view === 'month' ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'}
                                style={{ whiteSpace: 'nowrap', textAlign: 'center' }}
                                onClick={() => {
                                    this.setState({
                                        view: 'month',
                                    }, () => {
                                        console.log('เลือกเดือน', this.state.view)
                                        this.submitHandle('view')
                                    })
                                }}>
                                {this.props.t('Month')}
                            </button>
                            <button className={this.state.view === 'year' ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'}
                                style={{ marginLeft: 20, whiteSpace: 'nowrap', textAlign: 'center' }}
                                onClick={() => {
                                    this.setState({
                                        view: 'year',
                                    }, () => {
                                        console.log('เลือกปี', this.state.view)
                                        this.submitHandle('view')
                                    })
                                }}>
                                {this.props.t('Year')}
                            </button>
                        </Col>
                    </Row>
                </>
            )
        }
        if (this.props.type === 'tourists-report-count') {
            return (
                <>
                    <Row className="mb-3">
                        <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>
                            <span style={{ fontSize: 16 }}>{this.props.t('Tourism Trends')} : </span>
                        </Col>
                        <Col xl={5} lg={5} md={5} sm={12}>
                            <Row>
                                <Col xl={5} lg={5} md={5} sm={12}>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={12}>
                                            <span style={{ fontSize: 16 }}>{this.props.t('Start date')} :</span>
                                        </Col>
                                        <Col xl={10} lg={10} md={10} sm={12}><input type="date" value={this.state.selected_period.selected_period_start} onChange={(e) => {
                                            let obj = this.state.selected_period
                                            obj.selected_period_start = e.target.value
                                            this.setState({
                                                selected_period: obj
                                            }, () => {
                                                console.log("เริ่มต้น", this.state.selected_period)
                                                this.props.callback('date', this.state.selected_period)
                                            })
                                        }} placeholder="เริ่มต้น " className="form-control" ></input></Col>


                                    </Row>

                                </Col>
                                <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>-</Col>
                                <Col xl={5} lg={5} md={5} sm={12}>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={12}>
                                            <span style={{ fontSize: 16 }}>{this.props.t('End date')} :</span>
                                        </Col>
                                        <Col xl={9} lg={9} md={9} sm={9}><input type="date" value={this.state.selected_period.selected_period_end} onChange={(e) => {
                                            let obj = this.state.selected_period
                                            obj.selected_period_end = e.target.value
                                            this.setState({
                                                selected_period: obj
                                            }, () => {
                                                console.log("สิ้นสุด ", this.state.selected_period)
                                                this.props.callback('date', this.state.selected_period)
                                            })
                                        }} placeholder="สิ้นสุด" className="form-control" ></input></Col>
                                    </Row>
                                </Col>

                            </Row>

                        </Col>
                        <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>
                            <span style={{ fontSize: 16 }}>{this.props.t('Display of month and year graphs')} : </span>
                        </Col>
                        <Col xl={3} lg={3} md={3} sm={3} style={{ display: 'inline-flex', alignSelf: 'center' }}>
                            <button className={this.state.view === 'month' ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'}
                                style={{ whiteSpace: 'nowrap', textAlign: 'center' }}
                                onClick={() => {
                                    this.setState({
                                        view: 'month',
                                    }, () => {
                                        console.log('เลือกเดือน', this.state.view)
                                        this.submitHandle('view')
                                    })
                                }}>
                                {this.props.t('Month')}
                            </button>
                            <button className={this.state.view === 'year' ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'}
                                style={{ marginLeft: 20, whiteSpace: 'nowrap', textAlign: 'center' }}
                                onClick={() => {
                                    this.setState({
                                        view: 'year',
                                    }, () => {
                                        console.log('เลือกปี', this.state.view)
                                        this.submitHandle('view')
                                    })
                                }}>
                                {this.props.t('Year')}
                            </button>
                        </Col>
                    </Row>
                    {/* <Row>
                    <Col xl={2} lg={2} md={2} sm={12}></Col>
                    <Col xl={8} lg={8} md={8} sm={12}>
                        <button style={{ width: '100%', marginTop: 15 }} className='btn btn-primary '
                            onClick={() => {
                                if (this.state.selected_period.selected_period_start === "" || this.state.selected_period.selected_period_end === "") {
                                    Swal.fire(
                                        'ล้มเหลว !',
                                        'กรุณาเลือกวันเริ่มต้นและสิ้นสุดของรายงานที่ต้องการค้นหา',
                                        'error'
                                    )
                                }
                                else {
                                    let obj = this.state.selected_period
                                    obj.value = 'custom'
                                    obj.isSearch = false
                                    this.setState({
                                        selected_period: obj
                                    }, () => {
                                        this.submitHandle('date')
                                    })
                                }
                            }}>
                            {this.props.t('Search')}
                        </button>
                    </Col>
                </Row> */}
                </>
            )
        }
        if (this.props.type === 'search-tourists') {
            return (
                <>
                    <Row style={{ marginBottom: 20 }}>
                        <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>
                            <span style={{ fontSize: 16 }}>{this.props.t('เลือกวันที่เริ่มต้น-วันที่สินสุด')} : </span>
                        </Col>
                        <Col xl={5} lg={5} md={5} sm={12}>
                            <Row>
                                <Col xl={5} lg={5} md={5} sm={12}>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={12}>
                                            <span style={{ fontSize: 16 }}>{this.props.t('Start date')} :</span>
                                        </Col>
                                        <Col xl={10} lg={10} md={10} sm={12}><input type="date" value={this.state.selected_period.selected_period_start} onChange={(e) => {
                                            let obj = this.state.selected_period
                                            obj.selected_period_start = e.target.value
                                            this.setState({
                                                selected_period: obj
                                            }, () => {
                                                console.log("เริ่มต้น", this.state.selected_period)
                                                this.props.callback('date', this.state.selected_period)
                                            })
                                        }} placeholder="เริ่มต้น " className="form-control" ></input></Col>


                                    </Row>

                                </Col>
                                <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>-</Col>
                                <Col xl={5} lg={5} md={5} sm={12}>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={12}>
                                            <span style={{ fontSize: 16 }}>{this.props.t('End date')} :</span>
                                        </Col>
                                        <Col xl={9} lg={9} md={9} sm={9}><input type="date" value={this.state.selected_period.selected_period_end} onChange={(e) => {
                                            let obj = this.state.selected_period
                                            obj.selected_period_end = e.target.value
                                            this.setState({
                                                selected_period: obj
                                            }, () => {
                                                console.log("สิ้นสุด ", this.state.selected_period)
                                                this.props.callback('date', this.state.selected_period)
                                            })
                                        }} placeholder="สิ้นสุด" className="form-control" ></input></Col>
                                    </Row>
                                </Col>

                            </Row>

                        </Col>
                    </Row>
                </>
            )
        }
    }
}
export default (withTranslation()(SelectDateTab))