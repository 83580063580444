import React, { useEffect } from 'react';
import AnyChart from 'anychart-react'

const NetworkChart = (props) => {

  useEffect(() => {
  }, [props]);





  return (
    <div>
      <AnyChart
        width={'100%'}
        height={400}
        type='graph'
        data={{
          nodes: [
            { id: props.report.search },
            { id: "ถนนสายวัฒนธรรมสู้ศึก คึกคัก" },
            { id: "รฤก หัวหิน" },
            { id: "ตลาดขาว-แดง ปาดังเบซาร์" },
            { id: "Giant Tree &amp; River Walk" },
            { id: "ตลาดนัดชายแดนไทยลาว" },
            { id: "JAPAN" },
            { id: "CHINA" }
          ],
          edges: [
            { from: props.report.search, to: "ถนนสายวัฒนธรรมสู้ศึก คึกคัก" },
            { from: props.report.search, to: "รฤก หัวหิน" },
            { from: props.report.search, to: "ตลาดขาว-แดง ปาดังเบซาร์" },
            { from: props.report.search, to: "Giant Tree &amp; River Walk" },
            { from: props.report.search, to: "ตลาดนัดชายแดนไทยลาว" },
            { from: "JAPAN", to: "ถนนสายวัฒนธรรมสู้ศึก คึกคัก" },
            { from: "JAPAN", to: "Giant Tree &amp; River Walk" },
            { from: "CHINA", to: "ถนนสายวัฒนธรรมสู้ศึก คึกคัก" },
            { from: "CHINA", to: "ตลาดขาว-แดง ปาดังเบซาร์" },
            { from: "CHINA", to: "ตลาดนัดชายแดนไทยลาว" }
          ]
        }}
        title={'ผลลัพธ์การค้นหา : ' + props.report.search}
      />
    </div>
  )
}

export default NetworkChart;