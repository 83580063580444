import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Dropzone from "react-dropzone";
import Swal from "sweetalert2";
import { API_ENDPOINT } from "../../assets/api";
import { withTranslation } from "react-i18next";


class AddNews extends Component {
  constructor(props) {
    super(props);
    // window.title = "เพิ่มสถานที่ท่องเที่ยว";
    this.state = {
      selectedFiles: [],
      imagePreviews: [],
      forms: {
        news_title: "",
        news_link: "",
        news_img: "",
        news_startdate: "",
        news_enddate: "",
        news_status: ""
      },
    };
    this.handleAcceptedFiles.bind(this);
  }

  componentDidMount = async () => {
    // emit the event
    if (!localStorage.getItem("authUser")) {
      return window.location = "/login";
    }
  };

  async addNewsData(e) {
    e.preventDefault(); // ป้องกันการรีเฟรชหน้าเว็บ
    if (this.state.forms.news_title === "") {
      return Swal.fire("ผิดพลาด!", "กรุณาระบุชื่อเรื่อง", "error");
    }
    if (this.state.forms.news_link === "") {
      return Swal.fire("ผิดพลาด!", "กรุณาระบุลิงค์", "error");
    }
    if (this.state.forms.news_img === "") {
      return Swal.fire("ผิดพลาด!", "กรุณาใส่รูปภาพ", "error");
    }
    if (this.state.forms.news_startdate === "") {
      return Swal.fire("ผิดพลาด!", "กรุณาระบุวันที่เริ่มต้น", "error");
    }
    if (this.state.forms.news_enddate === "") {
      return Swal.fire("ผิดพลาด!", "กรุณาระบุวันที่สิ้นสุด", "error");
    }
    if (this.state.forms.news_status === null) {
      return Swal.fire("ผิดพลาด!", "กรุณาระบุสถานะ", "error");
    }

    const formData = new FormData();
    formData.append('title', this.state.forms.news_title);
    formData.append('link', this.state.forms.news_link);
    formData.append('start_date', this.state.forms.news_startdate);
    formData.append('end_date', this.state.forms.news_enddate);
    formData.append('status', this.state.forms.news_status);
    formData.append("images", API_ENDPOINT + this.state.forms.news_img);
    // if (this.state.forms.news_img.length === 0) {
    //   formData.append("images[]", "");
    // } else {
    //   console.log("forms", this.state.forms.news_img);
    //   for (var file of this.state.forms.news_img) {
    //     formData.append("images[]", API_ENDPOINT + file);
    //     console.log("images", file);
    //   }
    // }

    try {
      const url = API_ENDPOINT + "api/v2/web/contents/add";
      const auth = JSON.parse(await localStorage.getItem('authToken'));
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: auth.data.type + " " + auth.data.token,
        },
        body: formData,
      });

      const result = await response.json();
      console.log("Result:", result);
      if (result.status === 'success') {
        return Swal.fire("สำเร็จ !", "เพิ่มข่าวสำเร็จแล้ว", "success").then(() => {
          window.location = "/news"
        })
      } else {
        return Swal.fire("ล้มเหลว !", "เพิ่มข่าวที่ไม่สำเร็จ", "error")
      }

    } catch (error) {
      console.error("Error:", error);
    }
  }
  handleChange = (e) => {
    const { name, value } = e.target;
    const forms = { ...this.state.forms };
    forms[name] = value;
    this.setState({ forms });
  };
  handleAcceptedFiles = async (files) => {
    console.log(files);
    for (var file of files) {
      console.log(file);
      if (file.type.search("image") === -1) {
        return Swal.fire("ผิดพลาด ?", "กรุณาเลือกไฟล์รูปภาพเท่านั้น", "error");
      }
    }
    files.map((file) => {
      console.log("fileeeeeeeeeee", file)
      return (
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: this.formatBytes(file.size),
        })
      )
    }

    );

    // ตรวจสอบและอัพโหลดรูปภาพเหมือนเดิม
    if (files[0] !== undefined) {
      var formData = new FormData()
      formData.append('file', files[0], files[0].name);

      var auth = JSON.parse(await localStorage.getItem('authToken'))
      var response = await fetch(API_ENDPOINT + 'api/v2/web/file/upload_file', {
        method: 'POST',
        headers: {
          Authorization: auth.data.type + ' ' + auth.data.token,
        },
        body: formData
      });
      var responseJson = await response.json();
      console.log(responseJson)
      if (responseJson.status === 'success') {
        this.setState({ imagePreviews: [files[0]] });
        var forms = this.state.forms;
        forms.news_img = responseJson.filepath;
        this.setState({ forms: forms });

      }
    }
  };

  // handleAcceptedFiles = async (files) => {
  //   console.log(files);
  //   for (var file of files) {
  //     console.log(file);
  //     if (file.type.search("image") === -1) {
  //       return Swal.fire("ผิดพลาด ?", "กรุณาเลือกไฟล์รูปภาพเท่านั้น", "error");
  //     }
  //   }
  //   files.map((file) =>
  //     Object.assign(file, {
  //       preview: URL.createObjectURL(file),
  //       formattedSize: this.formatBytes(file.size),
  //     })
  //   );
  //   if (files[0] !== undefined) {
  //     var formData = new FormData()
  //     formData.append('file', files[0], files[0].name);

  //     var auth = JSON.parse(await localStorage.getItem('authToken'))
  //     var response = await fetch(API_ENDPOINT + 'api/v2/web/file/upload_file', {
  //       method: 'POST',
  //       headers: {
  //         Authorization: auth.data.type + ' ' + auth.data.token,
  //       },
  //       body: formData
  //     });
  //     var responseJson = await response.json();
  //     console.log(responseJson)
  //   }
  //   // var forms = this.state.forms;
  //   // forms.place_img = files;
  //   // this.setState({ forms: forms, selectedFiles: files });
  //   if (responseJson.status === 'success') {
  //     var forms = this.state.forms;
  //     forms.news_img = responseJson.filepath;
  //     console.log("filesssss", forms.news_img)
  //     this.setState({ forms: forms, imagePreviews: [...this.state.imagePreviews, ...files] });
  //   }

  // };

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  render() {
    console.log("images", this.state.forms.news_img)
    console.log("forms", this.state.forms)
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Row style={{ display: "flex", alignItems: "center" }}>
              <Col xl={8} lg={6} md={6} sm={12} xs={12}>
                <Breadcrumbs
                  title={this.props.t('Add News')}
                  breadcrumbItem={this.props.t('Add News')}
                />
              </Col>
            </Row>
            {/* Card No.1 Information */}
            <Row>
              <Col lg={12}>
                <Card className="card-shadow" style={{ marginTop: "1rem" }}>
                  <CardBody>
                    <Row>
                      <Col lg={12}>
                        <h2 className="font-style-24 cl-dark-blue">
                          {this.props.t("ข้อมูลทั่วไป")}
                        </h2>
                      </Col>
                    </Row>
                    <div>
                      <Row>
                        <Col lg={6}>
                          <FormGroup>
                            <Label
                              htmlFor="placename"
                              className="font-style-16 cl-black"
                            >
                              ชื่อข่าวสาร
                              <span style={{ color: "red", marginLeft: 5 }}>
                                *
                              </span>
                            </Label>
                            <Input
                              placeholder={"กรอกข้อมูลข่าวสาร"}
                              id="news_title"
                              name="news_title"
                              type="text"
                              className="form-control"
                              value={this.state.forms.news_title}
                              onChange={this.handleChange} // ใช้ฟังก์ชัน handleChange
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={6}>

                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          {/* <FormGroup>
                            <Label
                              htmlFor="productdesc"
                              className="font-style-16 cl-black"
                            >
                              รายละเอียดข่าวสาร
                              <span style={{ color: "red", marginLeft: 5 }}>
                                *
                              </span>
                            </Label>
                            <textarea
                              placeholder={"กรอกข้อมูลรายละข่าวสาร"}
                              className="form-control"
                              id="place_description"
                              rows="5"
                            ></textarea>
                          </FormGroup> */}
                          <FormGroup>
                            <Label
                              htmlFor="placename"
                              className="font-style-16 cl-black"
                            >
                              ลิงค์
                              <span style={{ color: "red", marginLeft: 5 }}>
                                *
                              </span>
                            </Label>
                            <Input
                              placeholder={"กรอกลิงค์"}
                              id="news_link"
                              name="news_link"
                              type="text"
                              className="form-control"
                              value={this.state.forms.news_link}
                              onChange={this.handleChange} // ใช้ฟังก์ชัน handleChange
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* Card No.2 upload Image */}
            <Row>
              <Col lg={12}>
                <Card className="card-shadow" style={{ marginTop: "1rem" }}>
                  <CardBody>
                    <Row>
                      <Col lg={12}>
                        <h2 className="font-style-24 cl-dark-blue">
                          {this.props.t("รูปภาพ")}
                        </h2>
                      </Col>
                    </Row>
                    <hr></hr>
                    <Row>
                      <Col lg={12}>
                        <Dropzone
                          onDrop={(acceptedFiles) =>
                            this.handleAcceptedFiles(acceptedFiles)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                                </div>
                                <h3>
                                  {this.props.t(
                                    "Drop files here or click to upload."
                                  )}
                                </h3>
                                <p className="font-style-18"><span className="text-alert">หมายเหตุ</span> : รูปภาพที่นำมาจากอินเตอร์เน็ต(Internet) ภาพบางภาพอาจมีลิขสิทธิ์ ซึ่งนำไปสู่การละเมิดลิขสิทธิ์ภาพถ่ายมีโทษตามมาตรา69 โทษปรับตั้งแต่ 20,000-200,000 บาทหรือทั้งจำทั้งปรับ</p>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                  <div className="font-style-18" style={{ marginRight: 15 }}>
                                    คำแนะนำ :
                                  </div>
                                  <div className="font-style-18" style={{ textAlign: 'left' }}>
                                    - ใช้รูปภาพที่ถ่ายด้วยตนเอง<br></br>
                                    - ตรวจสอบแหล่งที่มาของรูปภาพว่าไม่มีลิขสิทธิ์<br></br>
                                    - กรณีที่ไม่ใช่ภาพถ่ายของตนเอง ต้องได้รับอนุญาตจากเจ้าของภาพแล้วเท่านั้น<br></br>
                                    - ควรให้เครดิตแก่เจ้าของลิขสิทธิ์<br></br>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div className="dropzone-previews mt-3" id="file-previews">
                          {this.state.imagePreviews.map((f, i) => {
                            console.log("iiiii", i)
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                {/* แสดงรูปภาพที่ถูกเลือก */}
                                <div className="p-2">
                                  <Row
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      Swal.fire({
                                        title: "ลบไฟล์ ",
                                        text: "ต้องการลบไฟล์ที่เลือกใช่หรือไม่ ",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonColor: "#d33",
                                        cancelButtonText: "ยกเลิก",
                                        confirmButtonText: "ยืนยัน!",
                                      }).then((result) => {
                                        if (result.isConfirmed) {
                                          let selectfile =
                                            this.state.imagePreviews;
                                          console.log("selectfile", selectfile)
                                          selectfile.splice(i, 1);
                                          this.setState(
                                            {
                                              imagePreviews: selectfile,
                                            },
                                            () => {
                                              Swal.fire(
                                                "ลบแล้ว !",
                                                "ภาพของคุณถุกลบแล้ว",
                                                "success"
                                              );
                                            }
                                          );
                                        }
                                      });
                                    }}
                                    className="align-items-center"
                                  >
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        style={{
                                          width: "5rem",
                                          height: "5rem",
                                        }}
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        <span className="font-style-16 cl-dark-blue">
                                          {f.name}
                                        </span>
                                      </Link>
                                      <p className="mb-0">
                                        <span className="font-style-16 cl-gray">
                                          {this.formatBytes(f.size)}
                                        </span>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            );
                          })}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* Card No.3 status */}
            <Row>
              <Col lg={12}>
                <Card className="card-shadow" style={{ marginTop: "1rem" }}>
                  <CardBody>
                    <Row>
                      <Col lg={12}>
                        <h2 className="font-style-24 cl-dark-blue">
                          {this.props.t("สถานะ")}
                        </h2>
                      </Col>
                    </Row>
                    <div>
                      <Row>
                        <Col lg={6}>
                          <FormGroup>
                            <Label
                              htmlFor="placename"
                              className="font-style-16 cl-black"
                            >
                              วันที่เผยแพร่
                              <span style={{ color: "red", marginLeft: 5 }}>
                                *
                              </span>
                            </Label>
                            <input
                              type="date"
                              placeholder="วันที่เผยแพร่"
                              name="news_startdate"
                              className="form-control"
                              value={this.state.forms.news_startdate}
                              onChange={this.handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={6}>
                          <FormGroup>
                            <Label
                              htmlFor="placename"
                              className="font-style-16 cl-black"
                            >
                              เผยแพร่ถึงวันที่
                              <span style={{ color: "red", marginLeft: 5 }}>
                                *
                              </span>
                            </Label>
                            <input
                              type="date"
                              placeholder="เผยแพร่ถึงวันที่"
                              name="news_enddate"
                              className="form-control"
                              value={this.state.forms.news_enddate}
                              onChange={this.handleChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <Label
                            htmlFor="placename"
                            className="font-style-16 cl-black"
                          >
                            สถานะข้อมูล
                            <span style={{ color: "red", marginLeft: 5 }}>
                              *
                            </span>
                          </Label>
                          <FormGroup>
                            <select
                              name="news_status"
                              className="custom-select"
                              value={this.state.forms.news_status}
                              onChange={this.handleChange}
                            >
                              <option defaultValue>กรุณาเลือกสถานะ</option>
                              <option value="Y">เผยแพร่</option>
                              <option value="N">ไม่เผยแพร่</option>
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* Card No.4 Submit */}
            <Form onSubmit={(e) => this.addNewsData(e)}>
              <Row style={{ display: "flex", alignItems: "center" }}>
                <Col xl={8} lg={6} md={6} sm={12} xs={12}></Col>
                <Col xl={2} lg={3} md={3} sm={12} xs={12}>
                  <Link
                    to={{ pathname: "/news" }}
                    style={{ marginRight: "1rem" }}
                  >
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light"
                      style={{
                        width: "100%",
                        background: "#808080",
                        borderColor: "#808080",
                      }}
                    >
                      <span className="font-style-16">
                        {this.props.t("Cancel")}
                      </span>
                    </button>
                  </Link>
                </Col>
                <Col xl={2} lg={3} md={3} sm={12} xs={12} className="mr-bt">
                  <button
                    type="submit"
                    className="btn btn-primary waves-effect waves-light"
                    style={{
                      width: "100%",
                      background: "#28A745",
                      borderColor: "#28A745",
                    }}
                  >
                    <span className="font-style-16">
                      {this.props.t("ยืนยันข้อมูล")}
                    </span>
                  </button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </React.Fragment >
    );
  }
}

export default connect(null, {})((withTranslation()(AddNews)));
