import React, { Component } from "react";
// import PropTypes from "prop-types";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  Col,
  Row,
  Container,
} from "reactstrap";
// import img6 from "../../assets/images/small/img-6.jpg";
import Cardtimeperiod from "./components/Cardtimeperiod";
// import Cardtimeperiodcopy from "./components/Cardtimeperiodcopy";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { withTranslation } from "react-i18next";
import "./Trip.scss";
class Grouptimeperiod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      tripAdded: [],
    };
    console.log(this.props);
    this.cardSelected = this.cardSelected.bind(this);
    this.alert = this.alert.bind(this);
    this.removeState = this.removeState.bind(this);
  }
  myRef = React.createRef();

  alert() {
    return Swal.fire("ผิดพลาด !", "กรุณาเลือกช่วงเวลาที่ต้องการ !", "warning");
  }

  removeState() {
    localStorage.removeItem("state");
  }
  // componentWillUnmount() {
  //     alert('กรุณา สร้างแผนการท่องเที่ยวให้เสร็จ ก่อนจะไปหน้าอื่น')
  // }

  componentDidMount() {
    document.getElementById("Trip_navagation").classList.add("active");

    console.log("componentDidMount");
    let lcStorage = localStorage.getItem("state");
    console.log(lcStorage);
    if (typeof lcStorage == "string") {
      console.log("GOOD");
      this.setState(
        {
          tripAdded: JSON.parse(lcStorage),
          isLoading: false,
        },
        () => {
          console.log(this.state.tripAdded);
        }
      );
    } else {
      console.log("BAD");
    }
  }
  prevClick = () => {
    const slide = this.myRef.current;
    slide.scrollLeft -= slide.offsetWidth;
    if (slide.scrollLeft <= 0) {
      slide.scrollLeft = slide.scrollWidth;
    }
  };
  nextClick = () => {
    const slide = this.myRef.current;
    slide.scrollLeft += slide.offsetWidth;
    if (slide.scrollLeft >= slide.scrollWidth - slide.offsetWidth) {
      slide.scrollLeft = 0;
    }
  };

  cardSelected(e) {
    console.log(e);
    let arr = this.state.tripAdded;
    let x = arr.filter((item) => item.value !== e);
    if (x.length === 0) {
      let tripRange = [];
      for (let i = 0; i < e; i++) {
        tripRange.push({ day: i + 1, isOpen: false });
      }
      arr.push({ tripRange: tripRange });
      this.setState(
        {
          tripAdded: arr,
        },
        () => {
          localStorage.setItem("state", JSON.stringify(this.state.tripAdded));
          console.log(this.state.tripAdded);
        }
      );
    } else {
      let tripRange = [];
      for (let i = 0; i < e; i++) {
        tripRange.push({ day: i + 1, isOpen: false });
      }
      arr[0].tripRange = tripRange;
      this.setState(
        {
          tripAdded: arr,
        },
        () => {
          localStorage.setItem("state", JSON.stringify(this.state.tripAdded));
          console.log(this.state.tripAdded);
        }
      );
    }
  }

  render() {
    const data = [
      {
        src: "/imagestimeperiod/img4.svg",
        title: "แผนการท่องเที่ยว 1 วัน",
        value: 1,
      },
      {
        src: "/imagestimeperiod/img1.svg",
        title: "1-2 คืน",
        value: 2,
      },
      {
        src: "/imagestimeperiod/img2.svg",
        title: "3-4 คืน",
        value: 4,
      },
      {
        src: "/imagestimeperiod/img3.svg",
        title: "แผนการท่องเที่ยวระยะยาว",
        value: 6,
      },
    ];

    return (
      // <React.Fragment>

      //     <Row style={{ alignSelf: 'center', justifyContent: 'center' }}>
      //         <Cardtimeperiod data={data} cardSelected={this.cardSelected} />
      //     </Row>
      //     <div>
      //         <div style={{ textAlign: "center", marginTop: 25 }}>
      //             <h5 style={{ color: "black" }}>เลือกช่วงเวลาในการเที่ยว</h5>
      //         </div>

      //     </div>

      // </React.Fragment >
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row style={{ display: 'flex', alignItems: 'center' }}>
              <Col xl={8} lg={6} md={6} sm={12} xs={12}>
                <Breadcrumbs title="Trip" breadcrumbItem={this.props.t("Trip")} />
              </Col>
              <Col xl={2} lg={3} md={3} sm={12} xs={12}>
                {this.state.tripAdded.length < 1 ? (
                  <Link to={"/Trip/Addtrip"}>
                    <button type="button" className="btn btn-primary waves-effect waves-light"
                      style={{ width: "100%", backgroundColor: "#E7E7E7", borderColor: "#AAAAAA" }}>
                      <span className="font-style-16 cl-gray">
                        {this.props.t("Back")}
                      </span>
                    </button>
                  </Link>
                ) : (
                  <Link to={"/Trip/Addtrip"}>
                    <button onClick={() => {
                      this.removeState();
                    }}
                      type="button" className="btn btn-primary waves-effect waves-light"
                      style={{ width: "100%", backgroundColor: "#E7E7E7", borderColor: "#AAAAAA" }}>
                      <span className="font-style-16 cl-gray">
                        {this.props.t("Back")}
                      </span>
                    </button>
                  </Link>
                )}
              </Col>
              <Col xl={2} lg={3} md={3} sm={12} xs={12} className="mr-bt">
                {this.state.tripAdded.length < 1 ? (
                  <button type="button" className="btn btn-primary waves-effect waves-light"
                    style={{ width: "100%", backgroundColor: "#E7E7E7", borderColor: "#AAAAAA" }}
                    onClick={() => {
                      this.alert();
                    }}>
                    <span className="font-style-16 cl-gray">
                      {this.props.t("Next")}
                    </span>
                  </button>
                ) : (
                  <Link to={{ pathname: "/Trip/Addtrip/Selectregion", tripAdded: this.state.tripAdded }}>
                    <button
                      type="button" className="btn btn-primary waves-effect waves-light"
                      style={{ width: "100%", backgroundColor: "#224AAC", borderColor: "#224AAC" }}>
                      <span className="font-style-16">
                        {this.props.t("Next")}
                      </span>
                    </button>
                  </Link>
                )}
              </Col>
            </Row>
          </Container>
          {/* <Card style={{ width: "100%", backgroundColor: "#F0F0F0" }}>
            <CardBody>
              <Container fluid>
                <Row style={{ justifyContent: "flex-end", paddingLeft: 10 }}>
                  <Col lg={8} mg={8} sm={8}>
                                    </Col>
                  <Col xl={2} lg={3} md={6} sm={12} xs={12}>
                    {this.state.tripAdded.length < 1 ? (
                      <Link to={"/Trip/Addtrip"}>
                        <Button
                          style={{
                            width: "100%",
                            backgroundColor: "#E7E7E7",
                            borderColor: "#AAAAAA",
                          }}
                        >
                          <a style={{ color: "#8C8C8C" }}>
                            {this.props.t("Back")}
                          </a>
                        </Button>
                      </Link>
                    ) : (
                      <Link to={"/Trip/Addtrip"}>
                        <Button
                          onClick={() => {
                            this.removeState();
                          }}
                          style={{
                            width: "100%",
                            backgroundColor: "#E7E7E7",
                            borderColor: "#AAAAAA",
                          }}
                        >
                          <a style={{ color: "#8C8C8C" }}>
                            {this.props.t("Back")}
                          </a>
                        </Button>
                      </Link>
                    )}
                  </Col>
                  <Col xl={2} lg={3} md={6} sm={12} xs={12}>
                    {this.state.tripAdded.length < 1 ? (
                      <Button
                        className="mt-button-1"
                        style={{
                          width: "100%",
                          backgroundColor: "#E7E7E7",
                          borderColor: "#AAAAAA",
                        }}
                        onClick={() => {
                          this.alert();
                        }}
                      >
                        <a style={{ color: "#8C8C8C" }}>
                          {this.props.t("Next")}
                        </a>
                      </Button>
                    ) : (
                      <Link
                        to={{
                          pathname: "/Trip/Addtrip/Selectregion",
                          tripAdded: this.state.tripAdded,
                        }}
                      >
                        <Button
                          className="mt-button-1"
                          style={{
                            width: "100%",
                            backgroundColor: "#224AAC",
                            borderColor: "#224AAC",
                          }}
                        >
                          {this.props.t("Next")}
                        </Button>
                      </Link>
                    )}
                  </Col>
                </Row>
              </Container>
            </CardBody>
          </Card> */}
          {/* 
                    <Row className="justify-content-center mt-lg-5 row">
                        <Cardtimeperiod data={data} cardSelected={this.cardSelected} />
                        <Row style={{ marginTop: 50, justifyContent: 'center' }} >
                            <h4 style={{ color: '#224AAC' }}> {this.props.t('Step')} 1 / 3 </h4><h4 style={{ marginLeft: 15, color: 'black' }}>{this.props.t('Choose the duration of your desired travel plan')}</h4>
                        </Row>
                    </Row> */}
          <Cardtimeperiod data={data} cardSelected={this.cardSelected} />
          <Row style={{ marginTop: 50, justifyContent: "center" }}>
            <h4 style={{ color: "#224AAC" }}> {this.props.t("Step")} 1 / 3 </h4>
            <h4 style={{ marginLeft: 15, color: "black" }}>
              {this.props.t("Choose the duration of your desired travel plan")}
            </h4>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}
export default withTranslation()(Grouptimeperiod);
