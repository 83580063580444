import React, { Component } from 'react'
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import countries from '../../Joborder/men-component/countries.json'

class ReportSearchTourists extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            paginationModel: {
                pageSize: 10,
                page: 0,
            },
            rowCountState: 10,
            data_user: this.props.report.report_data,
        }
        console.log("props", props)
    }
    handlePaginationModelChange = (newModel) => {
        this.setState({ paginationModel: newModel });
    }
    getFullCountryName(countryCode) {
        const country = countries[countryCode];
        return country || countryCode; // ถ้าไม่เจอชื่อเต็มให้ใช้รหัสประเทศแทน
    }
    render() {

        const columns = [
            { field: 'no', headerName: 'ลำดับ', width: 100 },
            {
                field: 'full_name',
                headerName: 'ชื่อ-นามสกุล',
                width: 500,
                align: 'left',
                valueGetter: (params) => `${params.row.fname} ${params.row.lname}`
            },
            {
                field: 'gender',
                headerName: 'เพศ',
                width: 300,
                align: 'left',
            },
            {
                field: 'origincountry',
                headerName: 'ประเทศ',
                width: 300,
                align: 'left',
                valueGetter: (params) => this.getFullCountryName(params.row.origincountry),
                renderCell: (params) => (
                    params.value ? params.value : '-'
                ),
            },
            {
                field: 'passport',
                headerName: 'พาสปอร์ต',
                width: 300,
                align: 'left',
                renderCell: (params) => (
                    params.value ? params.value : '-'
                ),
            },
        ];
        if (this.state.isLoading === true) {
            return (
                <div></div>
            )
        }
        else {
            return (
                <React.Fragment>
                    <Box sx={{ height: 400, width: '100%' }}>
                        {this.state.data_user.length <= 0 ?
                            <></>
                            :
                            <DataGrid
                                getRowId={(row) => row.no}
                                rows={this.state.data_user}
                                columns={columns}
                                loading={this.state.isLoading}
                                pageSizeOptions={[10]}
                                onPaginationModelChange={this.handlePaginationModelChange}
                                disableRowSelectionOnClick
                            />
                        }

                    </Box>
                </React.Fragment>
            )
        }

    }
}
export default (
    (withTranslation()(ReportSearchTourists))
);
