import React, { Component } from "react";
//i18n
import { withTranslation } from 'react-i18next';
import './intro.css'
class FooterIntro extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
    }


    render() {
        return (
            <React.Fragment>
                <div >
                    {/* <div className="row" style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <div className="mr-15">
                            <p style={{ fontSize: 16, color: 'white', marginTop: 15 }}>ลิขสิทธิ์ ⓒ 2559 กรมการท่องเที่ยว (Department of Tourism)</p>
                            <p style={{ fontSize: 16, color: 'white', marginTop: 15 }}>ศูนย์ราชการเฉลิมพระเกียรติ 80 พรรษา 5 ธันวาคม 2550</p>
                            <p style={{ fontSize: 16, color: 'white', marginTop: 15 }}>อาคารรัฐประศาสนภักดี (อาคาร B) ศูนย์ราการฯแจ้งวัฒนะ ชั้น 2 - 3 เลขที่ 120 หมู่ที่ - ถนนแจ้งวัฒนะ</p>
                            <p style={{ fontSize: 16, color: 'white', marginTop: 15 }}>แขวงทุ่งสองห้อง เขตหลักสี่ กรุงเทพมหานคร 10210</p>
                            <p style={{ fontSize: 16, color: 'white', marginTop: 15 }}>เบอร์โทร: 02 141 3333 แฟกซ์: 02 143 9719</p>
                            <p style={{ fontSize: 16, color: 'white', marginTop: 15 }}>นโยบายความปลอดภัย | นโยบายการรักษาความปลอดภัยมั่นคงเว็บไซด์ | นโยบายการคุ้มครองข้อมูลส่วนบุคคลของ</p>
                            <div style={{ display: 'flex', marginTop: 40 }}>
                                <p style={{ fontSize: 16, color: 'white' }}>ที่อยู่ไปรษณีย์อีเล็กทรอนิกส์ (E-Mail)</p>
                                <div className="social-media-intro">
                                    <img src={'/assets/images/others/facebook.png'} alt={'facebook'} />
                                    <img src={'/assets/images/others/messenger.png'} alt={'messenger'} />
                                    <img src={'/assets/images/others/instagram.png'} alt={'instagram'} />
                                    <img src={'/assets/images/others/line.png'} alt={'line'} />
                                </div>
                            </div>
                            <div style={{ marginTop: 40 }}>
                                <p style={{ fontSize: 16, color: 'white' }}>webmaster@tourism.go.th</p>
                                <img style={{ width: 40, marginTop: 0, marginBottom: '1rem' }} src={'/assets/images/others/wifi.png'} alt={'wifi'} />
                            </div>
                        </div>
                        <div className="mr-15-end">
                            <div className="input-name">
                                <input type="text" placeholder="สมัครรับข้อมูลจากเรา" />
                                <i className="far fa-x2 fa-envelope-open" style={{ color: '#fff', marginLeft: -15 }}></i>
                                <span className="underline-animation"></span>
                            </div>
                            <div style={{ marginTop: 15 }}>
                                <p style={{ fontSize: 16, color: 'white' }}>สถิติผู้ใช้งานเว็บไซด์: 6,096,162</p>
                                <p style={{ fontSize: 16, color: 'white' }}>สถิติผู้ใช้งานเว็บไซด์วันนี้: 1,513</p>
                                <div className="call-center">
                                    <img src={'/assets/images/others/callcenter.png'} alt={'call_center'} />
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </React.Fragment >
        );
    }
}

export default (withTranslation()(FooterIntro));
