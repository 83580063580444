import React, { Component } from 'react'
//import PropTypes from 'prop-types'
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import './ReportStyle.scss';
import { Link } from "react-router-dom";
import Printer from 'react-pdf-print'
// import Lottie from "react-lottie";
// import * as loadingData from "../Joborder/men-component/loading.json";
import { API_ENDPOINT } from "../../assets/api";
//i18n
import { withTranslation } from 'react-i18next';
import SelectDateTab from './Component/SelectDateTab'
import ChartApex from './Component/ChartApex';
// import moment from 'moment'
// const defaultOptions = {
//     loop: true,
//     autoplay: true,
//     animationData: loadingData.default,
//     rendererSettings: {
//         preserveAspectRatio: "xMidYMid slice"
//     }
// };

const ids = ['1']
class ReportAdmin extends Component {
    /*static propTypes = {
         prop: PropTypes
     }*/
    constructor(props) {
        super(props);
        this.state = {
            fetch_report: false,
            error: true,
            report: {
                type: '',
                start_date: '',
                end_date: '',
                view: '',
                value: 0,
                data1: {
                    data: [],
                    series: [
                        {
                            data: []
                        }
                    ],
                    data1_count: '',
                },
                data2: {
                    data: [],
                    series: [
                        {
                            data: []
                        }
                    ],
                    data2_user_agency: '',
                },
                data3: {
                    data: [],
                    series: [
                        {
                            data: []
                        }
                    ],
                    data3_country: '',
                },
                data4: {
                    data: [],
                    series: [
                        {
                            data: []
                        }
                    ],
                    data4_user_guide: '',
                },
                data5: {
                    data: [],
                    series: [
                        {
                            data: []
                        }
                    ],
                    data5_place: '',
                }
            }

        }
        this.callback = this.callback.bind(this);
    };

    callback(target, data) {
        var obj = this.state.report
        console.log("OBJ", obj)
        switch (target) {
            case "date":
                if (obj.value === data.value && (obj.start_date === data.selected_period_start || obj.end_date === data.selected_period_end)) { return }
                obj.value = data.value

                if (data.value !== 'custom') {
                    obj.start_date = ''
                    obj.end_date = ''
                    this.setState(
                        {
                            report: obj,
                            fetch_report: false,
                        },
                        () => {
                            this.getData();
                        }
                    );
                }
                else {
                    obj.start_date = data.selected_period_start
                    obj.end_date = data.selected_period_end
                    this.setState(
                        {
                            report: obj,
                            fetch_report: true,
                        },
                        () => {
                            this.getData();
                        }
                    );
                }
                break;
            case "view":
                obj.view = data
                this.setState({
                    report: obj
                })
                if (obj.type === 'dashboard-executive') {
                    this.setState({
                        fetch_report: true
                    }, () => {
                        this.getData()
                    })
                }
                break;
            default:
                return
        }
    }

    getHeader() {
        var pathname = window.location.pathname;
        var obj = this.state.report
        switch (pathname) {
            case "/dashboard-executive":
                obj.type = "dashboard-executive"
                return this.props.t('รายงานสำหรับผู้บริหาร')
            default:
                return this.props.t('Report')
        }
    }

    async getData() {

        console.log("asdasdas-------")
        if (this.state.report.type === 'dashboard-executive' && this.state.report.value === 'custom' && (this.state.report.start_date === '' || this.state.report.end_date === '')) { return }
        if (this.state.report.type === 'dashboard-executive') {

            const path1 = 'report/joborder/count2';
            const object1 = {
                startdate: this.state.report.start_date,
                enddate: this.state.report.end_date,
                period: this.state.report.view
            }
            const path2 = 'report/joborder/user/agency';
            const object2 = {
                startdate: this.state.report.start_date,
                enddate: this.state.report.end_date,
                period: this.state.report.view
            }
            const path3 = 'report/joborder/country';
            const object3 = {
                startdate: this.state.report.start_date,
                enddate: this.state.report.end_date,
                period: this.state.report.view
            }
            const path4 = "report/joborder/user/guide"
            const object4 = {
                startdate: this.state.report.start_date,
                enddate: this.state.report.end_date,
                period: this.state.report.view
            }
            const path5 = "report/joborder/place"
            const object5 = {
                startdate: this.state.report.start_date,
                enddate: this.state.report.end_date,
                period: this.state.report.view
            }
            const response1 = await fetch(API_ENDPOINT + path1, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(object1)
            })
            const response2 = await fetch(API_ENDPOINT + path2, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(object2)
            })
            const response3 = await fetch(API_ENDPOINT + path3, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(object3)
            })
            const response4 = await fetch(API_ENDPOINT + path4, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(object4)
            })
            const response5 = await fetch(API_ENDPOINT + path5, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(object5)
            })
            // const [result1, result2, result3, result4, result5] = await Promise.all([response1, response2, response3, response4, response5]);
            const data1 = await response1.json();
            const data2 = await response2.json();
            const data3 = await response3.json();
            const data4 = await response4.json();
            const data5 = await response5.json();
            if (data1) {
                this.setState(prevState => ({
                    report: {
                        ...prevState.report,
                        data1: {
                            ...prevState.report.data1,
                            data: data1.data,
                            series: data1.series[0].data,
                            data1_count: "data1_count"
                        }
                    },
                    fetch_report: false
                }));
            }
            if (data2) {
                this.setState(prevState => ({
                    report: {
                        ...prevState.report,
                        data2: {
                            ...prevState.report.data1,
                            data: data2.data,
                            series: data2.series[0].data,
                            data2_user_agency: "data2_user_agency"
                        }
                    },
                    fetch_report: false
                }));
            }
            if (data3) {
                this.setState(prevState => ({
                    report: {
                        ...prevState.report,
                        data3: {
                            ...prevState.report.data3,
                            data: data3.data,
                            series: data3.series[0].data,
                            data3_country: "data3_country"
                        }
                    },
                    fetch_report: false
                }));
            }
            if (data4) {
                this.setState(prevState => ({
                    report: {
                        ...prevState.report,
                        data4: {
                            ...prevState.report.data4,
                            data: data4.data,
                            series: data4.series[0].data,
                            data4_user_guide: "data4_user_guide"
                        }
                    },
                    fetch_report: false
                }));
            }
            if (data5) {
                this.setState(prevState => ({
                    report: {
                        ...prevState.report,
                        data5: {
                            ...prevState.report.data5,
                            data: data5.data,
                            series: data5.series[0].data,
                            data5_place: "data5_place"
                        }
                    },
                    fetch_report: false
                }));
            }
        }
    }

    render() {
        console.log("report", this.state.report)
        return (
            <React.Fragment>
                <div className="page-content" id={ids[0]}>
                    <Container fluid>
                        <Row>
                            <Col xs="12">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Breadcrumbs title={this.getHeader()} breadcrumbItem={this.getHeader()} />
                                    {this.state.report.type === 'report-user-count' || this.state.report.type === 'report-attraction-count' ?
                                        <div className="d-print-none">
                                            <div className="float-right">
                                                <Link to="#" onClick={() => this.downloadCSV()} className="btn btn-success waves-effect waves-light mr-2"><i className="fa fa-download" aria-hidden="true"></i></Link>
                                            </div>
                                        </div>
                                        :
                                        ''
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Printer>
                            {this.state.report.type === 'dashboard-executive' &&
                                <>
                                    <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                        <CardBody>
                                            <SelectDateTab callback={this.callback} type={this.state.report.type}></SelectDateTab>
                                        </CardBody>
                                    </Card>
                                    <Row>
                                        {this.state.report.data1.data1_count === "data1_count" && (
                                            <Col sm={12} md={6}>
                                                {/* แสดง ChartApex สำหรับข้อมูลที่มีค่าเป็น "data1_count" */}
                                                <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                                    <CardBody>
                                                        <h4>จำนวนใบสั่งงาน Job Order</h4>
                                                    </CardBody>
                                                    <ChartApex report={this.state.report.data1}></ChartApex>
                                                </Card>
                                            </Col>
                                        )}

                                        {this.state.report.data2.data2_user_agency === "data2_user_agency" && (
                                            <Col sm={12} md={6}>
                                                {/* แสดง ChartApex สำหรับข้อมูลที่มีค่าเป็น "data2_user_agency" */}
                                                <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                                    <CardBody>
                                                        <h4>จำนวนธุรกิจนำเที่ยว</h4>
                                                    </CardBody>
                                                    <ChartApex report={this.state.report.data2}></ChartApex>
                                                </Card>
                                            </Col>
                                        )}

                                        {this.state.report.data3.data3_country === "data3_country" && (
                                            <Col sm={12} md={6}>
                                                {/* แสดง ChartApex สำหรับข้อมูลที่มีค่าเป็น "data2_user_agency" */}
                                                <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                                    <CardBody>
                                                        <h4>จำนวนนักท่องเที่ยวแยกตามประเทศ</h4>
                                                    </CardBody>
                                                    <ChartApex report={this.state.report.data3}></ChartApex>
                                                </Card>
                                            </Col>
                                        )}

                                        {this.state.report.data4.data4_user_guide === "data4_user_guide" && (
                                            <Col sm={12} md={6}>
                                                {/* แสดง ChartApex สำหรับข้อมูลที่มีค่าเป็น "data2_user_agency" */}
                                                <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                                    <CardBody>
                                                        <h4>จำนวนมัคคุเทศก์</h4>
                                                    </CardBody>
                                                    <ChartApex report={this.state.report.data4}></ChartApex>
                                                </Card>
                                            </Col>
                                        )}

                                        {this.state.report.data5.data5_place === "data5_place" && (
                                            <Col sm={12} md={12}>
                                                {/* แสดง ChartApex สำหรับข้อมูลที่มีค่าเป็น "data2_user_agency" */}
                                                <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                                    <CardBody>
                                                        <h4>จำนวนสถานที่ท่องเที่ยว</h4>
                                                    </CardBody>
                                                    <ChartApex report={this.state.report.data5}></ChartApex>
                                                </Card>
                                            </Col>
                                        )}
                                    </Row>
                                </>
                            }
                        </Printer>
                    </Container>
                </div>
            </React.Fragment >
        )
    }
}
export default withTranslation()(ReportAdmin);
