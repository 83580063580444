import React, { Component } from 'react';
// import { Link, useHistory } from "react-router-dom";
import { Container, FormGroup, Row, Col, Input, Button, Card, CardBody, CardImg, DropdownToggle, DropdownItem, DropdownMenu, ButtonDropdown, Label } from "reactstrap";
import { connect } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
// import Select from "react-select";
// import img4 from "../../../assets/images/product/img-4.png";
// import img7 from "../../../assets/images/product/img-7.png";
// import logo1 from "../../../assets/images/small/logos1.svg";
// import logo2 from "../../../assets/images/small/logos2.svg";
// import logo3 from "../../../assets/images/small/logos3.svg";
import { API_ENDPOINT } from "../../../assets/api";
import Swal from 'sweetalert2'
import { withTranslation } from 'react-i18next';

import '../style.scss';
// import { Dropdown } from 'bootstrap';
// import profile from '../../../assets/images/small/img-4.jpg';


class AddUsers extends Component {

    constructor(props) {
        super(props);
        this.empty_admin = {
            title: '',
            firstname: '',
            lastname: '',
            username: '',
            password: '',
            password_again: '',
            email: '',
            mobile: '',
            identication_card: '',
            photo: {}
        }
        this.empty_guide = {
            title: '',
            firstname: '',
            lastname: '',
            username: '',
            password: '',
            password_again: '',
            email: '',
            mobile: '',
            identication_card: '',
            photo: {},
            license: '',
            address: ''
        }
        this.empty_agency = {
            title: '',
            firstname: '',
            lastname: '',
            username: '',
            password: '',
            password_again: '',
            email: '',
            mobile: '',
            identication_card: '',
            photo: {},
            license: '',
            address: '',
            name: ''
        }
        this.empty_operator = {
            title: '',
            firstname: '',
            lastname: '',
            username: '',
            password: '',
            password_again: '',
            email: '',
            mobile: '',
            identication_card: '',
            photo: {}
        }
        this.empty_police = {
            title: '',
            firstname: '',
            lastname: '',
            username: '',
            password: '',
            password_again: '',
            email: '',
            mobile: '',
            identication_card: '',
            photo: {},
            address: ''
        }
        this.state = {
            selected_users_type: 'เลือกประเภทของผู้ใช้',
            admin: {
                title: '',
                firstname: '',
                lastname: '',
                username: '',
                password: '',
                password_again: '',
                email: '',
                mobile: '',
                identication_card: '',
                photo: {}
            },
            guide: {
                title: '',
                firstname: '',
                lastname: '',
                username: '',
                password: '',
                password_again: '',
                email: '',
                mobile: '',
                identication_card: '',
                photo: {},
                license: '',
                address: ''
            },
            agency: {
                title: '',
                firstname: '',
                lastname: '',
                username: '',
                password: '',
                password_again: '',
                email: '',
                mobile: '',
                identication_card: '',
                photo: {},
                license: '',
                address: '',
                name: ''
            },
            operator: {
                title: '',
                firstname: '',
                lastname: '',
                username: '',
                password: '',
                password_again: '',
                email: '',
                mobile: '',
                identication_card: '',
                photo: {},
                address: ''
            },
            police: {
                title: '',
                firstname: '',
                lastname: '',
                username: '',
                password: '',
                password_again: '',
                email: '',
                mobile: '',
                identication_card: '',
                photo: {},
                address: ''
            },
        }
    }

    async sweet_error(msg) {
        return Swal.fire({
            icon: 'error',
            title: 'ผิดพลาด',
            text: msg,
        })
    }
    async sweet_success(msg) {
        return Swal.fire({
            icon: 'success',
            title: 'สำเร็จ',
            text: msg,
        })
    }

    async add_admin_users() {
        var admin = this.state.admin
        var token = localStorage.getItem('authToken');
        token = JSON.parse(token)

        if (admin.firstname === '') {
            return this.sweet_error('กรุณาระบุชื่อจริงผู้ใช้ ')
        }

        if (admin.lastname === '') {
            return this.sweet_error('กรุณาระบุนามสกุลผู้ใช้ ')
        }

        if (admin.password === '') {
            return this.sweet_error('กรุณาระบุรหัสผ่านผู้ใช้ ')
        }

        if (admin.password_again === '') {
            return this.sweet_error('กรุณาระบุรหัสผ่านผู้ใช้อีกครั้ง ')
        }

        if (admin.password_again !== admin.password) {
            return this.sweet_error('กรุณาระบุรหัสผ่านให้ตรงกัน ')
        }

        if (admin.email === '') {
            return this.sweet_error('กรุณาระบุ email ')
        }

        if (admin.mobile === '') {
            return this.sweet_error('กรุณาระบุ mobile ')
        }

        if (admin.identication_card === '' || admin.identication_card.length !== 13) {
            return this.sweet_error('กรุณาระบุเลขบัตรประชาชนให้ถูกต้อ้ง ')
        }
        if (typeof admin.photo.length === 'undefined') {
            return this.sweet_error('กรุณาเลือกรูปผู้สมัคร ')
        }
        let formData = new FormData();
        formData.append('username', admin.username);   //append the values with key, value pair
        formData.append('password', admin.password);
        formData.append('email', admin.email);
        formData.append('title', admin.title);
        formData.append('firstname', admin.firstname);
        formData.append('lastname', admin.lastname);
        formData.append('mobile', admin.mobile);
        formData.append('identication_card', admin.identication_card);
        formData.append('photo', admin.photo[0], admin.photo[0].name);
        // wait for api logic
        var response = await fetch(API_ENDPOINT + 'api/v2/web/users/admin/add', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            headers: {
                // 'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token.data.type + ' ' + token.data.token
            },
            body: formData // body data type must match "Content-Type" header
        });
        response = await response.json()
        if (response.status === 'success') {
            this.sweet_success('เพิ่มผู้ใช้สำเร็จแล้ว')
            this.setState({
                admin: this.empty_admin
            })
            document.getElementById('admin_photo_preview').src = '/assets/images/others/avatar.png'
            this.props.history.push("/User");
        } else {
            this.sweet_error(response.users)
        }
    }

    async add_guide_users() {
        var guide = this.state.guide
        var token = localStorage.getItem('authToken');
        token = JSON.parse(token)
        if (guide.firstname === '') {
            return this.sweet_error('กรุณาระบุชื่อจริงผู้ใช้ ')
        }

        if (guide.lastname === '') {
            return this.sweet_error('กรุณาระบุนามสกุลผู้ใช้ ')
        }

        if (guide.password === '') {
            return this.sweet_error('กรุณาระบุรหัสผ่านผู้ใช้ ')
        }

        if (guide.password_again === '') {
            return this.sweet_error('กรุณาระบุรหัสผ่านผู้ใช้อีกครั้ง ')
        }

        if (guide.password_again !== guide.password) {
            return this.sweet_error('กรุณาระบุรหัสผ่านให้ตรงกัน ')
        }

        if (guide.email === '') {
            return this.sweet_error('กรุณาระบุ email ')
        }

        if (guide.mobile === '') {
            return this.sweet_error('กรุณาระบุ mobile ')
        }

        if (guide.identication_card === '' || guide.identication_card.length !== 13) {
            return this.sweet_error('กรุณาระบุเลขบัตรประชาชนให้ถูกต้อง ')
        }
        if (typeof guide.photo.length === 'undefined') {
            return this.sweet_error('กรุณาเลือกรูปผู้สมัคร ')
        }

        if (guide.license === '') {
            return this.sweet_error('กรุณาระบุเลขที่ใบอนุญาต ')
        }

        if (guide.address === '') {
            return this.sweet_error('กรุณาระบุที่อยู่ ')
        }

        // wait for api logic
        let formData = new FormData();
        formData.append('username', guide.username);   //append the values with key, value pair
        formData.append('password', guide.password);
        formData.append('email', guide.email);
        formData.append('mobile', guide.mobile);
        formData.append('title', guide.title);
        formData.append('firstname', guide.firstname);
        formData.append('lastname', guide.lastname);
        formData.append('identication_card', guide.identication_card);
        formData.append('photo', guide.photo[0], guide.photo[0].name);
        formData.append('license', guide.license);
        formData.append('address', guide.address);
        // wait for api logic
        var response = await fetch(API_ENDPOINT + 'api/v2/web/users/guide/add', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            headers: {
                // 'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token.data.type + ' ' + token.data.token
            },
            body: formData // body data type must match "Content-Type" header
        });
        response = await response.json()
        if (response.status === 'success') {
            this.sweet_success('เพิ่มผู้ใช้สำเร็จแล้ว')
            this.setState({
                guide: this.empty_guide
            })
            document.getElementById('guide_photo_preview').src = '/assets/images/others/avatar.png'
            this.props.history.push("/User");
        } else {
            this.sweet_error(response.users)
        }
    }

    async add_agency_users() {
        var agency = this.state.agency
        var token = localStorage.getItem('authToken');
        token = JSON.parse(token)
        if (agency.firstname === '') {
            return this.sweet_error('กรุณาระบุชื่อจริงผู้ใช้ ')
        }

        if (agency.lastname === '') {
            return this.sweet_error('กรุณาระบุนามสกุลผู้ใช้ ')
        }

        if (agency.password === '') {
            return this.sweet_error('กรุณาระบุรหัสผ่านผู้ใช้ ')
        }

        if (agency.password_again === '') {
            return this.sweet_error('กรุณาระบุรหัสผ่านผู้ใช้อีกครั้ง ')
        }

        if (agency.password_again !== agency.password) {
            return this.sweet_error('กรุณาระบุรหัสผ่านให้ตรงกัน ')
        }

        if (agency.email === '') {
            return this.sweet_error('กรุณาระบุ email ')
        }

        if (agency.mobile === '') {
            return this.sweet_error('กรุณาระบุ mobile ')
        }

        if (agency.identication_card === '' || agency.identication_card.length !== 13) {
            return this.sweet_error('กรุณาระบุเลขบัตรประชาชนให้ถูกต้อ้ง ')
        }
        if (typeof agency.photo.length === 'undefined') {
            return this.sweet_error('กรุณาเลือกรูปผู้สมัคร ')
        }

        if (agency.license === '') {
            return this.sweet_error('กรุณาระบุเลขที่ใบอนุญาต ')
        }

        if (agency.name === '') {
            return this.sweet_error('กรุณาระบุชื่อบริษัทนำเที่ยว ')
        }

        // wait for api logic
        let formData = new FormData();
        formData.append('username', agency.username);   //append the values with key, value pair
        formData.append('password', agency.password);
        formData.append('email', agency.email);
        formData.append('mobile', agency.mobile);
        formData.append('title', agency.title);
        formData.append('firstname', agency.firstname);
        formData.append('lastname', agency.lastname);
        formData.append('identication_card', agency.identication_card);
        formData.append('photo', agency.photo[0], agency.photo[0].name);
        formData.append('license', agency.license);
        formData.append('address', agency.address);
        formData.append('name', agency.name);
        // wait for api logic
        var response = await fetch(API_ENDPOINT + 'api/v2/web/users/agency/add', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            headers: {
                // 'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token.data.type + ' ' + token.data.token
            },
            body: formData // body data type must match "Content-Type" header
        });
        response = await response.json()
        if (response.status === 'success') {
            this.sweet_success('เพิ่มผู้ใช้สำเร็จแล้ว')
            this.setState({
                agency: this.empty_agency
            })
            document.getElementById('agency_photo_preview').src = '/assets/images/others/avatar.png'
            this.props.history.push("/User");
        } else {
            this.sweet_error(response.users)
        }
    }

    async add_operator_users() {
        var operator = this.state.operator
        var token = localStorage.getItem('authToken');
        token = JSON.parse(token)
        if (operator.firstname === '') {

            return this.sweet_error('กรุณาระบุชื่อจริงผู้ใช้ ')
        }

        if (operator.lastname === '') {

            return this.sweet_error('กรุณาระบุนามสกุลผู้ใช้ ')
        }

        if (operator.password === '') {

            return this.sweet_error('กรุณาระบุรหัสผ่านผู้ใช้ ')
        }

        if (operator.password_again === '') {

            return this.sweet_error('กรุณาระบุรหัสผ่านผู้ใช้อีกครั้ง ')
        }

        if (operator.password_again !== operator.password) {

            return this.sweet_error('กรุณาระบุรหัสผ่านให้ตรงกัน ')
        }

        if (operator.email === '') {
            return this.sweet_error('กรุณาระบุ email ')
        }

        if (operator.mobile === '') {
            return this.sweet_error('กรุณาระบุ mobile ')
        }

        if (operator.identication_card === '' || operator.identication_card.length !== 13) {
            return this.sweet_error('กรุณาระบุเลขบัตรประชาชนให้ถูกต้อง ')
        }
        if (typeof operator.photo.length === 'undefined') {
            return this.sweet_error('กรุณาเลือกรูปผู้สมัคร ')
        }

        // wait for api logic
        let formData = new FormData();
        formData.append('username', operator.username);   //append the values with key, value pair
        formData.append('password', operator.password);
        formData.append('email', operator.email);
        formData.append('mobile', operator.mobile);
        formData.append('title', operator.title);
        formData.append('firstname', operator.firstname);
        formData.append('lastname', operator.lastname);
        formData.append('identication_card', operator.identication_card);
        // formData.append('photo', operator.photo[0], operator.photo[0].name);
        // console.log("---------------",operator.username,operator.password,operator.email,operator.mobile,operator.title,operator.firstname,operator.lastname,operator.identication_card);
        // return

        // wait for api logic
        var response = await fetch(API_ENDPOINT + 'api/v2/web/users/operator/add', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            headers: {
                // 'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token.data.type + ' ' + token.data.token
            },
            body: formData // body data type must match "Content-Type" header
        });
        response = await response.json()
        if (response.status === 'success') {
            this.sweet_success('เพิ่มผู้ใช้สำเร็จแล้ว')
            this.setState({
                guide: this.empty_guide
            })
            document.getElementById('operator_photo_preview').src = '/assets/images/others/avatar.png'
            this.props.history.push("/User");
        } else {
            this.sweet_error(response.users)
        }
    }

    async add_police_users() {
        var police = this.state.police
        var token = localStorage.getItem('authToken');
        token = JSON.parse(token)
        if (police.firstname === '') {
            return this.sweet_error('กรุณาระบุชื่อจริงผู้ใช้ ')
        }

        if (police.lastname === '') {
            return this.sweet_error('กรุณาระบุนามสกุลผู้ใช้ ')
        }

        if (police.password === '') {
            return this.sweet_error('กรุณาระบุรหัสผ่านผู้ใช้ ')
        }

        if (police.password_again === '') {
            return this.sweet_error('กรุณาระบุรหัสผ่านผู้ใช้อีกครั้ง ')
        }

        if (police.password_again !== police.password) {
            return this.sweet_error('กรุณาระบุรหัสผ่านให้ตรงกัน ')
        }

        if (police.email === '') {
            return this.sweet_error('กรุณาระบุ email ')
        }

        if (police.mobile === '') {
            return this.sweet_error('กรุณาระบุ mobile ')
        }

        if (police.identication_card === '' || police.identication_card.length !== 13) {
            return this.sweet_error('กรุณาระบุเลขบัตรประชาชนให้ถูกต้อง ')
        }
        if (typeof police.photo.length === 'undefined') {
            return this.sweet_error('กรุณาเลือกรูปผู้สมัคร ')
        }

        // wait for api logic
        let formData = new FormData();
        formData.append('username', police.username);   //append the values with key, value pair
        formData.append('password', police.password);
        formData.append('email', police.email);
        formData.append('mobile', police.mobile);
        formData.append('title', police.title);
        formData.append('firstname', police.firstname);
        formData.append('lastname', police.lastname);
        formData.append('identication_card', police.identication_card);
        formData.append('photo', police.photo[0], police.photo[0].name);
        // wait for api logic
        var response = await fetch(API_ENDPOINT + 'api/v2/web/users/police/add', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            headers: {
                // 'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token.data.type + ' ' + token.data.token
            },
            body: formData // body data type must match "Content-Type" header
        });
        response = await response.json()
        if (response.status === 'success') {
            this.sweet_success('เพิ่มผู้ใช้สำเร็จแล้ว')
            this.setState({
                guide: this.empty_guide
            })
            document.getElementById('police_photo_preview').src = '/assets/images/others/avatar.png'
            this.props.history.push("/User");
        } else {
            this.sweet_error(response.users)
        }
    }

    render() {
        // const { selectedUser } = this.state;
        if (this.state.isLoading === true) {
            return (
                <React.Fragment></React.Fragment>
            )
        }
        else {
            return (
                <React.Fragment>
                    <div className="page-content">
                        <Container fluid>
                            {/* <Row>
                                <Col xl="12">
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Breadcrumbs title="เพิ่มข้อมูลผู้ใช้งาน" breadcrumbItem={this.props.t('เพิ่มข้อมูลผู้ใช้งาน')} />
                                        <Row style={{ margin: 0, alignItems: 'center' }}>
                                            <Link to={{ pathname: './component/TablePlace/TableplaceDv.js' }} style={{ marginRight: '1rem' }}>
                                                <button type="button" className="btn btn-primary waves-effect waves-light" style={{ width: '100%', minWidth: 200, background: '#808080', borderColor: '#808080' }}>
                                                    <span className="font-style-16"><span>{this.props.t('ยกเลิก')}</span></span>
                                                </button>
                                            </Link>
                                            <div >
                                                <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() => this.add_police_users()} style={{ width: '100%', minWidth: 200, background: '#28A745', borderColor: '#28A745' }}>
                                                    <span className="font-style-16"><span>{this.props.t('ยืนยันข้อมูล')}</span></span>
                                                </button>
                                            </div>
                                        </Row>
                                    </div>
                                </Col>
                            </Row> */}
                            <Row style={{ display: 'flex', alignItems: 'center' }}>
                                <Col xl={8} lg={6} md={6} sm={12} xs={12}>
                                    <Breadcrumbs title="เพิ่มข้อมูลผู้ใช้งานใหม่" breadcrumbItem={this.props.t('เพิ่มข้อมูลผู้ใช้งานใหม่')} />
                                </Col>
                                {/* <Col xl={2} lg={3} md={3} sm={12} xs={12}>
                                    <Link to={{ pathname: './component/TablePlace/TableplaceDv.js' }} style={{ marginRight: '1rem' }}>
                                        <button type="button" className="btn btn-primary waves-effect waves-light" style={{ width: '100%', background: '#808080', borderColor: '#808080' }}>
                                            <span className="font-style-16">{this.props.t('ยกเลิก')}</span>
                                        </button>
                                    </Link>
                                </Col>
                                <Col xl={2} lg={3} md={3} sm={12} xs={12} className="mr-bt">
                                    <div >
                                        <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() => this.add_police_users()} style={{ width: '100%', background: '#28A745', borderColor: '#28A745' }}>
                                            <span className="font-style-16">{this.props.t('ยืนยันข้อมูล')}</span>
                                        </button>
                                    </div>
                                </Col> */}
                            </Row>
                            <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                <CardBody>
                                    <div>
                                        <h2 className="font-style-24 cl-blue">{this.props.t('ข้อมูลผู้ใช้งาน')}</h2>
                                    </div>
                                    <hr></hr>
                                    <div style={{ marginBottom: '1.5rem' }}>
                                        <h4 className="font-style-16 cl-black">{this.props.t('Please select a user type')}<span style={{ color: 'red', marginLeft: 5 }}>*</span></h4>
                                        <Row>
                                            <Col xl={3}>
                                                <ButtonDropdown isOpen={this.state.drp_secondary} style={{ width: '100%' }}
                                                    toggle={() =>
                                                        this.setState({
                                                            drp_secondary: !this.state.drp_secondary
                                                        })
                                                    }>
                                                    <DropdownToggle style={{ backgroundColor: 'rgba(255, 0, 0, -0.7)', width: '100%', border: '1px solid #CED4DA' }} caret>
                                                        <Row className="font-style-16 cl-gray" style={{ cursor: 'pointer', justifyContent: 'space-between', margin: 0 }}>
                                                            {this.state.selected_users_type}
                                                            <i className="mdi mdi-chevron-down"></i>
                                                        </Row>
                                                        {/* <h5 className="ml-5" style={{ cursor: 'pointer', fontWeight: 'bold' }}>
                                                    {this.state.selected_users_type}
                                                    <i className="mdi mdi-chevron-down ml-5 mr-3"></i>
                                                </h5> */}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={() => { this.setState({ selected_users_type: 'admin', admin: this.empty_admin }) }}>{this.props.t('Add an administrator')}</DropdownItem>
                                                        {/* <DropdownItem onClick={() => { this.setState({ selected_users_type: 'guide', guide: this.empty_guide }) }}>เพิ่มมัคคุเทศก์</DropdownItem> */}
                                                        {/* <DropdownItem onClick={() => { this.setState({ selected_users_type: 'agency', agency: this.empty_agency }) }}>เพิ่มบริษัทนำเที่ยว</DropdownItem> */}
                                                        <DropdownItem onClick={() => { this.setState({ selected_users_type: 'operator', admin: this.empty_operator }) }}>{this.props.t('Add an operatoristrator')}</DropdownItem>
                                                        {/* <DropdownItem onClick={() => { this.setState({ selected_users_type: 'police', agency: this.empty_agency }) }}>เพิ่มตำรวจท่องเที่ยว</DropdownItem> */}
                                                    </DropdownMenu>
                                                </ButtonDropdown>
                                            </Col>
                                        </Row>
                                    </div>
                                    <hr></hr>
                                    {/* <div className="event-timeline-dot mt-4 ml-1 mr-3">
                                            <i className="bx bx-right-arrow-circle" style={{ paddingTop: 6 }}></i>
                                        </div> */}
                                    {/* <Card style={{ width: '96%', height: 'auto', border: '3px solid #E1E1E1' }}> */}
                                    {/* <Card>
                                            <CardBody>
                                        <Col xl={12} lg={12} md={12} sm={12} xs={12} >
                                            <Row style={{ margin: 'auto' }}>
                                                <Col xl={6} lg={6} md={6} sm={12} xs={12} style={{ alignSelf: 'center' }}>
                                                    <h4 style={{ fontWeight: 'bold' }}>{this.props.t('Please select a user type')}</h4>
                                                </Col>
                                                <Col xl={6} lg={6} md={6} sm={12} xs={12} style={{ textAlign: 'end', alignSelf: 'center' }}>
                                                    <ButtonDropdown isOpen={this.state.drp_secondary}
                                                        toggle={() =>
                                                            this.setState({
                                                                drp_secondary: !this.state.drp_secondary
                                                            })
                                                        }>
                                                        <DropdownToggle style={{ backgroundColor: 'rgba(255, 0, 0, -0.7)', width: '100%' }}
                                                            caret
                                                        >
                                                            <Row>
                                                                <h5 className="ml-5" style={{ cursor: 'pointer', fontWeight: 'bold' }}>
                                                                    {this.state.selected_users_type}
                                                                    <i className="mdi mdi-chevron-down ml-5 mr-3"></i>
                                                                </h5>
                                                            </Row>
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem onClick={() => { this.setState({ selected_users_type: 'admin', admin: this.empty_admin }) }}>เพิ่มผู้ดูแลระบบ</DropdownItem>
                                                            <DropdownItem onClick={() => { this.setState({ selected_users_type: 'guide', guide: this.empty_guide }) }}>เพิ่มมัคคุเทศก์</DropdownItem>
                                                                    <DropdownItem onClick={() => { this.setState({ selected_users_type: 'agency', agency: this.empty_agency }) }}>เพิ่มบริษัทนำเที่ยว</DropdownItem>
                                                            <DropdownItem onClick={() => { this.setState({ selected_users_type: 'operator', admin: this.empty_operator }) }}>เพิ่มเจ้าหน้าที่กรมการท่องเที่ยว</DropdownItem>
                                                            <DropdownItem onClick={() => { this.setState({ selected_users_type: 'police', agency: this.empty_agency }) }}>เพิ่มตำรวจท่องเที่ยว</DropdownItem>
                                                        </DropdownMenu>
                                                    </ButtonDropdown>
                                                </Col>
                                            </Row>
                                        </Col>
                                        </CardBody>
                                        </Card> */}

                                    {this.state.selected_users_type === 'เลือกประเภทของผู้ใช้' &&
                                        // <Card style={{ height: 'auto' }}>
                                        //     <CardBody className="text-center">
                                        //         <div> {this.props.t('Please select the type of user')} </div>
                                        //     </CardBody>
                                        // </Card>
                                        <></>
                                    }
                                    {this.state.selected_users_type === 'admin' &&
                                        <div>
                                            <Row style={{ margin: 0 }}>
                                                <h4 className="font-style-18 cl-gray">{this.props.t('Add an administrator')}</h4>
                                            </Row>
                                            <Row>
                                                <CardImg id="admin_photo_preview" src="/assets/images/others/avatar.png" alt="" className="rounded-circle img-lg" />
                                                <Col xl={4} md={6} style={{ marginTop: '2rem' }}>
                                                    <h4 className="font-style-16 cl-black">{this.props.t('Upload your image')}</h4>
                                                    <FormGroup>
                                                        <input onChange={(e) => {
                                                            var files = e.target.files
                                                            var allow_input = ['jpg', 'jpeg', 'png', 'gif']
                                                            var found = false
                                                            for (var allow of allow_input) {
                                                                if (files[0].type.search(allow) > -1) {
                                                                    found = true
                                                                }
                                                            }
                                                            if (found) {
                                                                var reader = new FileReader();
                                                                reader.onload = function (e) {
                                                                    document.getElementById('admin_photo_preview').src = e.target.result
                                                                }
                                                                reader.readAsDataURL(files[0]); // convert to base64 string

                                                                let admin = { ...this.state.admin };
                                                                admin.photo = files;
                                                                this.setState({ admin }, () => {
                                                                    console.log(this.state.admin)
                                                                })
                                                            } else {
                                                                Swal.fire({
                                                                    icon: 'error',
                                                                    title: 'ผิดพลาด',
                                                                    text: 'อนุญาตสนามสกุล jpg , jpeg , png และ gif เท่านั้น !',
                                                                })
                                                                let admin = { ...this.state.admin };
                                                                admin.photo = {};
                                                                e.target.value = null
                                                                this.setState({ admin }, () => {
                                                                    console.log(this.state.admin)
                                                                })
                                                            }
                                                        }} type="file" className="custom-file-input" id="admin_photo_files" />
                                                        <label className="custom-file-label" htmlFor="customFile">{this.props.t('Select Image')}</label>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: '1rem' }}>
                                                <Col>
                                                    <h4 className="font-style-16 cl-gray">ข้อมูลส่วนตัว :</h4>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: '0.5rem' }}>
                                                <Col lg="2">
                                                    <FormGroup>
                                                        <Label for="Title" className="font-style-16 cl-black">{this.props.t('Title')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.admin.title} onChange={(e) => { var admin = { ...this.state.admin }; admin.title = e.target.value; this.setState({ admin }) }} type="text" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="Firstname" className="font-style-16 cl-black">{this.props.t('First name')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.admin.firstname} onChange={(e) => { var admin = { ...this.state.admin }; admin.firstname = e.target.value; this.setState({ admin }) }} type="text" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="Lastname" className="font-style-16 cl-black">{this.props.t('Last name')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.admin.lastname} onChange={(e) => { var admin = { ...this.state.admin }; admin.lastname = e.target.value; this.setState({ admin }) }} type="text" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="Email" className="font-style-16 cl-black">{this.props.t('Email')}</Label>
                                                        <Input value={this.state.admin.email} onChange={(e) => { var admin = { ...this.state.admin }; admin.email = e.target.value; this.setState({ admin }) }} type="text" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="Mobile" className="font-style-16 cl-black">{this.props.t('Mobile')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.admin.mobile} onChange={(e) => { var admin = { ...this.state.admin }; admin.mobile = e.target.value; this.setState({ admin }) }} type="text" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="ID_card_number" className="font-style-16 cl-black">{this.props.t('ID card number')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.admin.identication_card} onChange={(e) => { var admin = { ...this.state.admin }; admin.identication_card = e.target.value; this.setState({ admin }) }} type="text" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: '1rem' }}>
                                                <Col>
                                                    <h4 className="font-style-16 cl-gray">ข้อมูลเข้าสู่ระบบ :</h4>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: '0.5rem' }}>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="Username" className="font-style-16 cl-black">{this.props.t('User name')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.admin.username} onChange={(e) => { var admin = { ...this.state.admin }; admin.username = e.target.value; this.setState({ admin }) }} type="text" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="Password" className="font-style-16 cl-black">{this.props.t('Password')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.admin.password} onChange={(e) => { var admin = { ...this.state.admin }; admin.password = e.target.value; this.setState({ admin }) }} type="password" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="Confirmpassword" className="font-style-16 cl-black">{this.props.t('Confirm Password')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.admin.password_again} onChange={(e) => { var admin = { ...this.state.admin }; admin.password_again = e.target.value; this.setState({ admin }) }} type="password" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <hr></hr>
                                            <Row>
                                                <Col lg={8} md={0} >
                                                </Col>
                                                <Col lg={2} md={6} sm={12} xs={12}>
                                                    <Button style={{ width: '100%' }}>ยกเลิก</Button>
                                                </Col>
                                                <Col lg={2} md={6} sm={12} xs={12}>
                                                    <Button onClick={() => this.add_admin_users()} style={{ width: '100%', backgroundColor: '#224AAC', borderColor: '#224AAC' }}>ยืนยันการเพิ่มผู้ใช้งาน</Button>
                                                </Col>
                                            </Row>
                                        </div>
                                        // <div className="event-timeline-dot mt-4 ml-1 mr-3">
                                        //     <i className="bx bx-right-arrow-circle" style={{ paddingTop: 6 }}></i>
                                        // </div>
                                        // <Card>
                                        //     <CardBody>
                                        // <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                        //     <Row style={{ margin: 'auto' }}>
                                        //         <Col xl={6} lg={6} md={6} sm={12} xs={12} style={{ alignSelf: 'center' }}>
                                        //             <h4 className="font-style-18 cl-gray">{this.props.t('Add an administrator')}</h4>
                                        //         </Col>
                                        //     </Row>
                                        //     <Row>
                                        //         <Col xl={12}>
                                        //             <Row className="mt-2">
                                        //                 <Col xl={2}>
                                        //                     <div className="ml-5">
                                        //                         <img id="admin_photo_preview" style={{ maxWidth: '30%', minHeight: '150px' }} src="/assets/images/others/avatar.png" />
                                        //                         <CardImg id="admin_photo_preview" src="/assets/images/others/avatar.png" alt="" className="rounded-circle avatar-lg" />
                                        //                     </div>
                                        //                 </Col>
                                        //                 <Col xl={10}>
                                        //                     <Row>
                                        //                         <Col xl={12} className="p-0">
                                        //                             <h4 style={{ fontWeight: 'bold', color: '#224AAC' }}>{this.props.t('Upload your image')}</h4>
                                        //                         </Col>
                                        //                         <Col xl={6} className="mt-1">
                                        //                             <FormGroup>
                                        //                                 <input onChange={(e) => {
                                        //                                     var files = e.target.files
                                        //                                     var allow_input = ['jpg', 'jpeg', 'png', 'gif']
                                        //                                     var found = false
                                        //                                     for (var allow of allow_input) {
                                        //                                         if (files[0].type.search(allow) > -1) {
                                        //                                             found = true
                                        //                                         }
                                        //                                     }
                                        //                                     if (found) {
                                        //                                         var reader = new FileReader();
                                        //                                         reader.onload = function (e) {
                                        //                                             document.getElementById('admin_photo_preview').src = e.target.result
                                        //                                         }
                                        //                                         reader.readAsDataURL(files[0]); // convert to base64 string

                                        //                                         var admin = { ...this.state.admin };
                                        //                                         admin.photo = files;
                                        //                                         this.setState({ admin }, () => {
                                        //                                             console.log(this.state.admin)
                                        //                                         })
                                        //                                     } else {
                                        //                                         Swal.fire({
                                        //                                             icon: 'error',
                                        //                                             title: 'ผิดพลาด',
                                        //                                             text: 'อนุญาตสนามสกุล jpg , jpeg , png และ gif เท่านั้น !',
                                        //                                         })
                                        //                                         var admin = { ...this.state.admin };
                                        //                                         admin.photo = {};
                                        //                                         e.target.value = null
                                        //                                         this.setState({ admin }, () => {
                                        //                                             console.log(this.state.admin)
                                        //                                         })
                                        //                                     }
                                        //                                 }} type="file" className="custom-file-input" id="admin_photo_files" />
                                        //                                 <label className="custom-file-label" htmlFor="customFile">{this.props.t('Select Image')}</label>
                                        //                             </FormGroup>
                                        //                         </Col>
                                        //                     </Row>
                                        //                 </Col>
                                        //             </Row>
                                        //         </Col>
                                        //     </Row>

                                        // <Row className="mt-2">
                                        //     <Col>
                                        //         <h4 style={{ fontWeight: 'bold', color: '#224AAC' }}>ข้อมูลบริษัทนำเที่ยว :</h4>
                                        //     </Col>
                                        // </Row>
                                        // <Row>
                                        //     <Col lg="4">
                                        //         <FormGroup>
                                        //             <Label for="basicpill-firstname-input1">{this.props.t('User name')}</Label>
                                        //             <Input value={this.state.admin.username} onChange={(e) => { var admin = { ...this.state.admin }; admin.username = e.target.value; this.setState({ admin }) }} type="text" className="form-control" />
                                        //         </FormGroup>
                                        //     </Col>
                                        // </Row>
                                        // <Row>
                                        //     <Col lg="4">
                                        //         <FormGroup>
                                        //             <Label for="basicpill-firstname-input1">{this.props.t('Password')}</Label>
                                        //             <Input value={this.state.admin.password} onChange={(e) => { var admin = { ...this.state.admin }; admin.password = e.target.value; this.setState({ admin }) }} type="password" className="form-control" />
                                        //         </FormGroup>
                                        //     </Col>
                                        //     <Col lg="4">
                                        //         <FormGroup>
                                        //             <Label for="basicpill-lastname-input2">{this.props.t('Enter password again')}</Label>
                                        //             <Input value={this.state.admin.password_again} onChange={(e) => { var admin = { ...this.state.admin }; admin.password_again = e.target.value; this.setState({ admin }) }} type="password" className="form-control" id="basicpill-lastname-input2" />
                                        //         </FormGroup>
                                        //     </Col>
                                        // </Row>
                                        // <Row>
                                        //     <Col lg="4">
                                        //         <FormGroup>
                                        //             <Label for="basicpill-title-input1">{this.props.t('Title')}</Label>
                                        //             <Input value={this.state.admin.title} onChange={(e) => { var admin = { ...this.state.admin }; admin.title = e.target.value; this.setState({ admin }) }} type="text" className="form-control" id="basicpill-title-input1" />
                                        //         </FormGroup>
                                        //     </Col>
                                        //     <Col lg="4">
                                        //         <FormGroup>
                                        //             <Label for="basicpill-firstname-input1">{this.props.t('First name')}</Label>
                                        //             <Input value={this.state.admin.firstname} onChange={(e) => { var admin = { ...this.state.admin }; admin.firstname = e.target.value; this.setState({ admin }) }} type="text" className="form-control" id="basicpill-firstname-input1" />
                                        //         </FormGroup>
                                        //     </Col>
                                        //     <Col lg="4">
                                        //         <FormGroup>
                                        //             <Label for="basicpill-lastname-input2">{this.props.t('Last name')}</Label>
                                        //             <Input value={this.state.admin.lastname} onChange={(e) => { var admin = { ...this.state.admin }; admin.lastname = e.target.value; this.setState({ admin }) }} type="text" className="form-control" id="basicpill-lastname-input2" />
                                        //         </FormGroup>
                                        //     </Col>
                                        // </Row>
                                        // <Row>
                                        //     <Col lg="4">
                                        //         <FormGroup>
                                        //             <Label for="basicpill-firstname-input1">{this.props.t('Mobile')}</Label>
                                        //             <Input value={this.state.admin.mobile} onChange={(e) => { var admin = { ...this.state.admin }; admin.mobile = e.target.value; this.setState({ admin }) }} type="text" className="form-control" id="basicpill-firstname-input1" />
                                        //         </FormGroup>
                                        //     </Col>
                                        //     <Col lg="4">
                                        //         <FormGroup>
                                        //             <Label for="basicpill-firstname-input1">{this.props.t('Email')}</Label>
                                        //             <Input value={this.state.admin.email} onChange={(e) => { var admin = { ...this.state.admin }; admin.email = e.target.value; this.setState({ admin }) }} type="text" className="form-control" id="basicpill-firstname-input1" />
                                        //         </FormGroup>
                                        //     </Col>
                                        //     <Col lg="4">
                                        //         <FormGroup>
                                        //             <Label for="basicpill-lastname-input2">{this.props.t('ID card number')}</Label>
                                        //             <Input value={this.state.admin.identication_card} onChange={(e) => { var admin = { ...this.state.admin }; admin.identication_card = e.target.value; this.setState({ admin }) }} type="text" className="form-control" id="basicpill-lastname-input2" />
                                        //         </FormGroup>
                                        //     </Col>
                                        // </Row>                                     

                                    }
                                    {this.state.selected_users_type === 'guide' &&
                                        <div>
                                            <Row style={{ margin: 0 }}>
                                                <h4 className="font-style-18 cl-gray">{this.props.t('Add a guide')}</h4>
                                            </Row>
                                            <Row>
                                                <CardImg id="guide_photo_preview" src="/assets/images/others/avatar.png" alt="" className="rounded-circle img-lg" />
                                                <Col xl={4} md={6} style={{ marginTop: '2rem' }}>
                                                    <h4 className="font-style-16 cl-black">{this.props.t('Upload your image')}</h4>
                                                    <FormGroup>
                                                        <input onChange={(e) => {
                                                            var files = e.target.files
                                                            var allow_input = ['jpg', 'jpeg', 'png', 'gif']
                                                            var found = false
                                                            for (var allow of allow_input) {
                                                                if (files[0].type.search(allow) > -1) {
                                                                    found = true
                                                                }
                                                            }
                                                            if (found) {
                                                                var reader = new FileReader();
                                                                reader.onload = function (e) {
                                                                    document.getElementById('guide_photo_preview').src = e.target.result
                                                                }
                                                                reader.readAsDataURL(files[0]); // convert to base64 string

                                                                let guide = { ...this.state.guide };
                                                                guide.photo = files;
                                                                this.setState({ guide }, () => {
                                                                    console.log(this.state.guide)
                                                                })
                                                            } else {
                                                                Swal.fire({
                                                                    icon: 'error',
                                                                    title: 'ผิดพลาด',
                                                                    text: 'อนุญาตสนามสกุล jpg , jpeg , png และ gif เท่านั้น !',
                                                                })
                                                                let guide = { ...this.state.guide };
                                                                guide.photo = {};
                                                                e.target.value = null
                                                                this.setState({ guide }, () => {
                                                                    console.log(this.state.guide)
                                                                })
                                                            }
                                                        }} type="file" className="custom-file-input" id="guide_photo_files" />
                                                        <label className="custom-file-label" htmlFor="customFile">{this.props.t('Select Image')}</label>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: '1rem' }}>
                                                <Col>
                                                    <h4 className="font-style-16 cl-gray">ข้อมูลส่วนตัว :</h4>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: '0.5rem' }}>
                                                <Col lg="2">
                                                    <FormGroup>
                                                        <Label for="Title" className="font-style-16 cl-black">{this.props.t('Title')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.guide.title} onChange={(e) => { var guide = { ...this.state.guide }; guide.title = e.target.value; this.setState({ guide }) }} type="text" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="Firstname" className="font-style-16 cl-black">{this.props.t('First name')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.guide.firstname} onChange={(e) => { var guide = { ...this.state.guide }; guide.firstname = e.target.value; this.setState({ guide }) }} type="text" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="Lastname" className="font-style-16 cl-black">{this.props.t('Last name')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.guide.lastname} onChange={(e) => { var guide = { ...this.state.guide }; guide.lastname = e.target.value; this.setState({ guide }) }} type="text" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="Address" className="font-style-16 cl-black">{this.props.t('Address')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.guide.address} onChange={(e) => { var guide = { ...this.state.guide }; guide.address = e.target.value; this.setState({ guide }) }} type="text" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="LicenseNumber" className="font-style-16 cl-black">{this.props.t('License number')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.guide.license} onChange={(e) => { var guide = { ...this.state.guide }; guide.license = e.target.value; this.setState({ guide }) }} type="text" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="ID_card_number" className="font-style-16 cl-black">{this.props.t('ID card number')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.guide.identication_card} onChange={(e) => { var guide = { ...this.state.guide }; guide.identication_card = e.target.value; this.setState({ guide }) }} type="text" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="Email" className="font-style-16 cl-black">{this.props.t('Email')}</Label>
                                                        <Input value={this.state.admin.email} onChange={(e) => { var admin = { ...this.state.admin }; admin.email = e.target.value; this.setState({ admin }) }} type="text" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="Mobile" className="font-style-16 cl-black">{this.props.t('Mobile')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.guide.mobile} onChange={(e) => { var guide = { ...this.state.guide }; guide.mobile = e.target.value; this.setState({ guide }) }} type="text" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: '1rem' }}>
                                                <Col>
                                                    <h4 className="font-style-16 cl-gray">ข้อมูลเข้าสู่ระบบ :</h4>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: '0.5rem' }}>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="Username" className="font-style-16 cl-black">{this.props.t('User name')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.guide.username} onChange={(e) => { var guide = { ...this.state.guide }; guide.username = e.target.value; this.setState({ guide }) }} type="text" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="Password" className="font-style-16 cl-black">{this.props.t('Password')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.guide.password} onChange={(e) => { var guide = { ...this.state.guide }; guide.password = e.target.value; this.setState({ guide }) }} type="password" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="Confirmpassword" className="font-style-16 cl-black">{this.props.t('Confirm Password')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.guide.password_again} onChange={(e) => { var guide = { ...this.state.guide }; guide.password_again = e.target.value; this.setState({ guide }) }} type="password" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                        // <Row className="mt-4">
                                        //     <div className="event-timeline-dot mt-4 ml-1 mr-3">
                                        //         <i className="bx bx-right-arrow-circle" style={{ paddingTop: 6 }}></i>
                                        //     </div>
                                        //     <Card style={{ width: '96%', height: 'auto', border: '3px solid #E1E1E1' }}>
                                        //         <CardBody>
                                        //             <Col xl={12} lg={12} md={12} sm={12} xs={12} >
                                        //                 <Row style={{ margin: 'auto' }}>
                                        //                     <Col xl={6} lg={6} md={6} sm={12} xs={12} style={{ alignSelf: 'center' }}>
                                        //                         <h4>{this.props.t('Add a guide')}</h4>
                                        //                     </Col>
                                        //                 </Row>
                                        //                 <hr></hr>
                                        //                 <Row>
                                        //                     <Col xl={12}>
                                        //                         <Row className="mt-2">
                                        //                             <Col xl={2}>
                                        //                                 <div className="ml-5">
                                        //                                     <img id="guide_photo_preview" style={{ maxWidth: '30%', minHeight: '150px' }} src="/assets/images/others/avatar.png" />
                                        //                                     <CardImg id="guide_photo_preview" src="/assets/images/others/avatar.png" alt="" className="rounded-circle avatar-lg" />
                                        //                                 </div>
                                        //                             </Col>
                                        //                             <Col xl={10}>
                                        //                                 <Row>
                                        //                                     <Col xl={12} className="p-0">
                                        //                                         <h4 style={{ fontWeight: 'bold', color: '#224AAC' }}>{this.props.t('Upload your image')}</h4>
                                        //                                     </Col>
                                        //                                     <Col xl={6} className="mt-1">
                                        //                                         <FormGroup>
                                        //                                             <input onChange={(e) => {
                                        //                                                 var files = e.target.files
                                        //                                                 var allow_input = ['jpg', 'jpeg', 'png', 'gif']
                                        //                                                 var found = false
                                        //                                                 for (var allow of allow_input) {
                                        //                                                     if (files[0].type.search(allow) > -1) {
                                        //                                                         found = true
                                        //                                                     }
                                        //                                                 }
                                        //                                                 if (found) {
                                        //                                                     var reader = new FileReader();
                                        //                                                     reader.onload = function (e) {
                                        //                                                         document.getElementById('guide_photo_preview').src = e.target.result
                                        //                                                     }
                                        //                                                     reader.readAsDataURL(files[0]); // convert to base64 string

                                        //                                                     var guide = { ...this.state.guide };
                                        //                                                     guide.photo = files;
                                        //                                                     this.setState({ guide }, () => {
                                        //                                                         console.log(this.state.guide)
                                        //                                                     })
                                        //                                                 } else {
                                        //                                                     Swal.fire({
                                        //                                                         icon: 'error',
                                        //                                                         title: 'ผิดพลาด',
                                        //                                                         text: 'อนุญาตสนามสกุล jpg , jpeg , png และ gif เท่านั้น !',
                                        //                                                     })
                                        //                                                     var guide = { ...this.state.guide };
                                        //                                                     guide.photo = {};
                                        //                                                     e.target.value = null
                                        //                                                     this.setState({ guide }, () => {
                                        //                                                         console.log(this.state.guide)
                                        //                                                     })
                                        //                                                 }
                                        //                                             }} type="file" className="custom-file-input" id="guide_photo_files" />
                                        //                                             <label className="custom-file-label" htmlFor="customFile">{this.props.t('Select Image')}</label>
                                        //                                         </FormGroup>
                                        //                                     </Col>
                                        //                                 </Row>
                                        //                             </Col>
                                        //                         </Row>
                                        //                     </Col>
                                        //                 </Row>
                                        //                 <hr className="mt-2"></hr>
                                        //                 <Row className="mt-2">
                                        //                     <Col>
                                        //                         <h4 style={{ fontWeight: 'bold', color: '#224AAC' }}>ข้อมูลบริษัทนำเที่ยว :</h4>
                                        //                     </Col>
                                        //                 </Row>
                                        //                 <Row>
                                        //                     <Col lg="4">
                                        //                         <FormGroup>
                                        //                             <Label for="basicpill-firstname-input1">{this.props.t('User name')}</Label>
                                        //                             <Input value={this.state.guide.username} onChange={(e) => { var guide = { ...this.state.guide }; guide.username = e.target.value; this.setState({ guide }) }} type="text" className="form-control" />
                                        //                         </FormGroup>
                                        //                     </Col>
                                        //                 </Row>
                                        //                 <Row>
                                        //                     <Col lg="4">
                                        //                         <FormGroup>
                                        //                             <Label for="basicpill-firstname-input1">{this.props.t('Password')}</Label>
                                        //                             <Input value={this.state.guide.password} onChange={(e) => { var guide = { ...this.state.guide }; guide.password = e.target.value; this.setState({ guide }) }} type="password" className="form-control" />
                                        //                         </FormGroup>
                                        //                     </Col>
                                        //                     <Col lg="4">
                                        //                         <FormGroup>
                                        //                             <Label for="basicpill-lastname-input2">{this.props.t('Enter password again')}</Label>
                                        //                             <Input value={this.state.guide.password_again} onChange={(e) => { var guide = { ...this.state.guide }; guide.password_again = e.target.value; this.setState({ guide }) }} type="password" className="form-control" id="basicpill-lastname-input2" />
                                        //                         </FormGroup>
                                        //                     </Col>
                                        //                 </Row>
                                        //                 <Row>
                                        //                     <Col lg="4">
                                        //                         <FormGroup>
                                        //                             <Label for="basicpill-title-input1">{this.props.t('Title')}</Label>
                                        //                             <Input value={this.state.guide.title} onChange={(e) => { var guide = { ...this.state.guide }; guide.title = e.target.value; this.setState({ guide }) }} type="text" className="form-control" id="basicpill-title-input1" />
                                        //                         </FormGroup>
                                        //                     </Col>
                                        //                     <Col lg="4">
                                        //                         <FormGroup>
                                        //                             <Label for="basicpill-firstname-input1">{this.props.t('First name')}</Label>
                                        //                             <Input value={this.state.guide.firstname} onChange={(e) => { var guide = { ...this.state.guide }; guide.firstname = e.target.value; this.setState({ guide }) }} type="text" className="form-control" id="basicpill-firstname-input1" />
                                        //                         </FormGroup>
                                        //                     </Col>
                                        //                     <Col lg="4">
                                        //                         <FormGroup>
                                        //                             <Label for="basicpill-lastname-input2">{this.props.t('Last name')}</Label>
                                        //                             <Input value={this.state.guide.lastname} onChange={(e) => { var guide = { ...this.state.guide }; guide.lastname = e.target.value; this.setState({ guide }) }} type="text" className="form-control" id="basicpill-lastname-input2" />
                                        //                         </FormGroup>
                                        //                     </Col>
                                        //                 </Row>
                                        //                 <Row>
                                        //                     <Col lg="4">
                                        //                         <FormGroup>
                                        //                             <Label for="basicpill-firstname-input1">{this.props.t('License number')}</Label>
                                        //                             <Input value={this.state.guide.license} onChange={(e) => { var guide = { ...this.state.guide }; guide.license = e.target.value; this.setState({ guide }) }} type="text" className="form-control" id="basicpill-firstname-input1" />
                                        //                         </FormGroup>
                                        //                     </Col>
                                        //                     <Col lg="4">
                                        //                         <FormGroup>
                                        //                             <Label for="basicpill-lastname-input2">{this.props.t('Address')}</Label>
                                        //                             <Input value={this.state.guide.address} onChange={(e) => { var guide = { ...this.state.guide }; guide.address = e.target.value; this.setState({ guide }) }} type="text" className="form-control" id="basicpill-lastname-input2" />
                                        //                         </FormGroup>
                                        //                     </Col>
                                        //                 </Row>
                                        //                 <Row>
                                        //                     <Col lg="4">
                                        //                         <FormGroup>
                                        //                             <Label for="basicpill-firstname-input1">{this.props.t('Mobile')}</Label>
                                        //                             <Input value={this.state.guide.mobile} onChange={(e) => { var guide = { ...this.state.guide }; guide.mobile = e.target.value; this.setState({ guide }) }} type="text" className="form-control" id="basicpill-firstname-input1" />
                                        //                         </FormGroup>
                                        //                     </Col>
                                        //                     <Col lg="4">
                                        //                         <FormGroup>
                                        //                             <Label for="basicpill-email-input2">{this.props.t('Email')}</Label>
                                        //                             <Input value={this.state.guide.email} onChange={(e) => { var guide = { ...this.state.guide }; guide.email = e.target.value; this.setState({ guide }) }} type="text" className="form-control" id="basicpill-email-input2" />
                                        //                         </FormGroup>
                                        //                     </Col>
                                        //                     <Col lg="4">
                                        //                         <FormGroup>
                                        //                             <Label for="basicpill-lastname-input2">{this.props.t('ID card number')}</Label>
                                        //                             <Input value={this.state.guide.identication_card} onChange={(e) => { var guide = { ...this.state.guide }; guide.identication_card = e.target.value; this.setState({ guide }) }} type="text" className="form-control" id="basicpill-lastname-input2" />
                                        //                         </FormGroup>
                                        //                     </Col>
                                        //                 </Row>
                                        //                 <hr></hr>
                                        //                 <Row>
                                        //                     <Col lg={8} md={0} >
                                        //                     </Col>
                                        //                     <Col lg={2} md={6} sm={12} xs={12}>
                                        //                         <Button style={{ width: '100%' }}>ยกเลิก</Button>
                                        //                     </Col>
                                        //                     <Col lg={2} md={6} sm={12} xs={12}>
                                        //                         <Button onClick={() => this.add_guide_users()} style={{ width: '100%', backgroundColor: '#224AAC', borderColor: '#224AAC' }}>ยืนยันการเพิ่มผู้ใช้งาน</Button>
                                        //                     </Col>
                                        //                 </Row>
                                        //             </Col>
                                        //         </CardBody>
                                        //     </Card>
                                        // </Row>
                                    }
                                    {this.state.selected_users_type === 'agency' &&
                                        <div>
                                            <Row style={{ margin: 0 }}>
                                                <h4 className="font-style-18 cl-gray">{this.props.t('Add a travel agency')}</h4>
                                            </Row>
                                            <Row>
                                                <CardImg id="agency_photo_preview" src="/assets/images/others/avatar.png" alt="" className="rounded-circle img-lg" />
                                                <Col xl={4} md={6} style={{ marginTop: '2rem' }}>
                                                    <h4 className="font-style-16 cl-black">{this.props.t('Upload your image')}</h4>
                                                    <FormGroup>
                                                        <input onChange={(e) => {
                                                            var files = e.target.files
                                                            var allow_input = ['jpg', 'jpeg', 'png', 'gif']
                                                            var found = false
                                                            for (var allow of allow_input) {
                                                                if (files[0].type.search(allow) > -1) {
                                                                    found = true
                                                                }
                                                            }
                                                            if (found) {
                                                                var reader = new FileReader();
                                                                reader.onload = function (e) {
                                                                    document.getElementById('agency_photo_preview').src = e.target.result
                                                                }
                                                                reader.readAsDataURL(files[0]); // convert to base64 string

                                                                let agency = { ...this.state.agency };
                                                                agency.photo = files;
                                                                this.setState({ agency }, () => {
                                                                    console.log(this.state.agency)
                                                                })
                                                            } else {
                                                                Swal.fire({
                                                                    icon: 'error',
                                                                    title: 'ผิดพลาด',
                                                                    text: 'อนุญาตสนามสกุล jpg , jpeg , png และ gif เท่านั้น !',
                                                                })
                                                                let agency = { ...this.state.agency };
                                                                agency.photo = {};
                                                                e.target.value = null
                                                                this.setState({ agency }, () => {
                                                                    console.log(this.state.agency)
                                                                })
                                                            }
                                                        }} type="file" className="custom-file-input" id="agency_photo_files" />
                                                        <label className="custom-file-label" htmlFor="customFile">{this.props.t('Select Image')}</label>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: '1rem' }}>
                                                <Col>
                                                    <h4 className="font-style-16 cl-gray">ข้อมูลส่วนตัว :</h4>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: '0.5rem' }}>
                                                <Col lg="2">
                                                    <FormGroup>
                                                        <Label for="Title" className="font-style-16 cl-black">{this.props.t('Title')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.agency.title} onChange={(e) => { var agency = { ...this.state.agency }; agency.title = e.target.value; this.setState({ agency }) }} type="text" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="Firstname" className="font-style-16 cl-black">{this.props.t('First name')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.agency.firstname} onChange={(e) => { var agency = { ...this.state.agency }; agency.firstname = e.target.value; this.setState({ agency }) }} type="text" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="Lastname" className="font-style-16 cl-black">{this.props.t('Last name')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.agency.lastname} onChange={(e) => { var agency = { ...this.state.agency }; agency.lastname = e.target.value; this.setState({ agency }) }} type="text" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="TravelAgencyAddress" className="font-style-16 cl-black">{this.props.t('Travel agency address')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.agency.address} onChange={(e) => { var agency = { ...this.state.agency }; agency.address = e.target.value; this.setState({ agency }) }} type="text" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="LicenseNumber" className="font-style-16 cl-black">{this.props.t('License number')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.agency.license} onChange={(e) => { var agency = { ...this.state.agency }; agency.license = e.target.value; this.setState({ agency }) }} type="text" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="ID_card_number" className="font-style-16 cl-black">{this.props.t('ID card number')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.guide.identication_card} onChange={(e) => { var guide = { ...this.state.guide }; guide.identication_card = e.target.value; this.setState({ guide }) }} type="text" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="TravelAgencyName" className="font-style-16 cl-black">{this.props.t('Travel agency name')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.agency.name} onChange={(e) => { var agency = { ...this.state.agency }; agency.name = e.target.value; this.setState({ agency }) }} type="text" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="Email" className="font-style-16 cl-black">{this.props.t('Email')}</Label>
                                                        <Input value={this.state.admin.email} onChange={(e) => { var admin = { ...this.state.admin }; admin.email = e.target.value; this.setState({ admin }) }} type="text" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="Mobile" className="font-style-16 cl-black">{this.props.t('Mobile')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.guide.mobile} onChange={(e) => { var guide = { ...this.state.guide }; guide.mobile = e.target.value; this.setState({ guide }) }} type="text" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: '1rem' }}>
                                                <Col>
                                                    <h4 className="font-style-16 cl-gray">ข้อมูลเข้าสู่ระบบ :</h4>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: '0.5rem' }}>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="Username" className="font-style-16 cl-black">{this.props.t('User name')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.agency.username} onChange={(e) => { var agency = { ...this.state.agency }; agency.username = e.target.value; this.setState({ agency }) }} type="text" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="Password" className="font-style-16 cl-black">{this.props.t('Password')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.agency.password} onChange={(e) => { var agency = { ...this.state.agency }; agency.password = e.target.value; this.setState({ agency }) }} type="password" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="Confirmpassword" className="font-style-16 cl-black">{this.props.t('Confirm Password')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.agency.password_again} onChange={(e) => { var agency = { ...this.state.agency }; agency.password_again = e.target.value; this.setState({ agency }) }} type="password" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                        // <Row className="mt-4">
                                        //     <div className="event-timeline-dot mt-4 ml-1 mr-3">
                                        //         <i className="bx bx-right-arrow-circle" style={{ paddingTop: 6 }}></i>
                                        //     </div>
                                        //     <Card style={{ width: '96%', height: 'auto', border: '3px solid #E1E1E1' }}>
                                        //         <CardBody>
                                        //             <Col xl={12} lg={12} md={12} sm={12} xs={12} >
                                        //                 <Row style={{ margin: 'auto' }}>
                                        //                     <Col xl={6} lg={6} md={6} sm={12} xs={12} style={{ alignSelf: 'center' }}>
                                        //                         <h4>{this.props.t('Add a travel agency')}</h4>
                                        //                     </Col>
                                        //                 </Row>
                                        //                 <hr></hr>
                                        //                 <Row>
                                        //                     <Col xl={12}>
                                        //                         <Row className="mt-2">
                                        //                             <Col xl={2}>
                                        //                                 <div className="ml-5">
                                        //                                     <img id="agency_photo_preview" style={{ maxWidth: '30%', minHeight: '150px' }} src="/assets/images/others/avatar.png" />
                                        //                                     <CardImg id="agency_photo_preview" src="/assets/images/others/avatar.png" alt="" className="rounded-circle avatar-lg" />
                                        //                                 </div>
                                        //                             </Col>
                                        //                             <Col xl={10}>
                                        //                                 <Row>
                                        //                                     <Col xl={12} className="p-0">
                                        //                                         <h4 style={{ fontWeight: 'bold', color: '#224AAC' }}>{this.props.t('Upload your image')}</h4>
                                        //                                     </Col>
                                        //                                     <Col xl={6} className="mt-1">
                                        //                                         <FormGroup>
                                        //                                             <input onChange={(e) => {
                                        //                                                 var files = e.target.files
                                        //                                                 var allow_input = ['jpg', 'jpeg', 'png', 'gif']
                                        //                                                 var found = false
                                        //                                                 for (var allow of allow_input) {
                                        //                                                     if (files[0].type.search(allow) > -1) {
                                        //                                                         found = true
                                        //                                                     }
                                        //                                                 }
                                        //                                                 if (found) {
                                        //                                                     var reader = new FileReader();
                                        //                                                     reader.onload = function (e) {
                                        //                                                         document.getElementById('agency_photo_preview').src = e.target.result
                                        //                                                     }
                                        //                                                     reader.readAsDataURL(files[0]); // convert to base64 string

                                        //                                                     var agency = { ...this.state.agency };
                                        //                                                     agency.photo = files;
                                        //                                                     this.setState({ agency }, () => {
                                        //                                                         console.log(this.state.agency)
                                        //                                                     })
                                        //                                                 } else {
                                        //                                                     Swal.fire({
                                        //                                                         icon: 'error',
                                        //                                                         title: 'ผิดพลาด',
                                        //                                                         text: 'อนุญาตสนามสกุล jpg , jpeg , png และ gif เท่านั้น !',
                                        //                                                     })
                                        //                                                     var agency = { ...this.state.agency };
                                        //                                                     agency.photo = {};
                                        //                                                     e.target.value = null
                                        //                                                     this.setState({ agency }, () => {
                                        //                                                         console.log(this.state.agency)
                                        //                                                     })
                                        //                                                 }
                                        //                                             }} type="file" className="custom-file-input" id="agency_photo_files" />
                                        //                                             <label className="custom-file-label" htmlFor="customFile">{this.props.t('Select Image')}</label>
                                        //                                         </FormGroup>
                                        //                                     </Col>
                                        //                                 </Row>
                                        //                             </Col>
                                        //                         </Row>
                                        //                     </Col>
                                        //                 </Row>
                                        //                 <hr className="mt-2"></hr>
                                        //                 <Row className="mt-2">
                                        //                     <Col>
                                        //                         <h4 style={{ fontWeight: 'bold', color: '#224AAC' }}>ข้อมูลบริษัทนำเที่ยว :</h4>
                                        //                     </Col>
                                        //                 </Row>
                                        //                 <Row>
                                        //                     <Col lg="4">
                                        //                         <FormGroup>
                                        //                             <Label for="basicpill-firstname-input1">{this.props.t('User name')}</Label>
                                        //                             <Input value={this.state.agency.username} onChange={(e) => { var agency = { ...this.state.agency }; agency.username = e.target.value; this.setState({ agency }) }} type="text" className="form-control" />
                                        //                         </FormGroup>
                                        //                     </Col>
                                        //                 </Row>
                                        //                 <Row>
                                        //                     <Col lg="4">
                                        //                         <FormGroup>
                                        //                             <Label for="basicpill-firstname-input1">{this.props.t('Password')}</Label>
                                        //                             <Input value={this.state.agency.password} onChange={(e) => { var agency = { ...this.state.agency }; agency.password = e.target.value; this.setState({ agency }) }} type="password" className="form-control" />
                                        //                         </FormGroup>
                                        //                     </Col>
                                        //                     <Col lg="4">
                                        //                         <FormGroup>
                                        //                             <Label for="basicpill-lastname-input2">{this.props.t('Enter password again')}</Label>
                                        //                             <Input value={this.state.agency.password_again} onChange={(e) => { var agency = { ...this.state.agency }; agency.password_again = e.target.value; this.setState({ agency }) }} type="password" className="form-control" id="basicpill-lastname-input2" />
                                        //                         </FormGroup>
                                        //                     </Col>
                                        //                 </Row>
                                        //                 <Row>
                                        //                     <Col lg="4">
                                        //                         <FormGroup>
                                        //                             <Label for="basicpill-title-input1">{this.props.t('Title')}</Label>
                                        //                             <Input value={this.state.agency.title} onChange={(e) => { var agency = { ...this.state.agency }; agency.title = e.target.value; this.setState({ agency }) }} type="text" className="form-control" id="basicpill-title-input1" />
                                        //                         </FormGroup>
                                        //                     </Col>
                                        //                     <Col lg="4">
                                        //                         <FormGroup>
                                        //                             <Label for="basicpill-firstname-input1">{this.props.t('First name')}</Label>
                                        //                             <Input value={this.state.agency.firstname} onChange={(e) => { var agency = { ...this.state.agency }; agency.firstname = e.target.value; this.setState({ agency }) }} type="text" className="form-control" id="basicpill-firstname-input1" />
                                        //                         </FormGroup>
                                        //                     </Col>
                                        //                     <Col lg="4">
                                        //                         <FormGroup>
                                        //                             <Label for="basicpill-lastname-input2">{this.props.t('Last name')}</Label>
                                        //                             <Input value={this.state.agency.lastname} onChange={(e) => { var agency = { ...this.state.agency }; agency.lastname = e.target.value; this.setState({ agency }) }} type="text" className="form-control" id="basicpill-lastname-input2" />
                                        //                         </FormGroup>
                                        //                     </Col>
                                        //                 </Row>
                                        //                 <Row>
                                        //                     <Col lg="4">
                                        //                         <FormGroup>
                                        //                             <Label for="basicpill-firstname-input1">{this.props.t('License number')}</Label>
                                        //                             <Input value={this.state.agency.license} onChange={(e) => { var agency = { ...this.state.agency }; agency.license = e.target.value; this.setState({ agency }) }} type="text" className="form-control" id="basicpill-firstname-input1" />
                                        //                         </FormGroup>
                                        //                     </Col>
                                        //                     <Col lg="4">
                                        //                         <FormGroup>
                                        //                             <Label for="basicpill-lastname-input2">{this.props.t('Travel agency name')}</Label>
                                        //                             <Input value={this.state.agency.name} onChange={(e) => { var agency = { ...this.state.agency }; agency.name = e.target.value; this.setState({ agency }) }} type="text" className="form-control" id="basicpill-lastname-input2" />
                                        //                         </FormGroup>
                                        //                     </Col>
                                        //                     <Col lg="4">
                                        //                         <FormGroup>
                                        //                             <Label for="basicpill-firstname-input1">{this.props.t('Travel agency address')}</Label>
                                        //                             <Input value={this.state.agency.address} onChange={(e) => { var agency = { ...this.state.agency }; agency.address = e.target.value; this.setState({ agency }) }} type="text" className="form-control" id="basicpill-firstname-input1" />
                                        //                         </FormGroup>
                                        //                     </Col>
                                        //                 </Row>
                                        //                 <Row>
                                        //                     <Col lg="4">
                                        //                         <FormGroup>
                                        //                             <Label for="basicpill-firstname-input1">{this.props.t('Mobile')}</Label>
                                        //                             <Input value={this.state.agency.mobile} onChange={(e) => { var agency = { ...this.state.agency }; agency.mobile = e.target.value; this.setState({ agency }) }} type="text" className="form-control" id="basicpill-firstname-input1" />
                                        //                         </FormGroup>
                                        //                     </Col>
                                        //                     <Col lg="4">
                                        //                         <FormGroup>
                                        //                             <Label for="basicpill-firstname-input1">{this.props.t('Email')}</Label>
                                        //                             <Input value={this.state.agency.email} onChange={(e) => { var agency = { ...this.state.agency }; agency.email = e.target.value; this.setState({ agency }) }} type="text" className="form-control" id="basicpill-firstname-input1" />
                                        //                         </FormGroup>
                                        //                     </Col>
                                        //                     <Col lg="4">
                                        //                         <FormGroup>
                                        //                             <Label for="basicpill-lastname-input2">{this.props.t('ID card number')}</Label>
                                        //                             <Input value={this.state.agency.identication_card} onChange={(e) => { var agency = { ...this.state.agency }; agency.identication_card = e.target.value; this.setState({ agency }) }} type="text" className="form-control" id="basicpill-lastname-input2" />
                                        //                         </FormGroup>
                                        //                     </Col>
                                        //                 </Row>
                                        //                 <hr></hr>
                                        //                 <Row>
                                        //                     <Col lg={8} md={0} >
                                        //                     </Col>
                                        //                     <Col lg={2} md={6} sm={12} xs={12}>
                                        //                         <Button style={{ width: '100%' }}>ยกเลิก</Button>
                                        //                     </Col>
                                        //                     <Col lg={2} md={6} sm={12} xs={12}>
                                        //                         <Button onClick={() => this.add_agency_users()} style={{ width: '100%', backgroundColor: '#224AAC', borderColor: '#224AAC' }}>ยืนยันการเพิ่มผู้ใช้งาน</Button>
                                        //                     </Col>
                                        //                 </Row>
                                        //             </Col>
                                        //         </CardBody>
                                        //     </Card>
                                        // </Row>
                                    }

                                    {this.state.selected_users_type === 'operator' &&
                                        <div>
                                            <Row style={{ margin: 0 }}>
                                                <h4 className="font-style-18 cl-gray">{this.props.t('Add an operatoristrator')}</h4>
                                            </Row>
                                            <Row>
                                                <CardImg id="admin_photo_preview" src="/assets/images/others/avatar.png" alt="" className="rounded-circle img-lg" />
                                                <Col xl={4} md={6} style={{ marginTop: '2rem' }}>
                                                    <h4 className="font-style-16 cl-black">{this.props.t('Upload your image')}</h4>
                                                    <FormGroup>
                                                        <input onChange={(e) => {
                                                            var files = e.target.files
                                                            var allow_input = ['jpg', 'jpeg', 'png', 'gif']
                                                            var found = false
                                                            for (var allow of allow_input) {
                                                                if (files[0].type.search(allow) > -1) {
                                                                    found = true
                                                                }
                                                            }
                                                            if (found) {
                                                                var reader = new FileReader();
                                                                reader.onload = function (e) {
                                                                    document.getElementById('operator_photo_preview').src = e.target.result
                                                                }
                                                                reader.readAsDataURL(files[0]); // convert to base64 string

                                                                let operator = { ...this.state.operator };
                                                                operator.photo = files;
                                                                this.setState({ operator }, () => {
                                                                    console.log(this.state.operator)
                                                                })
                                                            } else {
                                                                Swal.fire({
                                                                    icon: 'error',
                                                                    title: 'ผิดพลาด',
                                                                    text: 'อนุญาตสนามสกุล jpg , jpeg , png และ gif เท่านั้น !',
                                                                })
                                                                let operator = { ...this.state.operator };
                                                                operator.photo = {};
                                                                e.target.value = null
                                                                this.setState({ operator }, () => {
                                                                    console.log(this.state.operator)
                                                                })

                                                            }

                                                        }} type="file" className="custom-file-input" id="operator_photo_files" />
                                                        <label className="custom-file-label" htmlFor="customFile">{this.props.t('Select Image')}</label>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: '1rem' }}>
                                                <Col>
                                                    <h4 className="font-style-16 cl-gray">ข้อมูลส่วนตัว :</h4>
                                                </Col>
                                            </Row>

                                            <Row style={{ marginTop: '0.5rem' }}>
                                                <Col lg="2">
                                                    <FormGroup>
                                                        <Label for="Title" className="font-style-16 cl-black">{this.props.t('Title')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.operator.title} onChange={(e) => { var operator = { ...this.state.operator }; operator.title = e.target.value; this.setState({ operator }) }} type="text" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="Firstname" className="font-style-16 cl-black">{this.props.t('First name')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.operator.firstname} onChange={(e) => { var operator = { ...this.state.operator }; operator.firstname = e.target.value; this.setState({ operator }) }} type="text" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="Lastname" className="font-style-16 cl-black">{this.props.t('Last name')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.operator.lastname} onChange={(e) => { var operator = { ...this.state.operator }; operator.lastname = e.target.value; this.setState({ operator }) }} type="text" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="Email" className="font-style-16 cl-black">{this.props.t('Email')}</Label>
                                                        <Input value={this.state.operator.email} onChange={(e) => { var operator = { ...this.state.operator }; operator.email = e.target.value; this.setState({ operator }) }} type="text" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="Mobile" className="font-style-16 cl-black" >{this.props.t('Mobile')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.operator.mobile} onChange={(e) => { var operator = { ...this.state.operator }; operator.mobile = e.target.value; this.setState({ operator }) }} type="text" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="ID_card_number" className="font-style-16 cl-black">{this.props.t('ID card number')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.operator.identication_card} onChange={(e) => { var operator = { ...this.state.operator }; operator.identication_card = e.target.value; this.setState({ operator }) }} type="text" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: '1rem' }}>
                                                <Col>
                                                    <h4 className="font-style-16 cl-gray">ข้อมูลเข้าสู่ระบบ :</h4>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: '0.5rem' }}>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="Username" className="font-style-16 cl-black">{this.props.t('User name')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.operator.username} onChange={(e) => { var operator = { ...this.state.operator }; operator.username = e.target.value; this.setState({ operator }) }} type="text" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="Password" className="font-style-16 cl-black">{this.props.t('Password')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.operator.password} onChange={(e) => { var operator = { ...this.state.operator }; operator.password = e.target.value; this.setState({ operator }) }} type="password" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="3">
                                                    <FormGroup>
                                                        <Label for="Confirmpassword" className="font-style-16 cl-black">{this.props.t('Confirm Password')}
                                                            <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                                                        </Label>
                                                        <Input value={this.state.operator.password_again} onChange={(e) => { var operator = { ...this.state.operator }; operator.password_again = e.target.value; this.setState({ operator }) }} type="password" className="form-control" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <hr></hr>
                                            <Row>
                                                <Col lg={8} md={0} >
                                                </Col>
                                                <Col lg={2} md={6} sm={12} xs={12}>
                                                    <Button style={{ width: '100%' }}>ยกเลิก</Button>
                                                </Col>
                                                <Col lg={2} md={6} sm={12} xs={12}>
                                                    <Button onClick={() => this.add_operator_users()} style={{ width: '100%', backgroundColor: '#224AAC', borderColor: '#224AAC' }}>ยืนยันการเพิ่มผู้ใช้งาน</Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    }


                                    {this.state.selected_users_type === 'police' &&
                                        <Row className="mt-4">
                                            <div className="event-timeline-dot mt-4 ml-1 mr-3">
                                                <i className="bx bx-right-arrow-circle" style={{ paddingTop: 6 }}></i>
                                            </div>
                                            <Card style={{ width: '96%', height: 'auto', border: '3px solid #E1E1E1' }}>
                                                <CardBody>
                                                    <Col xl={12} lg={12} md={12} sm={12} xs={12} >
                                                        <Row style={{ margin: 'auto' }}>
                                                            <Col xl={6} lg={6} md={6} sm={12} xs={12} style={{ alignSelf: 'center' }}>
                                                                <h4>{this.props.t('Add an police')}</h4>
                                                            </Col>
                                                        </Row>
                                                        <hr></hr>
                                                        <Row>
                                                            <Col xl={12}>
                                                                <Row className="mt-2">
                                                                    <Col xl={2}>
                                                                        <div className="ml-5">
                                                                            {/* <img id="police_photo_preview" style={{ maxWidth: '30%', minHeight: '150px' }} src="/assets/images/others/avatar.png" /> */}
                                                                            <CardImg id="police_photo_preview" src="/assets/images/others/avatar.png" alt="" className="rounded-circle avatar-lg" />
                                                                        </div>
                                                                    </Col>

                                                                    <Col xl={10}>
                                                                        <Row>
                                                                            <Col xl={12} className="p-0">
                                                                                <h4 style={{ fontWeight: 'bold', color: '#224AAC' }}>{this.props.t('Upload your image')}</h4>
                                                                            </Col>
                                                                            <Col xl={6} className="mt-1">
                                                                                <FormGroup>
                                                                                    <input onChange={(e) => {
                                                                                        var files = e.target.files
                                                                                        var allow_input = ['jpg', 'jpeg', 'png', 'gif']
                                                                                        var found = false
                                                                                        for (var allow of allow_input) {
                                                                                            if (files[0].type.search(allow) > -1) {
                                                                                                found = true
                                                                                            }
                                                                                        }
                                                                                        if (found) {
                                                                                            var reader = new FileReader();
                                                                                            reader.onload = function (e) {
                                                                                                document.getElementById('police_photo_preview').src = e.target.result
                                                                                            }
                                                                                            reader.readAsDataURL(files[0]); // convert to base64 string

                                                                                            let police = { ...this.state.police };
                                                                                            police.photo = files;
                                                                                            this.setState({ police }, () => {
                                                                                                console.log(this.state.police)
                                                                                            })
                                                                                        } else {
                                                                                            Swal.fire({
                                                                                                icon: 'error',
                                                                                                title: 'ผิดพลาด',
                                                                                                text: 'อนุญาตสนามสกุล jpg , jpeg , png และ gif เท่านั้น !',
                                                                                            })
                                                                                            let police = { ...this.state.police };
                                                                                            police.photo = {};
                                                                                            e.target.value = null
                                                                                            this.setState({ police }, () => {
                                                                                                console.log(this.state.police)
                                                                                            })

                                                                                        }

                                                                                    }} type="file" className="custom-file-input" id="police_photo_files" />
                                                                                    <label className="custom-file-label" htmlFor="customFile">{this.props.t('Select Image')}</label>
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>


                                                                    </Col>
                                                                </Row>
                                                            </Col>

                                                        </Row>
                                                        <hr className="mt-2"></hr>
                                                        <Row className="mt-2">
                                                            <Col>
                                                                {/* <h4 style={{ fontWeight: 'bold', color: '#224AAC' }}>ข้อมูลบริษัทนำเที่ยว :</h4> */}
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="4">
                                                                <FormGroup>
                                                                    <Label for="basicpill-firstname-input1">{this.props.t('User name')}</Label>
                                                                    <Input value={this.state.police.username} onChange={(e) => { var police = { ...this.state.police }; police.username = e.target.value; this.setState({ police }) }} type="text" className="form-control" />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="4">
                                                                <FormGroup>
                                                                    <Label for="basicpill-firstname-input1">{this.props.t('Password')}</Label>
                                                                    <Input value={this.state.police.password} onChange={(e) => { var police = { ...this.state.police }; police.password = e.target.value; this.setState({ police }) }} type="password" className="form-control" />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg="4">
                                                                <FormGroup>
                                                                    <Label for="basicpill-lastname-input2">{this.props.t('Enter password again')}</Label>
                                                                    <Input value={this.state.police.password_again} onChange={(e) => { var police = { ...this.state.police }; police.password_again = e.target.value; this.setState({ police }) }} type="password" className="form-control" id="basicpill-lastname-input2" />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="4">
                                                                <FormGroup>
                                                                    <Label for="basicpill-title-input1">{this.props.t('Title')}</Label>
                                                                    <Input value={this.state.police.title} onChange={(e) => { var police = { ...this.state.police }; police.title = e.target.value; this.setState({ police }) }} type="text" className="form-control" id="basicpill-title-input1" />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg="4">
                                                                <FormGroup>
                                                                    <Label for="basicpill-firstname-input1">{this.props.t('First name')}</Label>
                                                                    <Input value={this.state.police.firstname} onChange={(e) => { var police = { ...this.state.police }; police.firstname = e.target.value; this.setState({ police }) }} type="text" className="form-control" id="basicpill-firstname-input1" />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg="4">
                                                                <FormGroup>
                                                                    <Label for="basicpill-lastname-input2">{this.props.t('Last name')}</Label>
                                                                    <Input value={this.state.police.lastname} onChange={(e) => { var police = { ...this.state.police }; police.lastname = e.target.value; this.setState({ police }) }} type="text" className="form-control" id="basicpill-lastname-input2" />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="4">
                                                                <FormGroup>
                                                                    <Label for="basicpill-firstname-input1">{this.props.t('Mobile')}</Label>
                                                                    <Input value={this.state.police.mobile} onChange={(e) => { var police = { ...this.state.police }; police.mobile = e.target.value; this.setState({ police }) }} type="text" className="form-control" id="basicpill-firstname-input1" />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg="4">
                                                                <FormGroup>
                                                                    <Label for="basicpill-firstname-input1">{this.props.t('Email')}</Label>
                                                                    <Input value={this.state.police.email} onChange={(e) => { var police = { ...this.state.police }; police.email = e.target.value; this.setState({ police }) }} type="text" className="form-control" id="basicpill-firstname-input1" />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg="4">
                                                                <FormGroup>
                                                                    <Label for="basicpill-lastname-input2">{this.props.t('ID card number')}</Label>
                                                                    <Input value={this.state.police.identication_card} onChange={(e) => { var police = { ...this.state.police }; police.identication_card = e.target.value; this.setState({ police }) }} type="text" className="form-control" id="basicpill-lastname-input2" />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <hr></hr>
                                                        <Row>
                                                            <Col lg={8} md={0} >
                                                            </Col>
                                                            <Col lg={2} md={6} sm={12} xs={12}>
                                                                <Button style={{ width: '100%' }}>ยกเลิก</Button>
                                                            </Col>
                                                            <Col lg={2} md={6} sm={12} xs={12}>
                                                                <Button onClick={() => this.add_police_users()} style={{ width: '100%', backgroundColor: '#224AAC', borderColor: '#224AAC' }}>ยืนยันการเพิ่มผู้ใช้งาน</Button>
                                                            </Col>
                                                        </Row>

                                                    </Col>
                                                </CardBody>
                                            </Card>

                                        </Row>
                                    }
                                    {/* <Row className="mt-4">
                                        <div className="event-timeline-dot mt-4 ml-1 mr-3">
                                            <i className="bx bx-right-arrow-circle" style={{ paddingTop: 6 }}></i>
                                        </div>
                                        <Card style={{ width: '96%', height: 'auto', border: '3px solid #E1E1E1' }}>
                                            <CardBody>
                                                <Col xl={12} lg={12} md={12} sm={12} xs={12} >
                                                    <Row style={{ margin: 'auto' }}>
                                                        <Col xl={6} lg={6} md={6} sm={12} xs={12} style={{ alignSelf: 'center' }}>
                                                            <h4 style={{ fontWeight: 'bold' }}>เพิ่มบริษัทนำเที่ยว</h4>
                                                        </Col>

                                                    </Row>
                                                    <hr></hr>
                                                    <Row>
                                                        <Col xl={12}>
                                                            <Row className="mt-2">
                                                                <Col xl={2}>
                                                                    <div className="ml-5">
                                                                        <CardImg src={profile} alt="" className="rounded-circle avatar-lg" />
                                                                    </div>
                                                                </Col>

                                                                <Col xl={10}>
                                                                    <Row>
                                                                        <Col xl={12} className="p-0">
                                                                            <h4 style={{ fontWeight: 'bold', color: '#224AAC' }}>อัพโหลดรูปภาพ</h4>
                                                                        </Col>
                                                                        <Col xl={6} className="mt-1">
                                                                            <FormGroup>
                                                                                <input onChange={(e) => {
                                                                                    var files = e.target.files
                                                                                    var allow_input = ['jpg', 'jpeg', 'png', 'gif']
                                                                                    var found = false
                                                                                    for (var allow of allow_input) {
                                                                                        if (files[0].type.search(allow) > -1) {
                                                                                            found = true
                                                                                        }
                                                                                    }
                                                                                    if (found) {
                                                                                        var reader = new FileReader();
                                                                                        reader.onload = function (e) {
                                                                                            document.getElementById('agency_photo_preview').src = e.target.result
                                                                                        }
                                                                                        reader.readAsDataURL(files[0]); // convert to base64 string

                                                                                        var agency = { ...this.state.agency };
                                                                                        agency.photo = files;
                                                                                        this.setState({ agency }, () => {
                                                                                            console.log(this.state.agency)
                                                                                        })
                                                                                    } else {
                                                                                        Swal.fire({
                                                                                            icon: 'error',
                                                                                            title: 'ผิดพลาด',
                                                                                            text: 'อนุญาตสนามสกุล jpg , jpeg , png และ gif เท่านั้น !',
                                                                                        })
                                                                                        var agency = { ...this.state.agency };
                                                                                        agency.photo = {};
                                                                                        e.target.value = null
                                                                                        this.setState({ agency }, () => {
                                                                                            console.log(this.state.agency)
                                                                                        })

                                                                                    }

                                                                                }} type="file" className="custom-file-input" id="agency_photo_files" />
                                                                                <label className="custom-file-label" htmlFor="customFile">{this.props.t('Select Image')}</label>
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>


                                                                </Col>
                                                            </Row>
                                                        </Col>

                                                    </Row>
                                                    <hr className="mt-2"></hr>
                                                    <Row className="mt-2">
                                                        <Col>
                                                            <h4 style={{ fontWeight: 'bold', color: '#224AAC' }}>ข้อมูลบริษัทนำเที่ยว :</h4>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-1">
                                                        <Col lg={6}>
                                                            <Form>
                                                                <Row>
                                                                    <Col lg={12}>
                                                                        <FormGroup>
                                                                            <Label htmlFor="tel">ชื่อบริษัทนำเที่ยว </Label>
                                                                            <Input id="tel" name="tel" type="text" className="form-control" />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg={12}>
                                                                        <FormGroup>
                                                                            <Label htmlFor="eMail">ชื่อบริษัทนำเที่ยว</Label>
                                                                            <Input id="eMail" name="eMail" type="text" className="form-control" />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg={12}>
                                                                        <FormGroup>
                                                                            <Label htmlFor="website">ชื่อบริษัทนำเที่ยว</Label>
                                                                            <Input id="website" name="website" type="text" className="form-control" />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            </Form>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <Row>
                                                                <Col lg={12}>
                                                                    <FormGroup>
                                                                        <Label htmlFor="facebook">ลูกทัวร์</Label>
                                                                        <Input id="facebook" name="facebook" type="text" className="form-control" />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg={12}>
                                                                    <FormGroup>
                                                                        <Label htmlFor="instagram">ลูกทัวร์</Label>
                                                                        <Input id="instagram" name="instagram" type="text" className="form-control" />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg={12}>
                                                                    <FormGroup>
                                                                        <Label htmlFor="business">ลูกทัวร์</Label>
                                                                        <Input id="business" name="business" type="text" className="form-control" />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <hr></hr>
                                                    <Row>
                                                        <Col lg={8} md={8} sm={8}>
                                                        </Col>
                                                        <Col lg={2} md={2} sm={2}>
                                                            <Button className="color-c">ยกเลิก</Button>
                                                        </Col>
                                                        <Col lg={2} md={2} sm={2}>
                                                            <Button style={{ width: '100%', backgroundColor: '#224AAC', borderColor: '#224AAC' }}>ยืนยันการเพิ่มผู้ใช้งาน</Button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </CardBody>
                                        </Card>

                                    </Row> */}
                                </CardBody>
                            </Card>

                        </Container>
                        {/* <div className="container">
                            <div className="card">
                                <div className="card-body" style={{ textAlign: 'center' }}>
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <h3>{this.props.t('Select the type of user to add')}</h3>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: 50 }} className="row">
                                        <div className="col-md-4">
                                            <div onClick={() => { this.setState({ selected_users_type: 'admin', admin: this.empty_admin }) }} style={{ cursor: 'pointer' }} className="card">
                                                <div style={{ border: this.state.selected_users_type == 'admin' ? 'solid 2px #224AAC' : 'white', borderRadius: 5 }} className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div>  <img style={{ maxWidth: '80%' }} src={logo1} /></div>

                                                            <br></br>
                                                            <h6>{this.props.t('Add an administrator')}</h6>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div onClick={() => { this.setState({ selected_users_type: 'guide', guide: this.empty_guide }) }} style={{ cursor: 'pointer' }} className="card">
                                                <div style={{ border: this.state.selected_users_type == 'guide' ? 'solid 2px #224AAC' : 'white', borderRadius: 5 }} className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div>
                                                                <img style={{ maxWidth: '80%' }} src={logo3} />
                                                            </div>

                                                            <br></br>
                                                            <h6>{this.props.t('Add a guide')}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div onClick={() => { this.setState({ selected_users_type: 'agency', agency: this.empty_agency }) }} style={{ cursor: 'pointer' }} className="card">
                                                <div style={{ border: this.state.selected_users_type == 'agency' ? 'solid 2px #224AAC' : 'white', borderRadius: 5 }} className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div>
                                                                <img style={{ maxWidth: '80%' }} src={logo2} />
                                                            </div>

                                                            <br></br>
                                                            <h6>{this.props.t('Add a travel agency')}</h6>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.selected_users_type == '' &&
                            <div className="container">
                                <div className="card">
                                    <div className="card-body text-center">
                                        <div> {this.props.t('Please select the type of user')} </div>
                                    </div>
                                </div>

                            </div>
                        }

                        {this.state.selected_users_type == 'admin' &&
                            <div className="container">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="col-md-12">
                                            <div className="text-left">
                                                <h4>{this.props.t('Add an administrator')}</h4>
                                                <hr></hr>
                                                <Row>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <Label for="basicpill-firstname-input1">{this.props.t('User name')}</Label>
                                                            <Input value={this.state.admin.username} onChange={(e) => { var admin = { ...this.state.admin }; admin.username = e.target.value; this.setState({ admin }) }} type="text" className="form-control" />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <Label for="basicpill-firstname-input1">{this.props.t('Password')}</Label>
                                                            <Input value={this.state.admin.password} onChange={(e) => { var admin = { ...this.state.admin }; admin.password = e.target.value; this.setState({ admin }) }} type="password" className="form-control" />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <Label for="basicpill-lastname-input2">{this.props.t('Enter password again')}</Label>
                                                            <Input value={this.state.admin.password_again} onChange={(e) => { var admin = { ...this.state.admin }; admin.password_again = e.target.value; this.setState({ admin }) }} type="password" className="form-control" id="basicpill-lastname-input2" />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <Label for="basicpill-firstname-input1">{this.props.t('First name')}</Label>
                                                            <Input value={this.state.admin.firstname} onChange={(e) => { var admin = { ...this.state.admin }; admin.firstname = e.target.value; this.setState({ admin }) }} type="text" className="form-control" id="basicpill-firstname-input1" />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <Label for="basicpill-lastname-input2">{this.props.t('Last name')}</Label>
                                                            <Input value={this.state.admin.lastname} onChange={(e) => { var admin = { ...this.state.admin }; admin.lastname = e.target.value; this.setState({ admin }) }} type="text" className="form-control" id="basicpill-lastname-input2" />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <Label for="basicpill-firstname-input1">{this.props.t('Email')}</Label>
                                                            <Input value={this.state.admin.email} onChange={(e) => { var admin = { ...this.state.admin }; admin.email = e.target.value; this.setState({ admin }) }} type="text" className="form-control" id="basicpill-firstname-input1" />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <Label for="basicpill-lastname-input2">{this.props.t('ID card number')}</Label>
                                                            <Input value={this.state.admin.identication_card} onChange={(e) => { var admin = { ...this.state.admin }; admin.identication_card = e.target.value; this.setState({ admin }) }} type="text" className="form-control" id="basicpill-lastname-input2" />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <hr></hr>
                                                <Row>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <input onChange={(e) => {
                                                                var files = e.target.files
                                                                var allow_input = ['jpg', 'jpeg', 'png', 'gif']
                                                                var found = false
                                                                for (var allow of allow_input) {
                                                                    if (files[0].type.search(allow) > -1) {
                                                                        found = true
                                                                    }
                                                                }
                                                                if (found) {
                                                                    var reader = new FileReader();
                                                                    reader.onload = function (e) {
                                                                        document.getElementById('admin_photo_preview').src = e.target.result
                                                                    }
                                                                    reader.readAsDataURL(files[0]); // convert to base64 string

                                                                    var admin = { ...this.state.admin };
                                                                    admin.photo = files;
                                                                    this.setState({ admin }, () => {
                                                                        console.log(this.state.admin)
                                                                    })
                                                                } else {
                                                                    Swal.fire({
                                                                        icon: 'error',
                                                                        title: 'ผิดพลาด',
                                                                        text: 'อนุญาตสนามสกุล jpg , jpeg , png และ gif เท่านั้น !',
                                                                    })
                                                                    var admin = { ...this.state.admin };
                                                                    admin.photo = {};
                                                                    e.target.value = null
                                                                    this.setState({ admin }, () => {
                                                                        console.log(this.state.admin)
                                                                    })

                                                                }

                                                            }} type="file" className="custom-file-input" id="admin_photo_files" />
                                                            <label className="custom-file-label" htmlFor="customFile">{this.props.t('Select Image')}</label>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="12">
                                                        <center><img id="admin_photo_preview" style={{ maxWidth: '30%', minHeight: '150px' }} src="/assets/images/others/avatar.png" /></center>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={12}>
                                                        <Card>
                                                            <CardBody>
                                                                <div className="text-center">
                                                                    <button onClick={() => this.add_admin_users()} className="btn btn-success">{this.props.t('Add User')}</button>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        }

                        {this.state.selected_users_type == 'guide' &&
                            <div className="container">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="col-md-12">
                                            <div className="text-left">
                                                <h4>{this.props.t('Add a guide')}</h4>
                                                <hr></hr>
                                                <Row>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <Label for="basicpill-firstname-input1">{this.props.t('User name')}</Label>
                                                            <Input value={this.state.guide.username} onChange={(e) => { var guide = { ...this.state.guide }; guide.username = e.target.value; this.setState({ guide }) }} type="text" className="form-control" />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <Label for="basicpill-firstname-input1">{this.props.t('Password')}</Label>
                                                            <Input value={this.state.guide.password} onChange={(e) => { var guide = { ...this.state.guide }; guide.password = e.target.value; this.setState({ guide }) }} type="password" className="form-control" />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <Label for="basicpill-lastname-input2">{this.props.t('Enter password again')}</Label>
                                                            <Input value={this.state.guide.password_again} onChange={(e) => { var guide = { ...this.state.guide }; guide.password_again = e.target.value; this.setState({ guide }) }} type="password" className="form-control" id="basicpill-lastname-input2" />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <Label for="basicpill-firstname-input1">{this.props.t('First name')}</Label>
                                                            <Input value={this.state.guide.firstname} onChange={(e) => { var guide = { ...this.state.guide }; guide.firstname = e.target.value; this.setState({ guide }) }} type="text" className="form-control" id="basicpill-firstname-input1" />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <Label for="basicpill-lastname-input2">{this.props.t('Last name')}</Label>
                                                            <Input value={this.state.guide.lastname} onChange={(e) => { var guide = { ...this.state.guide }; guide.lastname = e.target.value; this.setState({ guide }) }} type="text" className="form-control" id="basicpill-lastname-input2" />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <Label for="basicpill-firstname-input1">{this.props.t('Email')}</Label>
                                                            <Input value={this.state.guide.email} onChange={(e) => { var guide = { ...this.state.guide }; guide.email = e.target.value; this.setState({ guide }) }} type="text" className="form-control" id="basicpill-firstname-input1" />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <Label for="basicpill-lastname-input2">{this.props.t('Email')}</Label>
                                                            <Input value={this.state.guide.identication_card} onChange={(e) => { var guide = { ...this.state.guide }; guide.identication_card = e.target.value; this.setState({ guide }) }} type="text" className="form-control" id="basicpill-lastname-input2" />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <Label for="basicpill-firstname-input1">{this.props.t('License number')}</Label>
                                                            <Input value={this.state.guide.license} onChange={(e) => { var guide = { ...this.state.guide }; guide.license = e.target.value; this.setState({ guide }) }} type="text" className="form-control" id="basicpill-firstname-input1" />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <Label for="basicpill-lastname-input2">{this.props.t('Address')}</Label>
                                                            <Input value={this.state.guide.address} onChange={(e) => { var guide = { ...this.state.guide }; guide.address = e.target.value; this.setState({ guide }) }} type="text" className="form-control" id="basicpill-lastname-input2" />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <hr></hr>
                                                <Row>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <input onChange={(e) => {
                                                                var files = e.target.files
                                                                var allow_input = ['jpg', 'jpeg', 'png', 'gif']
                                                                var found = false
                                                                for (var allow of allow_input) {
                                                                    if (files[0].type.search(allow) > -1) {
                                                                        found = true
                                                                    }
                                                                }
                                                                if (found) {
                                                                    var reader = new FileReader();
                                                                    reader.onload = function (e) {
                                                                        document.getElementById('guide_photo_preview').src = e.target.result
                                                                    }
                                                                    reader.readAsDataURL(files[0]); // convert to base64 string

                                                                    var guide = { ...this.state.guide };
                                                                    guide.photo = files;
                                                                    this.setState({ guide }, () => {
                                                                        console.log(this.state.guide)
                                                                    })
                                                                } else {
                                                                    Swal.fire({
                                                                        icon: 'error',
                                                                        title: 'ผิดพลาด',
                                                                        text: 'อนุญาตสนามสกุล jpg , jpeg , png และ gif เท่านั้น !',
                                                                    })
                                                                    var guide = { ...this.state.guide };
                                                                    guide.photo = {};
                                                                    e.target.value = null
                                                                    this.setState({ guide }, () => {
                                                                        console.log(this.state.guide)
                                                                    })

                                                                }

                                                            }} type="file" className="custom-file-input" id="guide_photo_files" />
                                                            <label className="custom-file-label" htmlFor="customFile">{this.props.t('Select Image')}</label>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="12">
                                                        <center><img id="guide_photo_preview" style={{ maxWidth: '30%', minHeight: '150px' }} src="/assets/images/others/avatar.png" /></center>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={12}>
                                                        <Card>
                                                            <CardBody>
                                                                <div className="text-center">
                                                                    <button onClick={() => this.add_guide_users()} className="btn btn-success">{this.props.t('Add User')}</button>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        }

                        {this.state.selected_users_type == 'agency' &&
                            <div className="container">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="col-md-12">
                                            <div className="text-left">
                                                <h4>{this.props.t('Add a travel agency')}</h4>
                                                <hr></hr>
                                                <Row>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <Label for="basicpill-firstname-input1">{this.props.t('User name')}</Label>
                                                            <Input value={this.state.agency.username} onChange={(e) => { var agency = { ...this.state.agency }; agency.username = e.target.value; this.setState({ agency }) }} type="text" className="form-control" />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <Label for="basicpill-firstname-input1">{this.props.t('Password')}</Label>
                                                            <Input value={this.state.agency.password} onChange={(e) => { var agency = { ...this.state.agency }; agency.password = e.target.value; this.setState({ agency }) }} type="password" className="form-control" />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <Label for="basicpill-lastname-input2">{this.props.t('Enter password again')}</Label>
                                                            <Input value={this.state.agency.password_again} onChange={(e) => { var agency = { ...this.state.agency }; agency.password_again = e.target.value; this.setState({ agency }) }} type="password" className="form-control" id="basicpill-lastname-input2" />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <Label for="basicpill-firstname-input1">{this.props.t('First name')}</Label>
                                                            <Input value={this.state.agency.firstname} onChange={(e) => { var agency = { ...this.state.agency }; agency.firstname = e.target.value; this.setState({ agency }) }} type="text" className="form-control" id="basicpill-firstname-input1" />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <Label for="basicpill-lastname-input2">{this.props.t('Last name')}</Label>
                                                            <Input value={this.state.agency.lastname} onChange={(e) => { var agency = { ...this.state.agency }; agency.lastname = e.target.value; this.setState({ agency }) }} type="text" className="form-control" id="basicpill-lastname-input2" />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <Label for="basicpill-firstname-input1">{this.props.t('Email')}</Label>
                                                            <Input value={this.state.agency.email} onChange={(e) => { var agency = { ...this.state.agency }; agency.email = e.target.value; this.setState({ agency }) }} type="text" className="form-control" id="basicpill-firstname-input1" />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <Label for="basicpill-lastname-input2">{this.props.t('ID card number')}</Label>
                                                            <Input value={this.state.agency.identication_card} onChange={(e) => { var agency = { ...this.state.agency }; agency.identication_card = e.target.value; this.setState({ agency }) }} type="text" className="form-control" id="basicpill-lastname-input2" />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <Label for="basicpill-firstname-input1">{this.props.t('License number')}</Label>
                                                            <Input value={this.state.agency.license} onChange={(e) => { var agency = { ...this.state.agency }; agency.license = e.target.value; this.setState({ agency }) }} type="text" className="form-control" id="basicpill-firstname-input1" />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <Label for="basicpill-lastname-input2">{this.props.t('Travel agency name')}</Label>
                                                            <Input value={this.state.agency.name} onChange={(e) => { var agency = { ...this.state.agency }; agency.name = e.target.value; this.setState({ agency }) }} type="text" className="form-control" id="basicpill-lastname-input2" />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <Label for="basicpill-firstname-input1">{this.props.t('Travel agency address')}</Label>
                                                            <Input value={this.state.agency.address} onChange={(e) => { var agency = { ...this.state.agency }; agency.address = e.target.value; this.setState({ agency }) }} type="text" className="form-control" id="basicpill-firstname-input1" />
                                                        </FormGroup>
                                                    </Col>

                                                </Row>
                                                <hr></hr>
                                                <Row>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <input onChange={(e) => {
                                                                var files = e.target.files
                                                                var allow_input = ['jpg', 'jpeg', 'png', 'gif']
                                                                var found = false
                                                                for (var allow of allow_input) {
                                                                    if (files[0].type.search(allow) > -1) {
                                                                        found = true
                                                                    }
                                                                }
                                                                if (found) {
                                                                    var reader = new FileReader();
                                                                    reader.onload = function (e) {
                                                                        document.getElementById('agency_photo_preview').src = e.target.result
                                                                    }
                                                                    reader.readAsDataURL(files[0]); // convert to base64 string

                                                                    var agency = { ...this.state.agency };
                                                                    agency.photo = files;
                                                                    this.setState({ agency }, () => {
                                                                        console.log(this.state.agency)
                                                                    })
                                                                } else {
                                                                    Swal.fire({
                                                                        icon: 'error',
                                                                        title: 'ผิดพลาด',
                                                                        text: 'อนุญาตสนามสกุล jpg , jpeg , png และ gif เท่านั้น !',
                                                                    })
                                                                    var agency = { ...this.state.agency };
                                                                    agency.photo = {};
                                                                    e.target.value = null
                                                                    this.setState({ agency }, () => {
                                                                        console.log(this.state.agency)
                                                                    })

                                                                }

                                                            }} type="file" className="custom-file-input" id="agency_photo_files" />
                                                            <label className="custom-file-label" htmlFor="customFile">{this.props.t('Select Image')}</label>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="12">
                                                        <center><img id="agency_photo_preview" style={{ maxWidth: '30%', minHeight: '150px' }} src="/assets/images/others/avatar.png" /></center>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={12}>
                                                        <Card>
                                                            <CardBody>
                                                                <div className="text-center">
                                                                    <button onClick={() => this.add_agency_users()} className="btn btn-success">{this.props.t('Add User')}</button>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        } */}
                    </div>
                </React.Fragment >
            );
        }

    }
}
const mapStatetoProps = state => {
    return {
        ...state.Layout,
        login: state.Login
    };
};
export default connect(mapStatetoProps, {})(withTranslation()(AddUsers));