import React, { Component } from 'react'
import { Row, Col } from "reactstrap";
// import Swal from 'sweetalert2'
import Lottie from "react-lottie";
import * as loadingData from "../../Joborder/men-component/loading.json";
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

export default class SearchNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            loading: false
        }
        console.log("props", props)
    };

    submitHandle(value) {
        this.props.callback('search', value)
    }

    componentDidMount() {
        this.props.callback('search', this.state.search)
    }

    render() {
        return (
            <>
                <Row className="mb-3" >
                    {this.props.filter === 'country' ? <></>
                        :
                        <>
                            <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>
                                {this.props.filter === 'attraction' ?
                                    <>
                                        <span style={{ fontSize: 16 }}>ค้นหาสถานที่ท่องเที่ยว : </span>
                                    </>
                                    : this.props.filter === 'accommodation' ?
                                        <>
                                            <span style={{ fontSize: 16 }}>ค้นหาสถานโรงแรมหรือที่พัก : </span>
                                        </>
                                        :
                                        <> </>
                                }

                            </Col>
                            <Col xl={5} lg={5} md={5} sm={12} style={{ display: 'inline-flex', overflow: 'auto' }}>
                                <input type="text" placeholder="ค้นหา..." className="form-control"
                                    onChange={(e) => {
                                        this.setState({
                                            loading: true
                                        }, () => {
                                            if (typeof this.timeout !== 'undefined') {
                                                try {
                                                    clearTimeout(this.timeout);
                                                } catch (err) {

                                                }
                                            }
                                            this.timeout = setTimeout(async () => {
                                                this.setState({ search: e.target.value, loading: false }, () => { this.submitHandle(e.target.value) })
                                            }, 2000);
                                        })


                                    }} />
                            </Col>
                        </>
                    }

                    {this.state.loading === true &&
                        <Col xl={5} lg={5} md={5} sm={12} style={{ display: 'inline-flex', overflow: 'auto' }}>
                            <Row style={{ alignSelf: 'center' }}>
                                <Lottie options={defaultOptions} height={35} width={35} style={{ marginRight: 10 }} />
                                <span style={{ alignSelf: 'center' }}>กำลังค้นหา</span></Row>

                        </Col>
                    }



                </Row>
                {this.props.filter === "country" ?
                    <></>
                    :
                    <>
                        {this.state.search === '' || this.state.search === null ?
                            <Row className="mb-3" >
                                <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>

                                </Col>
                                <Col xl={5} lg={5} md={5} sm={12} style={{ display: 'inline-flex', overflow: 'auto', top: -10 }}>
                                    {this.props.filter === 'attraction' ?
                                        <>
                                            <small style={{ color: 'red' }}>* กรุณาระบุสถานที่ท่องเที่ยว</small>
                                        </>
                                        :
                                        <>
                                            <small style={{ color: 'red' }}>* กรุณาระบุโรงแรมหรือสถานที่พัก</small>
                                        </>
                                    }

                                </Col>
                            </Row>
                            :
                            null
                        }
                    </>
                }


            </>
        )
    }
}
