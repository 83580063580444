import React, { Component } from 'react'
import { Link } from "react-router-dom";
import {
    Row,
    Col,
    Input,
    Card,
    CardBody,
    Label,
    FormGroup,
    Button
} from "reactstrap";
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Breadcrumbs from '../../../src/components/Common/Breadcrumb';
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
import { withTranslation } from 'react-i18next';
import { API_ENDPOINT } from '../../../src/assets/api';

const columns = [
    { field: 'id', headerName: 'ลำดับ', width: 200 },
    {
        field: 'title',
        headerName: 'ชื่อข่าวสารและกิจกรรม',
        width: 500,
        align: 'left',
    },
    {
        field: 'start_date',
        headerName: 'วันที่เผยแพร่',
        width: 200,
        align: 'left',
    },
    {
        field: 'end_date',
        headerName: 'เผยแพร่ถึงวันที่',
        width: 200,

        align: 'left',
    },
    {
        field: 'status',
        headerName: 'สถานะ',
        width: 200,
        align: 'left',
    },
    {
        field: 'actions',
        type: 'actions',
        headerName: 'จัดการ',
        width: 200,
        align: 'center',
        renderCell: (params) => {
            const editLink = `/news/edit/${params.row.id}`
            return (
                <div>
                    <Link to={editLink}>
                        {/* <EditIcon /> */}
                        <Button type="button" color="primary" className="btn-sm btn-rounded">
                            <i className="fas fa-edit" style={{ color: 'white', marginRight: 5 }}></i>แก้ไข
                        </Button>
                    </Link>
                </div>
            )
        }
    }
];
class TableNews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: { contents: [], page: {} },
            isLoading: false,
            paginationModel: {
                pageSize: 10,
                page: 0,
            },
            rowCountState: 0,
            filter: {
                search: "",
                selected_date_start: "",
                selected_date_end: "",
                IsEnabled: "",
            },
        }

    }
    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.paginationModel.page !== this.state.paginationModel.page ||
            prevState.paginationModel.pageSize !== this.state.paginationModel.pageSize
        ) {
            this.fetchData(); // เรียก fetchData เมื่อมีการเปลี่ยนแปลง pagination model
        }
    }

    fetchData = async () => {
        this.setState({ isLoading: true });
        try {
            const auth = JSON.parse(localStorage.getItem('authToken'));
            const response = await fetch(
                API_ENDPOINT +
                `api/v2/web/contents?pageNumber=${this.state.paginationModel.page + 1
                }&maxPage=${this.state.paginationModel.pageSize
                }&Search=${this.state.filter.search
                }&StartDate=${this.state.filter.selected_date_start
                }&EndDate=${this.state.filter.selected_date_end
                }&IsEnabled=${this.state.filter.IsEnabled}`,
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: auth.data.type + ' ' + auth.data.token,
                    },
                }
            );

            const dataFromApi = await response.json();
            this.setState({
                data: dataFromApi,
                rowCountState: dataFromApi.page.totalResult || 0,
                isLoading: false,
            });
        } catch (error) {
            console.error('Error fetching data:', error);
            this.setState({ isLoading: false });
        }
    };

    handlePaginationModelChange = (newPaginationModel) => {
        this.setState({ paginationModel: newPaginationModel });
    };
    inputHandle = (name, value) => {
        console.log("name", name);
        console.log("value", value);
        this.setState((prev) => ({
            filter: {
                ...prev.filter,
                [name]: value,
            },
        }), () => {
            console.log("Updated filter:", this.state.filter); // นี้คือ console.log ที่เพิ่ม
        });
    }
    buttonSearchHandle = () => {
        this.fetchData();
    }
    buttonClearHandle = () => {
        this.setState({
            filter: {
                search: "",
                selected_date_start: "",
                selected_date_end: "",
                IsEnabled: "",
                pageNumber: 0,
                maxPage: 10,
            },
        }, () => {
            this.fetchData();
        });
    }
    render() {
        if (this.state.isLoading === true) {
            return (
                <div></div>
            )
        }
        else {
            return (
                <React.Fragment>
                    <div className="page-content">
                        <div className='container-fluid'>
                            <Row>
                                <Col xs="12">
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Breadcrumbs title="News" breadcrumbItem={this.props.t("News")} />
                                        <Link to={'/news/add'} style={{ display: 'flex', alignItems: 'center' }}>
                                            <button type="button" className="btn btn-primary waves-effect waves-light" style={{ width: '100%', backgroundColor: '#224AAC', borderColor: '#224AAC' }}>
                                                <i className="fas fa-plus-circle mr-2"></i>
                                                <span className="font-style-16"> {this.props.t('Add News')}</span>
                                            </button>
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                            {/*Card Search*/}
                            <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                <CardBody>
                                    <Row className="mb-4">
                                        <Col xl={3} lg={6} md={6} sm={12} xs={12}>
                                            <FormGroup className="mt-3 mb-0">
                                                <Label>ค้นหาจากชื่อ :</Label>
                                                <Input type="text" name="search" className="form-control" placeholder="ชื่อ"
                                                    value={this.state.filter.search}
                                                    onChange={(e) => this.inputHandle("search", e.target.value)} />
                                            </FormGroup>
                                        </Col>
                                        <Col xl={3} lg={6} md={6} sm={12} xs={12}>
                                            <FormGroup className="mt-3 mb-0">
                                                <Label for="type">วันที่เผยแพร่</Label>
                                                <input type="date" name="selected_date_start" placeholder="วันที่เผยแพร่" className="form-control"
                                                    value={this.state.filter.selected_date_start}
                                                    onChange={(e) => this.inputHandle("selected_date_start", e.target.value)} />
                                            </FormGroup>
                                        </Col>
                                        <Col xl={3} lg={6} md={6} sm={12} xs={12}>
                                            <FormGroup className="mt-3 mb-0">
                                                <Label for="type">เผยแพร่ถึงวันที่</Label>
                                                <input type="date" name="selected_date_end" placeholder="เผยแพร่ถึงวันที่" className="form-control"
                                                    value={this.state.filter.selected_date_end}
                                                    onChange={(e) => this.inputHandle("selected_date_end", e.target.value)} />
                                            </FormGroup>
                                        </Col>
                                        <Col xl={3} lg={6} md={6} sm={12} xs={12}>
                                            <FormGroup className="mt-3 mb-0">
                                                <Label for="kycselectcity-input">สถานะ</Label>
                                                <select className="custom-select"
                                                    value={this.state.filter.IsEnabled !== null ? this.state.filter.IsEnabled : ""}
                                                    onChange={(e) => this.inputHandle("IsEnabled", e.target.value)}>
                                                    <option value="">กรุณาเลือกสถานะ...</option>
                                                    <option value="Y">เผยแพร่</option>
                                                    <option value="N">ไม่เผยแพร่</option>
                                                </select>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <hr></hr>
                                    <Row style={{ justifyContent: 'flex-end' }}>
                                        <Col xl={2} lg={3} md={6} sm={12} xs={12} >
                                            <FormGroup className="mb-0">
                                                <button className="mt-1r btn btn-primary waves-effect waves-light"
                                                    onClick={this.buttonClearHandle}
                                                    style={{ width: '100%', background: 'transparent', borderColor: '#c2c2c2' }}>
                                                    <span className="font-style-16 cl-gray">{this.props.t('Clear')}</span>
                                                </button>
                                            </FormGroup>
                                        </Col>
                                        <Col xl={2} lg={3} md={6} sm={12} xs={12} >
                                            <FormGroup className="mb-0">
                                                <button className="btn btn-primary waves-effect waves-light"
                                                    onClick={this.buttonSearchHandle}
                                                    style={{ width: '100%', background: 'transparent', borderColor: '#2249AC' }}>
                                                    <img className="" src={'/assets/images/icon/search.svg'} alt="" style={{ marginRight: '0.5rem' }}></img><span className="font-style-16 cl-blue">{this.props.t('Search')}</span>
                                                </button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                <CardBody>
                                    <Box sx={{ height: 400, width: '100%' }}>
                                        <DataGrid
                                            rows={this.state.data.contents}
                                            columns={columns}
                                            rowCount={this.state.rowCountState}
                                            loading={this.state.isLoading}
                                            pageSizeOptions={[10]}
                                            paginationModel={this.state.paginationModel}
                                            paginationMode="server"
                                            onPaginationModelChange={this.handlePaginationModelChange}
                                            checkboxSelection
                                            disableRowSelectionOnClick
                                        />
                                    </Box>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </React.Fragment>
            )
        }

    }
}
export default (
    (withTranslation()(TableNews))
);
