import React from "react";
import { Redirect } from "react-router-dom";

// User profile
import UserProfile from "../pages/Authentication/UserProfile";
import TableNews from "../pages/News/TableNews"

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ResetPwd from "../pages/Authentication/ResetPassword";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

import JobOrderSearch from "../pages/JobOrderSearch/search";
import Intro from "../pages/Introduction/Intro";
//doodeep
import Trip from "../pages/Trip/Trip";
import Joborder from "../pages/Joborder/Joborder";
import TableUser from "../pages/Management/component/TableUser";
import AddUsers from "../pages/Management/users/AddUsers";
import UsersType from "../pages/Management/component/UserType";
import Report from "../pages/Report/Report";
import NewReport from "../pages/Report/NewReport";
import Addtrip from "../pages/Trip/Addtrip";
import Groupsave from "../pages/Trip/Groupsave";
import Grouptimeperiod from "../pages/Trip/Grouptimeperiod";
import Tripdetail from "../pages/Trip/Tripdetail";

import FormUpload from "../pages/Trip/components/FormUpload"
import ReportUserCount from "../pages/Report/ReportUserCount";
import ReportAttractionCount from "../pages/Report/ReportAttractionCount";
import NewTouristsReportCount from "../pages/Report/NewTouristsReportCount";
import NewTouristsTrends from "../pages/Report/NewTouristsTrends";
import NewDataRelationship from "../pages/Report/NewDataRelationship";
import TouristsReportCount from "../pages/Report/TouristsReportCount";
import TouristsReport from "../pages/Report/TouristsReport";
import TourismTrends from "../pages/Report/TourismTrends";
import DataRelationship from "../pages/Report/DataRelationship";
import StaffPerformance from "../pages/Report/StaffPerformance";
import HeatMap from "../pages/Report/HeatMap";
import SearchTourists from "../pages/Report/SearchTourists";

import AddPlaces from "../pages/Places/AddPlaces";
import EditPlaces from "../pages/Places/EditPlaces"
import AddNews from "../pages/News/Addnews"
import EditNews from "../pages/News/Editnews"
import AddJoborder from '../pages/Joborder/men-component/AddJoborder'
import EditJoborder from '../pages/Joborder/men-component/EditJoborder'
import JoborderProgress from '../pages/Joborder/progress/Progress'
import Savedoc from '../pages/Joborder/Savedoc';
import Searchdoc from '../pages/Joborder/SearchDoc';

import Groupregion from "../pages/Trip/Groupregion";

import Addjoborder from "../pages/Joborder/Addjoborder";
import Placedetail from "../pages/Management/Placedetail";
import Groupslides from "../pages/Trip/Groupslides";
import Userdetail from "../pages/Management/Userdetail";

import Tableplacedv from '../pages/Management/component/TablePlace/TableplaceDv';

import EditTrip from '../pages/Trip/EditTrip';
import TableTrip from '../pages/Management/component/TableTrip';

import ReportAdmin from '../pages/Report/reportAdmin'

const authProtectedRoutes = [
	//Trip
	{ path: "/Trip/Addtrip/Saveplan", component: Groupsave },
	{ path: "/Trip/Addtrip/Selectinterested", component: Groupslides },
	{ path: "/Trip/Addtrip/Selectregion", component: Groupregion },
	{ path: "/Trip/Addtrip/Selecttimeperiod", component: Grouptimeperiod },
	{ path: "/Trip/Addtrip", component: Addtrip },
	{ path: "/Trip/FormUpload", component: FormUpload },
	{ path: "/Trip", capacity: ['trip:view_navigate'], component: Trip },
	{ path: "/Tripdetail", component: Tripdetail },

	//Joborder
	{ path: "/joborder/progress", component: JoborderProgress },
	{ path: "/Joborder/Addjoborder", component: Addjoborder },
	{ path: "/Joborder", component: Joborder },

	// { path: "/kabanGuide", component: kabanGuide },
	{ path: "/Savedoc/:code", component: Savedoc },
	{ path: "/Savedoc", component: Savedoc },

	//Management
	{ path: "/news/add", component: AddNews },
	{ path: "/news/edit/:news_id", component: EditNews },
	{ path: "/places/add", component: AddPlaces },
	{ path: "/places/edit/:place_id", component: EditPlaces },
	{ path: "/places/detail", component: Placedetail },
	{ path: "/places", component: Tableplacedv },
	{ path: "/Tripmanagement/edit/:tour_id", component: EditTrip },
	{ path: "/Tripmanagement", component: TableTrip },

	{ path: "/Usermanagement", component: TableUser },
	{ path: "/User/add", component: AddUsers },
	{ path: "/Users/Type", component: UsersType },
	{ path: "/Userdetail/:id", component: Userdetail },


	//Report
	{ path: "/Report", component: Report },
	{ path: "/NewReport", component: NewReport },
	{ path: "/search-tourists", component: SearchTourists },
	{ path: "/tourists-report", component: TouristsReport },
	{ path: "/new-tourists-report-count", component: NewTouristsReportCount },
	{ path: "/new-tourism-trends", component: NewTouristsTrends },
	{ path: "/new-data-relationship", component: NewDataRelationship },
	{ path: "/report-attraction-count", component: ReportAttractionCount },
	{ path: "/report-user-count", component: ReportUserCount },
	{ path: "/tourists-report-count", component: TouristsReportCount },
	{ path: "/tourism-trends", component: TourismTrends },
	{ path: "/data-relationship", component: DataRelationship },
	{ path: "/staff-performance", component: StaffPerformance },
	{ path: "/heat-map", component: HeatMap },
	{ path: "/dashboard-executive", component: ReportAdmin },
	{ path: "/Joborderadd", component: AddJoborder },
	{ path: "/JoborderEdit/:code", component: EditJoborder },


	{ path: "/Joborderaddjb", component: AddJoborder },

	//profile
	{ path: "/profile", component: UserProfile },
	{ path: "/news", component: TableNews },

	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/Joborder" /> }
];

const publicRoutes = [
	{ path: "/joborder/paper/:code", component: Savedoc },
	{ path: "/joborder/search_paper/:code", component: Searchdoc },
	{ path: "/joborder_search", component: JobOrderSearch },
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
	{ path: "/reset-password", component: ResetPwd },
	{ path: "/forgot-password", component: ForgetPwd },
	{ path: "/introduction", component: Intro },
];

export { authProtectedRoutes, publicRoutes };
