import React, { Component } from "react";
import { Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";
import '../../assets/scss/custom.scss'
//i18n
import { withTranslation } from 'react-i18next';

class Navbar extends Component {
    constructor(props) {
        super(props);
        const user = JSON.parse(localStorage.getItem("authUser"))
        this.state = {
            user_type: user.type
        };

    }

    componentDidMount() {
        var matchingMenuItem = null;
        var ul = document.getElementById("navigation");
        var items = ul.getElementsByTagName("a");
        for (var i = 0; i < items.length; ++i) {
            if (this.props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }
    }

    activateParentDropdown = item => {
        item.classList.add("active");
        const parent = item.parentElement;
        if (parent) {
            parent.classList.add("active"); // li
            const parent2 = parent.parentElement;
            parent2.classList.add("active"); // li
            const parent3 = parent2.parentElement;
            if (parent3) {
                parent3.classList.add("active"); // li
                const parent4 = parent3.parentElement;
                if (parent4) {
                    parent4.classList.add("active"); // li
                    const parent5 = parent4.parentElement;
                    if (parent5) {
                        parent5.classList.add("active"); // li
                        const parent6 = parent5.parentElement;
                        if (parent6) {
                            parent6.classList.add("active"); // li
                        }
                    }
                }
            }
        }
        return false;
    };

    checkCapacity(require, userCapacity) {
        if (userCapacity.indexOf(require) > -1) return true
        return false
    }

    render() {
        return (
            <React.Fragment>
                <div className="topnav">
                    <div className="container-fluid" style={{ marginTop: '0.5rem' }}>
                        <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation">
                            <Collapse isOpen={this.props.menuOpen} className="navbar-collapse" id="topnav-menu-content">
                                <ul className="navbar-nav">{/* 
                                    <li className="nav-item dropdown" id="Dashboard_navagation" >
                                        <Link className="nav-link dropdown-toggle arrow-none" to="/Dashboard" style={{ fontSize: 18 }}>
                                            <i className="fas fa-home mr-2"></i>{this.props.t('Dashboard')}<div to="Dashboard"></div>
                                        </Link>
                                    </li> */}
                                    {['1', '3'].indexOf(String(this.state.user_type)) > -1 &&
                                        <li className="nav-item dropdown" id="Trip_navagation" >
                                            <Link className="nav-link dropdown-toggle arrow-none" to="/Trip" style={{ fontSize: 18 }}>
                                                <div>
                                                    <i className="fas fa-suitcase mr-2"></i>{this.props.t('Trip')}<div to="Trip"></div>
                                                </div>
                                            </Link>
                                        </li>
                                    }
                                    {['1', '3', '4'].indexOf(String(this.state.user_type)) > -1 &&
                                        <li className="nav-item dropdown" id="joborder_navagation" >
                                            <Link className="nav-link dropdown-toggle arrow-none" to="/Joborder" id="joborder_navagation" style={{ fontSize: 18 }}>
                                                <i className="far fa-file-alt mr-2"></i>{this.props.t('Joborder')}<div to="Joborder"></div>
                                            </Link>
                                        </li>
                                    }
                                    {['1'].indexOf(String(this.state.user_type)) > -1 &&
                                        <li className="nav-item dropdown" id="Management_navagation" >
                                            <Link to="/#" className="nav-link dropdown-toggle arrow-none" style={{ fontSize: 18 }}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.setState({ componentState: !this.state.componentState });
                                                }}>
                                                <i className="bx bx-collection mr-2"></i>{this.props.t('Management')} <div className="arrow-down"></div>
                                            </Link>
                                            <div className={classname("dropdown-menu", { show: this.state.componentState })}>
                                                <Link style={{ fontSize: 18 }} to="/Usermanagement" className="dropdown-item"><i className="fas fa-user-circle pr-2"></i>{this.props.t('User Management')}  </Link>
                                                <Link style={{ fontSize: 18 }} to="/places" className="dropdown-item"><i className="fa fa-map-marker pr-2" aria-hidden="true"></i>{this.props.t('Place Management')}</Link>

                                                <Link style={{ fontSize: 18 }} to="/Tripmanagement" className="dropdown-item"><i className="fas fa-plane-departure pr-2"></i>{this.props.t('Trip Management')}</Link>

                                            </div>
                                        </li>
                                    }
                                    {['3'].indexOf(String(this.state.user_type)) > -1 &&
                                        <li className="nav-item dropdown" id="Management_navagation" >
                                            <Link to="/#" className="nav-link dropdown-toggle arrow-none" style={{ fontSize: 18 }}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.setState({ componentState: !this.state.componentState });
                                                }}>
                                                <i className="bx bx-collection mr-2"></i>{this.props.t('Management')} <div className="arrow-down"></div>
                                            </Link>
                                            <div className={classname("dropdown-menu", { show: this.state.componentState })}>
                                                <Link style={{ fontSize: 18 }} to="/places" className="dropdown-item"><i className="fa fa-map-marker pr-2" aria-hidden="true"></i>{this.props.t('Place Management')}</Link>
                                            </div>
                                        </li>
                                    }
                                    {/* {['1', '4'].indexOf(String(this.state.user_type)) > -1 &&
                                        <li className="nav-item dropdown" id="Report_navagation" >
                                            <Link to="/#" className="nav-link dropdown-toggle arrow-none" style={{ fontSize: 18 }}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.setState({ componentStateReport: !this.state.componentStateReport });
                                                }}
                                            >
                                                <i className="fas fa-file-medical mr-2"></i>{this.props.t('Report')} <div className="arrow-down"></div>
                                            </Link>
                                            <div className={classname("dropdown-menu", { show: this.state.componentStateReport })}>
                                                <Link style={{ fontSize: 18 }} to="/tourists-report-count" className="dropdown-item"><i className="fas fa-users pr-2"></i>{this.props.t('The number of issuing job orders')}  </Link>
                                                <Link style={{ fontSize: 18 }} to="/tourists-report" className="dropdown-item"><i className="fas fa-users pr-2"></i>{this.props.t('Tourists Report')}  </Link>
                                                <Link style={{ fontSize: 18 }} to="/tourism-trends" className="dropdown-item"><i className="fas fa-poll pr-2"></i>{this.props.t('Tourism Trends')}  </Link>
                                                <Link style={{ fontSize: 18 }} to="/data-relationship" className="dropdown-item"><i className="fas fa-project-diagram pr-2"></i>{this.props.t('Data Relationship')}  </Link>
                                                <Link style={{ fontSize: 18 }} to="/staff-performance" className="dropdown-item"><i className="fas fa-user-tie pr-2"></i>{this.props.t('Staff Performance')}  </Link>
                                                <Link style={{ fontSize: 18 }} to="/heat-map" className="dropdown-item"><i className="fas fa-map pr-2"></i>{this.props.t('Heat Map')}  </Link>
                                            </div>
                                        </li>
                                    } */}
                                    {['1', '4'].indexOf(String(this.state.user_type)) > -1 &&
                                        <li className="nav-item dropdown" id="Report_navagation" >
                                            <Link to="/#" className="nav-link dropdown-toggle arrow-none" style={{ fontSize: 18 }}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.setState({ componentStateReport: !this.state.componentStateReport });
                                                }}
                                            >
                                                <i className="fas fa-file-medical mr-2"></i>{this.props.t('Report')} <div className="arrow-down"></div>
                                            </Link>
                                            <div className={classname("dropdown-menu", { show: this.state.componentStateReport })}>
                                                <Link style={{ fontSize: 18 }} to="/search-tourists" className="dropdown-item"><i className="fa fa-search pr-2"></i>{this.props.t('ค้นหารายชื่อนักท่องเที่ยว')}  </Link>
                                                <Link style={{ fontSize: 18 }} to="/report-user-count" className="dropdown-item"><i className="fas fa-users pr-2"></i>{this.props.t('User statistics')}  </Link>
                                                <Link style={{ fontSize: 18 }} to="/report-attraction-count" className="dropdown-item"><i className="fas fa-users pr-2"></i>{this.props.t('Attraction statistics')}  </Link>
                                                <Link style={{ fontSize: 18 }} to="/tourists-report" className="dropdown-item"><i className="fas fa-users pr-2"></i>{this.props.t('Tourists Report')}  </Link>
                                                <Link style={{ fontSize: 18 }} to="/new-tourists-report-count" className="dropdown-item"><i className="fas fa-users pr-2"></i>{this.props.t('The number of issuing job orders')}  </Link>
                                                <Link style={{ fontSize: 18 }} to="/new-tourism-trends" className="dropdown-item"><i className="fas fa-poll pr-2"></i>{this.props.t('Tourism Trends')}  </Link>
                                                <Link style={{ fontSize: 18 }} to="/data-relationship" className="dropdown-item"><i className="fas fa-project-diagram pr-2"></i>{this.props.t('Data Relationship')}  </Link>
                                                <Link style={{ fontSize: 18 }} to="/staff-performance" className="dropdown-item"><i className="fas fa-user-tie pr-2"></i>{this.props.t('Staff Performance')}  </Link>
                                                <Link style={{ fontSize: 18 }} to="/heat-map" className="dropdown-item"><i className="fas fa-map pr-2"></i>{this.props.t('Heat Map')}  </Link>

                                            </div>
                                        </li>
                                    }
                                    {['1', '4'].indexOf(String(this.state.user_type)) > -1 &&
                                        <li className="nav-item dropdown" id="dashboard-executive" >
                                            <Link className="nav-link dropdown-toggle arrow-none" to="/dashboard-executive" id="dashboard-executive" style={{ fontSize: 18 }}>
                                                <i className="far fa-file-alt mr-2"></i>{this.props.t('รายงานสำหรับผู้บริหาร')}<div to="dashboard-executive"></div>
                                            </Link>
                                        </li>
                                    }
                                    {['3'].indexOf(String(this.state.user_type)) > -1 &&
                                        <li className="nav-item dropdown" id="Report_navagation" >
                                            <Link to="/#" className="nav-link dropdown-toggle arrow-none" style={{ fontSize: 18 }}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.setState({ componentStateReport: !this.state.componentStateReport });
                                                }}
                                            >
                                                <i className="fas fa-file-medical mr-2"></i>{this.props.t('Report')} <div className="arrow-down"></div>
                                            </Link>
                                            <div className={classname("dropdown-menu", { show: this.state.componentStateReport })}>
                                                <Link style={{ fontSize: 18 }} to="/tourists-report" className="dropdown-item"><i className="fas fa-users pr-2"></i>{this.props.t('Tourists Report')}  </Link>
                                            </div>
                                        </li>
                                    }
                                    {['1', '4'].indexOf(String(this.state.user_type)) > -1 &&
                                        <li className="nav-item dropdown" id="joborder_navagation" >
                                            <Link className="nav-link dropdown-toggle arrow-none" to="/news" id="joborder_navagation" style={{ fontSize: 18 }}>
                                                <i className="far fa-file-alt mr-2"></i>{this.props.t('News')}
                                            </Link>
                                        </li>
                                    }
                                </ul>
                            </Collapse>
                        </nav>
                    </div>
                </div>
                {/* <div className="topnav">
                    <div className="container-fluid" style={{ height: '4.5rem' }}>
                        <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation">
                            <Collapse isOpen={this.props.menuOpen} className="navbar-collapse" id="topnav-menu-content">
                                <ul className="navbar-nav" style={{ marginLeft: '3.125rem' }}>
                                    <li className="nav-item dropdown mr-navbar" id="Dashboard_navagation" >
                                        <Link className="nav-link dropdown-toggle arrow-none style-navbar" to="/Dashboard">
                                            <div>
                                                <i className="fas fa-home mr-2"></i>{this.props.t('Dashboard')}<div to="Dashboard"></div>
                                            </div>
                                        </Link>
                                    </li>
                                    {['1', '3'].indexOf(String(this.state.user_type)) > -1 &&
                                        <li className="nav-item dropdown mr-navbar" id="Trip_navagation" >
                                            <Link className="nav-link dropdown-toggle arrow-none style-navbar" to="/Trip">
                                                <div>
                                                    <i className="fas fa-suitcase mr-2"></i>{this.props.t('Trip')}<div to="Trip"></div>
                                                </div>
                                            </Link>
                                        </li>
                                    }

                                    {['1', '3', '4'].indexOf(String(this.state.user_type)) > -1 &&
                                        <li className="nav-item dropdown mr-navbar" id="joborder_navagation" >
                                            <Link className="nav-link dropdown-toggle arrow-none style-navbar" to="/Joborder" id="joborder_navagation">
                                                <div>
                                                    <i className="far fa-file-alt mr-2"></i>{this.props.t('Joborder')}<div to="Joborder"></div>
                                                </div>
                                            </Link>
                                        </li>
                                    }
                                    {['1'].indexOf(String(this.state.user_type)) > -1 &&
                                        <li className="nav-item dropdown mr-navbar" id="Management_navagation" >
                                            <Link to="/#" className="nav-link dropdown-toggle arrow-none style-navbar"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.setState({ componentState: !this.state.componentState });
                                                }}>
                                                <div>
                                                    <i className="bx bx-collection mr-2"></i>{this.props.t('Management')} <div className="arrow-down"></div>
                                                </div>
                                            </Link>
                                            <div className={classname("dropdown-menu", { show: this.state.componentState })}>
                                                <Link style={{ fontSize: 18 }} to="/Usermanagement" className="dropdown-item"><i className="fas fa-user-circle pr-2"></i>{this.props.t('User Management')}  </Link>
                                                <Link style={{ fontSize: 18 }} to="/places" className="dropdown-item"><i className="fa fa-map-marker pr-2" aria-hidden="true"></i>{this.props.t('Place Management')}</Link>
                                            </div>
                                        </li>
                                    }
                                    {['1', '4'].indexOf(String(this.state.user_type)) > -1 &&
                                        <li className="nav-item dropdown mr-navbar" id="Report_navagation" >
                                            <Link to="/#" className="nav-link dropdown-toggle arrow-none style-navbar"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.setState({ componentStateReport: !this.state.componentStateReport });
                                                }}
                                            >
                                                <div>
                                                    <i className="fas fa-file-medical mr-2"></i>{this.props.t('Report')} <div className="arrow-down"></div>
                                                </div>
                                            </Link>
                                            <div className={classname("dropdown-menu", { show: this.state.componentStateReport })}>
                                                <Link style={{ fontSize: 18 }} to="/tourists-report" className="dropdown-item"><i className="fas fa-users pr-2"></i>{this.props.t('Tourists Report')}  </Link>
                                                <Link style={{ fontSize: 18 }} to="/tourism-trends" className="dropdown-item"><i className="fas fa-poll pr-2"></i>{this.props.t('Tourism Trends')}  </Link>
                                                <Link style={{ fontSize: 18 }} to="/data-relationship" className="dropdown-item"><i className="fas fa-project-diagram pr-2"></i>{this.props.t('Data Relationship')}  </Link>
                                                <Link style={{ fontSize: 18 }} to="/staff-performance" className="dropdown-item"><i className="fas fa-user-tie pr-2"></i>{this.props.t('Staff Performance')}  </Link>
                                                <Link style={{ fontSize: 18 }} to="/heat-map" className="dropdown-item"><i className="fas fa-map pr-2"></i>{this.props.t('Heat Map')}  </Link>
                                            </div>
                                        </li>
                                    }
                                    {['3'].indexOf(String(this.state.user_type)) > -1 &&
                                        <li className="nav-item dropdown mr-navbar" id="Report_navagation" >
                                            <Link to="/#" className="nav-link dropdown-toggle arrow-none style-navbar"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.setState({ componentStateReport: !this.state.componentStateReport });
                                                }}
                                            >
                                                <div>
                                                    <i className="fas fa-file-medical mr-2"></i>{this.props.t('Report')} <div className="arrow-down"></div>
                                                </div>
                                            </Link>
                                            <div className={classname("dropdown-menu", { show: this.state.componentStateReport })}>
                                                <Link style={{ fontSize: 18 }} to="/tourists-report" className="dropdown-item"><i className="fas fa-users pr-2"></i>{this.props.t('Tourists Report')}  </Link>
                                            </div>
                                        </li>
                                    }
                                </ul>
                            </Collapse>
                        </nav>
                    </div>
                </div> */}
            </React.Fragment>
        );
    }
}

export default withRouter(withTranslation()(Navbar));
