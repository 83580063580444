// const API_ENDPOINT = "https://drjotest-api.melivecode.com/";
// const API_DEVEXPRESS_ENDPOINT = "https://drjotest-api.melivecode.com/";

// const IMAGE_ENDPOINT = "https://drjotest-api.melivecode.com/assets/images";

// const API_UPLOAD_ENDPOINT = "https://drjotest-api.melivecode.com/";

// export {
//   API_ENDPOINT,
//   API_DEVEXPRESS_ENDPOINT,
//   IMAGE_ENDPOINT,
//   API_UPLOAD_ENDPOINT,
// };

require("dotenv").config();

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const API_DEVEXPRESS_ENDPOINT = process.env.REACT_APP_API_DEVEXPRESS_ENDPOINT;
const IMAGE_ENDPOINT = process.env.REACT_APP_IMAGE_ENDPOINT;
const API_UPLOAD_ENDPOINT = process.env.REACT_APP_API_UPLOAD_ENDPOINT;

export {
  API_ENDPOINT,
  API_DEVEXPRESS_ENDPOINT,
  IMAGE_ENDPOINT,
  API_UPLOAD_ENDPOINT,
};