
import React, { Component } from "react";
import { Alert } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { userForgetPassword } from "../../store/actions";

// import images
// import profile from "../../assets/images/profile-img.png";
// import logo from "../../assets/images/logo.svg";
// import imglogin from "../../assets/images/small/bgloginnew.svg"
// import imgkrom from "../../assets/images/small/logokrom.png"
// import imgcard from "../../assets/images/small/img-10.png"
// import login1 from "../../assets/images/icon/login.png"
// import thai from "../../assets/images/icon/thai.svg"
// import engGray from "../../assets/images/icon/eng-gray.svg"
import { API_ENDPOINT } from "../../assets/api";
import { withTranslation } from 'react-i18next';
class ForgetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forgetSuccessMsg: '',
      forgetError: ''
    };

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  // handleValidSubmit
  async handleValidSubmit(event, values) {
    console.log(values)
    let username = values.email
    var response = await fetch(API_ENDPOINT + 'password/reset/request', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: username
      })
    });
    var responseJson = await response.json();
    console.log(responseJson)
    if (responseJson.result === true) {
      this.setState({
        forgetSuccessMsg: 'Please check your email',
        forgetError: ''
      })
    } else {
      this.setState({
        forgetError: responseJson.msg,
        forgetSuccessMsg: ''
      })
    }
    // this.props.userForgetPassword(values, this.props.history);
  }

  render() {
    // let publicUrl = process.env.PUBLIC_URL + '/'
    return (
      <React.Fragment>
        <div className="login-page">
          <div className="style-login">
            <div className="box-flex-1">
              <div className="box-logo">
                <img className="logo-img" src={'/assets/images/icon/login.png'} alt="" />
              </div>
            </div>
            <div className="box-flex-2">
              <div className="card-forgot-password" >
                <span className="card-text" >{this.props.t('เปลี่ยนรหัสผ่าน')}</span>
                <span className="normal-text text-center" >{this.props.t('กรอกอีเมล์หรือชื่อผู้ใช้งานของคุณเพื่อใช้ในการเปลี่ยนรหัสผ่านก่อนเข้าสู่ระบบ')}</span>
                <div className="style-card-login">
                  {this.state.forgetError ? (
                    <Alert color="danger" style={{ marginTop: "13px" }}>
                      {this.state.forgetError}
                    </Alert>
                  ) : null}
                  {this.state.forgetSuccessMsg.length > 0 ? (
                    <Alert color="success" style={{ marginTop: "13px" }}>
                      {this.state.forgetSuccessMsg}
                    </Alert>
                  ) : null}
                  <AvForm className="form-horizontal mt-4" onValidSubmit={this.handleValidSubmit}>
                    <div className="form-group normal-text" style={{ marginTop: 10, marginBottom: 70 }}>
                      <AvField
                        name="email"
                        label="อีเมล์ หรือ ชื่อผู้ใช้งาน"
                        className="form-control"
                        placeholder="กรอกอีเมล์ หรือ ชื่อผู้ใช้งาน"
                        type="email"
                        required
                      />
                    </div>
                    <div className="">
                      <button className="btn btn-primary btn-block waves-effect waves-light" style={{ marginTop: 40 }} type="submit"><span className="normal-text-18">ยืนยัน</span></button>
                    </div>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', marginTop: 40, textAlign: 'center' }}>
                      <label className="normal-text text-align" style={{ color: 'black' }}>
                        <img className="icon-thai" src={'/assets/images/icon/thai.svg'} alt=""></img>
                        <span className="normal-text">ไทย</span> /
                        <img className="icon-eng-gray" style={{ marginLeft: 5 }} src={'/assets/images/icon/eng-gray.svg'} alt=""></img>
                        <span className="normal-text">Eng</span></label>
                    </div>
                    <div className="row" style={{ justifyContent: 'center', marginTop: 16 }}>
                      <label className="normal-text" style={{ color: 'black' }}>{this.props.t('หากคุณเป็นสมาชิกแล้ว')} <span style={{ color: '#173865', cursor: 'pointer' }}><Link to="login">{this.props.t('เข้าสู่ระบบ')}</Link></span> <span>{this.props.t('ได้ที่นี่')}</span></label>
                    </div>
                  </AvForm>

                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark"><i className="bx bx-home h2"></i></Link>
        </div>
        <div className="container-fluid">
          <div className="bg_image" style={{
            backgroundImage: `url(${imglogin})`,
            backgroundSize: "cover",
            height: "100vh",

          }}>

            <Row>
              <Col lg={1}>
              </Col>
              <Col lg={6} style={{ marginTop: "25vh" }}>
                <img src={imgkrom} />
              </Col>
              <Col lg={5}>
                <div className="account-pages my-5 pt-sm-5">
                  <Container>

                    <Col md={12} lg={12} xl={9}>
                      <Card className="overflow-hidden">
                        <div className="btn-primary">
                          <Row>
                            <Col className="col-7">
                              <div className="text-light p-4">
                                <h5 className="text-light">Welcome Back !</h5>
                                <p className="text-light">Reset Password.</p>
                              </div>
                            </Col>
                            <Col className="col-5 align-self-end">
                              <img src={imgcard} alt="" className="img-fluid" />
                            </Col>
                          </Row>
                        </div>
                        <CardBody className="pt-0">
                          <div>
                            <Link to="/">
                              <div className="avatar-md profile-user-wid mb-4">
                                <span className="avatar-title rounded-circle bg-light">
                                  <img src={logo} alt="" className="rounded-circle" height="34" />
                                </span>
                              </div>
                            </Link>
                          </div>
                          <div className="p-2">

                            {this.state.forgetError ? (
                              <Alert color="danger" style={{ marginTop: "13px" }}>
                                {this.state.forgetError}
                              </Alert>
                            ) : null}
                            {this.state.forgetSuccessMsg.length > 0 ? (
                              <Alert color="success" style={{ marginTop: "13px" }}>
                                {this.state.forgetSuccessMsg}
                              </Alert>
                            ) : null}

                            <AvForm
                              className="form-horizontal mt-4"
                              onValidSubmit={this.handleValidSubmit}
                            >

                              <div className="form-group">
                                <AvField
                                  name="email"
                                  label="Email or Username"
                                  className="form-control"
                                  placeholder="Enter email or username"
                                  type="email"
                                  required
                                />
                              </div>
                              <Row className="form-group">
                                <Col className="text-right">
                                  <button
                                    className="btn btn-primary w-md waves-effect waves-light"
                                    type="submit"
                                  >
                                    Reset
                                  </button>
                                  <div className="mt-5 text-center">
                                    <p>
                                      Go back to{" "}
                                      <Link
                                        to="login"
                                        className="font-weight-medium text-primary"
                                      >
                                        Login
                                      </Link>{" "}
                                    </p>

                                  </div>
                                </Col>
                              </Row>
                            </AvForm>
                          </div>
                        </CardBody>
                      </Card>

                    </Col>

                  </Container>
                </div>
              </Col>

            </Row>

          </div>

        </div> */}

      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg };
};

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(withTranslation()(ForgetPasswordPage)));
