import React, { Component } from 'react'
import { Row, Col } from "reactstrap";
// import Swal from 'sweetalert2'
import Lottie from "react-lottie";
import * as loadingData from "../../Joborder/men-component/loading.json";
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

export default class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            loading: false,
            showSearchWarning: true
        }
    };

    submitHandle(value) {
        this.props.callback('search', value)
    }

    isSearchValid(searchValue) {
        return searchValue.length >= 3;
    }
    // clearSearch(value) {
    //     this.setState({ search: '' });
    //     this.props.callback('search', value)
    // }
    componentDidMount() {
        this.props.callback('search', this.state.search)
    }

    render() {
        if (this.props.type === 'search-tourists') {
            return (
                <>
                    <Row className="mb-3" >
                        <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>
                            <span style={{ fontSize: 16 }}>ค้นหา : </span>
                        </Col>
                        <Col xl={5} lg={5} md={5} sm={12} style={{ display: 'inline-flex', overflow: 'auto' }}>
                            <input type="text" placeholder="ค้นหา..." className="form-control"
                                value={this.state.search}
                                onChange={(e) => this.setState({ search: e.target.value })} />
                        </Col>
                        <Col xl={2} lg={2} md={2} sm={12}>
                            <button className="btn btn-primary" onClick={() => {
                                if (this.isSearchValid(this.state.search)) {
                                    this.submitHandle(this.state.search)
                                    this.setState({ showSearchWarning: false });
                                } else {
                                    alert("กรุณาป้อนคำค้าหามากกว่า 3 ตัวอักษรขึ้นไป")
                                    this.setState({ showSearchWarning: true });
                                }

                            }}>
                                <i className="fa fa-search pr-2"></i>ค้นหา</button>
                            {/* <button className="btn btn-secondary" onClick={() => this.clearSearch(this.state.search)}>
                                <i className="fa fa-times"></i> ล้างข้อมูล
                            </button> */}
                        </Col>
                    </Row>
                    {this.state.showSearchWarning &&
                        <Row className="mb-3" >
                            <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>

                            </Col>
                            <Col xl={5} lg={5} md={5} sm={12} style={{ display: 'inline-flex', overflow: 'auto', top: -10 }}>
                                <span style={{ color: 'red' }}>* กรุณาระบุชื่อนักท่องเที่ยวที่ต้องการจะค้นหา (ต้องค้นหามากกว่า 3 ตัวอักษรขึ้นไป)</span>
                            </Col>
                        </Row>
                    }
                </>
            )
        }
        return (
            <>
                <Row className="mb-3" >
                    <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>
                        <span style={{ fontSize: 16 }}>ค้นหา : </span>
                    </Col>
                    <Col xl={5} lg={5} md={5} sm={12} style={{ display: 'inline-flex', overflow: 'auto' }}>
                        <input type="text" placeholder="ค้นหา..." className="form-control"
                            onChange={(e) => {
                                this.setState({
                                    loading: true
                                }, () => {
                                    if (typeof this.timeout !== 'undefined') {
                                        try {
                                            clearTimeout(this.timeout);
                                        } catch (err) {

                                        }
                                    }
                                    this.timeout = setTimeout(async () => {
                                        this.setState({ search: e.target.value, loading: false }, () => { this.submitHandle(e.target.value) })
                                    }, 2000);
                                })
                            }} />
                    </Col>
                    {this.state.loading === true &&
                        <Col xl={5} lg={5} md={5} sm={12} style={{ display: 'inline-flex', overflow: 'auto' }}>
                            <Row style={{ alignSelf: 'center' }}>
                                <Lottie options={defaultOptions} height={35} width={35} style={{ marginRight: 10 }} />
                                <span style={{ alignSelf: 'center' }}>กำลังค้นหา</span></Row>
                        </Col>
                    }
                </Row>
                {this.state.search === '' || this.state.search === null ?
                    <Row className="mb-3" >
                        <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>

                        </Col>
                        <Col xl={5} lg={5} md={5} sm={12} style={{ display: 'inline-flex', overflow: 'auto', top: -10 }}>
                            <small style={{ color: 'red' }}>* กรุณาระบุชื่อประเทศที่ต้องการค้นหา</small>
                        </Col>
                    </Row>
                    :
                    null
                }

            </>
        )
    }
}
