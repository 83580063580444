import React, { Component } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";



class ConsentPopup extends Component {

  render() {
    const { isOpen, toggle, onConfirm } = this.props;
    return (
      <>
        <Modal isOpen={isOpen} toggle={toggle}>
          <ModalHeader toggle={toggle}>
            ความยินยอมในการเข้าถึงข้อมูล
          </ModalHeader>
          <ModalBody>
            <p>
              ยืนยันการอนุญาตให้กรมการท่องเที่ยวสามารถจัดเก็บข้อมูลส่วนบุคคลของท่านจากการขอใช้บริการ{" "}
              <br></br>
              กรมการท่องเที่ยวได้ตระหนักถึงความสำคัญของการคุ้มครองข้อมูลส่วนบุคคล
              และมีความยินดีเป็นอย่างยิ่งที่ท่านให้ความสนใจในการใช้บริการของเรา
              โดยที่ทางกรมการท่องเที่ยวจะเก็บรวบรวมข้อมูลส่วนบุคคลที่ท่านได้กรอกเข้ามา
              โดยมีวัตถุประสงค์ ขอบเขตและใช้วิธีการที่ชอบด้วยกฎหมาย <br></br>
              ข้อมูลส่วนบุคคลทั้งหมดต้องถูกให้ความยินยอมจากเจ้าของข้อมูลส่วนบุคคลนั้นแล้ว
              โดยในการเก็บนั้นจะทำเพียงเท่าที่จำเป็นแก่การดำเนินงานภายใต้วัตถุประสงค์ของการให้บริการ
              เราจะประมวลผลข้อมูลส่วนบุคคลของคุณเพื่อให้บริการตามที่ท่านสมัคร
              ร้องขอ และ เพื่อการให้บริการที่มีประสิทธิภาพสูงสุด
            </p>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={onConfirm}>
              ยินยอม
            </Button>
            <Button
              color="secondary"
              onClick={() =>
                this.props.hasDisagreed
                  ? this.props.toggle()
                  : this.props.onCancel()
              }
            >
              ไม่ยินยอม
            </Button>
          </ModalFooter>
        </Modal>


      </>
    );
  }
}

export default ConsentPopup;