import React, { Component } from 'react'
import Report from './Report';

export default class TouristsReportCount extends Component {
    /*static propTypes = {
         prop: PropTypes
     }*/
    constructor(props) {
        super(props);
        this.state = {

        }
    };



    render() {
        return (
            <React.Fragment>
                <Report></Report>
            </React.Fragment>
        )
    }
}
