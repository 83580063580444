import React, { Component } from 'react';

//Import Breadcrumb
// import Breadcrumbs from "../../../../components/Common/Breadcrumb";

//Import Task Cards
import UncontrolledBoard from "./UncontrolledBoard";

//Import Images
// import avatar4 from "../../assets/images/users/avatar-4.jpg";
// import avatar5 from "../../assets/images/users/avatar-5.jpg";
// import img1 from "../../../../assets/images/small/img-1.jpg";
// import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import "./tasks.scss";

class TasksKanbans extends Component {
	constructor(props) {
		super(props);

		this.state = {
			columns: this.props.data
		}
		console.log(this.props)
		// this.onDragEnd = this.onDragEnd.bind(this);
	}

	render() {
		return (
			<React.Fragment>
				{/* <div style={{ flexDirection: 'row' }}>
					<div style={{
						display: "flex", width: '100%', justifyContent: 'center', overflowX: 'scroll',
						position: 'relative', whiteSpace: 'nowrap'
					}}>
					</div>
				</div> */}


				{/* Render Breadcrumbs */}

				<UncontrolledBoard modalCallback={this.props.modalCallback} board={this.state} content={this.state.columns} />


			</React.Fragment>
		);
	}
}

export default TasksKanbans;