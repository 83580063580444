import React, { Component } from "react";

import { GoogleApiWrapper } from "google-maps-react";
import { connect } from "react-redux";
// import Gallery from "react-photo-gallery";

import { withTranslation } from "react-i18next";
// import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
// import img1 from "../../assets/images/small/img-1.jpg";
// import img2 from "../../assets/images/small/img-2.jpg";
// import img3 from "../../assets/images/small/img-15.jpg";
// import img4 from "../../assets/images/small/img-16.jpg";
// import img5 from "../../assets/images/small/img-17.jpg";
// import img6 from "../../assets/images/small/img-18.jpg";
// import img7 from "../../assets/images/small/img-19.jpg";
// import img8 from "../../assets/images/small/img-20.jpeg";
// import img9 from "../../assets/images/small/img-21.jpg";
// import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";

// import Cardtrip from "./components/Cardtrip";
import Grouptrips from "./Grouptrips";
// import { View } from "devextreme-react/scheduler";
// import { API_ENDPOINT } from "../../assets/api";
import '../../assets/scss/custom.scss'
import { IMAGE_ENDPOINT } from '../../assets/api'

const LoadingContainer = (props) => <div>Loading...</div>;

class Tripdetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tripInfo: props.location.params,
      images: this.props.images,
      thumbContainer: {
        width: '100%',
        height: 400,
      },
      thumbnail: {
        flex: 1,
        width: undefined,
        height: undefined,
        resizeMode: 'cover'
      },
      isLoading: false,
    };
  }

  render() {
    if (this.state.isLoading === true) {
      return (
        <React.Fragment></React.Fragment>
      )
    }
    else {
      // const photos5 = [
      //   {
      //     src: IMAGE_ENDPOINT + this.state.tripInfo.tripInfo[0].dayInfo[0].cards[0].content.photo,
      //     width: 4,
      //     height: 5,
      //   },
      //   {
      //     src: img7,
      //     width: 4,
      //     height: 5
      //   },
      //   {
      //     src: IMAGE_ENDPOINT + this.state.tripInfo.tripInfo[0].dayInfo[0].cards[0].content.photo,
      //     width: 4,
      //     height: 5
      //   },
      //   {
      //     src: img7,
      //     width: 4,
      //     height: 5
      //   },
      // ];


      console.log(this.state.tripInfo.tripInfo);

      let image = [], arr = this.state.tripInfo.tripInfo
      if (arr.length > 0) {
        for (var i = 0; i < arr.length; i++) {
          for (var j = 0; j < arr[i].dayInfo.length; j++) {
            for (var k = 0; k < arr[i].dayInfo[j].cards.length; k++) {
              image.push(arr[i].dayInfo[j].cards[k].content.photo)
            }
          }
        }
      }
      return (
        <React.Fragment>
          <div className="page-content">
            <div className="container-fluid">
              <Row>
                <Col xs="12">
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Breadcrumbs title={"รายละเอียดแผนการท่องเที่ยว"} breadcrumbItem={this.props.t("รายละเอียดแผนการท่องเที่ยว")} />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="container-fluid">
              <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                <CardBody>
                  <Row>
                    <Col xl={4} sm={12} lg={12} md={12} xs={12} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'space-evenly' }}>
                      <div style={{ display: 'flex' }}>
                        <Col xl={6} sm={6} lg={6} md={6} xs={6} style={{ padding: 3 }}>
                          <div>
                            <CardImg src={IMAGE_ENDPOINT + image[0]} style={{ height: '150px', borderRadius: 5, objectFit: 'cover', minHeight: '150px', maxHeight: '150px' }} ></CardImg>
                          </div>
                          <div style={{ paddingTop: 10 }}>
                            <CardImg src={image[3] === undefined ? IMAGE_ENDPOINT + image[0] : IMAGE_ENDPOINT + image[2]} style={{ borderRadius: 5, objectFit: 'cover', minHeight: '100px', maxHeight: '100px' }}></CardImg>
                          </div>
                        </Col>
                        <Col xl={6} sm={6} lg={6} md={6} xs={6} style={{ padding: 3 }}>
                          <div>
                            <CardImg src={image[3] === undefined ? IMAGE_ENDPOINT + image[0] : IMAGE_ENDPOINT + image[3]} style={{ borderRadius: 5, objectFit: 'cover', minHeight: '100px', maxHeight: '100px' }} ></CardImg>
                          </div>
                          <div style={{ paddingTop: 10 }}>
                            <CardImg src={image[3] === undefined ? IMAGE_ENDPOINT + image[0] : IMAGE_ENDPOINT + image[3]} style={{ height: '150px', borderRadius: 5, objectFit: 'cover', minHeight: '150px', maxHeight: '150px' }}></CardImg>
                          </div>
                        </Col>
                      </div>
                    </Col>
                    <Col xl={8} sm={12} lg={12} md={12} xs={12} style={{ paddingLeft: '1.5rem' }}>
                      <Row style={{ display: 'flex', flexDirection: 'flex', flexWrap: 'warp', alignContent: 'space-between', paddingLeft: '0.5rem' }}>
                        <div style={{ display: 'block' }}>
                          <h6 className="font-style-24 cl-black">{this.state.tripInfo.name}</h6>
                          <h6 className="font-style-16 cl-gray" style={{ marginTop: '1rem' }}>{this.props.t('Trip Information')}</h6>
                          <h6 className='font-style-16 cl-black'>{this.state.tripInfo.trip_details}</h6>
                          <div style={{ display: 'flex', paddingTop: '2rem' }}>
                            <div className="media-body">
                              <h6 className="mb-1 font-style-16 cl-gray"> {this.props.t('Time Period')} :</h6>
                              <h6 className="font-style-16 cl-blue">{this.state.tripInfo.tripInfo.length} วัน</h6>
                            </div>
                            <div className="media-body">
                              <h6 className="mb-1 font-style-16 cl-gray"> {this.props.t('Province')} :</h6>
                              <h6 className="font-style-16 cl-blue">{this.state.tripInfo.province}</h6>
                            </div>
                          </div>
                        </div>
                      </Row>
                      <hr className="hr-style"></hr>
                      <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Col xl={4} lg={3} md={6} sm={12} xs={12} style={{ textAlign: 'end' }}>
                          <button type="button" className="btn btn-primary waves-effect waves-light resize-button" style={{ backgroundColor: '#42B600', borderColor: '#42B600', color: 'white' }} onClick={() => window.open('/Tripmanagement/edit/' + this.state.tripInfo.id)}>
                            <span className="font-style-16 cl-gray" style={{ color: 'white' }} >{this.props.t('Edit Detail')}</span>
                          </button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                <CardBody>
                  <Row style={{ margin: 0, justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <h6 className="font-style-24 cl-dark-blue">ข้อมูลการท่องเที่ยว</h6>
                    </div>
                    {/* <button type="button" className="btn btn-primary waves-effect waves-light"
                      style={{ width: 250, background: 'transparent', borderColor: '#2249AC' }}                                             >
                      <img className="" src={'/assets/images/icon/plus.svg'} style={{ marginRight: '0.5rem', marginTop: '-0.1rem' }} alt=""></img>
                      <span className="font-style-16 cl-blue">{this.props.t('Choose This Trip')}</span>
                    </button> */}
                  </Row>
                  <hr></hr>
                  <Row>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Grouptrips tripInfo={this.state.tripInfo.tripInfo} />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}
const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
    login: state.Login,
  };
};
export default connect(
  mapStatetoProps,
  {}
)(
  GoogleApiWrapper({
    apiKey: "AIzaSyD0AIarEA25PY-NvHNsZDAVqM3qa8krHfs",
    libraries: ["visualization"],
    LoadingContainer: LoadingContainer,
    v: "3",
  })(withTranslation()(Tripdetail))
);
