import React, { useEffect } from 'react';
import 'devextreme/data/odata/store';
import DataGrid, { Column, Paging, Pager, SearchPanel, Export, RemoteOperations, Grouping, GroupPanel } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import 'devextreme/dist/css/dx.light.css';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { API_ENDPOINT } from '../../../assets/api';

const TouristsGrid = (props) => {

    useEffect(() => {
    }, [props]);

    const store = new CustomStore({
        key: 'ranking',
        load: function (loadOptions) {
            let isNotEmpty = (value) => {
                return value !== undefined && value !== null && value !== '';
            }
            let params = '?';
            [
                'skip',
                'take',
                'requireTotalCount',
                'requireGroupCount',
                'sort',
                'filter',
                'totalSummary',
                'group',
                'groupSummary'
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i])) { params += `${i}=${JSON.stringify(loadOptions[i])}&`; }
            });
            params = params.slice(0, -1);
            let url = `${API_ENDPOINT}report/joborder/table/country${params}&period=${props.report.value}`
            if (props.report.value === 'custom') url += `&startdate=${props.report.start_date}&enddate=${props.report.end_date}`
            return fetch(url)
                .then(response => response.json())
                .then((data) => {
                    return {
                        data: data.data,
                        totalCount: data.totalCount,
                        summary: data.summary,
                        groupCount: data.groupCount
                    };
                })
                .catch(() => {
                    throw new Error("Data Loading Error");
                });
        }
    });

    // const isNotEmpty = (value) => {
    //     return value !== undefined && value !== null && value !== '';
    // }

    const onExporting = (e) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
            });
        });
        e.cancel = true;
    }



    return (
        <div>
            <DataGrid
                dataSource={store}
                showBorders={true}
                onExporting={onExporting}
            >
                <RemoteOperations groupPaging={true} />
                <Grouping autoExpandAll={false} />
                <GroupPanel visible={true} />
                <SearchPanel visible={true}
                    width={240}
                    placeholder="Search..." />
                <Column
                    allowFiltering={false}
                    dataField="ranking"
                    dataType="number"
                />
                <Column
                    dataField="country_name"
                    dataType="string"
                />
                <Column
                    allowFiltering={false}
                    dataField="amount"
                    dataType="number"
                />

                <Paging defaultPageSize={20} />
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[5, 10, 20]}
                />
                <Export enabled={true} allowExportSelectedData={false} />
            </DataGrid>
        </div>
    )
}

export default TouristsGrid;