import React, { Component } from 'react'
import { Card, CardBody, Table, Container } from "reactstrap";
import { Link } from "react-router-dom";
import { API_ENDPOINT } from "../../assets/api";
import moment from 'moment-timezone';
import QRCode from "react-qr-code";

export default class Savedoc extends Component {
    constructor(props) {
        super(props);
        this.printInvoice.bind(this);
        this.state = {
            joborder_code: "",
            fetch_joborder: true,
            isLoading: true,
            auth: JSON.parse(localStorage.getItem('authToken')),
            step_1: {
                code: '',
                external_agency: '',
                selectedCountry: '',
                joborder_start: '',
                joborder_stop: '',
                flights_external: [],
                flights_internal: [],
                agency_name: '',
                agency_license: '',
                joborder_id: '',
                created_at: '',
                inbound_type: '',
                inbound_no: '',
                inbound_date: '',
                inbound_country: '',
                outbound_type: '',
                outbound_no: '',
                outbound_date: '',
                outbound_country: '',
            },
            step_2: {
                days: [],
                places_modal_is_open: false,
                places_period_modal_is_open: false,
                guides_modal_is_open: false,
                fetch_guides: true,
                fetch_places: true,
                guides_fee: 0,
                guides: [],
                guides_skip: 0,
                guides_take: 10,
                guides_name_filter: '',
                guides_selected_day: 0,
                places: [],
                places_name_filter: '',
                places_selected: {},
                places_selected_day: 0,
                places_selected_daypart: [],
                places_selected_category: [],
                places_categorys: []
            },
            step_3: {
                tourists_adults: 0,
                tourists_children: 0,
                tourists_followers: 0,
                tourists_all: 0,
                tourists: []
            },
            step_4: [
                {
                    policy_set_sequence_number: '',
                    insurer_id: '',
                    delivery_date: '',
                    policy_number: '',
                    policy_start_date: '',
                    policy_end_date: '',
                    tourists: []
                }
            ],
            step_5: [],
            step_6: ''
        }
        console.log("props", props)
        // this.auth = JSON.parse(localStorage.getItem('authToken'))
    };

    async get_agency_detail() {
        var auth = JSON.parse(await localStorage.getItem('authToken'))
        var response = await fetch(API_ENDPOINT + 'api/v2/web/agency/get/' + auth.users.agency_id, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: auth.data.type + ' ' + auth.data.token,
            }
        });
        var responseJson = await response.json();
        var agency = responseJson
        this.setState({
            step_1: {
                ...this.state.step_1,
                agency_license: agency.license,
                agency_name: agency.name
            }
        })
        console.log(responseJson)
    }

    async get_joborder_detail(joborder_code) {
        // var auth = JSON.parse(await localStorage.getItem('authToken'))
        // var lang = 'th'
        var response = await fetch(API_ENDPOINT + 'joborder/get', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                // Authorization: auth.data.type + ' ' + auth.data.token,
            },
            body: JSON.stringify({
                joborder_code: joborder_code,
                lang: 'th'
            })
        });
        var responseJson = await response.json();
        console.log("hello response", responseJson)
        var state = this.state
        state.joborder_id = responseJson.joborder.id
        state.step_1.code = responseJson.joborder.code
        state.step_1.external_agencys = responseJson.joborder.external_agencys
        state.step_1.external_agencys_code = responseJson.joborder.external_agencys_code
        state.step_1.joborder_start = moment(new Date(responseJson.joborder.joborder_start_date)).format('YYYY-MM-DD')
        state.step_1.joborder_stop = moment(new Date(responseJson.joborder.joborder_stop_date)).format('YYYY-MM-DD')
        state.step_1.agency_name = responseJson.joborder.agencys[0].name
        state.step_1.agency_license = responseJson.joborder.agencys[0].license
        state.step_1.created_at = moment(new Date(responseJson.joborder.created_at)).format('YYYY-MM-DD')

        state.step_1.inbound_type = responseJson.joborder.inbound_type
        state.step_1.inbound_no = responseJson.joborder.inbound_no
        state.step_1.inbound_date = moment(new Date(responseJson.joborder.inbound_date)).format('YYYY-MM-DD')
        state.step_1.inbound_country = responseJson.joborder.inbound_country
        state.step_1.outbound_type = responseJson.joborder.outbound_type
        state.step_1.outbound_no = responseJson.joborder.outbound_no
        state.step_1.outbound_date = moment(new Date(responseJson.joborder.outbound_date)).format('YYYY-MM-DD')
        state.step_1.outbound_country = responseJson.joborder.outbound_country

        state.step_2.guides_fee = responseJson.joborder.guides_fee

        state.step_3.tourists_adults = responseJson.joborder.tourists_adults
        state.step_3.tourists_children = responseJson.joborder.tourists_children
        state.step_3.tourists_followers = responseJson.joborder.tourists_followers
        state.step_3.tourists_all = responseJson.joborder.tourists_all
        state.step_4 = responseJson.joborder.policysets

        // for (var flight of responseJson.joborder.flights_external) {
        //     state.step_1.flights_external.push({
        //         flight_departure_date: moment(new Date(flight.flight_depart)).format('YYYY-MM-DD'),
        //         flight_no: flight.flight_no,
        //         flight_from: flight.flight_from,
        //         flight_departure_datetime: new Date(flight.flight_depart).toISOString().slice(0, 16),
        //         flight_to: flight.flight_to,
        //         flight_arrival_datetime: new Date(flight.flight_arrival).toISOString().slice(0, 16)

        //     })
        // }

        // for (var flight of responseJson.joborder.flights_internal) {
        //     state.step_1.flights_internal.push({
        //         flight_departure_date: moment(new Date(flight.flight_depart)).format('YYYY-MM-DD'),
        //         flight_no: flight.flight_no,
        //         flight_from: flight.flight_from,
        //         flight_departure_datetime: new Date(flight.flight_depart).toISOString().slice(0, 16),
        //         flight_to: flight.flight_to,
        //         flight_arrival_datetime: new Date(flight.flight_arrival).toISOString().slice(0, 16)

        //     })
        // }
        // console.log("state.step_1.joborder_start", state.step_1.joborder_start)
        var days = []
        var placeordering = 1
        for (var response_day of responseJson.joborder.days) {
            days.push({
                day: response_day.dayno,
                dayparts: [],
            })
            let dayindex = days.map(day => day.day).indexOf(response_day.dayno)
            for (var response_daypart of response_day.day_details) {
                let daypartindex = days[dayindex].dayparts.map(daypart => daypart.daypart).indexOf(response_daypart.daypart)
                if (daypartindex === -1) {
                    days[dayindex].dayparts.push({
                        daypart: response_daypart.daypart,
                        places: []
                    })
                    daypartindex = days[dayindex].dayparts.map(daypart => daypart.daypart).indexOf(response_daypart.daypart)
                }
                days[dayindex].dayparts[daypartindex].places.push({
                    place_id: response_daypart.place_id,
                    place_img: response_daypart.place.photo,
                    place_name: response_daypart.place.name,
                    place_detail: response_daypart.place.detail,
                    place_category_name: response_daypart.place.category_name,
                    place_ordering: placeordering,
                    place_category_icon: response_daypart.place.category_icon

                })
                placeordering++
                if (typeof days[dayindex].guides == 'undefined') {
                    days[dayindex].guides = []
                }
                if (response_daypart.guides.length > 0) {
                    console.log(response_daypart.guides.length)
                    for (var tmp_guide of response_daypart.guides) {
                        let guideindex = days[dayindex].guides.map(guide => guide.guide_id).indexOf(tmp_guide.guide_id)
                        if (guideindex === -1) {
                            days[dayindex].guides.push({
                                guide_id: tmp_guide.guide_id,
                                fname: tmp_guide.guide.guide_info.fname,
                                lname: tmp_guide.guide.guide_info.lname,
                                license: tmp_guide.guide.license,
                                address: tmp_guide.guide.address,
                                photo: 'under cnstruction'
                            })
                        }
                    }
                }

            }
        }

        for (var tourist of responseJson.joborder.tourists) {
            state.step_3.tourists.push({
                passport: tourist.passport,
                passport_file: tourist.passport_file,
                prefix: tourist.prefix,
                firstname: tourist.fname,
                lastname: tourist.lname,
                date_of_birth: moment(new Date(tourist.dob)).format('YYYY-MM-DD'),
                nation: tourist.origincountry,
                flight: tourist.arrivalflight,
                flight_no: tourist.arrivalflight
            })
        }
        this.setState({
            ...state,
            fetch_joborder: false,
            step_2: {
                ...state.step_2,
                days: days,
            },
        }, () => {
            console.log("check Step_4", this.state.step_4)
            // console.log('finish load')
            // console.log("-------------------------------------", this.state.step_1.joborder_start)
        })
    }

    printInvoice() {
        window.print();
    }

    async componentDidMount() {
        const code = this.props.match.params.code
        // console.log("code", code)
        if (code) {
            console.log("asdasdasdasdasdads")
            await this.get_joborder_detail(code)
        } else {
            console.log("xcvxcvxcvxcvxcvxcvxcv")
            // console.log("check step_6", JSON.parse(await localStorage.getItem('step_6')))
            // console.log("check step_5", JSON.parse(await localStorage.getItem('step_5')))
            this.setState({
                ...this.state,
                step_1: JSON.parse(await localStorage.getItem('step_1')),
                step_2: JSON.parse(await localStorage.getItem('step_2')),
                step_3: JSON.parse(await localStorage.getItem('step_3')),
                step_4: JSON.parse(await localStorage.getItem('step_4')),
                step_5: JSON.parse(await localStorage.getItem('step_5')),
                step_6: JSON.parse(await localStorage.getItem('step_6')),
            }, () => {
                console.log("------111111", this.state.step_1)
                console.log("------222222", this.state.step_2)
                console.log("------444444", this.state.step_4)
            })
            await this.get_agency_detail()
        }

        let guidesSet = new Set()
        for (let day of this.state.step_2.days) {
            if (day.guides) {
                for (let guide of day.guides) {
                    guidesSet.add(JSON.stringify({
                        fullname: guide.fname + ' ' + guide.lname,
                        license: guide.license,
                        days: []
                    }))
                }
            }
            // for (let daypart of day.dayparts) {
            //     for (let place of daypart.places) {
            //         placesSet.add(JSON.stringify({
            //         }))
            //     }
            // }
        }
        let guides = []
        for (let guide of guidesSet) {
            guides.push(JSON.parse(guide))
        }
        for (let day of this.state.step_2.days) {
            if (day.guides) {
                for (let guide of day.guides) {
                    var guideIndex = guides.findIndex(x => x.license === guide.license)
                    var guideDay = moment(new Date(this.state.step_1.joborder_start)).add((day.day - 1), 'days').format('YYYY-MM-DD')
                    guides[guideIndex]['days'].push(guideDay)
                }
            }
        }
        this.setState({
            guides: guides,
            isLoading: false
        }, () => {
            console.log("=====================")
            console.log("check step 4", this.state.step_4)
        })
    }


    render() {
        const dayparts = {
            1: 'เช้า',
            2: 'บ่าย',
            3: 'เย็น'
        }
        const travelTypes = {
            1: 'เครื่องบิน',
            2: 'รถ',
            3: 'เรือ',
            4: 'อื่นๆ',
            5: 'เดินทางภายในประเทศ'
        }
        const travelNumberText = {
            1: 'เที่ยวบินที่',
            2: 'ทะเบียนเลขที่',
            3: 'ชื่อเรือ',
            4: ''
        }
        const formattedNumber = Number(this.state.step_2.guides_fee);
        const guides_fee = formattedNumber.toLocaleString();

        console.log('this.state.step_1.inbound_type', this.state.step_1.inbound_type)
        if (this.state.isLoading === true) {
            return (
                <div></div>
            )
        }
        else {
            return (
                <React.Fragment>
                    <div className="page-content" >
                        <Container fluid>
                            <Card style={{
                                height: 'auto',
                                backgroundImage: `url("/assets/images/watermark/watermark_logo.jpg")`,
                                backgroundSize: '100%'
                            }}>
                                <CardBody>
                                    <div className="d-print-none">
                                        <div className="float-right">
                                            <Link to="#" onClick={this.printInvoice} className="btn btn-success waves-effect waves-light mr-2"><i className="fa fa-print"></i></Link>
                                        </div>
                                    </div>
                                    <div className="invoice-title" style={{ textAlign: 'center', marginTop: "5px" }} >
                                        <h4 style={{ fontWeight: 'bold', color: 'black' }} >ใบสั่งงานมัคคุเทศก์</h4>
                                        <h4 style={{ color: 'black' }} >(Job Order )</h4>
                                    </div>
                                    <div className="py-2 mb-2">
                                        <span style={{ fontSize: 16 }}>ใบสั่งงานเลขที่</span><span>&nbsp;&nbsp;</span>
                                        {this.state.step_6 ? this.state.step_6 : '.........'}<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        <span style={{ fontSize: 16 }}>วันที่สั่งงาน</span><span>&nbsp;&nbsp;</span>
                                        {this.state.step_1.created_at ? this.state.step_1.created_at : '.........'}
                                    </div>
                                    <div style={{ border: 'solid', borderWidth: '1px' }}>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td className="text-left" style={{ borderBottom: "1px solid" }}>
                                                        <div>
                                                            <p className="pt-2" style={{ fontSize: 16 }}>(1) ชื่อผู้ประกอบธุรกิจนำเที่ยว : <span className="pl-3">{this.state.step_1.agency_name}</span></p>
                                                            <p className="pl-3" style={{ fontSize: 16 }}>ใบอนุญาตประกอบธุรกิจนำเที่ยวเลขที่ : <span className="pl-3">{this.state.step_1.agency_license}</span></p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left" style={{ borderBottom: "1px solid" }}>
                                                        <div>
                                                            <p className="pt-2" style={{ fontSize: 16 }}>(2) ขอมอบหมายให้ (ชื่อมัคคุเทศก์และเลขที่ใบอนุญาตเป็นมัคคุเทศก์)</p>
                                                            {
                                                                this.state.guides.map((guide, i) => {
                                                                    return (
                                                                        <div className="pl-3 mt-2">
                                                                            <div className="row">
                                                                                <div className="col-lg-3">
                                                                                    <p style={{ fontSize: 16 }}>{i + 1}.<span className="pl-3">ชื่อมัคคุเทศก์ :</span> <span className="pl-3">{guide.fullname}</span></p>
                                                                                </div>
                                                                                <div className="col-lg-3">
                                                                                    <p style={{ fontSize: 16 }}>ใบอนุญาตเป็นมัคคุเทศก์เลขที่ : <span className="pl-3">{guide.license} </span></p>
                                                                                </div>
                                                                                {/* <p style={{ fontSize: 16 }}>2.1<span>ชื่อมัคคุเทศก์ : &nbsp;&nbsp;</span>{guide.fullname}<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                                                    ใบอนุญาตเป็นมัคคุเทศก์เลขที่<span>&nbsp;&nbsp;</span>{guide.license}<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                                                    วันที่<span>&nbsp;&nbsp;</span>{guide.days.join(", ")}</p> */}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                            <div>
                                                                <p style={{ fontSize: 16 }}>ปฏิบัติหน้าที่เป็นมัคคุเทศก์เพื่อให้บริการแก่นักท่องเที่ยวคณะนี้ ในอัตราค่าตอบแทนวันละ <span className="pl-3 pr-3">{guides_fee} บาท</span>รายละเอียดดังนี้</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left" style={{ borderBottom: "1px solid" }}>
                                                        <div>
                                                            <p className="pt-2" style={{ fontSize: 16 }}>(3) ชื่อบริษัทนำเที่ยวจากต่างประเทศ : <span className="pl-3">{this.state.step_1.external_agencys}</span></p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left" style={{ border: "0px" }}>
                                                        {this.state.step_1.inbound_type === 5 ?
                                                            <div>
                                                                <p className="pt-2" style={{ fontSize: 16 }}>(4) เดินทางภายในประเทศ</p>
                                                            </div>
                                                            :
                                                            <>
                                                                <div>
                                                                    <p className="pt-2" style={{ fontSize: 16 }}>(4) ข้อมูลการเดินทางเข้าและออกราชอาณาจักร</p>
                                                                </div>
                                                                <div className="pl-3">
                                                                    <p className="pt-2" style={{ fontSize: 16 }}>1. เดินทางจากประเทศ : {this.state.step_1.inbound_country}</p>
                                                                    <p style={{ fontSize: 16 }}>2. การเดินทางเข้าราชอาณาจักร (วันที่เดินทางมาถึง) {this.state.step_1.inbound_date}</p>
                                                                    <div className="row">
                                                                        <div className="col-lg-3">
                                                                            <p className="pl-3" style={{ fontSize: 16 }}>พาหนะที่ใช้ในการเดินทาง</p>
                                                                        </div>
                                                                        <div className="col-lg-3">
                                                                            <div className="form-check">
                                                                                <input type="checkbox" className="form-check-input" id="exampleCheck1" checked={this.state.step_1.inbound_type === "1" || this.state.step_1.inbound_type === 1} readOnly />
                                                                                <p style={{ fontSize: 16 }}>ทางอากาศ</p>
                                                                            </div>
                                                                            <div>
                                                                                <p style={{ fontSize: 16, paddingLeft: 20 }}>เที่ยวบินที่ : {this.state.step_1.inbound_type === "1" || this.state.step_1.inbound_type === 1 ? this.state.step_1.inbound_no : ""}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-3">
                                                                            <div class="form-check">
                                                                                <input type="checkbox" class="form-check-input" id="exampleCheck2" checked={this.state.step_1.inbound_type === "2" || this.state.step_1.inbound_type === 2} />
                                                                                <p style={{ fontSize: 16 }}>ทางบก (รถยนต์,รถตู้,อื่นๆ)</p>
                                                                            </div>
                                                                            <div>
                                                                                <p style={{ fontSize: 16, paddingLeft: 20 }}>ทะเบียนเลขที่ : {this.state.step_1.inbound_type === "2" || this.state.step_1.inbound_type === 2 ? this.state.step_1.inbound_no : ""}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-3">
                                                                            <div class="form-check">
                                                                                <input type="checkbox" class="form-check-input" id="exampleCheck3" checked={this.state.step_1.inbound_type === "3" || this.state.step_1.inbound_type === 3} />
                                                                                <p style={{ fontSize: 16 }}>ทางน้ำ</p>
                                                                            </div>
                                                                            <div>
                                                                                <p style={{ fontSize: 16, paddingLeft: 20 }}>ชื่อเรือ : {this.state.step_1.inbound_type === "3" || this.state.step_1.inbound_type === 3 ? this.state.step_1.inbound_no : ""}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="pl-3">
                                                                    <p style={{ fontSize: 16 }}>3. การเดินทางออกราชอาณาจักร (วันที่เดินทางกลับ) {this.state.step_1.outbound_date}</p>
                                                                    <div className="row">
                                                                        <div className="col-lg-3">
                                                                            <p className="pl-3" style={{ fontSize: 16 }}>พาหนะที่ใช้ในการเดินทาง</p>
                                                                        </div>
                                                                        <div className="col-lg-3">
                                                                            <div className="form-check">
                                                                                <input type="checkbox" className="form-check-input" id="exampleCheck1" checked={this.state.step_1.outbound_type === "1" || this.state.step_1.outbound_type === 1} readOnly />
                                                                                <p style={{ fontSize: 16 }}>ทางอากาศ</p>
                                                                            </div>
                                                                            <div>
                                                                                <p style={{ fontSize: 16, paddingLeft: 20 }}>เที่ยวบินที่ : {this.state.step_1.outbound_type === "1" || this.state.step_1.outbound_type === 1 ? this.state.step_1.outbound_no : ""}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-3">
                                                                            <div class="form-check">
                                                                                <input type="checkbox" class="form-check-input" id="exampleCheck2" checked={this.state.step_1.outbound_type === "2" || this.state.step_1.outbound_type === 2} />
                                                                                <p style={{ fontSize: 16 }}>ทางบก (รถยนต์,รถตู้,อื่นๆ)</p>
                                                                            </div>
                                                                            <div>
                                                                                <p style={{ fontSize: 16, paddingLeft: 20 }}>ทะเบียนเลขที่ : {this.state.step_1.outbound_type === "2" || this.state.step_1.outbound_type === 2 ? this.state.step_1.outbound_no : ""}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-3">
                                                                            <div class="form-check">
                                                                                <input type="checkbox" class="form-check-input" id="exampleCheck3" checked={this.state.step_1.outbound_type === "3" || this.state.step_1.outbound_type === 3} />
                                                                                <p style={{ fontSize: 16 }}>ทางน้ำ</p>
                                                                            </div>
                                                                            <div>
                                                                                <p style={{ fontSize: 16, paddingLeft: 20 }}>ชื่อเรือ : {this.state.step_1.outbound_type === "3" || this.state.step_1.outbound_type === 3 ? this.state.step_1.outbound_no : ""}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="mt-2 pl-3">
                                                                    <span style={{ fontSize: 16 }}>2 ช่องทางที่คณะนักท่องเที่ยวเดินทางมาถึง เดินทางโดย : {travelTypes[this.state.step_1.inbound_type]}&nbsp;&nbsp;
                                                                        {this.state.step_1.inbound_type === 5}
                                                                        {travelNumberText[this.state.step_1.inbound_type]}
                                                                        {this.state.step_1.inbound_no}
                                                                        วันที่เดินทางมาถึง : &nbsp;&nbsp;
                                                                        {this.state.step_1.inbound_date}
                                                                    </span>
                                                                    <br />
                                                                    <span style={{ fontSize: 16 }}>4.2 ช่องทางที่คณะนักท่องเที่ยวเดินทางกลับ เดินทางโดย : {travelTypes[this.state.step_1.outbound_type]}&nbsp;&nbsp;
                                                                        {this.state.step_1.inbound_type === 5}
                                                                        {travelNumberText[this.state.step_1.outbound_type]}&nbsp;&nbsp;
                                                                        {this.state.step_1.outbound_no}&nbsp;&nbsp;
                                                                        วันที่เดินทางกลับ : &nbsp;&nbsp;
                                                                        {this.state.step_1.outbound_date}
                                                                    </span>
                                                                </div> */}
                                                            </>
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                            {/* <tbody>
                                                <tr>ะ
                                                    <td className="text-left" style={{ border: "0px" }}>
                                                        3. ชื่อบริษัทนำเที่ยวจากต่างประเทศ<span>&nbsp;&nbsp;</span>
                                                        {this.state.step_1.external_agencys}

                                                        {this.state.step_1.inbound_type === 5 ?
                                                            <div>
                                                                4. เดินทางภายในประเทศ
                                                            </div>
                                                            :
                                                            <div>
                                                                <br />
                                                                4. เดินทางจากประเทศ<span>&nbsp;&nbsp;</span>
                                                                {this.state.step_1.inbound_country}
                                                                <br />
                                                                ช่องทางที่คณะนักท่องเที่ยวเดินทางมาถึง เดินทางโดย
                                                                {travelTypes[this.state.step_1.inbound_type]}
                                                                <br />
                                                                {this.state.step_1.inbound_type === 5}
                                                                {travelNumberText[this.state.step_1.inbound_type]}<span>&nbsp;&nbsp;</span>
                                                                {this.state.step_1.inbound_no}
                                                                <br />
                                                                วันที่เดินทางมาถึง<span>&nbsp;&nbsp;</span>
                                                                {this.state.step_1.inbound_date}
                                                                <br />
                                                                ช่องทางที่คณะนักท่องเที่ยวเดินทางกลับ เดินทางโดย
                                                                {travelTypes[this.state.step_1.outbound_type]}
                                                                <br />
                                                                {travelNumberText[this.state.step_1.outbound_type]}<span>&nbsp;&nbsp;</span>
                                                                {this.state.step_1.outbound_no}
                                                                <br />
                                                                วันที่เดินทางกลับ<span>&nbsp;&nbsp;</span>
                                                                {this.state.step_1.outbound_date}
                                                            </div>
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody> */}
                                        </Table>
                                    </div>
                                    <div className="py-2 mt-3">
                                        <span style={{ fontSize: 16 }}>(5) ข้อมูลการเดินทางเข้าและออกนอกประเทศ / รายชื่อนักท่องเที่ยว</span>
                                    </div>
                                    <p style={{ fontSize: 16 }}>จำนวนนักท่องเที่ยว
                                        <span className="pl-3">ผู้ใหญ่</span>
                                        <span className="pl-2">{this.state.step_3.tourists_adults} คน</span>
                                        <span className="pl-3">เด็ก (อายุต่ำกว่า 12 ปี)</span>
                                        <span className="pl-2">{this.state.step_3.tourists_children} คน</span>
                                        <span className="pl-3">ผู้ติดตาม</span>
                                        <span className="pl-2">{this.state.step_3.tourists_followers} คน</span>
                                        <span className="pl-3">รวม</span>
                                        <span className="pl-2">{this.state.step_3.tourists_all} คน</span>
                                    </p>
                                    <div className="table-responsive">
                                        <table className="custom-table" style={{ width: '100%', borderCollapse: 'collapse' }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ padding: "5px", fontSize: 16, border: '1px solid black', textAlign: 'center' }}>ลำดับ</th>
                                                    <th style={{ width: "40%", padding: "5px", fontSize: 16, border: '1px solid black' }}>ชื่อสกุล</th>
                                                    <th style={{ width: "40%", padding: "5px", fontSize: 16, border: '1px solid black' }}>บัตรประจำตัวประชาชนหรือหมายเลขหนังสือเดินทาง</th>
                                                    <th style={{ padding: "5px", fontSize: 16, border: '1px solid black' }}>สัญชาติ</th>
                                                    <th style={{ padding: "5px", fontSize: 16, border: '1px solid black' }}>หมายเหตุ</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.step_3.tourists.map((e, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td style={{ padding: "5px", fontSize: 16, border: '1px solid black', textAlign: 'center' }}>{i + 1}</td>
                                                            <td style={{ padding: "5px", fontSize: 16, border: '1px solid black' }}>{e.prefix} {e.firstname} {e.lastname}</td>
                                                            <td style={{ padding: "5px", fontSize: 16, border: '1px solid black' }}>{e.passport}</td>
                                                            <td style={{ padding: "5px", fontSize: 16, border: '1px solid black' }}>{e.nation}</td>
                                                            <td style={{ padding: "5px", fontSize: 16, border: '1px solid black' }}></td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="py-2 mt-3">
                                        <span style={{ fontSize: 16 }}>รายการนำเที่ยว</span>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="custom-table" style={{ width: '100%', borderCollapse: 'collapse' }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "20%", padding: "10px", fontSize: 16, border: '1px solid black' }}>กำหนด วัน/เดือน/ปี <br /> และเวลานำเที่ยว แต่ละรายการ</th>
                                                    <th style={{ width: "50%", padding: "10px", fontSize: 16, border: '1px solid black' }}>รายละเอียดการจัดนำเที่ยว ประกอบด้วย ลักษณะและประเภทของยานพาหนะที่ใช้ <br /> ในการเดินทางจุดหมายปลายทางและที่แวะพัก รวมทั้งสถานที่สำคัญในการนำเที่ยว <br /> ลักษณะและประเภทของที่พัก และการจัดบริการอาหาร</th>
                                                    <th style={{ width: "10%", padding: "10px", fontSize: 16, border: '1px solid black' }}>หมายเหตุ</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.step_2.days.map((day, i) => {
                                                        console.log("this.state.step_1.joborder_start", this.state.step_1.joborder_start)
                                                        var date = moment(this.state.step_1.joborder_start).add(i, 'days');
                                                        console.log("date", date)
                                                        return (
                                                            day.dayparts.map((daypart, j) => {
                                                                return (
                                                                    daypart.places.map((place, k) => {
                                                                        return (
                                                                            <tr>
                                                                                <td style={{ padding: "5px", fontSize: 16, border: '1px solid black' }}>
                                                                                    {date.format('YYYY-MM-DD')}
                                                                                    <span>&nbsp;&nbsp;</span>
                                                                                    {dayparts[daypart.daypart]}
                                                                                </td>
                                                                                <td style={{ padding: "5px", fontSize: 16, border: '1px solid black' }}>
                                                                                    <span>&nbsp;&nbsp;</span>
                                                                                    {place.place_name}
                                                                                </td>
                                                                                <td style={{ padding: "5px", fontSize: 16, border: '1px solid black' }}>
                                                                                    <span></span>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                )
                                                            })
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="py-2 mt-3">
                                        <span style={{ fontSize: 16 }}>รายละเอียดการทำประกันอุบัติเหตุ</span>
                                    </div>
                                    <div style={{ border: 'solid', borderWidth: '1px' }}>
                                        <Table>
                                            <tbody>
                                                {this.state.step_4.length === 0 ?
                                                    <tr>
                                                        <div className="p-3">
                                                            <p style={{ fontSize: 16 }}>กรมธรรม์ชุดที่ </p>
                                                            <p style={{ fontSize: 16 }}>เลขที่กรมธรรม์ : </p>
                                                            <p style={{ fontSize: 16 }}>วัน/เดือน/ปีที่นำส่งรายชื่อผู้เอาประกันภัยให้แก่ผู้รับประกันภัย :</p>
                                                            <p style={{ fontSize: 16 }}>จำนวนผู้เอาประกัน : คน</p>
                                                            <p style={{ fontSize: 16 }}>มีระยะเวลาประกัน เริ่มต้นตั้งแต่วันที่ : สิ้นสุดวันที่ : </p>
                                                            <p style={{ fontSize: 16 }}>(ไม่ได้ทำการแนบหลักฐานรายชื่อผู้เอาประกันภัยมาด้วย)</p>
                                                        </div>
                                                    </tr>
                                                    :
                                                    <tr>
                                                        {this.state.step_4.map((e, i) => {
                                                            return (
                                                                <div key={i} className="p-3">
                                                                    <p style={{ fontSize: 16 }}>กรมธรรม์ชุดที่ {e.policy_set_sequence_number}</p>
                                                                    <p style={{ fontSize: 16 }}>เลขที่กรมธรรม์ : {e.policy_number}</p>
                                                                    <p style={{ fontSize: 16 }}>วัน/เดือน/ปีที่นำส่งรายชื่อผู้เอาประกันภัยให้แก่ผู้รับประกันภัย : {e.delivery_date}</p>
                                                                    <p style={{ fontSize: 16 }}>จำนวนผู้เอาประกัน : {e.tourists.length} คน</p>
                                                                    <p style={{ fontSize: 16 }}>มีระยะเวลาประกัน เริ่มต้นตั้งแต่วันที่ : {e.policy_start_date} สิ้นสุดวันที่ : {e.policy_end_date}</p>
                                                                    {this.state.step_5.length === 0 ? <p style={{ fontSize: 16 }}>(ไม่ได้ทำการแนบหลักฐานรายชื่อผู้เอาประกันภัยมาด้วย)</p> : <p style={{ fontSize: 16 }}> (ได้ทำการแนบหลักฐานรายชื่อผู้เอาประกันภัยมาด้วย)</p>}
                                                                </div>
                                                            )
                                                        })}
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                    {this.state.step_1.code && this.state.step_1.code !== "" ?
                                        <div className="py-2 mt-3">
                                            <QRCode value={this.state.step_1.code} />
                                        </div>
                                        :
                                        <div></div>
                                    }
                                </CardBody>
                            </Card>
                        </Container>
                    </div>
                </React.Fragment>
            )
        }
    }
}