import React, { Component } from 'react'
import { Row, Col } from "reactstrap";
// import Swal from 'sweetalert2'
// import moment from 'moment-timezone';


export default class GraphTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            garph: 'pareto'
        }
    };

    submitHandle(value) {
        switch (value) {
            case "garph":
                this.props.callback(value, this.state.garph)

                break;
            default:
                return
        }
    }

    componentDidMount() {
        this.props.callback('garph', this.state.garph)
    }

    render() {
        return (
            <Row className="mb-3">
                <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>
                    <span style={{ fontSize: 16 }}>การแสดงผลของกราฟ : </span>
                </Col>
                <Col xl={3} lg={3} md={3} sm={3} style={{ display: 'inline-flex', alignSelf: 'center' }}>
                    <button className={this.state.garph === 'pareto' ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'}
                        style={{ whiteSpace: 'nowrap', textAlign: 'center' }}
                        onClick={() => {
                            this.setState({
                                garph: 'pareto',
                                // fetch_report: true
                            }, () => {
                                this.submitHandle('garph')
                            })
                        }}>
                        กราฟแท่ง
                    </button>
                    <button className={this.state.garph === 'network' ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'}
                        style={{ marginLeft: 20, whiteSpace: 'nowrap', textAlign: 'center' }}
                        onClick={() => {
                            this.setState({
                                garph: 'network',
                                // fetch_report: true
                            }, () => {
                                this.submitHandle('garph')
                            })
                        }}>
                        แสดงความสัมพันธ์
                    </button>
                </Col>
            </Row>
        )
    }
}
