import React, { Component } from "react";

import {
  Map,
  Marker,
  GoogleApiWrapper,
} from "google-maps-react";
import { connect } from "react-redux";

import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Dropzone from "react-dropzone";
import Swal from "sweetalert2";
import { API_ENDPOINT } from "../../assets/api";
import { withTranslation } from "react-i18next";



const LoadingContainer = (props) => <div>Loading...</div>;

class AddPlaces extends Component {
  constructor(props) {
    super(props);
    window.title = "เพิ่มสถานที่ท่องเที่ยว";
    this.state = {
      specify: true,
      selectedFiles: [],

      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      open_selected: "0",
      open_selected_text: "",
      open_open_time: "",
      open_close_time: "",
      heat: [
        {
          A: "P17060024503",
          B: "6/14/2017 21:54",
          C: "4",
          D: "10",
          E: "",
          F: "14TH",
          G: "ST",
          H: "10 14TH ST, San Diego, CA",
          I: "1151",
          J: "O",
          K: "521",
          L: "2",
          M: "13.9656153",
          N: "100.5839537",
        },
        {
          A: "P17030051227",
          B: "3/29/2017 22:24",
          C: "4",
          D: "10",
          E: "",
          F: "14TH",
          G: "ST",
          H: "10 14TH ST, San Diego, CA",
          I: "1016",
          J: "A",
          K: "521",
          L: "2",
          M: "13.9663727",
          N: "100.5847208",
        },
        {
          A: "P17060004814",
          B: "6/3/2017 18:04",
          C: "7",
          D: "10",
          E: "",
          F: "14TH",
          G: "ST",
          H: "10 14TH ST, San Diego, CA",
          I: "1016",
          J: "A",
          K: "521",
          L: "2",
          M: "13.9701118",
          N: "100.5849381",
        },
        {
          A: "P17030029336",
          B: "3/17/2017 10:57",
          C: "6",
          D: "10",
          E: "",
          F: "14TH",
          G: "ST",
          H: "10 14TH ST, San Diego, CA",
          I: "1151",
          J: "OT",
          K: "521",
          L: "2",
          M: "13.970797",
          N: "100.5769102",
        },
        {
          A: "P17030005412",
          B: "3/3/2017 23:45",
          C: "6",
          D: "10",
          E: "",
          F: "15TH",
          G: "ST",
          H: "10 15TH ST, San Diego, CA",
          I: "911P",
          J: "CAN",
          K: "521",
          L: "2",
          M: "13.9732612",
          N: "100.5755993",
        },
      ],
      reports: [
        { title: "Orders", iconClass: "bx-copy-alt", description: "1,235" },
        {
          title: "Revenue",
          iconClass: "bx-archive-in",
          description: "$35, 723",
        },
        {
          title: "Average Price",
          iconClass: "bx-purchase-tag-alt",
          description: "$16.2",
        },
      ],
      select: {
        place_category: [],
        provinces: [],
        districts: [],
        sub_districts: [],
      },
      active_lang: "th",
      forms: {
        place_detail: [
          {
            lang_code: "th",
            place_name: "",
            place_detail: "",
          },
          {
            lang_code: "en",
            place_name: "",
            place_detail: "",
          },
          {
            lang_code: "ch",
            place_name: "",
            place_detail: "",
          },
        ],
        place_category: "0",
        place_soft_capactity: "",
        place_hard_capactity: "",
        place_img: [],
        place_open_period: [],
        place_address: "",
        place_province: "0",
        place_districts: "0",
        place_sub_district: "0",
        place_latitude: 13.8807872,
        place_longitude: 100.5624942,
        place_status: "new",

        place_contact_tel: "",
        place_contact_email: "",
        place_contact_website: "",
        place_contact_facebook: "",
        place_contact_instagram: "",
        place_contact_google_business: "",
      },
      userType: "3",
    };
    this.fetch_place_category = this.fetch_place_category.bind(this);
    this.fetch_provinces = this.fetch_provinces.bind(this);
    this.fetch_district = this.fetch_district.bind(this);
    this.fetch_sub_district = this.fetch_sub_district.bind(this);
    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.add_place_detail_lang = this.add_place_detail_lang.bind(this);
    this.handleAcceptedFiles.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.fetch_provinces();
    this.fetch_place_category();
  }

  componentDidMount = async () => {
    // emit the event
    if (!localStorage.getItem("authUser")) {
      return (window.location = "/login");
    }
    // console.log();
    var type = JSON.parse(await localStorage.getItem("authUser")).type;
    console.log("type" + type);
    await this.setState({ userType: type });
    console.log("userType" + this.state.userType);
  };

  async add_place_detail_lang() {
    Swal.fire({
      title: "Please input language code",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Choose",
      showLoaderOnConfirm: true,
      preConfirm: (lang) => {
        console.log(lang);
        let index = this.state.forms.place_detail
          .map((e) => e.lang_code)
          .indexOf(lang);
        if (index > -1) {
          return Swal.fire({
            icon: "error",
            title: "Error !!",
            text: "Exist Language Code!",
          });
        }
        var forms = this.state.forms;
        forms.place_detail.push({
          lang_code: lang,
          place_name: "",
          place_detail: "",
        });
        this.setState({
          forms: forms,
        });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }

  async language_code_input_handler(element, index) {
    var text = element.target.value;
    var forms = this.state.forms;
    for (var i = 0; i < forms.place_detail.length; i++) {
      if (i === index) continue;
      if (forms.place_detail[i].lang_code === text) {
        return Swal.fire({
          icon: "error",
          title: "Error !!",
          text: "Exist Language Code !",
        }).then(() => {
          forms.place_detail[i].lang_code = "";
          this.setState({
            forms: forms,
          });
        });
      }
    }
    forms.place_detail[index].lang_code = text;
    this.setState({
      forms: forms,
    });
  }
  async place_name_input_handler(element, index) {
    var forms = this.state.forms;
    forms.place_detail[index].place_name = element.target.value;
    this.setState({
      forms: forms,
    });
  }
  async place_detail_input_handler(element, index) {
    var forms = this.state.forms;
    forms.place_detail[index].place_detail = element.target.value;
    this.setState({
      forms: forms,
    });
  }
  async add_place(e) {
    e.preventDefault();
    console.log(this.state.forms);
    for (var i = 0; i < this.state.forms.place_detail.length; i++) {
      if (this.state.forms.place_detail[i].lang_code !== "th") continue;

      if (this.state.forms.place_detail[i].place_name === "") {
        return Swal.fire(
          "ผิดพลาด!",
          "กรุณาระบุชื่อสถานที่ของภาษาไทยเป็นอย่างน้อย ",
          "error"
        );
      }
      if (this.state.forms.place_detail[i].place_detail === "") {
        return Swal.fire(
          "ผิดพลาด!",
          "กรุณาระบุรายละเอียดสถานที่ของภาษาไทยเป็นอย่างน้อย ",
          "error"
        );
      }
    }
    if (this.state.forms.place_category === "0") {
      return Swal.fire("ผิดพลาด!", "กรุณาระบุประเภทสถานที่", "error");
    }

    // if (this.state.forms.place_img.length == 0) {
    // return Swal.fire(
    //     'ผิดพลาด!',
    //     'กรุณาแนบรูปภาพสถานที่',
    //     'error'
    // )
    // }
    // if (this.state.forms.place_soft_capactity == "" || this.state.forms.place_soft_capactity < 1) {
    //     return Swal.fire(
    //         'ผิดพลาด!',
    //         'กรุณาระบุจำนวนนักท่องเที่ยวที่น้อยที่สุดให้ถูกต้อง',
    //         'error'
    //     )
    // }
    // if (this.state.forms.place_hard_capactity == 0 || this.state.forms.place_hard_capactity < 1) {
    //     return Swal.fire(
    //         'ผิดพลาด!',
    //         'กรุณาระบุจำนวนนักท่องเที่ยวที่มากที่สุดที่สุดให้ถูกต้อง',
    //         'error'
    //     )
    // }

    // if (this.state.forms.place_open_period.length == 0) {
    //     return Swal.fire(
    //         'ผิดพลาด!',
    //         'กรุณาระบุช่วงเวลาเปิด-ปิดสถานที่',
    //         'error'
    //     )
    // }
    if (this.state.forms.place_address === "") {
      return Swal.fire("ผิดพลาด!", "กรุณาระบุที่อยู่", "error");
    }
    if (this.state.forms.place_province === "0") {
      return Swal.fire("ผิดพลาด!", "กรุณาระบุจังหวัด", "error");
    }
    if (this.state.forms.place_districts === "0") {
      return Swal.fire("ผิดพลาด!", "กรุณาระบุเขต", "error");
    }
    if (this.state.forms.place_sub_district === "0") {
      return Swal.fire("ผิดพลาด!", "กรุณาระบุแขวง", "error");
    }
    if (this.state.forms.place_latitude === "") {
      return Swal.fire("ผิดพลาด!", "กรุณาระบุแขวง", "error");
    }
    if (this.state.forms.place_longitude === "") {
      return Swal.fire("ผิดพลาด!", "กรุณาระบุแขวง", "error");
    }
    var formData = new FormData();
    formData.append(
      "place_detail",
      JSON.stringify([this.state.forms.place_detail[0]])
    );
    formData.append("place_category", this.state.forms.place_category);
    formData.append(
      "place_soft_capactity",
      this.state.forms.place_soft_capactity
    );
    formData.append(
      "place_hard_capactity",
      this.state.forms.place_hard_capactity
    );
    if (this.state.forms.place_img.length === 0) {
      formData.append("place_img[]", "");
    } else {
      console.log(this.state.forms.place_img);
      for (var file of this.state.forms.place_img) {
        formData.append("place_img[]", file);
        console.log(file);
      }
    }
    for (var place_open_period of this.state.forms.place_open_period) {
      formData.append("place_open_period[]", place_open_period);
    }
    formData.append("place_address", this.state.forms.place_address);
    formData.append("place_province", this.state.forms.place_province);
    formData.append("place_districts", this.state.forms.place_districts);
    formData.append("place_sub_district", this.state.forms.place_sub_district);
    formData.append("place_latitude", this.state.forms.place_latitude);
    formData.append("place_longitude", this.state.forms.place_longitude);

    formData.append("place_status", this.state.forms.place_status);

    formData.append("place_contact_tel", this.state.forms.place_contact_email);
    formData.append("place_contact_email", this.state.forms.place_longitude);
    formData.append(
      "place_contact_website",
      this.state.forms.place_contact_website
    );
    formData.append(
      "place_contact_facebook",
      this.state.forms.place_contact_facebook
    );
    formData.append(
      "place_contact_instagram",
      this.state.forms.place_contact_instagram
    );
    formData.append(
      "place_contact_google_business",
      this.state.forms.place_contact_google_business
    );
    var token = localStorage.getItem("authToken");
    token = JSON.parse(token);
    var place_add_response = await fetch(
      API_ENDPOINT + "api/v2/web/place/add",
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          Authorization: token.data.type + " " + token.data.token,
        },
        body: formData,
      }
    );
    // var place_add_response = await fetch(API_ENDPOINT + 'api/v2/web/place/add', {
    //     method: 'POST', // *GET, POST, PUT, DELETE, etc.
    //     headers: {
    //         'Authorization': token.data.type + ' ' + token.data.token
    //     },
    //     body: formData
    // });
    console.log(place_add_response);
    var place_add_response_object = await place_add_response.json();
    console.log(place_add_response_object);
    if (place_add_response_object.status === "success") {
      return Swal.fire("สำเร็จ !", "เพิ่มสถานที่สำเร็จแล้ว", "success").then(
        () => {
          this.props.history.push("/places");
        }
      );
    } else {
      return Swal.fire("ล้มเหลว !", place_add_response_object.msg, "error");
    }
  }

  async fetch_place_category() {
    var token = localStorage.getItem("authToken");
    token = JSON.parse(token);
    var place_category_response = await fetch(
      API_ENDPOINT + "api/v2/web/place/category/get?lang=th",
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: {
          Authorization: token.data.type + " " + token.data.token,
        },
      }
    );
    var place_category_response_object = await place_category_response.json();
    console.log(place_category_response_object);
    var select = this.state.select;
    select.place_category = place_category_response_object;
    this.setState({ select: select });
  }

  async fetch_provinces() {
    var token = localStorage.getItem("authToken");
    token = JSON.parse(token);
    var province_response = await fetch(
      API_ENDPOINT + "api/v2/web/data/provinces/get",
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Authorization: token.data.type + " " + token.data.token,
        },
        body: JSON.stringify({
          lang: "th",
        }), // body data type must match "Content-Type" header
      }
    );
    var province_response_object = await province_response.json();
    var select = this.state.select;
    select.provinces = province_response_object;
    this.setState({ select: select });
  }

  async fetch_district() {
    var token = localStorage.getItem("authToken");
    token = JSON.parse(token);
    var provinces_id = this.state.forms.place_province;
    if (provinces_id === "0") return;
    var district_response = await fetch(
      API_ENDPOINT + "api/v2/web/data/districts/get",
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Authorization: token.data.type + " " + token.data.token,
        },
        body: JSON.stringify({
          lang: "th",
          provinces_id: provinces_id,
        }), // body data type must match "Content-Type" header
      }
    );
    var district_response_object = await district_response.json();
    var select = this.state.select;
    select.districts = district_response_object;
    this.setState({ select: select });
  }

  async fetch_sub_district() {
    var token = localStorage.getItem("authToken");
    token = JSON.parse(token);
    var districts_id = this.state.forms.place_districts;
    if (districts_id === "0") return;
    var sub_district_response = await fetch(
      API_ENDPOINT + "api/v2/web/data/sub_districts/get",
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          Authorization: token.data.type + " " + token.data.token,
        },
        body: JSON.stringify({
          lang: "th",
          districts_id: districts_id,
        }), // body data type must match "Content-Type" header
      }
    );
    var sub_district_response_object = await sub_district_response.json();
    var select = this.state.select;
    select.sub_districts = sub_district_response_object;
    this.setState({ select: select });
  }

  printInvoice() {
    window.print();
  }

  add_open_period() {
    if (this.state.open_selected === "-1") {
      var period = this.state.open_selected_text;
    } else {
      if (this.state.open_selected === "0") {
        return Swal.fire("ผิดพลาด!", "กรุณาระบุวันที่เปิดให้บริการ", "error");
      }
      var period = this.state.open_selected;
    }

    if (this.state.open_selected === "-1" && period === "") {
      return Swal.fire("ผิดพลาด!", "กรุณาระบุวันที่เปิดให้บริการ", "error");
    }
    if (this.state.open_open_time === "") {
      return Swal.fire("ผิดพลาด!", "กรุณาเลือกเวลาเปิด", "error");
    }
    if (this.state.open_close_time === "") {
      return Swal.fire("ผิดพลาด!", "กรุณาเลือกเวลาปิด", "error");
    }

    var tmp_forms = this.state.forms;
    tmp_forms.place_open_period.push({
      day: period,
      open_time: this.state.open_open_time,
      close_time: this.state.open_close_time,
    });
    this.setState({
      forms: tmp_forms,
      open_selected: "0",
      open_selected_text: "",
      open_open_time: "",
      open_close_time: "",
    });
  }

  handleAcceptedFiles = (files) => {
    console.log(files);
    for (var file of files) {
      console.log(file);
      if (file.type.search("image") === -1) {
        return Swal.fire("ผิดพลาด ?", "กรุณาเลือกไฟล์รูปภาพเท่านั้น", "error");
      }
    }
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    );
    var forms = this.state.forms;
    forms.place_img = files;
    this.setState({ forms: forms, selectedFiles: files });
  };

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  onMarkerClick(props, marker, e) {
    alert("You clicked in this marker");
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }
  after_map_ready(mapProps, map) {
    // const { google } = mapProps;
    // var searchplace = document.getElementById("searchplace");
    // const searchBox = new google.maps.places.SearchBox(searchplace);
    // let markers = [];
    // searchBox.addListener("places_changed", () => {
    //   console.log(searchBox.getPlaces());
    //   var places = searchBox.getPlaces();
    //   if (places.length == 0) {
    //     return;
    //   }
    //   markers.forEach((marker) => {
    //     marker.setMap(null);
    //   });
    //   markers = [];
    //   const bounds = new google.maps.LatLngBounds();
    //   places.forEach((place) => {
    //     if (!place.geometry) {
    //       console.log("Returned place contains no geometry");
    //       return;
    //     }
    //     const icon = {
    //       url: place.icon,
    //       size: new google.maps.Size(71, 71),
    //       origin: new google.maps.Point(0, 0),
    //       anchor: new google.maps.Point(17, 34),
    //       scaledSize: new google.maps.Size(25, 25),
    //     };
    //     // Create a marker for each place.
    //     markers.push(
    //       new google.maps.Marker({
    //         map,
    //         icon,
    //         title: place.name,
    //         position: place.geometry.location,
    //       })
    //     );

    //     if (place.geometry.viewport) {
    //       // Only geocodes have viewport.
    //       bounds.union(place.geometry.viewport);
    //     } else {
    //       bounds.extend(place.geometry.location);
    //     }
    //   });
    //   map.fitBounds(bounds);
    // });
    map.addListener("click", (mapsMouseEvent) => {
      console.log(mapsMouseEvent.latLng.toJSON());
      Swal.fire({
        title: "ต้องการเลือกสถานที่ ?",
        html: `ต้องการเลือกสถานที่นี้ใช่หรือไม่ <br>
                ละติจูด : ${mapsMouseEvent.latLng.toJSON().lat} <br>
                ลองจิจูด : ${mapsMouseEvent.latLng.toJSON().lng}`,
        // text: `ต้องการเลือกสถานที่นี้ใช่หรือไม่ <br>
        // ละติจูด : ${mapsMouseEvent.latLng.toJSON().lat} <br>
        // ลองจิจูด : ${mapsMouseEvent.latLng.toJSON().lng}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ใช่ ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          var forms = this.state.forms;
          forms.place_latitude = mapsMouseEvent.latLng.toJSON().lat;
          forms.place_longitude = mapsMouseEvent.latLng.toJSON().lng;
          this.setState({
            forms: forms,
          });
        }
      });
    });
    // console.log(google)
    // console.log(service)
  }

  render() {
    // const positions = this.state.heat.map((item) => {
    //   return { lat: item.M, lng: item.N };
    // });

    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Row style={{ display: "flex", alignItems: "center" }}>
              <Col xl={8} lg={6} md={6} sm={12} xs={12}>
                <Breadcrumbs
                  title="เพิ่มสถานที่ท่องเที่ยว"
                  breadcrumbItem="เพิ่มสถานที่ท่องเที่ยว"
                />
              </Col>
            </Row>
            {/* Card No.1 Information */}
            {/* <Row style={{ margin: 'auto', backgroundColor: 'white' }}>
                            <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                                <NavItem >
                                    <NavLink onClick={() => { this.setState({ active_lang: 'th' }) }} active={this.state.active_lang == 'th'} style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                        ภาษาไทย
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => { this.setState({ active_lang: 'en' }) }} active={this.state.active_lang == 'en'} style={{ fontSize: '20px', fontWeight: 'bold' }} >
                                        English
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => { this.setState({ active_lang: 'ch' }) }} active={this.state.active_lang == 'ch'} style={{ fontSize: '20px', fontWeight: 'bold' }} >
                                        Chinese
                                    </NavLink>
                                </NavItem>
                            </ul>
                        </Row> */}
            <Row>
              <Col lg={12}>
                <Card className="card-shadow" style={{ marginTop: "1rem" }}>
                  <CardBody>
                    <Row>
                      <Col lg={12}>
                        {/* <div className="mb-2" style={{ fontSize: 20, fontWeight: 'bold' }}>
                                                    <i className="far fa-file-alt text-primary" style={{ fontSize: 30, marginRight: '1vw' }}></i> {this.props.t('Information')}</div> */}
                        <h2 className="font-style-24 cl-dark-blue">
                          {this.props.t("ข้อมูลทั่วไป")}
                        </h2>
                      </Col>
                    </Row>
                    <hr></hr>

                    {/* <Row style={{ marginBottom: '15px' }}>
                                            <Col lg={12}>
                                                <button onClick={() => { this.add_place_detail_lang() }} className="btn btn-primary">Add Language</button>
                                            </Col>
                                        </Row> */}
                    {this.state.forms.place_detail.map((element, i) => {
                      if (element.lang_code !== this.state.active_lang)
                        return <></>;
                      return (
                        <div key={i}>
                          <Row>
                            <Col lg={6}>
                              {/* <FormGroup>
                                                                            <Label htmlFor="placename">Place Language Code</Label>
                                                                            <Input placeholder={'Please fill language code'} value={element.lang_code} onChange={(e) => { this.language_code_input_handler(e, i) }} id="placename" name="placename" type="text" className="form-control" />
                                                                        </FormGroup> */}
                              <FormGroup>
                                <Label
                                  htmlFor="placename"
                                  className="font-style-16 cl-black"
                                >
                                  ชื่อสถานที่
                                  <span style={{ color: "red", marginLeft: 5 }}>
                                    *
                                  </span>
                                </Label>
                                <Input
                                  placeholder={"กรอกข้อมูลชื่อสถานที่"}
                                  value={element.place_name}
                                  onChange={(e) => {
                                    this.place_name_input_handler(e, i);
                                  }}
                                  id="placename"
                                  name="placename"
                                  type="text"
                                  className="form-control"
                                />
                              </FormGroup>
                            </Col>
                            <Col lg={6}>
                              <FormGroup>
                                <Label
                                  id="place_category"
                                  className="font-style-16 cl-black"
                                >
                                  {this.props.t("Place Category")}
                                  <span style={{ color: "red", marginLeft: 5 }}>
                                    *
                                  </span>
                                </Label>
                                <select
                                  onChange={(e) => {
                                    var forms = this.state.forms;
                                    forms.place_category = e.target.value;
                                    this.setState({ forms: forms });
                                  }}
                                  value={this.state.forms.place_category}
                                  className="form-control select2"
                                >
                                  <option value="0">
                                    {this.props.t("Please select a place type")}
                                  </option>
                                  {this.state.select.place_category.map(
                                    (element, i) => {
                                      return (
                                        <option key={i} value={element.id}>
                                          {element.name}{" "}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={6}>
                              <FormGroup>
                                <Label
                                  htmlFor="productdesc"
                                  className="font-style-16 cl-black"
                                >
                                  รายละเอียดสถานที่
                                  <span style={{ color: "red", marginLeft: 5 }}>
                                    *
                                  </span>
                                </Label>
                                <textarea
                                  placeholder={"กรอกข้อมูลรายละเอียดสถานที่"}
                                  value={element.place_detail}
                                  onChange={(e) => {
                                    this.place_detail_input_handler(e, i);
                                  }}
                                  className="form-control"
                                  id="place_description"
                                  rows="5"
                                ></textarea>
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* Card No.2 Place Address */}
            <Row>
              <Col lg={12}>
                <Card className="card-shadow" style={{ marginTop: "1rem" }}>
                  <CardBody>
                    <Row>
                      <Col lg={12}>
                        {/* <div className="mb-2" style={{ fontSize: 20, fontWeight: 'bold' }}>
                                                    <i className="far fa-file-alt text-primary" style={{ fontSize: 30, marginRight: '1vw' }}></i>{this.props.t('Place Address')}</div> */}
                        <h2 className="font-style-24 cl-dark-blue">
                          {this.props.t("ข้อมูลที่อยู่")}
                        </h2>
                      </Col>
                    </Row>
                    <hr></hr>
                    <Row>
                      <Col lg={6}>
                        <Form>
                          <Row>
                            <Col lg={12}>
                              <FormGroup>
                                <Label
                                  htmlFor="placename"
                                  className="font-style-16 cl-black"
                                >
                                  {this.props.t("Address")}
                                  <span style={{ color: "red", marginLeft: 5 }}>
                                    *
                                  </span>
                                </Label>
                                <Input
                                  value={this.state.forms.place_address}
                                  onChange={(e) => {
                                    var forms = this.state.forms;
                                    forms.place_address = e.target.value;
                                    this.setState({ forms: forms });
                                  }}
                                  id="placename"
                                  name="placename"
                                  placeholder="*59/230 หมู่ 3"
                                  type="text"
                                  className="form-control"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={12}>
                              <FormGroup>
                                <Label
                                  className="control-label font-style-16 cl-black"
                                >
                                  {this.props.t("Province")}
                                  <span style={{ color: "red", marginLeft: 5 }}>
                                    *
                                  </span>
                                </Label>
                                <select
                                  onChange={(e) => {
                                    var forms = this.state.forms;
                                    forms.place_province = e.target.value;
                                    this.setState({ forms: forms }, () => {
                                      this.fetch_district();
                                    });
                                  }}
                                  value={this.state.forms.place_province}
                                  className="form-control select2"
                                >
                                  <option value="0">
                                    {this.props.t("Please select province")}
                                  </option>
                                  {this.state.select.provinces.map(
                                    (element, i) => {
                                      return (
                                        <option key={i} value={element.id}>
                                          {element.name}{" "}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={12}>
                              <FormGroup>
                                <Label
                                  htmlFor="county"
                                  className="font-style-16 cl-black"
                                >
                                  {this.props.t("Area / District")}
                                  <span style={{ color: "red", marginLeft: 5 }}>
                                    *
                                  </span>
                                </Label>
                                <select
                                  onChange={(e) => {
                                    var forms = this.state.forms;
                                    forms.place_districts = e.target.value;
                                    this.setState({ forms: forms }, () => {
                                      this.fetch_sub_district();
                                    });
                                  }}
                                  value={this.state.forms.place_districts}
                                  className="form-control select2"
                                >
                                  <option
                                    value="0"
                                    style={{ color: "#495057" }}
                                  >
                                    {this.props.t(
                                      "Please choose a Area / District"
                                    )}
                                  </option>
                                  {this.state.select.districts.map(
                                    (element, i) => {
                                      return (
                                        <option key={i} value={element.id}>
                                          {element.name}{" "}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={12}>
                              <FormGroup>
                                <Label
                                  htmlFor="zone"
                                  className="font-style-16 cl-black"
                                >
                                  {this.props.t("Sub-area / Sub-district")}
                                  <span style={{ color: "red", marginLeft: 5 }}>
                                    *
                                  </span>
                                </Label>
                                <select
                                  onChange={(e) => {
                                    var forms = this.state.forms;
                                    forms.place_sub_district = e.target.value;
                                    this.setState({ forms: forms });
                                  }}
                                  value={this.state.forms.place_sub_district}
                                  className="form-control select2"
                                >
                                  <option value="0">
                                    {this.props.t(
                                      "Please choose a Sub-area / Sub-district"
                                    )}
                                  </option>
                                  {this.state.select.sub_districts.map(
                                    (element, i) => {
                                      return (
                                        <option key={i} value={element.id}>
                                          {element.name}{" "}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                              </FormGroup>
                            </Col>
                          </Row>
                          {/* <Row>
                                                        <Col lg={12}>
                                                            <FormGroup>
                                                                <Label htmlFor="zone">รหัสไปรษณีย์</Label>
                                                                <select onChange={(e) => { var forms = this.state.forms; forms.place_sub_district = e.target.value; this.setState({ forms: forms }) }} value={this.state.forms.place_sub_district} className="form-control select2">
                                                                    <option value="0">กรุณาเลือก รหัสไปรษณีย์</option>
                                                                    {this.state.select.ห.map((element, i) => {
                                                                        return <option key={i} value={element.id}>{element.name} </option>
                                                                    })}
                                                                </select>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row> */}
                          {/* <Row>
                                                        <Col lg={12}>
                                                            <Label style={{ paddingLeft: '2vw' }}>
                                                                <Input
                                                                    name="specify"
                                                                    type="checkbox"
                                                                    checked={this.state.specify}
                                                                    onChange={this.handleInputChange} />
                                                                {this.props.t('Want to specify latitude and longitude')}
                                                            </Label>
                                                        </Col>
                                                    </Row> */}
                        </Form>
                      </Col>
                      <Col lg={6}>
                        <Row>
                          <Col lg={6}>
                            <FormGroup>
                              <Label
                                htmlFor="latitude"
                                className="font-style-16 cl-black"
                              >
                                {this.props.t("latitude")}
                                <span
                                  style={{ color: "red", marginLeft: 5 }}
                                ></span>
                              </Label>
                              <Input
                                value={this.state.forms.place_latitude}
                                onChange={(e) => {
                                  var forms = this.state.forms;
                                  forms.place_latitude = e.target.value;
                                  this.setState({ forms: forms });
                                }}
                                id="latitude"
                                name="latitude"
                                placeholder="*16.60388472592952"
                                type="number"
                                className="form-control"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg={6}>
                            <FormGroup>
                              <Label
                                htmlFor="Longitude"
                                className="font-style-16 cl-black"
                              >
                                {this.props.t("longitude")}
                                <span
                                  style={{ color: "red", marginLeft: 5 }}
                                ></span>
                              </Label>
                              <Input
                                value={this.state.forms.place_longitude}
                                onChange={(e) => {
                                  var forms = this.state.forms;
                                  forms.place_longitude = e.target.value;
                                  this.setState({ forms: forms });
                                }}
                                id="Longitude"
                                name="Longitude"
                                placeholder="*103.60509577587891"
                                type="text"
                                className="form-control"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <div id="gmaps-markers" className="gmaps">
                              <Map
                                google={this.props.google}
                                onReady={this.after_map_ready.bind(this)}
                                zoom={14}
                                style={{
                                  width: "97%",
                                  height: "100%",
                                  borderRadius: 5,
                                }}
                                center={{
                                  lat: this.state.forms.place_latitude,
                                  lng: this.state.forms.place_longitude,
                                }}
                              >
                                <Marker
                                  position={{
                                    lat: this.state.forms.place_latitude,
                                    lng: this.state.forms.place_longitude,
                                  }}
                                />
                                <Marker />
                                {/* <HeatMap
                                                                    gradient={gradient}
                                                                    opacity={3}
                                                                    positions={positions}
                                                                    radius={30}
                                                                /> */}
                              </Map>
                            </div>
                          </Col>
                        </Row>
                        <Row className="pt-1">
                          <Col md={6}>
                            <Label style={{ 'textDecoration': 'underline', marginLeft: 10 }}>
                              <a href="/images/วิธีการกรอกข้อมูลละติดจูดและลองติจูด.jpg" target="_blank">
                                วิธีการกรอกข้อมูลละติดจูดและลองติจูด
                              </a>
                            </Label>
                          </Col>
                          <Col style={{ textAlign: 'right' }}>
                            <Label style={{ marginLeft: 10 }}>
                              *หมายเหตุ: สามารถกดที่แผนที่เพื่อนเลือกพิกัดได้
                            </Label>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* Card No.3 upload Image */}
            <Row>
              <Col lg={12}>
                <Card className="card-shadow" style={{ marginTop: "1rem" }}>
                  <CardBody>
                    <Row>
                      <Col lg={12}>
                        {/* <div className="mb-2" style={{ fontSize: 20, fontWeight: 'bold' }}>
                                                    <i className="far fa-file-alt text-primary" style={{ fontSize: 30, marginRight: '1vw' }}></i>{this.props.t('Contact Information')}</div> */}
                        <h2 className="font-style-24 cl-dark-blue">
                          {this.props.t("รูปภาพและวีดีโอ")}
                        </h2>
                      </Col>
                    </Row>
                    <hr></hr>
                    <Row>
                      <Col lg={12}>
                        <h4 className="font-style-16 cl-black">
                          {this.props.t("Place Image")}
                        </h4>
                        <Dropzone
                          onDrop={(acceptedFiles) =>
                            this.handleAcceptedFiles(acceptedFiles)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                                </div>
                                <h3>
                                  {this.props.t(
                                    "Drop files here or click to upload."
                                  )}
                                </h3>
                                <p className="font-style-18"><span className="text-alert">หมายเหตุ</span> : รูปภาพที่นำมาจากอินเตอร์เน็ต(Internet) ภาพบางภาพอาจมีลิขสิทธิ์ ซึ่งนำไปสู่การละเมิดลิขสิทธิ์ภาพถ่ายมีโทษตามมาตรา69 โทษปรับตั้งแต่ 20,000-200,000 บาทหรือทั้งจำทั้งปรับ</p>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                  <div className="font-style-18" style={{ marginRight: 15 }}>
                                    คำแนะนำ :
                                  </div>
                                  <div className="font-style-18" style={{ textAlign: 'left' }}>
                                    - ใช้รูปภาพที่ถ่ายด้วยตนเอง<br></br>
                                    - ตรวจสอบแหล่งที่มาของรูปภาพว่าไม่มีลิขสิทธิ์<br></br>
                                    - กรณีที่ไม่ใช่ภาพถ่ายของตนเอง ต้องได้รับอนุญาตจากเจ้าของภาพแล้วเท่านั้น<br></br>
                                    - ควรให้เครดิตแก่เจ้าของลิขสิทธิ์<br></br>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {this.state.selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      Swal.fire({
                                        title: "ลบไฟล์ ",
                                        text: "ต้องการลบไฟล์ที่เลือกใช่หรือไม่ ",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonColor: "#d33",
                                        cancelButtonText: "ยกเลิก",
                                        confirmButtonText: "ยืนยัน!",
                                      }).then((result) => {
                                        if (result.isConfirmed) {
                                          let selectfile =
                                            this.state.selectedFiles;
                                          selectfile.splice(i, 1);
                                          this.setState(
                                            {
                                              selectedFiles: selectfile,
                                            },
                                            () => {
                                              Swal.fire(
                                                "ลบแล้ว !",
                                                "ภาพของคุณถุกลบแล้ว",
                                                "success"
                                              );
                                            }
                                          );
                                        }
                                      });
                                    }}
                                    className="align-items-center"
                                  >
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        style={{
                                          width: "5rem",
                                          height: "5rem",
                                        }}
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        <span className="font-style-16 cl-dark-blue">
                                          {f.name}
                                        </span>
                                      </Link>
                                      <p className="mb-0">
                                        <span className="font-style-16 cl-gray">
                                          {f.formattedSize}
                                        </span>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            );
                          })}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* Card No.4 approved stauts */}
            {(this.state.userType === "1" || this.state.userType === "4") && (
              <Row>
                <Col lg={12}>
                  <Card className="card-shadow" style={{ marginTop: "1rem" }}>
                    <CardBody>
                      <Row>
                        <Col lg={12}>
                          <h2 className="font-style-24 cl-dark-blue">
                            {this.props.t("อนุมัติ")}
                          </h2>
                        </Col>
                      </Row>
                      <hr></hr>
                      <Row>
                        <Col lg={6}>
                          <FormGroup>
                            <Label
                              id="place_category"
                              className="font-style-16 cl-black"
                            >
                              {this.props.t("อนุมัติ")}
                            </Label>
                            <select
                              onChange={(e) => {
                                var forms = this.state.forms;
                                forms.place_status = e.target.value;
                                this.setState({ forms: forms });
                              }}
                              value={this.state.forms.place_status}
                              className="form-control select2"
                            >
                              <option value="new">
                                {this.props.t("ใหม่")}
                              </option>
                              <option value="approved">
                                {this.props.t("อนุมัติ")}
                              </option>
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
            {/* Card No.5 Submit */}
            <Form>
              <Row style={{ display: "flex", alignItems: "center" }}>
                <Col xl={8} lg={6} md={6} sm={12} xs={12}></Col>
                <Col xl={2} lg={3} md={3} sm={12} xs={12}>
                  <Link
                    to={{ pathname: "/places" }}
                    style={{ marginRight: "1rem" }}
                  >
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light"
                      style={{
                        width: "100%",
                        background: "#808080",
                        borderColor: "#808080",
                      }}
                    >
                      <span className="font-style-16">
                        {this.props.t("Cancel")}
                      </span>
                    </button>
                  </Link>
                </Col>
                <Col xl={2} lg={3} md={3} sm={12} xs={12} className="mr-bt">
                  <button
                    className="btn btn-primary waves-effect waves-light"
                    onClick={(e) => {
                      this.add_place(e);
                    }}
                    style={{
                      width: "100%",
                      background: "#28A745",
                      borderColor: "#28A745",
                    }}
                  >
                    <span className="font-style-16">
                      {this.props.t("ยืนยันข้อมูล")}
                    </span>
                  </button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </React.Fragment >
    );
  }
}

export default connect(
  null,
  {}
)(
  GoogleApiWrapper({
    apiKey: "AIzaSyD0AIarEA25PY-NvHNsZDAVqM3qa8krHfs",
    libraries: ["visualization", "places"],
    LoadingContainer: LoadingContainer,
    v: "3",
  })(withTranslation()(AddPlaces))
);
