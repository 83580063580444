import React, { Component } from 'react';
import AnyChart from 'anychart-react';

class ParetoChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resultSearch: "",
            isLoading: false,
        };
    }

    // componentDidMount() {
    //     this.updateResultSearch();
    // }

    // componentDidUpdate(prevProps) {
    //     if (prevProps.report !== this.props.report) {
    //         this.updateResultSearch();
    //     }
    // }

    // updateResultSearch = () => {
    //     const { search, view } = this.props.report;
    //     console.log("this.props.report", this.props.report)
    //     const resultSearch = search === "" ? view : search;
    //     this.setState({
    //         resultSearch: resultSearch,
    //     });
    //     if (!this.state.isLoading) {
    //         console.log("report_result:", this.props.report);
    //     }
    // };

    render() {
        return (
            <div>
                <AnyChart
                    width={'100%'}
                    height={400}
                    type='column'
                    data={this.props.report.pareto_data}
                    title={'ผลลัพธ์การค้นหา : ' + this.props.report.filterISO}
                />
            </div>
        );
    }
}

export default ParetoChart;

