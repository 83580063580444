import React, { Component } from "react";
import { withTranslation } from 'react-i18next';

import NavbarIntro from "./NavbarIntro";
import BodyIntro from "./BodyIntro";
import FooterIntro from "./Footer-Intro";
import './intro.css'
class Intro extends Component {
    constructor(props) {
        super(props);
        this.state = {};

    }

    componentDidMount() {

    }

    render() {

        return (
            <React.Fragment>
                <div id="layout-wrapper">
                    <NavbarIntro />
                    <div className="main-content-intro">
                        <BodyIntro />
                    </div>
                    <FooterIntro />
                </div>
            </React.Fragment >
        );
    }
}

export default (withTranslation()(Intro));
