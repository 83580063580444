// import React, { Component } from 'react';
// import { Row, Col } from "reactstrap";
// // import Lottie from "react-lottie";
// // import * as loadingData from "../../Joborder/men-component/loading.json";
// import country from "../../Joborder/men-component/countries.json"

// // const defaultOptions = {
// //     loop: true,
// //     autoplay: true,
// //     animationData: loadingData.default,
// //     rendererSettings: {
// //         preserveAspectRatio: "xMidYMid slice"
// //     }
// // };

// // ตรวจสอบประเทศ
// const countries = Object.entries(country).map(([value, label]) => ({
//     value: value,
//     label: label,
// }));

// export default class DropdownISO extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             selectISO: '',
//             loading: false,
//             showErrorMessage: false // เพิ่ม state สำหรับแสดงข้อความข้อผิดพลาด
//         };
//     }

//     submitHandle(value) {
//         this.props.callback('filterISO', value);
//     }

//     handleSelectChange = (e) => {
//         const selectedValue = e.target.value;
//         this.setState({
//             selectISO: selectedValue,
//             // loading: true,
//             showErrorMessage: false // ซ่อนข้อความข้อผิดพลาดเมื่อมีการเลือกประเทศใหม่
//         }, () => {
//             // if (typeof this.timeout !== 'undefined') {
//             //     try {
//             //         clearTimeout(this.timeout);
//             //     } catch (err) {
//             //         console.log("error")
//             //     }
//             // }
//             // this.timeout = setTimeout(async () => {
//             //     this.setState({ loading: false }, () => {
//             //         // this.submitHandle(selectedValue); // อย่าเรียก submitHandle ที่นี่
//             //     });
//             // }, 2000);
//         });
//     };

//     handleSubmitClick = () => {
//         this.setState({ showErrorMessage: true })
//         this.submitHandle(this.state.selectISO);
//     };

//     componentDidMount() {
//         this.props.callback('filterISO', this.state.selectISO);
//     }

//     render() {
//         return (
//             <>
//                 {this.props.filter === "country" ?
//                     <>
//                         <Row className="mb-3">
//                             <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>
//                                 <span style={{ fontSize: 16 }}>ค้นหาประเทศ : </span>
//                             </Col>
//                             <Col xl={5} lg={5} md={5} sm={12} style={{ display: 'inline-flex', overflow: 'auto' }}>
//                                 <select
//                                     value={this.state.selectISO}
//                                     onChange={this.handleSelectChange}
//                                     className="form-control"
//                                 >
//                                     <option value="" disabled>กรุณาเลือกประเทศ</option>
//                                     {countries.map((country) => (
//                                         <option key={country.value} value={country.value}>
//                                             {country.label}
//                                         </option>
//                                     ))}
//                                 </select>
//                             </Col>
//                             <Col xl={3} lg={3} md={6} sm={12} style={{ display: 'inline-flex', overflow: 'auto' }}>
//                                 <button className='btn btn-outline-primary' onClick={this.handleSubmitClick}>
//                                     ค้นหา
//                                 </button>
//                             </Col>
//                             {/* {this.state.loading === true && (
//                                 <Col xl={5} lg={5} md={5} sm={12} style={{ display: 'inline-flex', overflow: 'auto' }}>
//                                     <Row style={{ alignSelf: 'center' }}>
//                                         <Lottie options={defaultOptions} height={35} width={35} style={{ marginRight: 10 }} />
//                                         <span style={{ alignSelf: 'center' }}>กำลังค้นหา</span>
//                                     </Row>
//                                 </Col>
//                             )} */}
//                         </Row>
//                         {this.state.showErrorMessage === false ?
//                             <>
//                                 <Row className="mb-3">
//                                     <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>

//                                     </Col>
//                                     <Col xl={5} lg={5} md={5} sm={12} style={{ display: 'inline-flex', overflow: 'auto', top: -10 }}>
//                                         <small style={{ color: 'red' }}>* กรุณาเลือกประเทศที่ต้องการค้นหา</small>
//                                     </Col>
//                                 </Row>
//                             </> :
//                             <></>
//                         }
//                     </>
//                     :
//                     <></>
//                 }
//             </>
//         )
//     }
// }
import React, { Component } from 'react';
import Select from 'react-select'
import { Row, Col } from "reactstrap";
import country from "../../Joborder/men-component/countries.json"

const countries = Object.entries(country).map(([value, label]) => ({
    value: value,
    label: label,
}));

export default class DropdownISO extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectISO: null, // เปลี่ยนให้เป็น null แทน '' เพื่อให้ react-select ทำงานได้ถูกต้อง
            showErrorMessage: false
        };
    }

    handleSelectChange = (selectedOption) => {
        this.setState({
            selectISO: selectedOption,
            showErrorMessage: false
        });
    };

    handleSubmitClick = () => {
        if (this.state.selectISO) {
            this.props.callback('filterISO', this.state.selectISO.value);
        } else {
            this.setState({ showErrorMessage: true });
        }
    };

    render() {
        return (
            <>
                {this.props.filter === "country" ?
                    <>
                        <Row className="mb-3">
                            <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>
                                <span style={{ fontSize: 16 }}>ค้นหาประเทศ : </span>
                            </Col>
                            <Col xl={5} lg={5} md={5} sm={12} >
                                <Select
                                    value={this.state.selectISO}
                                    onChange={this.handleSelectChange}
                                    options={countries}
                                    placeholder="กรุณาเลือกประเทศ"
                                />
                            </Col>
                            <Col xl={3} lg={3} md={6} sm={12} style={{ display: 'inline-flex', overflow: 'auto' }}>
                                <button className='btn btn-outline-primary' onClick={this.handleSubmitClick}>
                                    ค้นหา
                                </button>
                            </Col>
                        </Row>
                        {this.state.showErrorMessage === true ?
                            <Row className="mb-3">
                                <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>

                                </Col>
                                <Col xl={5} lg={5} md={5} sm={12} style={{ display: 'inline-flex', overflow: 'auto', top: -10 }}>
                                    <small style={{ color: 'red' }}>* กรุณาเลือกประเทศที่ต้องการค้นหา</small>
                                </Col>
                            </Row>
                            :
                            null
                        }
                    </>
                    :
                    null
                }
                {this.props.type === 'data-relationship'
                    ?
                    <>
                        <Row className="mb-3">
                            <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>
                                <span style={{ fontSize: 16 }}>ค้นหาประเทศ : </span>
                            </Col>
                            <Col xl={5} lg={5} md={5} sm={12} >
                                <Select
                                    value={this.state.selectISO}
                                    onChange={this.handleSelectChange}
                                    options={countries}
                                    placeholder="กรุณาเลือกประเทศ"
                                />
                            </Col>
                            <Col xl={3} lg={3} md={6} sm={12} style={{ display: 'inline-flex', overflow: 'auto' }}>
                                <button className='btn btn-outline-primary' onClick={this.handleSubmitClick}>
                                    ค้นหา
                                </button>
                            </Col>
                        </Row>
                        {this.state.showErrorMessage === true ?
                            <Row className="mb-3">
                                <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>

                                </Col>
                                <Col xl={5} lg={5} md={5} sm={12} style={{ display: 'inline-flex', overflow: 'auto', top: -10 }}>
                                    <small style={{ color: 'red' }}>* กรุณาเลือกประเทศที่ต้องการค้นหา</small>
                                </Col>
                            </Row>
                            :
                            null
                        }
                    </>
                    :
                    null}
                {this.props.type === 'heat-map'
                    ?
                    <>
                        <Row className="mb-3">
                            <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>
                                <span style={{ fontSize: 16 }}>ค้นหาประเทศ : </span>
                            </Col>
                            <Col xl={5} lg={5} md={5} sm={12} >
                                <Select
                                    value={this.state.selectISO}
                                    onChange={this.handleSelectChange}
                                    options={countries}
                                    placeholder="กรุณาเลือกประเทศ"
                                />
                            </Col>
                            <Col xl={3} lg={3} md={6} sm={12} style={{ display: 'inline-flex', overflow: 'auto' }}>
                                <button className='btn btn-outline-primary' onClick={this.handleSubmitClick}>
                                    ค้นหา
                                </button>
                            </Col>
                        </Row>
                        {this.state.showErrorMessage === true ?
                            <Row className="mb-3">
                                <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>

                                </Col>
                                <Col xl={5} lg={5} md={5} sm={12} style={{ display: 'inline-flex', overflow: 'auto', top: -10 }}>
                                    <small style={{ color: 'red' }}>* กรุณาเลือกประเทศที่ต้องการค้นหา</small>
                                </Col>
                            </Row>
                            :
                            null
                        }
                    </>
                    :
                    null}
            </>
        )
    }
}
