import React, { Component } from 'react'
import { Row, Col, } from "reactstrap";
import moment from 'moment-timezone';
import { withTranslation } from 'react-i18next';

class UserCountTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_period: { value: 'custom', selected_period_start: '', selected_period_end: '' },
            view: 'month',
            usertype: 'agency'
        }
    };

    submitHandle(value) {
        switch (value) {
            case "view":
                this.props.callback(value, this.state.view)
                break;
            case "usertype":
                this.props.callback(value, this.state.usertype)
                break;
            default:
                return
        }
    }

    componentDidMount() {
        let startOfMonth = moment().startOf('year').format('YYYY-MM-DD');
        let endOfMonth = moment().endOf('month').format('YYYY-MM-DD');
        let obj = this.state.selected_period
        obj.selected_period_start = startOfMonth;
        obj.selected_period_end = endOfMonth;
        this.setState({
            selected_period: obj
        }, () => {
            this.props.callback('usertype', this.state.usertype)
            this.props.callback('view', this.state.view)
            this.props.callback('date', this.state.selected_period)
        })
    }

    render() {
        return (
            <>
                <Row className="mb-3">
                    <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>
                        <span style={{ fontSize: 16 }}>{this.props.t('User statistics')} : </span>
                    </Col>
                    <Col xl={5} lg={5} md={5} sm={12}>
                        <Row>
                            <Col xl={5} lg={5} md={5} sm={12}>
                                <Row>
                                    <Col xl={12} lg={12} md={12} sm={12}>
                                        <span style={{ fontSize: 16 }}>{this.props.t('Start date')} :</span>
                                    </Col>
                                    <Col xl={10} lg={10} md={10} sm={12}><input type="date" value={this.state.selected_period.selected_period_start} onChange={(e) => {
                                        let obj = this.state.selected_period
                                        obj.selected_period_start = e.target.value
                                        this.setState({
                                            selected_period: obj
                                        }, () => {
                                            console.log("เริ่มต้น", this.state.selected_period)
                                            this.props.callback('date', this.state.selected_period)
                                        })
                                    }} placeholder="เริ่มต้น " className="form-control" ></input></Col>


                                </Row>

                            </Col>
                            <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>-</Col>
                            <Col xl={5} lg={5} md={5} sm={12}>
                                <Row>
                                    <Col xl={12} lg={12} md={12} sm={12}>
                                        <span style={{ fontSize: 16 }}>{this.props.t('End date')} :</span>
                                    </Col>
                                    <Col xl={9} lg={9} md={9} sm={9}><input type="date" value={this.state.selected_period.selected_period_end} onChange={(e) => {
                                        let obj = this.state.selected_period
                                        obj.selected_period_end = e.target.value
                                        this.setState({
                                            selected_period: obj
                                        }, () => {
                                            console.log("สิ้นสุด ", this.state.selected_period)
                                            this.props.callback('date', this.state.selected_period)
                                        })
                                    }} placeholder="สิ้นสุด" className="form-control" ></input></Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>
                        <span style={{ fontSize: 16 }}>แสดงผลของตารางตามเดือนและปี : </span>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>
                        <button className={this.state.view === 'month' ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'}
                            style={{ whiteSpace: 'nowrap', textAlign: 'center' }}
                            onClick={() => {
                                this.setState({
                                    view: 'month',
                                }, () => {
                                    this.submitHandle('view')
                                })
                            }}>
                            {this.props.t('Month')}
                        </button>
                        <button className={this.state.view === 'year' ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'}
                            style={{ marginLeft: 20, whiteSpace: 'nowrap', textAlign: 'center' }}
                            onClick={() => {
                                this.setState({
                                    view: 'year',
                                }, () => {
                                    this.submitHandle('view')
                                })
                            }}>
                            {this.props.t('Year')}
                        </button>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>
                        <span style={{ fontSize: 16 }}>แสดงผลของตารางตามผู้ใช้งาน : </span>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={12} style={{ display: 'inline-flex', alignSelf: 'center' }}>
                        <button className={this.state.usertype === 'agency' ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'}
                            style={{ whiteSpace: 'nowrap', textAlign: 'center' }}
                            onClick={() => {
                                this.setState({
                                    usertype: 'agency',
                                }, () => {
                                    this.submitHandle('usertype')
                                })
                            }}>
                            {this.props.t('Agency')}
                        </button>
                        <button className={this.state.usertype === 'guide' ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'}
                            style={{ marginLeft: 20, whiteSpace: 'nowrap', textAlign: 'center' }}
                            onClick={() => {
                                this.setState({
                                    usertype: 'guide',
                                }, () => {
                                    this.submitHandle('usertype')
                                })
                            }}>
                            {this.props.t('Guide')}
                        </button>
                        <button className={'btn btn-outline-secondary'}
                            style={{ marginLeft: 20, whiteSpace: 'nowrap', textAlign: 'center' }}
                        >
                            {this.props.t('ผู้นำเที่ยว')}
                        </button>
                    </Col>
                </Row>
            </>
        )
    }
}
export default (withTranslation()(UserCountTab))