import React, { Component } from 'react'
//import PropTypes from 'prop-types'
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import './ReportStyle.scss';
import { Link } from "react-router-dom";
// import Printer from 'react-pdf-print'
import Lottie from "react-lottie";
import * as loadingData from "../Joborder/men-component/loading.json";
import { API_ENDPOINT } from "../../assets/api";
//i18n
import { withTranslation } from 'react-i18next';
import SelectDateTab from './Component/SelectDateTab'
import ChartApex from './Component/ChartApex';
import ReportUserTable from './Component/ReportUserTable';
import ReportSearchTourists from './Component/ReportSearchTourists';
import UserCountTab from './Component/UserCountTab';
import NewTypeTab from './Component/NewTypeTab';
import Search from './Component/Search';
import ParetoChart from './Component/ParetoChart';
import ChartApexTest from './Component/ChartApex-test';
import RangeTab from './Component/RangeTab';
import GraphTab from './Component/GraphTab';
import NetworkChart from './Component/NetworkChart';
import ReportAttractionTable from './Component/ReportAttractionTable';
import DropdownISO from './Component/DropdownISO';
import SearchNew from './Component/SearchNew';
// import TypeTab from './Component/TypeTab';
// import FadeIn from "react-fade-in";
// import CountryGrid from './Component/CountryGrid'
// import AttractionGrid from './Component/AttractionGrid'
// import AccommodationGrid from './Component/AccommodationGrid'
// import GuideGrid from './Component/GuideGrid'
// import AgencyGrid from './Component/AgencyGrid'

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

const ids = ['1']
class NewReport extends Component {
    /*static propTypes = {
         prop: PropTypes
     }*/
    constructor(props) {
        super(props);
        this.state = {
            fetch_report: false,
            error: true,
            report: {
                type: '',
                value: 0,
                filter: 'country',
                start_date: '',
                end_date: '',
                view: '',
                usertype: '',
                garph: '',
                search: '',
                filterISO: '',
                data: [],
                series: [
                    {
                        data: []
                    }
                ],
                report_data: [],
            },
        }
        this.callback = this.callback.bind(this);
    };

    callback(target, data) {
        var obj = this.state.report
        console.log("OBJ", obj)
        switch (target) {
            case "date":
                if (obj.value === data.value && (obj.start_date === data.selected_period_start || obj.end_date === data.selected_period_end)) { return }
                obj.value = data.value

                if (data.value !== 'custom') {
                    obj.start_date = ''
                    obj.end_date = ''
                    this.setState(
                        {
                            report: obj,
                            fetch_report: false,
                        },
                        () => {
                            this.getData();
                        }
                    );
                }
                else {
                    obj.start_date = data.selected_period_start
                    obj.end_date = data.selected_period_end
                    this.setState(
                        {
                            report: obj,
                            fetch_report: true,
                        },
                        () => {
                            this.getData();
                        }
                    );
                }
                break;
            case "filter":
                obj.filter = data
                this.setState({
                    report: obj
                }, () => {
                    if (obj.type === 'new-tourism-trends') {
                        this.setState({
                            fetch_report: true
                        }, () => {
                            this.getData()
                        })
                    }
                })
                break;
            case "view":
                obj.view = data
                this.setState({
                    report: obj
                })
                if (obj.type === 'new-tourists-report-count') {
                    this.setState({
                        fetch_report: true
                    }, () => {
                        this.getData()
                    })
                }
                if (obj.type === 'report-user-count') {
                    this.setState({
                        fetch_report: true
                    }, () => {
                        this.getData()
                    })
                }
                if (obj.type === 'report-attraction-count') {
                    this.setState({
                        fetch_report: true
                    }, () => {
                        this.getData()
                    })
                }
                if (obj.type === 'new-tourism-trends') {
                    this.setState({
                        fetch_report: true
                    }, () => {
                        this.getData()
                    })
                }
                break;
            case "usertype":
                obj.usertype = data
                this.setState({
                    report: obj
                })
                if (obj.type === 'report-user-count') {
                    this.setState({
                        fetch_report: true
                    }, () => {
                        this.getData()
                    })
                }
                if (obj.type === 'report-attraction-count') {
                    this.setState({
                        fetch_report: true
                    }, () => {
                        this.getData()
                    })
                }
                break;

            case "garph":
                obj.garph = data
                this.setState({
                    report: obj
                })
                break;
            case "search":
                obj.search = data
                this.setState({
                    report: obj
                }, () => {
                    if (obj.search === "") {
                        this.setState({
                            fetch_report: true
                        }, () => {
                            this.getData()
                        })
                    }
                })
                break;
            case "filterISO":
                obj.filterISO = data
                this.setState({
                    report: obj,
                    fetch_report: true
                }, () => {
                    this.getData();
                })
                break;
            default:
                return
        }

        if (this.state.report.search !== "") {
            this.setState({ fetch_report: true }, () => {
                // เรียก getData ที่นี่หลังจากตั้งค่า fetch_report เป็น true
                this.getData();
            });

            // รอให้ API เสร็จสิ้นและข้อมูลถูกโหลดเสร็จก่อนที่จะตั้งค่า fetch_report เป็น false
            setTimeout(() => {
                if (this.state.report.data.length === 0) {
                    this.setState({ fetch_report: false });
                }
            }, 5000); // 5000 มิลลิวินาที (5 วินาที)
        }
    }

    getHeader() {
        var pathname = window.location.pathname;
        var obj = this.state.report
        switch (pathname) {
            case "/new-tourists-report-count":
                obj.type = "new-tourists-report-count"
                return this.props.t('The number of issuing job orders')
            case "/report-user-count":
                obj.type = "report-user-count"
                return this.props.t('User statistics')
            case "/search-tourists":
                obj.type = "search-tourists"
                return this.props.t('ค้นหารายชื่อนักท่องเที่ยว')
            // case "/tourists-report":
            //     obj.type = "tourists-report"
            //     return this.props.t('Tourists Report')
            case "/report-attraction-count":
                obj.type = "report-attraction-count"
                return this.props.t('Attraction statistics')
            case "/new-tourism-trends":
                obj.type = "new-tourism-trends"
                return this.props.t('Tourism Trends')
            case "/new-data-relationship":
                obj.type = "new-data-relationship"
                return this.props.t('Data Relationship')
            default:
                return this.props.t('Report')
        }
    }

    async getData() {
        var path = "report/trending/country2"
        var body = {
            startdate: this.state.report.start_date,
            enddate: this.state.report.end_date,
            period: this.state.report.view,
            country: this.state.report.filterISO
        }
        if (this.state.report.filter === 'attraction') {
            path = "report/trending/places2";
            body = {
                startdate: this.state.report.start_date,
                enddate: this.state.report.end_date,
                period: this.state.report.view,
                places: this.state.report.search
            }
        }
        if (this.state.report.filter === 'accommodation') {
            path = "report/trending/accommodation2";
            body = {
                startdate: this.state.report.start_date,
                enddate: this.state.report.end_date,
                period: this.state.report.view,
                places: this.state.report.search
            }
        }
        if (this.state.report.filter === 'country-attraction') {
            path = "report/relation/country_places";
            body = {
                startdate: this.state.report.start_date,
                enddate: this.state.report.end_date,
                period: this.state.report.value,
                country: this.state.report.search
            }
        }
        if (this.state.report.filter === 'attraction-country') {
            path = "report/relation/places_country";
            body = {
                startdate: this.state.report.start_date,
                enddate: this.state.report.end_date,
                period: this.state.report.value,
                places: this.state.report.search
            }
        }
        if (this.state.report.filter === 'country-accommodation') {
            path = "report/relation/country_accommodation";
            body = {
                startdate: this.state.report.start_date,
                enddate: this.state.report.end_date,
                period: this.state.report.value,
                country: this.state.report.search
            }
        }
        if (this.state.report.filter === 'accommodation-country') {
            path = "report/relation/accommodation_country";
            body = {
                startdate: this.state.report.start_date,
                enddate: this.state.report.end_date,
                period: this.state.report.value,
                places: this.state.report.search
            }
        }
        // console.log("asdasdas-------")
        if (this.state.report.type === 'new-tourists-report-count' && this.state.report.value === 'custom' && (this.state.report.start_date === '' || this.state.report.end_date === '')) { return }
        if (this.state.report.type === 'new-tourists-report-count') {
            const path = 'report/joborder/count2'
            const body = {
                startdate: this.state.report.start_date,
                enddate: this.state.report.end_date,
                period: this.state.report.view
            }
            const response = await fetch(API_ENDPOINT + path, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })
            const _result = await response.json()
            console.log("new-tourists-report-count", _result)
            if (_result) {
                let obj = this.state.report
                obj.data = _result.data
                obj.series = _result.series[0].data
                this.setState({
                    report: obj,
                    fetch_report: false
                })
            }
        }
        if (this.state.report.type === 'report-user-count' && this.state.report.value === 'custom' && (this.state.report.start_date === '' || this.state.report.end_date === '')) { return }
        if (this.state.report.type === 'report-user-count') {
            const path = 'report/user/count'
            const body = {
                startdate: this.state.report.start_date,
                enddate: this.state.report.end_date,
                period: this.state.report.view,
                usertype: this.state.report.usertype
            }
            const response = await fetch(API_ENDPOINT + path, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })
            var _result_user = await response.json()
            console.log("report-user-count", _result_user)
            if (_result_user) {
                let obj = this.state.report
                obj.report_data = _result_user
                this.setState({
                    report: obj,
                    fetch_report: false
                })
            }
        }
        if (this.state.report.type === 'report-attraction-count' && this.state.report.value === 'custom' && (this.state.report.start_date === '' || this.state.report.end_date === '')) { return }
        if (this.state.report.type === 'report-attraction-count') {
            // console.log('dfgdfgdfgdfgdfgdfgdfgdfgdfgdfg')
            const path = 'report/place/count'
            const body = {
                startdate: this.state.report.start_date,
                enddate: this.state.report.end_date,
                period: this.state.report.view,
            }
            const response = await fetch(API_ENDPOINT + path, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })
            var _result_data = await response.json()
            console.log("report-attraction-count", _result_data)
            if (_result_data) {
                let obj = this.state.report
                obj.report_data = _result_data
                this.setState({
                    report: obj,
                    fetch_report: false
                })
            }
        }

        if (this.state.report.type === 'search-tourists') {
            const path = "report/tourists"
            const body = {
                name: this.state.report.search,
                startdate: this.state.report.start_date,
                enddate: this.state.report.end_date
            }
            const response = await fetch(API_ENDPOINT + path, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })
            const result = await response.json();
            console.log("result", result)

            let obj = this.state.report
            obj.report_data = result
            this.setState({
                report: obj,
                fetch_report: false
            })

        }
        else {
            const response = await fetch(API_ENDPOINT + path, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            });
            // console.log("response", response)
            if (response.ok) {
                const _result = await response.json()
                console.log("data", _result)
                if (_result) {
                    let obj = this.state.report;
                    if (_result.data && _result.data.length > 0) {
                        obj.data = _result.data;
                        obj.series = _result.series[0].data;
                    } else {
                        obj.data = [];
                        obj.series = [];
                        // ทำอะไรก็ตามที่คุณต้องการเมื่อไม่มีข้อมูล
                    }
                    this.setState({
                        report: obj,
                        fetch_report: false,
                    });
                } else {
                    console.log('error : ' + _result);
                    let obj = this.state.report;
                    obj.data = [];
                    obj.series = [];
                    this.setState({
                        report: obj,
                    });
                }
            } else {
                console.error("Error fetching data from API");
            }
        }
    }

    downloadCSV() {
        console.log("report_asdasd", this.state.report)
        //ตัวอย่างลิงค์ download "https://drjotest-api.melivecode.com/report/user/count/csv?period=month&usertype=guide&startdate=2023-05-01&enddate=2023-08-31"
        if (this.state.report.type === "report-user-count") {
            let path_url = API_ENDPOINT + 'report/user/count/csv?'
            if (this.state.report && this.state.report.view === 'month' && this.state.report.usertype === 'agency') {
                path_url += "period=" + this.state.report.view + "&usertype=" + this.state.report.usertype + "&startdate=" + this.state.report.start_date + "&enddate=" + this.state.report.end_date;
                console.log("url", path_url)
                window.open(path_url, '_blank');
            } else if (this.state.report && this.state.report.view === 'year' && this.state.report.usertype === 'agency') {
                path_url += "period=" + this.state.report.view + "&usertype=" + this.state.report.usertype + "&startdate=" + this.state.report.start_date + "&enddate=" + this.state.report.end_date;
                console.log("url", path_url)
                window.open(path_url, '_blank');
            } else if (this.state.report && this.state.report.view === 'month' && this.state.report.usertype === 'guide') {
                path_url += "period=" + this.state.report.view + "&usertype=" + this.state.report.usertype + "&startdate=" + this.state.report.start_date + "&enddate=" + this.state.report.end_date;
                console.log("url", path_url)
                window.open(path_url, '_blank');
            } else if (this.state.report && this.state.report.view === 'year' && this.state.report.usertype === 'guide') {
                path_url += "period=" + this.state.report.view + "&usertype=" + this.state.report.usertype + "&startdate=" + this.state.report.start_date + "&enddate=" + this.state.report.end_date;
                console.log("url", path_url)
                window.open(path_url, '_blank');
            }
        } else if (this.state.report.type === 'report-attraction-count') {
            let path_url = API_ENDPOINT + 'report/place/count/csv?'
            if (this.state.report && this.state.report.view === 'month') {
                path_url += "period=" + this.state.report.view + "&startdate=" + this.state.report.start_date + "&enddate=" + this.state.report.end_date;
                console.log("url", path_url)
                window.open(path_url, '_blank');
            } else {
                path_url += "period=" + this.state.report.view + "&startdate=" + this.state.report.start_date + "&enddate=" + this.state.report.end_date;
                console.log("url", path_url)
                window.open(path_url, '_blank');
            }
        } else if (this.state.report.type === 'search-tourists') {
            //https://drjotest-api.melivecode.com/report/tourists/csv?name=yan&startdate=2023-01-01&enddate=2023-10-10
            let path_url = API_ENDPOINT + 'report/tourists/csv?'
            path_url += "name=" + this.state.report.search + "&startdate=" + this.state.report.start_date + "&enddate=" + this.state.report.end_date;
            console.log("url", path_url)
            window.open(path_url, '_blank');
        }
    }

    componentDidMount() {
        if (this.state.report.type === 'new-tourism-trends') {
            this.getData();
        }


    }

    render() {

        return (
            <React.Fragment>
                <div className="page-content" id={ids[0]}>
                    <Container fluid>
                        <Row>
                            <Col xs="12">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Breadcrumbs title={this.getHeader()} breadcrumbItem={this.getHeader()} />
                                    {this.state.report.type === 'report-user-count' || this.state.report.type === 'report-attraction-count' || this.state.report.type === 'search-tourists' ?
                                        <div className="d-print-none">
                                            <div className="float-right">
                                                <Link to="#" onClick={() => this.downloadCSV()} className="btn btn-success waves-effect waves-light mr-2"><i className="fa fa-download" aria-hidden="true"></i></Link>
                                            </div>
                                        </div>
                                        :
                                        ''
                                    }
                                </div>
                            </Col>
                        </Row>

                        {this.state.report.type === 'search-tourists' &&
                            <>
                                <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                    <CardBody>
                                        <SelectDateTab callback={this.callback} type={this.state.report.type}></SelectDateTab>
                                        <Search callback={this.callback} type={this.state.report.type}></Search>
                                    </CardBody>
                                </Card>
                                {this.state.fetch_report === true ?
                                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', height: '100%' }}>
                                        <Lottie options={defaultOptions} height={200} width={200} />
                                        <div style={{ fontSize: 16 }}>กำลังค้นหา</div>
                                    </div>
                                    :
                                    <>
                                        <ReportSearchTourists report={this.state.report} ></ReportSearchTourists>
                                    </>
                                }
                            </>
                        }
                        {this.state.report.type === 'report-user-count' &&
                            <>
                                <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                    <CardBody>
                                        <UserCountTab callback={this.callback}></UserCountTab>
                                        <NewTypeTab callback={this.callback} type={this.state.report.type}></NewTypeTab>
                                    </CardBody>
                                </Card>
                                {this.state.fetch_report === true ?
                                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', height: '100%' }}>
                                        <Lottie options={defaultOptions} height={200} width={200} />
                                        <div style={{ fontSize: 16 }}>กำลังค้นหา</div>
                                    </div>
                                    :
                                    <>
                                        <ReportUserTable report={this.state.report} ></ReportUserTable>
                                    </>
                                }
                            </>
                        }
                        {this.state.report.type === 'report-attraction-count' &&
                            <>
                                <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                    <CardBody>
                                        <SelectDateTab callback={this.callback} type={this.state.report.type}></SelectDateTab>
                                    </CardBody>
                                </Card>
                                {this.state.fetch_report === true ?
                                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', height: '100%' }}>
                                        <Lottie options={defaultOptions} height={200} width={200} />
                                        <div style={{ fontSize: 16 }}>กำลังค้นหา</div>
                                    </div>
                                    :
                                    <>
                                        <ReportAttractionTable report={this.state.report} ></ReportAttractionTable>
                                    </>
                                }
                            </>
                        }
                        {this.state.report.type === 'new-tourists-report-count' &&
                            <>
                                <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                    <CardBody>
                                        <SelectDateTab callback={this.callback} type={this.state.report.type}></SelectDateTab>
                                    </CardBody>
                                </Card>
                                <Row>
                                    <Col lg={12} >
                                        {this.state.fetch_report === true ?
                                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', height: '100%' }}>
                                                <Lottie options={defaultOptions} height={200} width={200} />
                                                <div style={{ fontSize: 16 }}>กำลังค้นหา</div>
                                            </div>
                                            :
                                            <>
                                                {this.state.report.data.length < 1 ?
                                                    <>
                                                        <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                                            <CardBody>
                                                                <span>ผลลัพธ์การค้นหา " {this.state.report.search} " : <span style={{ color: 'red' }}>ไม่พบข้อมูลที่ค้นหาในช่วงเวลาหรือรูปแบบที่กำหนด</span></span>
                                                            </CardBody>
                                                        </Card>
                                                    </>
                                                    :
                                                    <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                                        <ChartApex report={this.state.report}></ChartApex>
                                                    </Card>
                                                }
                                            </>
                                        }
                                    </Col>
                                </Row>
                            </>
                        }
                        {this.state.report.type === 'new-tourism-trends' &&
                            <>
                                <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                    <CardBody>
                                        <SelectDateTab callback={this.callback} type={this.state.report.type}></SelectDateTab>
                                        <NewTypeTab callback={this.callback} type={this.state.report.type}></NewTypeTab>
                                        <DropdownISO callback={this.callback} filter={this.state.report.filter}></DropdownISO>
                                        <SearchNew callback={this.callback} filter={this.state.report.filter}></SearchNew>
                                    </CardBody>
                                </Card>
                                {this.state.report.filterISO === '' ?
                                    <>
                                        {this.state.fetch_report === true ?
                                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', height: '100%' }}>
                                                <Lottie options={defaultOptions} height={200} width={200} />
                                                <div style={{ fontSize: 16 }}>กำลังค้นหา</div>
                                            </div>
                                            :
                                            <>
                                                {
                                                    this.state.report.data.length === 0 ?
                                                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', height: '100%' }}>
                                                            <Lottie options={defaultOptions} height={200} width={200} />
                                                            <div style={{ fontSize: 16 }}>กำลังค้นหา</div>
                                                        </div>
                                                        :
                                                        <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                                            <CardBody>
                                                                <ChartApexTest report={this.state.report}></ChartApexTest>
                                                            </CardBody>
                                                        </Card>
                                                }
                                            </>
                                        }

                                    </>
                                    :
                                    <>
                                        {this.state.fetch_report === true ?
                                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', height: '100%' }}>
                                                <Lottie options={defaultOptions} height={200} width={200} />
                                                <div style={{ fontSize: 16 }}>กำลังค้นหา</div>
                                            </div>
                                            :
                                            <>
                                                {
                                                    this.state.report.data.length === 0 ?
                                                        <>
                                                            <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                                                <CardBody>
                                                                    <span>ผลลัพธ์การค้นหา " {this.state.report.search || this.state.report.filterISO} " : <span style={{ color: 'red' }}>ไม่พบข้อมูลที่ค้นหาในช่วงเวลาหรือรูปแบบที่กำหนด</span></span>
                                                                </CardBody>
                                                            </Card>
                                                        </>
                                                        :
                                                        <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                                            <CardBody>
                                                                <ChartApexTest report={this.state.report}></ChartApexTest>
                                                            </CardBody>
                                                        </Card>
                                                }
                                            </>
                                        }

                                    </>
                                }
                            </>
                        }
                        {/* {this.state.report.type === 'tourists-report' &&
                                <>
                                    <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                        <CardBody>
                                            <RangeTab callback={this.callback}></RangeTab>
                                            <TypeTab callback={this.callback} type={this.state.report.type}></TypeTab>
                                        </CardBody>
                                    </Card>
                                    {
                                        this.state.fetch_report ?
                                            <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                                <CardBody>
                                                    <Row >
                                                        <Col>
                                                            <div style={{ marginTop: 50 }} className="text-center">
                                                                <FadeIn>
                                                                    <div style={{ display: "flex", justifyContent: 'center' }}>
                                                                        <Card>
                                                                            <CardBody>
                                                                                <Lottie options={defaultOptions} height={300} width={300} />
                                                                                <div>
                                                                                    <h4 style={{ color: 'black', padding: 30 }}>กำลังเตรียมข้อมูล กรุณารอสักครู่</h4>
                                                                                </div>
                                                                            </CardBody>
                                                                        </Card>

                                                                    </div>
                                                                </FadeIn>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                            :
                                            <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                                <CardBody style={{ background: 'white' }}>
                                                    <Row>
                                                        <Col xl={12} lg={12} md={12} sm={12} style={{ display: 'inline-flex' }}>
                                                            <Card>
                                                                <CardBody>
                                                                    {this.state.report.filter === 'country' && <CountryGrid report={this.state.report}></CountryGrid>}
                                                                    {this.state.report.filter === 'attraction' && <AttractionGrid report={this.state.report}></AttractionGrid>}
                                                                    {this.state.report.filter === 'accommodation' && <AccommodationGrid report={this.state.report}></AccommodationGrid>}
                                                                    {this.state.report.filter === 'guide' && <GuideGrid report={this.state.report}></GuideGrid>}
                                                                    {this.state.report.filter === 'agency' && <AgencyGrid report={this.state.report}></AgencyGrid>}
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                    }
                                </>
                            } */}
                        {this.state.report.type === 'new-data-relationship' &&
                            <>
                                <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                    <CardBody>
                                        <RangeTab callback={this.callback}></RangeTab>
                                        <NewTypeTab callback={this.callback} type={this.state.report.type}></NewTypeTab>
                                        <GraphTab callback={this.callback}></GraphTab>
                                        <Search callback={this.callback}></Search>
                                    </CardBody>
                                </Card>
                                {this.state.report.filterISO === '' ?
                                    <>
                                        {this.state.fetch_report === true ?
                                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', height: '100%' }}>
                                                <Lottie options={defaultOptions} height={200} width={200} />
                                                <div style={{ fontSize: 16 }}>กำลังค้นหา</div>
                                            </div>
                                            :
                                            <>
                                                {
                                                    this.state.report.data.length === 0 ?
                                                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', height: '100%' }}>
                                                            <Lottie options={defaultOptions} height={200} width={200} />
                                                            <div style={{ fontSize: 16 }}>กำลังค้นหา</div>
                                                        </div>
                                                        :
                                                        <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                                            <CardBody>
                                                                {this.state.report.garph === 'pareto' && <ParetoChart report={this.state.report}></ParetoChart>}
                                                                {this.state.report.garph === 'network' && <NetworkChart report={this.state.report}></NetworkChart>}
                                                            </CardBody>
                                                        </Card>
                                                }
                                            </>
                                        }

                                    </>
                                    :
                                    <>
                                        {this.state.fetch_report === true ?
                                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', height: '100%' }}>
                                                <Lottie options={defaultOptions} height={200} width={200} />
                                                <div style={{ fontSize: 16 }}>กำลังค้นหา</div>
                                            </div>
                                            :
                                            <>
                                                {
                                                    this.state.report.data.length === 0 ?
                                                        <>
                                                            <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                                                <CardBody>
                                                                    <span>ผลลัพธ์การค้นหา " {this.state.report.search || this.state.report.filterISO} " : <span style={{ color: 'red' }}>ไม่พบข้อมูลที่ค้นหาในช่วงเวลาหรือรูปแบบที่กำหนด</span></span>
                                                                </CardBody>
                                                            </Card>
                                                        </>
                                                        :
                                                        <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                                            <CardBody>
                                                                {this.state.report.garph === 'pareto' && <ParetoChart report={this.state.report}></ParetoChart>}
                                                                {this.state.report.garph === 'network' && <NetworkChart report={this.state.report}></NetworkChart>}
                                                            </CardBody>
                                                        </Card>
                                                }
                                            </>
                                        }

                                    </>
                                }
                                {/* {this.state.report.search === '' || this.state.report.search === null ?
                                    null
                                    :
                                    <>
                                        {this.state.report.pareto_data.length < 1 ?
                                            <>
                                                <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                                    <CardBody>
                                                        <span>ผลลัพธ์การค้นหา " {this.state.report.search} " : <span style={{ color: 'red' }}>ไม่พบข้อมูลที่ค้นหาในช่วงเวลาหรือรูปแบบที่กำหนด</span></span>
                                                    </CardBody>
                                                </Card>
                                            </>
                                            :
                                            <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                                <CardBody>
                                                    {this.state.report.garph === 'pareto' && <ParetoChart report={this.state.report}></ParetoChart>}
                                                    {this.state.report.garph === 'network' && <NetworkChart report={this.state.report}></NetworkChart>}
                                                </CardBody>
                                            </Card>
                                        }
                                    </>
                                } */}
                            </>
                        }

                    </Container>
                </div>
            </React.Fragment >
        )
    }
}
export default withTranslation()(NewReport);
