import React, { Component } from 'react'
import {
    Row,
    Col,
    Card,
    CardBody
} from "reactstrap";
import Board from '@lourenci/react-kanban'
import './custom.css'
// import sun from "../../../assets/images/icon/sun.svg"
// import night from "../../../assets/images/icon/night.svg"
// import cloudy from "../../../assets/images/icon/cloudy.svg"
// import pin from "../../../assets/images/icon/pin-drop.svg"
// import file from "../../../assets/images/icon/file-text.svg"
import { IMAGE_ENDPOINT } from "../../../assets/api";
// import { API_ENDPOINT } from "../../../assets/api";

export default class PlacesKanban extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        console.log(this.props)
        // let index = this.props.index
        let days = this.props.days
        const board = {
            columns: []
        }
        for (var i = 0; i < 3; i++) {
            let title = ""
            let icon = ""
            if (i === 0) {
                title = "ช่วงเช้า"
                icon = '/assets/images/icon/sun.svg'
            } else if (i === 1) {
                title = "ช่วงบ่าย"
                icon = '/assets/images/icon/cloudy.svg'
            } else {
                title = "ช่วงเย็น"
                icon = '/assets/images/icon/night.svg'
            }
            // let tmpid = (index * 3) + i
            board.columns.push({
                id: days.day + '-' + (i + 1),
                title: title,
                icon: icon,
                cards: []
            })
            let daypartindex = days.dayparts.map(e => e.daypart).indexOf(i + 1)
            if (daypartindex > -1) {
                for (var j = 0; j < days.dayparts[daypartindex].places.length; j++) {
                    // console.log(days.day + '' + days.dayparts[daypartindex].daypart + '' + (j + 1))
                    board.columns[days.dayparts[daypartindex].daypart - 1].cards.push({
                        id: days.day + '-' + days.dayparts[daypartindex].daypart + '-' + (j + 1),
                        places: days.dayparts[daypartindex].places[j],
                        day: days.day,
                        daypart: days.dayparts[daypartindex].daypart,
                        place_index: j,

                    })
                }
            }
        }
        return <Board
            onCardDragEnd={(card, source, destination) => {
                var from = {
                    day: card.day,
                    daypart: card.daypart,
                    place_index: card.place_index,
                }

                var split_to = destination.toColumnId.split('-')
                var to = {
                    day: parseInt(split_to[0]),
                    daypart: parseInt(split_to[1]),
                    place_index: destination.toPosition
                }
                this.props.kanban_move_places(from, to)
            }}
            renderColumnHeader={({ title, icon }) => {
                return (
                    <Row style={{ margin: '1vh', borderBottom: "2px solid white", padding: '0vh' }}>
                        <div>
                            <div style={{ padding: 20 }}>
                                <font style={{ fontSize: '2em' }} color={"white"}><img className="" src={icon} style={{ paddingRight: 20 }} alt="" />{title}</font>
                            </div>
                        </div>
                    </Row>

                )
            }}
            renderCard={(card, { removeCard, dragging }) => {
                let img = card.places.place_img.split(';')[0]
                console.log(card)
                return (
                    <div style={{ margin: 8, minWidth: 436 }}>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm={3} md={3} lg={3} style={{ textAlign: 'center', alignSelf: 'center' }}>
                                        <img className="rounded-circle avatar-lg" style={{ height: 60, width: 60, objectFit: 'cover' }} alt="Skote" src={IMAGE_ENDPOINT + img} />
                                    </Col>
                                    <Col sm={9} md={9} lg={9}>
                                        <Row>
                                            <h4 style={{ color: 'black', fontWeight: 'bold' }}>{card.places.place_name.substring(0, 20)}...</h4>
                                        </Row>
                                        <Row>
                                            <div>
                                                <img
                                                    src={IMAGE_ENDPOINT + "/logo/" + card.places.place_category_icon + '.svg'}
                                                    alt=""
                                                    width={10}
                                                />
                                            </div>
                                            <p style={{ marginLeft: 5, color: 'black', fontSize: '0.8rem', fontWeight: 'bold' }}>{card.places.place_category_name}</p>
                                        </Row>
                                        {card.places.place_detail != null && (
                                            <Row >
                                                <i className="fas fa-map-marker-alt text-primary"></i>   <p style={{ marginLeft: 5, color: 'black', fontSize: 10, fontWeight: 'bold' }}>{card.places.place_detail.substring(0, 40)}...</p>

                                            </Row>
                                        )}
                                        {card.places.place_detail == null && (
                                            <Row >
                                                <i className="fas fa-map-marker-alt text-primary"></i>   <p style={{ marginLeft: 5, color: 'black', fontSize: 10, fontWeight: 'bold' }}>ไม่พบข้อมูล</p>

                                            </Row>
                                        )}
                                        <Row style={{ borderTop: 'solid 1px rgba(0, 0, 0, 0.1)', alignItems: 'center' }}>
                                            <Col className="mt-1" xs={8} sm={8} md={8} lg={8} xl={8} >
                                                <Row >
                                                    <div >
                                                        <img
                                                            src={'/assets/images/icon/file-text.svg'}
                                                            alt=""
                                                            width={20}
                                                            height={20}
                                                        />
                                                    </div>
                                                    <div onClick={event => {
                                                        let place_id = card.places.place_id
                                                        if (place_id === undefined) {
                                                            place_id = card.places.place_id
                                                        }
                                                        window.open('/places/detail?placeid=' + place_id)
                                                    }
                                                    }
                                                    >
                                                        <h6 style={{ cursor: 'pointer' }} className="mt-1 ml-2" >คลิกดูรายละเอียดสถานที่</h6>
                                                    </div>
                                                </Row>
                                            </Col>
                                            <Col className="mt-1" xs={4} sm={4} md={4} lg={4} xl={4} style={{ textAlign: 'end' }}>

                                                <div onClick={() => this.props.kanban_remove_places(card.day, card.daypart, card.place_index)}>
                                                    <h6 className="mt-1" style={{ cursor: 'pointer', color: 'red', fontSize: 10 }} >ลบ <i className="far fa-trash-alt"></i></h6>
                                                </div>

                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>

                )
            }}

        >{board}</Board>
    }
}
