import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Row, Card, CardBody } from "reactstrap";
// import img6 from "../../assets/images/small/img-6.jpg";
// import img10 from "../../assets/images/small/img-10.png";
// import img11 from "../../assets/images/small/img-11.png";
// import img13 from "../../assets/images/small/img-13.png";
// import img14 from "../../assets/images/small/img-14.png";
// import img15 from "../../assets/images/small/img-trip.png";
// import img16 from "../../assets/images/small/img-trip2.png";
// import jobod from "../../assets/images/small/Group110.svg"
// import templates from "../../assets/images/small/templates.svg"
// import upload from "../../assets/images/small/img-trip2.png"
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
class Addjoborder extends Component {
    static propTypes = {
        prop: PropTypes
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <div className="container-fluid">
                        <Breadcrumbs title="Joborder" breadcrumbItem={this.props.t('Joborder')} />
                        <div>
                            <Row style={{ justifyContent: 'center', alignSelf: 'center' }}>
                                <Link to={'/Trip'}>
                                    <Card style={{ width: "50rem", height: "10rem" }}>

                                        <CardBody style={{ alignSelf: 'center' }}>
                                            <div className="media">
                                                <div className="align-self-center mr-4">
                                                    <img src={'/assets/images/icon/Group110.svg'} alt="" style={{ width: "110px", height: "110px" }} />
                                                </div>
                                                <div className="align-self-center ">
                                                    <h2 style={{ fontWeight: 'bold', marginBottom: 0, color: 'black' }}>สร้าง Job order ด้วยตัวเอง</h2>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Link>
                            </Row>
                            {/* <Row style={{ justifyContent: 'center', paddingTop: 30 }}>
                                <Link to={'/Trip/FormUpload'}>
                                    <Card style={{ width: "50rem", height: "10rem" }}>
                                        <CardBody style={{ alignSelf: 'center' }}>
                                            <div className="media">
                                                <div className="align-self-center mr-4">
                                                    <img src={upload} alt="" style={{ width: "110px", height: "110px", marginLeft: -92 }} />
                                                </div>
                                                <div className="align-self-center ">
                                                    <h2 style={{ fontWeight: 'bold', marginBottom: 0, color: 'black' }}>Upload File CSV</h2>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Link>
                            </Row> */}
                        </div>
                        {/* <Row style={{ justifyContent: 'center', paddingTop: 30 }}>
                            <h3 style={{ color: 'black' }}>{this.props.t('You can choose the pattern yourself')}</h3>
                        </Row> */}

                    </div>



                </div>
                {/* <div className="container-fluid">

                        <div style={{ alignSelf: 'center', justifyContent: 'center' }}>
                            <Row style={{ alignSelf: 'center', justifyContent: 'center' }}>
                                <Card className="text-center" style={{ width: "35vh", height: "42vh", justifyContent: 'center', margin: 25 }}>
                                    <Link to={'/Trip'}>
                                        <CardBody>
                                            <div className="mt-4 mt-md-0">
                                                <img src={jobod} style={{ width: "20vh", height: "20vh", marginBottom: 25 }} />
                                            </div>

                                            <CardTitle style={{}}> <h4 style={{ color: "black", fontWeight: 'bold' }}>สร้าง Job order ด้วยตัวเอง</h4></CardTitle>
                                        </CardBody>
                                    </Link>
                                </Card>
                                <Card className="text-center" style={{ width: "35vh", height: "42vh", justifyContent: 'center', margin: 25 }}>
                                    <Link to={'/FormUpload'}>
                                        <CardBody>
                                            <div className="mt-4 mt-md-0">
                                                <img src={templates} style={{ width: "20vh", height: "20vh", marginBottom: 25 }} />
                                            </div>

                                            <div style={{}}> <h4 style={{ color: "black", fontWeight: 'bold' }}>Upload File CSV</h4></div>

                                        </CardBody>
                                    </Link>
                                </Card>





                            </Row>
                            <div>
                                <div style={{ textAlign: "center", marginTop: 25 }}>
                                    <h5 style={{ color: "black" }}>คุณสามรถเลือกรูปแบบได้</h5>

                                </div>
                            </div>
                        </div>
                    </div> */}


            </React.Fragment>
        )
    }
}
export default (
    (withTranslation()(Addjoborder))
);