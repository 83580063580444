import React, { Component } from 'react'
//import PropTypes from 'prop-types'
import { Card, CardBody, Table, Container } from "reactstrap";
// import Breadcrumbs from '../../components/Common/Breadcrumb';

import { Link } from "react-router-dom";
// import Printer, { print } from 'react-pdf-print'
import { API_ENDPOINT } from "../../assets/api";
import moment from 'moment-timezone';

import QRCode from "react-qr-code";


export default class Savedoc extends Component {
    /*static propTypes = {
         prop: PropTypes
     }*/
    constructor(props) {
        super(props);
        this.printInvoice.bind(this);
        this.state = {
            joborder_code: "",
            fetch_joborder: true,
            isLoading: true,
            auth: JSON.parse(localStorage.getItem('authToken')),
            step_1: {
                code: '',
                external_agency: '',
                joborder_start: '',
                joborder_stop: '',
                flights_external: [],
                flights_internal: [],
                agency_name: '',
                agency_license: '',
                joborder_id: '',
                created_at: '',
                inbound_type: '',
                inbound_no: '',
                inbound_date: '',
                inbound_country: '',
                outbound_type: '',
                outbound_no: '',
                outbound_date: '',
                outbound_country: '',
            },
            step_2: {
                days: [],
                places_modal_is_open: false,
                places_period_modal_is_open: false,
                guides_modal_is_open: false,
                fetch_guides: true,
                fetch_places: true,

                guides_fee: 0,
                guides: [],
                guides_skip: 0,
                guides_take: 10,
                guides_name_filter: '',
                guides_selected_day: 0,

                places: [],
                places_name_filter: '',
                places_selected: {},
                places_selected_day: 0,
                places_selected_daypart: [],
                places_selected_category: [],
                places_categorys: []


            },
            step_3: {
                tourists_adults: 0,
                tourists_children: 0,
                tourists_followers: 0,
                tourists_all: 0,
                tourists: []
            }
        }
        // this.auth = JSON.parse(localStorage.getItem('authToken'))
    };

    async get_agency_detail() {
        var auth = JSON.parse(await localStorage.getItem('authToken'))
        var response = await fetch(API_ENDPOINT + 'api/v2/web/agency/get/' + auth.users.agency_id, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: auth.data.type + ' ' + auth.data.token,
            }
        });
        var responseJson = await response.json();
        var agency = responseJson
        this.setState({
            step_1: {
                ...this.state.step_1,
                agency_license: agency.license,
                agency_name: agency.name
            }
        })
        console.log(responseJson)
    }

    async get_joborder_detail(joborder_code) {
        // var auth = JSON.parse(await localStorage.getItem('authToken'))
        // var lang = 'th'
        var response = await fetch(API_ENDPOINT + 'joborder/get', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                // Authorization: auth.data.type + ' ' + auth.data.token,
            },
            body: JSON.stringify({
                joborder_code: joborder_code,
                lang: 'th'
            })
        });
        var responseJson = await response.json();
        console.log(responseJson)
        var state = this.state
        state.joborder_id = responseJson.joborder.id
        state.step_1.code = responseJson.joborder.code
        state.step_1.external_agencys = responseJson.joborder.external_agencys
        state.step_1.external_agencys_code = responseJson.joborder.external_agencys_code
        state.step_1.joborder_start = moment(new Date(responseJson.joborder.joborder_start_date)).format('YYYY-MM-DD')
        state.step_1.joborder_stop = moment(new Date(responseJson.joborder.joborder_stop_date)).format('YYYY-MM-DD')
        state.step_1.agency_name = responseJson.joborder.agencys[0].name
        state.step_1.agency_license = responseJson.joborder.agencys[0].license
        state.step_1.created_at = moment(new Date(responseJson.joborder.created_at)).format('YYYY-MM-DD')

        state.step_1.inbound_type = responseJson.joborder.inbound_type
        state.step_1.inbound_no = responseJson.joborder.inbound_no
        state.step_1.inbound_date = moment(new Date(responseJson.joborder.inbound_date)).format('YYYY-MM-DD')
        state.step_1.inbound_country = responseJson.joborder.inbound_country
        state.step_1.outbound_type = responseJson.joborder.outbound_type
        state.step_1.outbound_no = responseJson.joborder.outbound_no
        state.step_1.outbound_date = moment(new Date(responseJson.joborder.outbound_date)).format('YYYY-MM-DD')
        state.step_1.outbound_country = responseJson.joborder.outbound_country

        state.step_2.guides_fee = responseJson.joborder.guides_fee

        state.step_3.tourists_adults = responseJson.joborder.tourists_adults
        state.step_3.tourists_children = responseJson.joborder.tourists_children
        state.step_3.tourists_followers = responseJson.joborder.tourists_followers
        state.step_3.tourists_all = responseJson.joborder.tourists_all

        // for (var flight of responseJson.joborder.flights_external) {
        //     state.step_1.flights_external.push({
        //         flight_departure_date: moment(new Date(flight.flight_depart)).format('YYYY-MM-DD'),
        //         flight_no: flight.flight_no,
        //         flight_from: flight.flight_from,
        //         flight_departure_datetime: new Date(flight.flight_depart).toISOString().slice(0, 16),
        //         flight_to: flight.flight_to,
        //         flight_arrival_datetime: new Date(flight.flight_arrival).toISOString().slice(0, 16)

        //     })
        // }

        // for (var flight of responseJson.joborder.flights_internal) {
        //     state.step_1.flights_internal.push({
        //         flight_departure_date: moment(new Date(flight.flight_depart)).format('YYYY-MM-DD'),
        //         flight_no: flight.flight_no,
        //         flight_from: flight.flight_from,
        //         flight_departure_datetime: new Date(flight.flight_depart).toISOString().slice(0, 16),
        //         flight_to: flight.flight_to,
        //         flight_arrival_datetime: new Date(flight.flight_arrival).toISOString().slice(0, 16)

        //     })
        // }
        var days = []
        var placeordering = 1
        for (var response_day of responseJson.joborder.days) {
            days.push({
                day: response_day.dayno,
                dayparts: [],
            })
            let dayindex = days.map(day => day.day).indexOf(response_day.dayno)
            for (var response_daypart of response_day.day_details) {
                let daypartindex = days[dayindex].dayparts.map(daypart => daypart.daypart).indexOf(response_daypart.daypart)
                if (daypartindex === -1) {
                    days[dayindex].dayparts.push({
                        daypart: response_daypart.daypart,
                        places: []
                    })
                    daypartindex = days[dayindex].dayparts.map(daypart => daypart.daypart).indexOf(response_daypart.daypart)
                }
                days[dayindex].dayparts[daypartindex].places.push({
                    place_id: response_daypart.place_id,
                    place_img: response_daypart.place.photo,
                    place_name: response_daypart.place.name,
                    place_detail: response_daypart.place.detail,
                    place_category_name: response_daypart.place.category_name,
                    place_ordering: placeordering,
                    place_category_icon: response_daypart.place.category_icon

                })
                placeordering++
                if (typeof days[dayindex].guides == 'undefined') {
                    days[dayindex].guides = []
                }
                if (response_daypart.guides.length > 0) {
                    console.log(response_daypart.guides.length)
                    for (var tmp_guide of response_daypart.guides) {
                        let guideindex = days[dayindex].guides.map(guide => guide.guide_id).indexOf(tmp_guide.guide_id)
                        if (guideindex === -1) {
                            days[dayindex].guides.push({
                                guide_id: tmp_guide.guide_id,
                                fname: tmp_guide.guide.guide_info.fname,
                                lname: tmp_guide.guide.guide_info.lname,
                                license: tmp_guide.guide.license,
                                address: tmp_guide.guide.address,
                                photo: 'under cnstruction'
                            })
                        }
                    }
                }

            }
        }

        for (var tourist of responseJson.joborder.tourists) {
            state.step_3.tourists.push({
                passport: tourist.passport,
                passport_file: tourist.passport_file,
                prefix: tourist.prefix,
                firstname: tourist.fname,
                lastname: tourist.lname,
                date_of_birth: moment(new Date(tourist.dob)).format('YYYY-MM-DD'),
                nation: tourist.origincountry,
                flight: tourist.arrivalflight,
                flight_no: tourist.arrivalflight
            })
        }
        this.setState({
            ...state,
            fetch_joborder: false,
            step_2: {
                ...state.step_2,
                days: days,
            }
        }, () => {
            console.log(days)
            console.log('finish load')
        })
    }

    printInvoice() {
        window.print();
    }

    async componentDidMount() {
        const code = this.props.match.params.code
        if (code) {
            await this.get_joborder_detail(code)
        } else {
            this.setState({
                ...this.state,
                step_1: JSON.parse(await localStorage.getItem('step_1')),
                step_2: JSON.parse(await localStorage.getItem('step_2')),
                step_3: JSON.parse(await localStorage.getItem('step_3')),
            })
            await this.get_agency_detail()
        }

        // let step1 = this.state.step_1
        // let step2 = this.state.step_2
        // let step3 = this.state.step_3
        let guidesSet = new Set()
        // let placesSet = new Set()
        for (let day of this.state.step_2.days) {
            if (day.guides) {
                for (let guide of day.guides) {
                    guidesSet.add(JSON.stringify({
                        fullname: guide.fname + ' ' + guide.lname,
                        license: guide.license,
                        days: []
                    }))
                }
            }
            // for (let daypart of day.dayparts) {
            //     for (let place of daypart.places) {
            //         placesSet.add(JSON.stringify({
            //         }))
            //     }
            // }
        }
        let guides = []
        for (let guide of guidesSet) {
            guides.push(JSON.parse(guide))
        }
        for (let day of this.state.step_2.days) {
            if (day.guides) {
                for (let guide of day.guides) {
                    var guideIndex = guides.findIndex(x => x.license === guide.license)
                    var guideDay = moment(new Date(this.state.step_1.joborder_start)).add((day.day - 1), 'days').format('YYYY-MM-DD')
                    guides[guideIndex]['days'].push(guideDay)
                }
            }
        }

        this.setState({
            // step_1: step1,
            // step_2: step2,
            // step_3: step3,
            guides: guides,
            isLoading: false

        }, () => {
            console.log("=====================")
            console.log("this.state.step_1")
            console.log(this.state.step_1)
            console.log("this.state.step_2")
            console.log(this.state.step_2)
            console.log("this.state.step_3")
            console.log(this.state.step_3)
            console.log("this.state.auth")
            console.log(this.state.auth)
            console.log("=====================")
        })
        // this.getJoborder()
    }


    render() {
        const dayparts = {
            1: 'เช้า',
            2: 'บ่าย',
            3: 'เย็น'
        }
        const travelTypes = {
            1: 'เครื่องบิน',
            2: 'รถ',
            3: 'เรือ',
            4: 'อื่นๆ'
        }
        const travelNumberText = {
            1: 'เที่ยวบินที่',
            2: 'ทะเบียนเลขที่',
            3: 'ชื่อเรือ',
            4: ''
        }
        if (this.state.isLoading === true) {
            return (
                <div></div>
            )
        }
        else {
            return (
                <React.Fragment>
                    <div className="page-content" >
                        <Container fluid>
                            <Card style={{ height: '1500px', backgroundImage: `url("/assets/images/watermark/watermark_logo.jpg")` }}>
                                <CardBody>
                                    <div className="d-print-none">
                                        <div className="float-right">
                                            <Link to="#" onClick={this.printInvoice} className="btn btn-success waves-effect waves-light mr-2"><i className="fa fa-print"></i></Link>
                                        </div>
                                    </div>

                                    <div className="invoice-title" style={{ textAlign: 'center', marginTop: "5px" }} >
                                        <h4 style={{ fontWeight: 'bold', color: 'black' }} >ใบสั่งงานมัคคุเทศก์</h4>
                                        {/* <p style={{ fontWeight: 'bold', color: 'black' }}>{this.state.step_1.external_agency}</p> */}
                                        {/* <p style={{ fontWeight: 'bold' }}>{this.state.step_1.external_agency}</p> */}
                                    </div>

                                    <div style={{ border: 'solid', borderWidth: '1px' }}>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td className="text-left" style={{ border: "0px" }}>
                                                        ใบสั่งงานเลขที่<span>&nbsp;&nbsp;</span>
                                                        {this.state.step_1.code ? this.state.step_1.code : '.........'}<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                        วันที่<span>&nbsp;&nbsp;</span>
                                                        {this.state.step_1.created_at ? this.state.step_1.created_at : '.........'}
                                                        <br />
                                                        1. ชื่อผู้ประกอบธุรกิจนำเที่ยว<span>&nbsp;&nbsp;</span>
                                                        {this.state.step_1.agency_name}
                                                        <br />
                                                        ใบอนุญาตเลขที่<span>&nbsp;&nbsp;</span>
                                                        {this.state.step_1.agency_license}
                                                        <br />
                                                        2. ขอมอบหมายให้
                                                        {
                                                            this.state.guides.map((guide, i) => {
                                                                return (
                                                                    <div>
                                                                        2.1<span>&nbsp;&nbsp;</span>{guide.fullname}<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                                        ใบอนุญาตเป็นมัคคุเทศก์เลขที่<span>&nbsp;&nbsp;</span>{guide.license}<span>&nbsp;&nbsp;&nbsp;&nbsp;</span><br />
                                                                        วันที่<span>&nbsp;&nbsp;</span>{guide.days.join(", ")}
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        ปฏิบัติหน้าที่เป็นมัคคุเทศก์เพื่อให้บริการแก่นักท่องเที่ยวคณะนี้
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody>
                                                <tr>
                                                    <td className="text-left" style={{ border: "0px" }}>
                                                        3. ชื่อบริษัทนำเที่ยวจากต่างประเทศ<span>&nbsp;&nbsp;</span>
                                                        {this.state.step_1.external_agencys}

                                                        {this.state.step_1.inbound_type === 5 ?
                                                            <div>
                                                                4. เดินทางภายในประเทศ
                                                            </div>
                                                            :
                                                            <div>
                                                                <br />
                                                                4. เดินทางจากประเทศ<span>&nbsp;&nbsp;</span>
                                                                {this.state.step_1.inbound_country}
                                                                <br />
                                                                ช่องทางที่คณะนักท่องเที่ยวเดินทางมาถึง เดินทางโดย
                                                                {travelTypes[this.state.step_1.inbound_type]}
                                                                <br />
                                                                {this.state.step_1.inbound_type === 5}
                                                                {travelNumberText[this.state.step_1.inbound_type]}<span>&nbsp;&nbsp;</span>
                                                                {this.state.step_1.inbound_no}
                                                                <br />
                                                                วันที่เดินทางมาถึง<span>&nbsp;&nbsp;</span>
                                                                {this.state.step_1.inbound_date}
                                                                <br />
                                                                ช่องทางที่คณะนักท่องเที่ยวเดินทางกลับ เดินทางโดย
                                                                {travelTypes[this.state.step_1.outbound_type]}
                                                                <br />
                                                                {travelNumberText[this.state.step_1.outbound_type]}<span>&nbsp;&nbsp;</span>
                                                                {this.state.step_1.outbound_no}
                                                                <br />
                                                                วันที่เดินทางกลับ<span>&nbsp;&nbsp;</span>
                                                                {this.state.step_1.outbound_date}
                                                            </div>
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>

                                    <div className="py-2 mt-3">
                                        5. รายการนำเที่ยว
                                    </div>
                                    <div className="table-responsive" style={{ border: 'solid', borderWidth: '1px' }}>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    {/* <th style={{ width: "70px" }}>ลำดับ</th> */}
                                                    <th>กำหนด วัน/เดือน/ปี <br /> และเวลานำเที่ยว <br /> แต่ละรายการ</th>
                                                    <th>รายละเอียดการจัดนำเที่ยว ประกอบด้วย ลักษณะและประเภทของยานพาหนะที่ใช้ <br /> ในการเดินทางจุดหมายปลายทางและที่แวะพัก รวมทั้งสถานที่สำคัญในการนำเที่ยว <br /> ลักษณะและประเภทของที่พัก และการจัดบริการอาหาร</th>
                                                    <th>หมายเหตุ</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.step_2.days.map((day, i) => {
                                                        var date = moment(this.state.step_1.joborder_start).add(i, 'days');
                                                        return (
                                                            day.dayparts.map((daypart, j) => {
                                                                return (
                                                                    daypart.places.map((place, k) => {
                                                                        return (
                                                                            <tr>
                                                                                <td>
                                                                                    {date.format('YYYY-MM-DD')}
                                                                                    <span>&nbsp;&nbsp;</span>
                                                                                    {dayparts[daypart.daypart]}
                                                                                </td>
                                                                                <td>
                                                                                    <span>&nbsp;&nbsp;</span>
                                                                                    {place.place_name}
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                )
                                                            })
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                    {this.state.step_1.code && this.state.step_1.code !== "" ?
                                        <div className="py-2 mt-3">
                                            <QRCode value={this.state.step_1.code} />
                                        </div>
                                        :
                                        <div></div>
                                    }
                                </CardBody>
                            </Card>
                        </Container>
                    </div>
                </React.Fragment>
            )
        }
    }
}