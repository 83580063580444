import React, { Component } from "react";
import Chart from "react-apexcharts";
import { withTranslation } from 'react-i18next';

class ChartApexTest extends Component {
    constructor(props) {
        super(props);
        this.state = {

            options: {
                chart: {
                    id: "basic-bar"
                },
                xaxis: {
                    categories: props.report.series
                }
            },
            series: [
                {
                    name: this.props.t('Tredning'),
                    data: props.report.data
                }
            ]
        };
        console.log("props---->", props)
    }

    render() {
        return (
            <div className="mixed-chart">
                <Chart
                    options={this.state.options}
                    series={this.state.series}
                    type="bar"
                    width={'100%'}
                    height={400}
                />
            </div>
        );
    }
}

export default withTranslation()(ChartApexTest);
