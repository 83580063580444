import React, { useEffect } from 'react';
import { Map, GoogleApiWrapper, HeatMap } from "google-maps-react";





const Maps = (props) => {

    useEffect(() => {
    }, [props]);

    // const gradient = [
    //     'rgba(0, 255, 255, 0)',
    //     'rgba(0, 255, 255, 1)',
    //     'rgba(0, 191, 255, 1)',
    //     'rgba(0, 127, 255, 1)',
    //     'rgba(0, 63, 255, 1)',
    //     'rgba(0, 0, 255, 1)',
    //     'rgba(0, 0, 223, 1)',
    //     'rgba(0, 0, 191, 1)',
    //     'rgba(0, 0, 159, 1)',
    //     'rgba(0, 0, 127, 1)',
    //     'rgba(63, 0, 91, 1)',
    //     'rgba(127, 0, 63, 1)',
    //     'rgba(191, 0, 31, 1)',
    //     'rgba(255, 0, 0, 1)'
    // ];



    return (
        <Map
            className="map"
            google={props.google}
            // onClick={this.onMapClicked}
            style={{ height: "100%", position: "relative", width: "100%" }}
            zoom={5}
            // style={{ width: "96%", height: "92%" }}
            initialCenter={{

                lat: 13.736717,
                lng: 100.523186
            }}
        // center={this.centerHandle(places, last_checkin_index)}
        >
            {/* 
            {(props.data.length > 0 && props.google.maps.visualization !== undefined) &&
           
            } */}
            {'visualization' in props.google.maps && (
                <HeatMap
                    // gradient={gradient}
                    positions={props.data}
                    opacity={0.7}
                    radius={40}
                />
            )}




        </Map>



    )


}

export default GoogleApiWrapper({
    apiKey: "AIzaSyD0AIarEA25PY-NvHNsZDAVqM3qa8krHfs",
    libraries: ["visualization"]
})(Maps);

