import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Col, Row, Container } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'
import Cardfocus from "./components/Cardfocus"
import { withTranslation } from 'react-i18next';
import './Trip.scss';
class Groupslides extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            tripAdded: []
        }
        console.log(props)
        this.cardSelected = this.cardSelected.bind(this);
        this.alert = this.alert.bind(this);
    };
    myRef = React.createRef();
    getData = async () => {
        const res = await fetch('data.json')
        const data = await res.json();
        this.setState({ data: data })
    };

    alert() {
        return (
            Swal.fire(
                'ผิดพลาด !',
                'กรุณาเลือกเลือกประเภทการท่องเที่ยวที่ต้องการ !',
                'warning'
            )
        )
    }


    cardSelected(e) {
        console.log(e)
        let arr = this.state.tripAdded
        if (arr.length === 2) {
            arr.push({
                category: [e]
            })
            this.setState({
                tripAdded: arr
            }, () => {
                localStorage.setItem('state', JSON.stringify(this.state.tripAdded))
                console.log(this.state.tripAdded)
            })
        }
        else {
            let arr_filter = this.state.tripAdded[2].category
            console.log(arr_filter)
            for (let i = 0; i < arr_filter.length; i++) {
                if (arr_filter[i] === e) {
                    console.log('xxx')
                    arr_filter.splice(i, 1);
                    console.log(arr_filter)
                    this.setState({
                        tripAdded: arr
                    }, () => {
                        localStorage.setItem('state', JSON.stringify(this.state.tripAdded))
                        console.log(this.state.tripAdded)
                    })
                    return
                }
            }
            arr[2].category.push(e)
            this.setState({
                tripAdded: arr
            }, () => {
                localStorage.setItem('state', JSON.stringify(this.state.tripAdded))
                console.log(this.state.tripAdded)
            })

        }
    }

    componentDidMount() {
        document.getElementById("Trip_navagation").classList.add("active");

        console.log('componentDidMount')
        let lcStorage = localStorage.getItem('state')
        console.log(lcStorage)
        if (typeof lcStorage == 'string') {
            console.log('GOOD')
            this.setState({
                tripAdded: JSON.parse(lcStorage),
                isLoading: false
            }, () => {
                console.log(this.state.tripAdded)
                this.getData();
            })

        }
        else {
            console.log('BAD')
            this.getData();
        }
    };

    prevClick = () => {
        const slide = this.myRef.current;
        slide.scrollLeft -= slide.offsetWidth;
        if (slide.scrollLeft <= 0) {
            slide.scrollLeft = slide.scrollWidth;
        }
    };
    nextClick = () => {
        const slide = this.myRef.current;
        slide.scrollLeft += slide.offsetWidth;
        if (slide.scrollLeft >= (slide.scrollWidth - slide.offsetWidth)) {
            slide.scrollLeft = 0;
        }
    };

    render() {
        const data = [
            {
                "src": "/images/img1.svg",
                "title": "ร้านอาหาร",
                "caption": "ร้านอาหาร"
            },
            {
                "src": "/images/img8.svg",
                "title": "นันทนาการและการพักผ่อน",
                "caption": "นันทนาการและการพักผ่อน"
            },
            {
                "src": "/images/img3.svg",
                "title": "ท่องเที่ยวในธรรมชาติ",
                "caption": "ท่องเที่ยวในธรรมชาติ"
            },
            {
                "src": "/images/img7.svg",
                "title": "ท่องเที่ยวผจญภัย",
                "caption": "ท่องเที่ยวผจญภัย"
            },
            {
                "src": "/images/img2.svg",
                "title": "ท่องเที่ยวศาสนาและวัฒนธรรม",
                "caption": "ท่องเที่ยวศาสนาและวัฒนธรรม"
            },
            {
                "src": "/images/img6.svg",
                "title": "ที่พัก",
                "caption": "ที่พัก"
            },
            {
                "src": "/images/img4.svg",
                "title": "ร้านค้าของฝาก",
                "caption": "ร้านค้าของฝาก"
            }
        ]

        return (
            // <React.Fragment>





            //     <Row style={{ alignSelf: 'center', justifyContent: 'center' }}>
            //         <Cardfocus data={data} cardSelected={this.cardSelected} />
            //     </Row>
            //     <div>
            //         <div style={{ textAlign: "center", marginTop: 25 }}>
            //             <h5 style={{ color: "black" }}>เลือกประเภทการท่องเที่ยวที่คุณสนใจเลือกได้มากกว่า 1</h5>

            //         </div>
            //     </div>





            // </React.Fragment>
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Row style={{ display: 'flex', alignItems: 'center' }}>
                            <Col xl={8} lg={6} md={6} sm={12} xs={12}>
                                <Breadcrumbs title="Trip" breadcrumbItem={this.props.t("Trip")} />
                            </Col>
                            <Col xl={2} lg={3} md={3} sm={12} xs={12}>
                                {this.state.tripAdded.length < 3 ?
                                    <Link to={{
                                        pathname: '/Trip/Addtrip/Selectregion',
                                        tripAdded: this.state.tripAdded
                                    }}>
                                        <button type="button" className="btn btn-primary waves-effect waves-light"
                                            style={{ width: '100%', backgroundColor: '#E7E7E7', borderColor: '#AAAAAA' }}>
                                            <span className="font-style-16 cl-gray">
                                                {this.props.t('Back')}
                                            </span>
                                        </button>
                                    </Link>
                                    :
                                    <Link to={'/Trip/Addtrip/Selectregion'}>
                                        <button type="button" className="btn btn-primary waves-effect waves-light"
                                            style={{ width: '100%', backgroundColor: '#E7E7E7', borderColor: '#AAAAAA' }}>
                                            <span className="font-style-16 cl-gray">
                                                {this.props.t('Back')}
                                            </span>
                                        </button>
                                    </Link>
                                }
                            </Col>
                            <Col xl={2} lg={3} md={3} sm={12} xs={12} className="mr-bt">
                                {this.state.tripAdded.length < 3 ?
                                    <button type="button" className="btn btn-primary waves-effect waves-light"
                                        style={{ width: '100%', backgroundColor: '#E7E7E7', borderColor: '#AAAAAA' }}
                                        onClick={() => { this.alert() }}>
                                        <span className="font-style-16 cl-gray">
                                            {this.props.t('Next')}
                                        </span>
                                    </button>
                                    :
                                    <Link to={{
                                        pathname: '/Trip/Addtrip/Saveplan',
                                        tripAdded: this.state.tripAdded
                                    }}>
                                        <button type="button" className="btn btn-primary waves-effect waves-light"
                                            style={{ width: '100%', backgroundColor: '#224AAC', borderColor: '#224AAC' }}>
                                            <span className="font-style-16">
                                                {this.props.t("Next")}
                                            </span>
                                        </button>
                                    </Link>
                                }
                            </Col>
                        </Row>
                    </Container>
                    {/* <Card style={{ width: '100%', backgroundColor: '#F0F0F0' }}>
                        <CardBody>
                            <Container fluid>
                                <Row style={{ justifyContent: 'flex-end' }}>
                                    <Col xl={2} lg={3} md={6} sm={12} xs={12} >
                                        {this.state.tripAdded.length < 3 ?
                                            <Link to={{
                                                pathname: '/Trip/Addtrip/Selectregion',
                                                tripAdded: this.state.tripAdded
                                            }}>
                                                <Button style={{ width: '100%', backgroundColor: '#E7E7E7', borderColor: '#AAAAAA' }}><a style={{ color: '#8C8C8C' }}>{this.props.t('Back')}</a></Button>
                                            </Link>
                                            :
                                            <Link to={'/Trip/Addtrip/Selectregion'}>
                                                <Button style={{ width: '100%', backgroundColor: '#E7E7E7', borderColor: '#AAAAAA', }}><a style={{ color: '#8C8C8C' }}>{this.props.t('Back')}</a></Button>

                                            </Link>
                                        }
                                    </Col>
                                    <Col xl={2} lg={3} md={6} sm={12} xs={12} >

                                        {this.state.tripAdded.length < 3 ?
                                            <Button className="mt-button-1" style={{ width: '100%', backgroundColor: '#E7E7E7', borderColor: '#AAAAAA' }} onClick={() => { this.alert() }}><a style={{ color: '#8C8C8C' }}>{this.props.t('Next')}</a></Button>
                                            :
                                            <Link to={{
                                                pathname: '/Trip/Addtrip/Saveplan',
                                                tripAdded: this.state.tripAdded
                                            }}>
                                                <Button className="mt-button-1" style={{ width: '100%', backgroundColor: '#224AAC', borderColor: '#224AAC' }}>{this.props.t('Next')}</Button>

                                            </Link>
                                        }

                                    </Col>
                                </Row>
                            </Container>
                        </CardBody>
                    </Card> */}
                    {/* <Col style={{ paddingTop: 20 }}>
                        <h3 style={{ textAlign: 'center' }}>         ขั้นตอนที่ 3 เลือกประเภทการท่องเที่ยวที่คุณสนใจ</h3>
                        <div style={{ paddingTop: 10 }}>
                            <Progress striped animated color="success" value={75} >
                                75%
                            </Progress>
                        </div>
                    </Col> */}
                    <Row className="justify-content-center mt-lg-5 row">
                        <Cardfocus data={data} cardSelected={this.cardSelected} />
                        <Row style={{ padding: 50, justifyContent: 'center' }} >
                            <h4 style={{ color: '#224AAC' }}> {this.props.t('Step')} 3 / 3 </h4><h4 style={{ marginLeft: 15, color: 'black' }}>{this.props.t('Select the type of place you are interested in travel plans. (Can choose more than 1 type)')}</h4>
                        </Row>
                    </Row>
                </div>
            </React.Fragment>
        )
    }
}
export default (
    (withTranslation()(Groupslides))
);
