import React, { Component } from 'react';

import { GoogleApiWrapper } from "google-maps-react";
import { connect } from "react-redux";
// import Gallery from 'react-photo-gallery';
import { withTranslation } from 'react-i18next';

import '../../../assets/scss/custom.scss'
// import StarRatings from 'react-star-ratings';

import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody, CardImg } from "reactstrap";


// import img7 from "../../../assets/images/small/img-19.jpg";


// import TripModal from "./TasksTrip/TripModal"


// import iconfood from "../../../assets/images/icon/food.svg"
// import iconriver from "../../../assets/images/icon/river.svg"
// import iconshop from "../../../assets/images/icon/shop.svg"
// import icontemple from "../../../assets/images/icon/temple.svg"

import { IMAGE_ENDPOINT } from '../../../assets/api'

const LoadingContainer = props => <div>Loading...</div>;

class Cardtrip extends Component {
    constructor(props) {
        super(props);

        console.log(this.props)
        this.state = {
            loadmore: 0,
            images: this.props.images,
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            step1: true,
            step2: false,
            thumbContainer: {
                width: '100%',
                height: 400,
            },
            thumbnail: {
                flex: 1,
                width: undefined,
                height: undefined,
                resizeMode: 'cover'
            },
            tripInfo: [],

            visible: false,

            modal_scroll: false,
            isLoading: true,


        }

        this.onMarkerClick = this.onMarkerClick.bind(this);

        this.tog_scroll = this.tog_scroll.bind(this);
    }



    onMarkerClick(props, marker, e) {
        alert("You clicked in this marker");
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    tog_scroll() {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll
        }));
        this.removeBodyCss();
    }

    show() {
        this.setState({ visible: true });
    }
    hide() {
        this.setState({ visible: false });
    }

    componentDidMount() {

        this.setState({
            tripInfo: this.props.data,
            isLoading: false
        }, () => { console.log(this.state.tripInfo) })

    }


    render() {
        if (this.state.isLoading === true) {
            return (
                <></>
            )
        }
        else {


            // const photos5 = [
            //     {
            //         src: IMAGE_ENDPOINT + this.state.tripInfo.tripInfo[0] === 'undefined' ? IMAGE_ENDPOINT + "/assets/images/no_photo.png" : IMAGE_ENDPOINT + this.state.tripInfo.tripInfo[0].dayInfo[0].cards[0].content.photo,
            //         width: 4,
            //         height: 5,
            //     },
            //     {
            //         src: img7,
            //         width: 4,
            //         height: 5
            //     },
            //     {
            //         src: IMAGE_ENDPOINT + this.state.tripInfo.tripInfo[0].dayInfo[0].cards[0].content.photo === 'undefined' ? IMAGE_ENDPOINT + "/assets/images/no_photo.png" : IMAGE_ENDPOINT + this.state.tripInfo.tripInfo[0].dayInfo[0].cards[0].content.photo,
            //         width: 4,
            //         height: 5
            //     },
            //     {
            //         src: img7,
            //         width: 4,
            //         height: 5
            //     },
            // ]
            //     ;



            let image = [], arr = this.state.tripInfo.tripInfo
            if (arr.length < 0 || arr.length === 0) {
                return <div></div>
            }
            else if (arr.length > 0) {
                for (var i = 0; i < arr.length; i++) {
                    for (var j = 0; j < arr[i].dayInfo.length; j++) {
                        for (var k = 0; k < arr[i].dayInfo[j].cards.length; k++) {
                            image.push(arr[i].dayInfo[j].cards[k].content.photo)
                        }
                    }
                }
            }


            return (

                <React.Fragment>
                    <Card >
                        <CardBody>
                            <Row>
                                <Col xl={4} sm={12} lg={12} md={12} xs={12} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'space-evenly' }}>
                                    {/* <Row>
                                        <Col xl={12} sm={12} lg={12} md={12} xs={12} style={{ alignSelf: 'center' }}> */}
                                    {/* <Row> */}
                                    <div style={{ display: 'flex' }}>
                                        <Col xl={6} sm={6} lg={6} md={6} xs={6} style={{ padding: 3 }}>
                                            <div>
                                                <CardImg src={IMAGE_ENDPOINT + image[0]} style={{ height: '150px', borderRadius: 5, objectFit: 'cover', minHeight: '150px', maxHeight: '150px' }} ></CardImg>
                                            </div>
                                            <div style={{ paddingTop: 10 }}>
                                                <CardImg src={image[3] === undefined ? IMAGE_ENDPOINT + image[0] : IMAGE_ENDPOINT + image[2]} style={{ borderRadius: 5, objectFit: 'cover', minHeight: '100px', maxHeight: '100px' }}></CardImg>
                                            </div>
                                        </Col>
                                        <Col xl={6} sm={6} lg={6} md={6} xs={6} style={{ padding: 3 }}>
                                            <div>
                                                <CardImg src={image[3] === undefined ? IMAGE_ENDPOINT + image[0] : IMAGE_ENDPOINT + image[3]} style={{ borderRadius: 5, objectFit: 'cover', minHeight: '100px', maxHeight: '100px' }} ></CardImg>
                                            </div>
                                            <div style={{ paddingTop: 10 }}>
                                                <CardImg src={image[3] === undefined ? IMAGE_ENDPOINT + image[0] : IMAGE_ENDPOINT + image[3]} style={{ height: '150px', borderRadius: 5, objectFit: 'cover', minHeight: '150px', maxHeight: '150px' }}></CardImg>
                                            </div>
                                        </Col>
                                    </div>
                                    {/* </Row> */}
                                    {/* </Col> */}
                                    {/* </Row> */}
                                </Col>
                                <Col xl={8} sm={12} lg={12} md={12} xs={12}>
                                    <Row style={{ display: 'flex', flexDirection: 'flex', flexWrap: 'warp', alignContent: 'space-between', paddingLeft: '0.5rem' }}>
                                        <div style={{ display: 'block' }}>
                                            <h6 className="font-style-24 cl-black">{this.state.tripInfo.name}</h6>
                                            <h6 className="font-style-16 cl-gray" style={{ marginTop: '1rem' }}>{this.props.t('Trip Information')}</h6>
                                            <h6 className='font-style-16 cl-black'>{this.state.tripInfo.trip_details}</h6>
                                        </div>
                                    </Row>
                                    <Row style={{ display: 'flex', flexDirection: 'flex', flexWrap: 'warp', alignContent: 'space-between', paddingLeft: '0.5rem', paddingTop: '2rem' }}>
                                        <div className="media-body">
                                            <h6 className="mb-1 font-style-16 cl-gray"> {this.props.t('Time Period')} :</h6>
                                            <h6 className="font-style-16 cl-blue">{this.state.tripInfo.tripInfo.length} วัน</h6>
                                        </div>
                                        <div className="media-body">
                                            <h6 className="mb-1 font-style-16 cl-gray"> {this.props.t('Province')} :</h6>
                                            <h6 className="font-style-16 cl-blue">{this.state.tripInfo.province}</h6>
                                        </div>
                                    </Row>
                                    <hr className="hr-style"></hr>
                                    {/* <div style={{ borderTop: '1px dashed #DFDFDF' }}></div> */}
                                    <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Col xl={4} lg={3} md={6} sm={12} xs={12} style={{ textAlign: 'end' }}>
                                            <Link to={{ pathname: '/Tripdetail', params: this.state.tripInfo }}>
                                                {/* <Link to="ideas" params={{ testvalue: "hello" }}>Create Idea</Link> */}
                                                <button type="button" className="btn btn-primary waves-effect waves-light resize-button" style={{ background: 'transparent', borderColor: '#c2c2c2' }} >
                                                    <span className="font-style-16 cl-gray">{this.props.t('See More Details')}</span>
                                                </button>
                                            </Link>
                                        </Col>
                                        <Col xl={4} lg={3} md={6} sm={12} xs={12} className="mr-bt">
                                            <button type="button" className="btn btn-primary waves-effect waves-light" style={{ width: '100%', background: 'transparent', borderColor: '#2249AC' }}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    window.location.href = '/Joborderaddjb?tour_id=' + this.state.tripInfo.id;
                                                }} >
                                                <img className="" src={'/assets/images/icon/plus.svg'} style={{ marginRight: '0.5rem', marginTop: '-0.1rem' }} alt=""></img>
                                                <span className="font-style-16 cl-blue">{this.props.t('Choose This Trip')}</span>
                                            </button>
                                        </Col>
                                    </Row>
                                    {/* <Row> */}
                                    {/* <Col xl={12} lg={12} sm={12} md={12} xs={12} > */}
                                    {/* </Col> */}
                                    {/* </Row> */}
                                    {/* <Row className="mt-1" style={{ height: "auto" }}>
                                        <Col xl={12} lg={12} sm={12} md={12} xs={12}> */}
                                    {/* {this.state.tripInfo.tripInfo.map((e, i) => {
                                                return (
                                                    <div key={i} className="row" >
                                                        <div className="col-lg-1">
                                                            <p className="normal-text pd-bt-15">วันที่ {e.day}</p>
                                                        </div>
                                                        <div className="col-lg-11">
                                                            {e.dayInfo.map((h, j) => {
                                                                return (
                                                                    <div key={j} >
                                                                        <div className="row">
                                                                            {h.title} -
                                                                            {h.cards.map((k, t) => {
                                                                                return (
                                                                                    <div key={t} >
                                                                                        <p className="normal-text pd-bt-15" >{k.content.name}</p>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>)
                                            })} */}
                                    {/* <p className='textlinenumber' style={{ marginBottom: 0 }}>{this.state.tripInfo.trip_details}</p> */}
                                    {/* </Col>
                                    </Row>*/}
                                    {/* <Row className="mt-2" style={{ alignItems: 'center' }}>
                                        <Col xl={4} lg={4} sm={6} md={4} xs={5} style={{ alignSelf: 'center' }}>
                                            <h5 style={{ fontWeight: 'bold' }}>{this.props.t('Tourism Activities')}</h5>
                                        </Col>
                                        <Col xl={8} lg={8} sm={6} md={8} xs={7}>
                                            <Row >
                                                <CardImg src={iconfood} alt="" className="ml-3" style={{ width: '2rem' }} />
                                                <CardImg src={iconriver} alt="" className="ml-3" style={{ width: '2rem' }} />
                                                <CardImg src={iconshop} alt="" className="ml-3" style={{ width: '2rem' }} />
                                                <CardImg src={icontemple} alt="" className="ml-3" style={{ width: '2rem' }} />
                                            </Row>
                                        </Col>
                                    </Row> */}
                                    {/* <Col xl={12}> */}
                                    {/* <Col className="align-col" xl={4} lg={4} md={4} sm={12} xs={12} style={{ display: 'flex', borderRight: 'solid 1px rgba(0, 0, 0, 0.1)' }}>
                                            <div className="media time-period">
                                                <div className="mr-2 mt-1 icon-calendar" >
                                                    <i className="fas fa-calendar-week fa-2x" style={{ color: '#224AAC' }}></i>
                                                </div>
                                                <div className="media-body">
                                                    <h6 className="mb-1" style={{ fontWeight: 'bold' }}> {this.props.t('Time Period')} </h6>
                                                    <h6 className="detail-date">{this.state.tripInfo.tripInfo.length} วัน</h6>
                                                </div>
                                            </div>
                                        </Col> */}
                                    {/* <Col className="align-col" xl={4} lg={4} md={4} sm={12} xs={12} style={{ display: 'flex', borderRight: 'solid 1px rgba(0, 0, 0, 0.1)' }}>
                                            <div className="media province">
                                                <div className="mr-2 mt-1 icon-marker" >
                                                    <i className="fas fa-map-marker-alt fa-2x" style={{ color: '#224AAC' }}></i>
                                                </div>
                                                <div className="media-body">
                                                    <h6 className="mb-1 align-text" style={{ fontWeight: 'bold' }}> {this.props.t('Province')} </h6>
                                                    <h6 className="detail-province">{this.state.tripInfo.province}</h6>
                                                </div>
                                            </div>
                                        </Col> */}
                                    {/* <Col className="align-col" xl={4} lg={4} md={4} sm={12} xs={12} style={{ display: 'flex' }}> */}
                                    {/* <div className="media tourist">
                                                            <div className="mr-2 mt-1 icon-people" >
                                                                <i className="fas fa-user-friends fa-2x" style={{ color: '#224AAC' }}></i>
                                                            </div>
                                                            <div>
                                                                <h6 className="mb-1 align-text-1" style={{ fontWeight: 'bold' }}> {this.props.t('Recommended Tourists')} </h6>
                                                                <h6 className="detail-tourist">{this.state.tripInfo.tripMinMax.split("|")[0]}-{this.state.tripInfo.tripMinMax.split("|")[1]} คน</h6>
                                                            </div>
                                                        </div> */}
                                    {/* <p onClick={this.tog_scroll}
                                                data-toggle="modal"
                                                style={{ color: 'gray', marginBottom: 0, cursor: 'pointer' }}>
                                            </p> */}
                                    {/* <button type="button" className="btn btn-primary waves-effect waves-light" style={{ width: '100%', background: '#c2c2c2', borderColor: '#c2c2c2' }}
                                                {() => { this.tog_scroll() }}
                                            >
                                                <i className="fas fa-info-circle pr-1 mr-2" aria-hidden="true"></i>{this.props.t('See More Details')}
                                            </button> */}
                                    {/* </Col> */}
                                </Col>
                                {/* <Col className="mt-1" xl={3} >
                                    <Row style={{ justifyContent: 'flex-end' }}> */}
                                {/* <Link to={{
                                                        pathname: '/Joborderaddjb?tour_id='+this.state.tripInfo.id,
                                                        // tour_id: this.state.tripInfo.id
                                                    }}> */}
                                {/* <button type="button" className="btn btn-primary waves-effect waves-light" style={{ width: '100%' }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                window.location.href = '/Joborderaddjb?tour_id=' + this.state.tripInfo.id;
                                            }} >
                                            <i className="fa fa-plus-circle pr-1 mr-2" aria-hidden="true"></i>{this.props.t('Choose This Trip')}
                                        </button> */}
                                {/* </Link> */}
                                {/* </Row> */}
                                {/* </Col>
                                        </Row> */}
                                {/* </Col> */}

                                {/* </Col> */}
                            </Row>
                        </CardBody>
                    </Card>
                    {/* {this.state.modal_scroll == true ? <TripModal show={true} tripInfo={this.state.tripInfo}></TripModal> : null} */}
                </React.Fragment >
            );
        }
    }
}

export default connect(
    null,
    {}
)(
    GoogleApiWrapper({
        apiKey: "AIzaSyD0AIarEA25PY-NvHNsZDAVqM3qa8krHfs",
        libraries: ["visualization"],
        LoadingContainer: LoadingContainer,
        v: "3"
    })(withTranslation()(Cardtrip))
);