import React from 'react';
// import DataGrid, {
//     Column,
//     SearchPanel,
//     FilterRow,
//     HeaderFilter,
//     GroupPanel,
//     Pager, Paging,
//     FilterPanel,
//     FilterBuilderPopup,
//     Scrolling, RemoteOperations, Grouping, Export
// } from 'devextreme-react/data-grid';
import { Button, Container, Form, Card, CardBody, Row, Col, Input, TabContent, TabPane, Label, CardImg, CardImgOverlay, DropdownToggle, DropdownMenu, DropdownItem, Modal, ButtonDropdown, FormGroup } from "reactstrap";
import classnames from 'classnames';
import './../../../../assets/scss/custom-respondsive.scss'
// import CustomStore from 'devextreme/data/custom_store';
import Lottie from "react-lottie";
import * as loadingData from "../../../Joborder/men-component/loading.json";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import service from './data.js';
import { API_ENDPOINT, API_DEVEXPRESS_ENDPOINT } from "../../../../assets/api";
import { IMAGE_ENDPOINT } from "../../../../assets/api";
import { Link } from "react-router-dom";
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import GridLoader from "react-spinners/GridLoader";
import { css } from "@emotion/core";
// import pin from "../../../../assets/images/icon/pin-drop.svg"
// import file from "../../../../assets/images/icon/file-text.svg"
import { withTranslation } from 'react-i18next';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import PlaceGrid from '../PlaceGrid';
import '../../../../assets/scss/custom.scss'
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
// const store = new CustomStore({
//     key: 'id',
//     load: function (loadOptions) {
//         let isNotEmpty = (value) => {
//             return value !== undefined && value !== null && value !== '';
//         }
//         let params = '?';
//         [
//             'skip',
//             'take',
//             'requireTotalCount',
//             'requireGroupCount',
//             'sort',
//             'filter',
//             'totalSummary',
//             'group',
//             'groupSummary'
//         ].forEach(function (i) {
//             if (i in loadOptions && isNotEmpty(loadOptions[i])) { params += `${i}=${JSON.stringify(loadOptions[i])}&`; }
//         });
//         params = params.slice(0, -1);
//         let url = `${API_DEVEXPRESS_ENDPOINT}devexpress/placeslist${params}&lang=th`
//         return fetch(url)
//             .then(response => response.json())
//             .then((data) => {
//                 return {
//                     data: data.data,
//                     totalCount: data.totalCount,
//                     summary: data.summary,
//                     groupCount: data.groupCount
//                 };
//             })
//             .catch(() => { throw 'Data Loading Error'; });
//     }
// });
class Tableplacedv extends React.Component {

    constructor(props) {
        super(props);
        this.employees = service.getEmployees();
        this.state = {
            activeTab: '1',
            Orders: [],
            isLoading: true,
            isLoadingCategory: true,
            placeSelected: "",
            modalHeader: "",
            modalphoto: "",
            pageSkip: 0,
            pageTake: 4,
            search: {
                value: "",
                timer: 0
            },
            filter: {
                name: "",
                province: "",
                district: "",
                sub_district: "",
                status: ""
            },
            isSearching: false,
            loadingData: false,
            category: [],
            categorySelected: []
        }

        this.auth = JSON.parse(localStorage.getItem('authToken'))
        this.user = JSON.parse(localStorage.getItem('authUser'))
        this.getPlaces = this.getPlaces.bind(this);
        this.Operation = this.Operation.bind(this);
        this.calculateCellphoto = this.calculateCellphoto.bind(this);
        this.search = this.search.bind(this);
        this.getCategory = this.getCategory.bind(this);
        this.checkBox = this.checkBox.bind(this);
        this.pageControl = this.pageControl.bind(this);

        this.searchenable = {}
    }
    checkBox(i, id) {
        console.log(id)
        let arr = this.state.category, arrSelect = this.state.categorySelected
        if ('isCheck' in arr[i]) {
            console.log('found')
            if (arr[i].isCheck === true) {
                arr[i] = { ...arr[i], isCheck: !arr[i].isCheck }
                arrSelect.splice(arrSelect.indexOf(id), 1)
                this.setState({
                    category: arr,
                    categorySelected: arrSelect
                }, () => {
                    this.search()
                    console.log(this.state.categorySelected)
                })
            }
            else {
                arr[i] = { ...arr[i], isCheck: !arr[i].isCheck }
                arrSelect.push(id)
                this.setState({
                    category: arr,
                    categorySelected: arrSelect
                }, () => {
                    this.search()
                    console.log(this.state.categorySelected)
                })
            }

        }
        else {
            console.log('Notfound')
            arr[i] = { ...arr[i], isCheck: true }
            arrSelect.push(id)
            this.setState({
                category: arr,
                categorySelected: arrSelect
            }, () => {
                this.search()
                console.log(this.state.category)
            })
        }

    }

    async search(e) {
        console.log(e)
        let name = ""
        if (e !== undefined) {
            name = e.target.value
        }
        this.setState({
            isSearching: true
        }, async () => {
            await clearTimeout(this.searchenable);
            this.searchenable = setTimeout(async () => {
                // Do the ajax stuff
                console.log(this.state.categorySelected)
                var response = await fetch(API_ENDPOINT + 'api/v2/web/place/filter?skip=' + this.state.pageSkip + '&take=' + this.state.pageTake + '&categorys=[' + this.state.categorySelected + ']&name=' + name, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: this.auth.data.type + ' ' + this.auth.data.token,

                    }
                });
                console.log(response)
                var responseJson = await response.json();
                console.log(responseJson)
                this.setState({
                    Orders: responseJson,
                    isSearching: false
                }, () => {
                    console.log(this.state.Orders)
                    console.log(this.state.isSearching)
                })
            }, 1000); // Will do the ajax stuff after 1000 ms, or 1 s
        })
        console.log(name)




    }
    pageControl(action) {
        this.setState({
            isLoading: true
        }, () => {
            let skip = this.state.pageSkip, take = this.state.pageTake
            console.log(action)
            if (action === 'next') {
                this.setState({
                    pageSkip: parseInt(skip) + 4,
                    pageTake: take
                }, () => {
                    console.log(this.state)
                    this.getPlaces()
                })
            }
            if (action === 'back') {
                this.setState({
                    pageSkip: parseInt(skip) - 4,
                    pageTake: take
                }, () => {
                    console.log(this.state)
                    this.getPlaces()
                })

            }
            else
                return
        })

    }
    async getCategory() {
        var response = await fetch(API_ENDPOINT + 'api/v2/web/place/category/get?lang=th', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: this.auth.data.type + ' ' + this.auth.data.token,

            }
        });
        var responseJson = await response.json();
        console.log(responseJson)
        this.setState({
            category: responseJson,
            isLoadingCategory: false
        })
    }
    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    async getPlaces() {
        var url = API_DEVEXPRESS_ENDPOINT + 'web/places?lang=th'
        // var dev_url =
        //     "https://drjotest-api.melivecode.com/api/v2/web/place/get?take=100&skip=0&lang=th";
        var response = await fetch(url, {
            method: 'GET'
        });
        var responseJson = await response.json();
        console.log(responseJson)
        this.setState({
            Orders: responseJson.data,
            isLoading: false
        })
        console.log(this.state.Orders)
    }
    // async getPlaces() {
    //     console.log(this.state.pageSkip)
    //     console.log(this.state.pageTake)
    //     var response = await fetch(API_ENDPOINT + 'api/v2/web/place/get?lang=th&take=' + this.state.pageTake + '&skip=' + this.state.pageSkip, {
    //         method: 'GET',
    //         headers: {
    //             Accept: 'application/json',
    //             'Content-Type': 'application/json',
    //             Authorization: this.auth.data.type + ' ' + this.auth.data.token,

    //         }
    //     });
    //     var responseJson = await response.json();
    //     console.log(responseJson)

    //     this.setState({
    //         Orders: responseJson,
    //         isLoading: false
    //     }, () => {
    //         console.log(this.state.Orders)
    //     })
    // }
    componentDidMount() {
        // console.log(auth)
        // this.getPlaces()
    }
    calculateCellValue(Orders) {
        return [Orders.name];
    }
    calculateCellphoto(cellData) {
        return <img style={{ borderRadius: '50%', width: 50, height: 50, verticalAlign: 'middle' }} src={IMAGE_ENDPOINT + String(cellData.value).split(';')[0]} alt=""></img>
    }

    renderMyCommand(cellData) {
        console.log(cellData)
        return (
            <div>
                <Link to={{
                    pathname: '/places/detail',
                    placeDetail: cellData.data

                }}>
                    <Button type="button" color="primary" className="btn-sm btn-rounded">
                        View Details
                    </Button>

                </Link>
            </div>

        )
    }
    Operation(data) {
        return (
            <div>
                <div><i onClick={() => {
                    this.props.history.push({
                        pathname: '/places/detail/edit',
                    })
                }} style={{ color: 'gray', cursor: 'pointer' }} className="mdi mdi-pencil font-size-18 mr-3"></i><br></br></div>

            </div>


        )
    }

    filterHandle(target, value) {
        let obj = this.state.filter
        if (target === 'clear') {
            obj.name = value
            obj.province = value
            obj.district = value
            obj.sub_district = value
            document.getElementById('name').value = ""
            document.getElementById('province').value = ""
            document.getElementById('district').value = ""
            document.getElementById('sub_district').value = ""
            document.getElementById('status_select').value = ""
            this.setState({
                filter: obj
            }, () => {
                // this.getUser()
            })
        }
        else {

            obj[target] = value
            this.setState({
                filter: obj,
                loadingData: false
            }, () => {
                // console.log(this.state.filter)
            })
        }

    }

    onExporting(e) {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
            });
        });
        e.cancel = true;
    }


    render() {


        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Row>
                            <Col xs="12">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Breadcrumbs title="Place Management" breadcrumbItem={this.props.t("Place Management")} />
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <button type="button" className="btn btn-primary waves-effect waves-light" onClick={event => window.location.href = '/places/add'} style={{ width: '100%', backgroundColor: '#224AAC', borderColor: '#224AAC' }}>
                                            <i className="fas fa-plus-circle mr-3"></i>
                                            <span className="font-style-16"> {this.props.t('Add New Place')}</span>
                                        </button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {/*<ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                                    <NavItem>
                                        <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTab('1'); }} >
                                            <button type="button" outline className="btn btn-primary waves-effect waves-light" style={{borderRadius: 20}}>
                                                <i className="fa fa-th-large" aria-hidden="true"></i></button>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggleTab('2'); }} >
                                            <button type="button" outline className="btn btn-primary waves-effect waves-light" style={{ borderRadius: 20 }}>
                                                <i className="fa fa-bars" aria-hidden="true"></i></button>
                                        </NavLink>
                                    </NavItem>
                                </ul>*/}
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1" id="all-order">
                                <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                    <CardBody>
                                        {/* <Row className="mb-4">
                                            <Col xl={8} lg={8} sm={12} md={12} xs={12}>

                                            </Col>
                                            <Col xl={4} lg={4} sm={12} md={12} xs={12} style={{ textAlign: 'end', paddingLeft: '0rem' }}> */}
                                        {/* <Button style={{ width: 200, backgroundColor: '#224AAC', borderColor: '#224AAC' }} type="button" className="waves-effect waves-light mr-3" onClick={event => window.location.href = '/places/add'}>
                                                    <i className="fas fa-plus-circle mr-3"></i>
                                                    {this.props.t('Add New Place')}
                                                </Button> */}
                                        {/* <Link className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTab('1'); }} >
                                            <button type="button" outline className="btn btn-primary waves-effect waves-light " style={{ borderRadius: 20 }}>
                                                <i className="fa fa-bars" aria-hidden="true" color='white'></i></button>
                                        </Link>
                                        <Link className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggleTab('2'); }} >
                                            <button type="button" outline className="btn  waves-effect waves-light ml-3" style={{ borderRadius: 20 }}>
                                                <i className="fa fa-th-large" aria-hidden="true"></i></button>
                                        </Link> */}
                                        {/* </Col>
                                            <hr></hr>
                                        </Row> */}
                                        <Row className="mb-4">
                                            <Col xl={3} lg={6} md={6} sm={12} xs={12}>
                                                <FormGroup className="mt-3 mb-0">
                                                    <Label>ค้นหาจากชื่อสถานที่ :</Label>
                                                    <Input type="text" id="name" className="form-control" placeholder="ชื่อสถานที่"
                                                        onChange={(e) => {
                                                            if (typeof this.timeout !== 'undefined') {
                                                                try {
                                                                    clearTimeout(this.timeout);
                                                                } catch (err) {

                                                                }
                                                            }
                                                            this.timeout = setTimeout(async () => {
                                                                this.filterHandle('name', e.target.value)
                                                            }, 2000);
                                                        }} />
                                                </FormGroup>
                                            </Col>
                                            <Col xl={3} lg={6} md={6} sm={12} xs={12}>
                                                <FormGroup className="mt-3 mb-0">
                                                    <Label>ค้นหาจากจังหวัด :</Label>
                                                    <Input type="text" id="province" className="form-control" placeholder="จังหวัด"
                                                        onChange={(e) => {
                                                            // this.setState({
                                                            //     loadingData: true
                                                            // }, () => {
                                                            //     if (typeof this.timeout !== 'undefined') {
                                                            //         try {
                                                            //             clearTimeout(this.timeout);
                                                            //         } catch (err) {

                                                            //         }
                                                            //     }
                                                            //     this.timeout = setTimeout(async () => {
                                                            //         this.filterHandle('province', e.target.value)
                                                            //     }, 2000);
                                                            // })
                                                            if (typeof this.timeout !== 'undefined') {
                                                                try {
                                                                    clearTimeout(this.timeout);
                                                                } catch (err) {

                                                                }
                                                            }
                                                            this.timeout = setTimeout(async () => {
                                                                this.filterHandle('province', e.target.value)
                                                            }, 2000);
                                                        }} />
                                                </FormGroup>
                                            </Col>
                                            <Col xl={3} lg={6} md={6} sm={12} xs={12}>
                                                <FormGroup className="mt-3 mb-0">
                                                    <Label for="from">สถานะ</Label>
                                                    <Input type="select" id="status_select" className="form-control"
                                                        // value={this.state.step_1.inbound_type}
                                                        onChange={(e) => {
                                                            this.filterHandle('status', e.target.value)
                                                        }}
                                                    >
                                                        <option value="">เลือกสถานะ</option>
                                                        <option value="new">ใหม่</option>
                                                        <option value="approved">อนุมัติ</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <hr></hr>
                                        <Row style={{ justifyContent: 'flex-end' }}>
                                            <Col xl={2} lg={3} md={6} sm={12} xs={12} style={{ display: 'inline-flex', overflow: 'auto' }}>
                                                {/* <FormGroup className="mb-0">
                            <Button className="" onClick={() => {
                              this.filterHandle('submit', '')
                            }} style={{ width: '100%', backgroundColor: '#224AAC', borderColor: '#224AAC' }}>{this.props.t('Search')}</Button>
                          </FormGroup> */}
                                                {this.state.loadingData === true &&
                                                    <Row style={{ alignSelf: 'center' }}>
                                                        <Lottie options={defaultOptions} height={35} width={35} style={{ marginRight: 10 }} />
                                                        <span style={{ alignSelf: 'center' }}>กำลังค้นหา</span>
                                                    </Row>
                                                }
                                            </Col>
                                            <Col xl={2} lg={3} md={6} sm={12} xs={12} >
                                                <FormGroup className="mb-0">
                                                    <button type="button" className="mt-1r btn btn-primary waves-effect waves-light" onClick={() => {
                                                        this.filterHandle('clear', '')
                                                    }} style={{ width: '100%', background: 'transparent', borderColor: '#c2c2c2' }}>
                                                        <span className="font-style-16 cl-gray">{this.props.t('Clear')}</span></button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                                    <CardBody>
                                        <Form>
                                            {/* <DataGrid
                                                dataSource={store}
                                                showBorders={true}
                                                onExporting={(e) => { this.onExporting(e) }}
                                            >
                                                <RemoteOperations groupPaging={true} />
                                                <Grouping autoExpandAll={false} />
                                                <GroupPanel visible={true} />
                                                <SearchPanel visible={true}
                                                    width={240}
                                                    placeholder="Search..." />
                                                <Column dataField={"photo"}
                                                    width={120}
                                                    cellRender={this.calculateCellphoto}
                                                    alignment='center'
                                                />
                                                <Column dataField={"name"}
                                                    calculateCellValue={this.calculateCellValue}
                                                />
                                                <Column dataField="province_name" />
                                                <Column dataField="district_name" />
                                                <Column dataField="sub_district_name" />

                                                <Column dataField="Detail"
                                                    type="buttons"

                                                    cellRender={this.renderMyCommand}>


                                                </Column>

                                                <Paging defaultPageSize={20} />
                                                <Pager
                                                    showPageSizeSelector={true}
                                                    allowedPageSizes={[5, 10, 20]}
                                                    visible={true}
                                                    showInfo={true}
                                                />
                                                <Export enabled={true} allowExportSelectedData={false} />
                                            </DataGrid> */}
                                            <PlaceGrid filter={this.state.filter} user={this.user}></PlaceGrid>
                                        </Form>
                                    </CardBody>
                                </Card>



                                {/* {this.state.pageSkip == 0 ?
                                            <Col md={12} lg={12} onClick={() => { this.pageControl('next') }} >
                                                <p className="text-success" style={{ cursor: "pointer" }} >ถัดไป</p>
                                            </Col>
                                            :
                                            <div>
                                                <Row>
                                                    <Col md={6} lg={6} onClick={() => { this.pageControl('back') }} style={{ textAlign: 'left' }}>

                                                        <p className="text-success" style={{ cursor: "pointer" }} >ย้อนกลับ</p>
                                                    </Col>
                                                    <Col md={6} lg={6} onClick={() => { this.pageControl('next') }} style={{ textAlign: 'right', padding: 10 }}>
                                                        <p className="text-success" style={{ cursor: "pointer" }}>ถัดไป</p>
                                                    </Col>
                                                </Row>
                                            </div>
                                        } */}
                            </TabPane>
                            <TabPane tabId="2" id="processing">
                                <Row className="mb-4">
                                    <Col xl={8} lg={8} sm={12} md={12} xs={12}>

                                    </Col>
                                    <Col xl={4} lg={4} sm={12} md={12} xs={12} style={{ textAlign: 'end', paddingLeft: '0rem' }}>
                                        <Button style={{ width: 200, backgroundColor: '#224AAC', borderColor: '#224AAC' }} type="button" className="waves-effect waves-light mr-3" onClick={event => window.location.href = '/places/add'}>
                                            <i className="fas fa-plus-circle mr-3"></i>
                                            {this.props.t('Add New Place')}
                                        </Button>
                                        <Link className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTab('1'); }} >
                                            <button type="button" outline className="btn  waves-effect waves-light " style={{ borderRadius: 20 }}>
                                                <i className="fa fa-bars" aria-hidden="true"></i></button>
                                        </Link>



                                        <Link className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggleTab('2'); }} >
                                            <button type="button" outline className="btn btn-primary waves-effect waves-light ml-3" style={{ borderRadius: 20 }}>
                                                <i className="fa fa-th-large" aria-hidden="true"></i></button>
                                        </Link>

                                    </Col>
                                </Row>

                                <Row>


                                    <Col xl={3} lg={3} sm={12} md={12} xs={12}>

                                        <Card>
                                            <CardBody>



                                                <form className="app-search d-none d-lg-block">
                                                    <div className="position-relative">
                                                        <input type="text" className="form-control" placeholder={"Search ..."} onChange={(e) => { this.search(e) }} />
                                                        <span className="bx bx-search-alt"></span>
                                                    </div>
                                                </form>
                                                <div>
                                                    <h5 className=" mb-3" style={{ color: '#224AAC', fontWeight: 'bold' }}>{this.props.t('Place Category')}</h5>
                                                    {this.state.category.map((e, i) => {

                                                        return (
                                                            <div key={i}>
                                                                <div className="custom-control custom-checkbox mt-2" onClick={() => { this.checkBox(i, e.id) }} >
                                                                    <Input type="checkbox" className="custom-control-input" checked={e.isCheck} />
                                                                    <Label className="custom-control-label" htmlFor="productdiscountCheck1">{e.name}</Label>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                    <div className="custom-control custom-checkbox mt-2" style={{ fontSize: 15 }}>
                                                        <Input type="checkbox" value="0" className="custom-control-input" id="<2" />
                                                        <Label className="custom-control-label" htmlFor="<2">{this.props.t('Nature Travel')}</Label>
                                                    </div>
                                                    <div className="custom-control custom-checkbox mt-2" style={{ fontSize: 15 }}>
                                                        <Input type="checkbox" value="1-2" className="custom-control-input" id="1-2" />
                                                        <Label className="custom-control-label" htmlFor="1-2">{this.props.t('Short Trip')}</Label>
                                                    </div>
                                                    <div className="custom-control custom-checkbox mt-2" style={{ fontSize: 15 }}>
                                                        <Input type="checkbox" value="3-4" className="custom-control-input" id="3-4" />
                                                        <Label className="custom-control-label" htmlFor="3-4">{this.props.t('1-2 Days')}</Label>
                                                    </div>
                                                    <div className="custom-control custom-checkbox mt-2" style={{ fontSize: 15 }}>
                                                        <Input type="checkbox" value="3" className="custom-control-input" id="productdiscountCheck4" />
                                                        <Label className="custom-control-label" htmlFor="productdiscountCheck4">{this.props.t('3-4 Days')}</Label>
                                                    </div>
                                                    <div className="custom-control custom-checkbox mt-2" style={{ fontSize: 15 }}>
                                                        <Input type="checkbox" value=">4" className="custom-control-input" id=">4" />
                                                        <Label className="custom-control-label" htmlFor=">4">{this.props.t('Long Trip')}</Label>
                                                    </div>

                                                </div>
                                                <hr></hr>

                                                <h5 className="mt-4" style={{ color: '#224AAC', fontWeight: 'bold' }}>{this.props.t('Desired Region')}</h5>

                                                <div className="mail-list mt-1">
                                                    <div className="custom-control custom-checkbox mt-2" style={{ fontSize: 15 }}>
                                                        <Input type="checkbox" value="0" className="custom-control-input" id="productdiscountCheck6" />
                                                        <Label className="custom-control-label" htmlFor="productdiscountCheck6">{this.props.t('The Central Region')}</Label>
                                                    </div>
                                                    <div className="custom-control custom-checkbox mt-2" style={{ fontSize: 15 }}>
                                                        <Input type="checkbox" value="1" className="custom-control-input" id="productdiscountCheck7" />
                                                        <Label className="custom-control-label" htmlFor="productdiscountCheck7">{this.props.t('The North Region')}</Label>
                                                    </div>
                                                    <div className="custom-control custom-checkbox mt-2" style={{ fontSize: 15 }}>
                                                        <Input type="checkbox" value="2" className="custom-control-input" id="productdiscountCheck8" />
                                                        <Label className="custom-control-label" htmlFor="productdiscountCheck8">{this.props.t('The South Region')}</Label>
                                                    </div>
                                                    <div className="custom-control custom-checkbox mt-2" style={{ fontSize: 15 }}>
                                                        <Input type="checkbox" value="3" className="custom-control-input" id="productdiscountCheck9" />
                                                        <Label className="custom-control-label" htmlFor="productdiscountCheck9">{this.props.t('The Eastern Region')}</Label>
                                                    </div>
                                                    <div className="custom-control custom-checkbox mt-2" style={{ fontSize: 15 }}>
                                                        <Input type="checkbox" value="4" className="custom-control-input" id="productdiscountCheck10" />
                                                        <Label className="custom-control-label" htmlFor="productdiscountCheck10">{this.props.t('The Western Region')}</Label>
                                                    </div>

                                                </div>
                                                <hr></hr>
                                                {/* <hr></hr>
                                        <div>
                                            <h5 className="font-size-14 mb-4">รองรับนักท่องเที่ยว</h5>
                                            <br />
                                            <Nouislider range={{ min: 0, max: 600 }} tooltips={true} start={[100, 500]} connect />
                                        </div> */}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xl={9} lg={9} sm={12} md={12} xs={12}>
                                        <Row>
                                            <Col xl={8} lg={7} sm={7} md={7} xs={7} style={{ alignSelf: 'center' }}>
                                                <Row >
                                                    <Col xl={12} lg={12} sm={12} md={12} xs={12}>
                                                        <h5>{this.props.t('Show')} <span style={{ color: 'rgb(34, 74, 172)' }}>3</span> {this.props.t('from')} <span style={{ color: 'rgb(34, 74, 172)' }}>350</span> {this.props.t('Sort by date of Trip to')} <span style={{ color: 'rgb(34, 74, 172)' }}>{this.props.t('build a new - old')}</span> </h5>
                                                    </Col>

                                                </Row>
                                                {/* <h6> 3 จาก 350 แผนการท่องเที่ยวเรียงจาก วันที่สร้าง ใหม่ - เก่า</h6> */}
                                            </Col>
                                            {/* <Col xl={2} lg={2} sm={2} md={2} xs={2} style={{ alignSelf: 'center' }}>


                                                </Col> */}
                                            <Col xl={4} lg={5} sm={5} md={5} xs={5} style={{ textAlign: 'end', padding: 10 }}>
                                                <div className="btn-group mb-2" >
                                                    <ButtonDropdown

                                                        isOpen={this.state.drp_secondary}
                                                        toggle={() =>
                                                            this.setState({
                                                                drp_secondary: !this.state.drp_secondary
                                                            })
                                                        }
                                                    >
                                                        <DropdownToggle style={{ backgroundColor: 'rgba(255, 0, 0, -0.7)', borderColor: '#224AAC' }}
                                                            caret
                                                            className="btn-sm"
                                                        >
                                                            <div >
                                                                <label style={{
                                                                    cursor: 'pointer',
                                                                    color: "#224AAC", marginTop: 6
                                                                }}>   วันที่สร้าง ใหม่ - เก่า &nbsp;{" "}
                                                                    <i className="mdi mdi-chevron-down"></i>
                                                                </label>
                                                            </div>
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem>วันที่สร้าง เก่า - ใหม่</DropdownItem>
                                                            <DropdownItem divider />
                                                            <DropdownItem>ยอดนิยม</DropdownItem>
                                                        </DropdownMenu>
                                                    </ButtonDropdown>
                                                </div>{" "}
                                            </Col>
                                        </Row>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                            {this.state.isSearching === false ?
                                                <div>
                                                    <Row className="mt-2">
                                                        {this.state.Orders.map((e, i) => {
                                                            // console.log(this.state.Orders.length)
                                                            if (this.state.Orders.length > 0) {
                                                                return (
                                                                    <Col xs={12} sm={12} md={12} lg={6} xl={6} >
                                                                        <Card style={{ borderRadius: 5 }} key={i}>
                                                                            <CardImg top src={IMAGE_ENDPOINT + e.photo.split(';')[0]} style={{ objectFit: 'cover', borderRadius: '5px 5px 0px 0px', width: 'auto', height: 180 }} />
                                                                            <CardImgOverlay>
                                                                                <Row>
                                                                                    <Col lg={9}></Col>
                                                                                    <Col lg={3}   >
                                                                                        {/* <Row>
                                                                                    <Col lg={12}>
                                                                                        <div className="avatar-md " >
                                                                                            <span className="avatar-title rounded-circle  bg-light">
                                                                                                <Row>
                                                                                                    <Col lg={12} style={{ textAlign: 'center', padding: '5px' }}>
                                                                                                        <i className="fas fa-user-friends font-size-20 text-success"></i>
                                                                                                    </Col>
                                                                                                    <Col style={{ textAlign: 'center' }}>
                                                                                                        <p className="text-success" >ไม่หนาแน่น</p>
                                                                                                    </Col>
                                                                                                </Row>

                                                                                            </span>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row> */}
                                                                                    </Col>
                                                                                </Row>
                                                                            </CardImgOverlay>
                                                                            <CardBody>
                                                                                <Row>
                                                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                                        {e.name.length < 50 ?
                                                                                            <h5 style={{ fontWeight: 'bold' }}>{e.name}</h5>
                                                                                            :
                                                                                            <h5 style={{ fontWeight: 'bold' }}>{e.name.substring(0, 62)}...</h5>}
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row className="justify-content-center">
                                                                                    <Col xs={12} sm={12} md={6} lg={12} xl={6}>
                                                                                        <Row className="ml-0 mt-1">
                                                                                            <div>
                                                                                                <img
                                                                                                    src={IMAGE_ENDPOINT + "/logo/" + e.category_icon + '.svg'}
                                                                                                    alt=""
                                                                                                    width={20}
                                                                                                />
                                                                                            </div>

                                                                                            {e.category_name.length < 28 ?
                                                                                                <h6 className="mt-1 ml-2" style={{ fontWeight: 'bold' }}>{e.category_name}</h6>
                                                                                                :
                                                                                                <h6 className="mt-1 ml-2" style={{ fontWeight: 'bold' }}>{e.category_name.substring(0, 26)}...</h6>
                                                                                            }
                                                                                        </Row>
                                                                                    </Col>
                                                                                    <Col xs={12} sm={12} md={6} lg={12} xl={6}>
                                                                                        <Row className="ml-0 mt-1">
                                                                                            <div >
                                                                                                <img
                                                                                                    src={'/assets/images/icon/pin-drop.svg'}
                                                                                                    alt=""
                                                                                                    width={20}
                                                                                                    height={20}
                                                                                                />
                                                                                            </div>

                                                                                            <h6 className="mt-1 ml-2" style={{ fontWeight: 'bold' }}>จังหวัด{e.province_name}</h6>
                                                                                        </Row>
                                                                                    </Col>
                                                                                </Row>
                                                                                <hr></hr>
                                                                                <Row className="justify-content-center">
                                                                                    <Col className="mt-1" xs={12} sm={12} md={6} lg={6} xl={7}>
                                                                                        <Row className="ml-0">
                                                                                            <div >
                                                                                                <img
                                                                                                    src={'/assets/images/icon/file-text.svg'}
                                                                                                    alt=""
                                                                                                    width={20}
                                                                                                    height={20}
                                                                                                    className="w-icon"
                                                                                                />
                                                                                            </div>
                                                                                            <div>
                                                                                                <Link to={{
                                                                                                    pathname: '/places/detail',
                                                                                                    placeDetail: e
                                                                                                }}>
                                                                                                    <h6 className="mt-1 ml-2 f-text">{this.props.t('Click to view place details')}</h6>
                                                                                                </Link>
                                                                                            </div>

                                                                                        </Row>
                                                                                    </Col>
                                                                                    <Col xs={12} sm={12} md={6} lg={6} xl={5} style={{ textAlign: "right" }} >
                                                                                        <Link to="#" onClick={() => {
                                                                                            this.setState({
                                                                                                placeSelected: e,
                                                                                                modalHeader: e.name,
                                                                                                modalphoto: e.photo,
                                                                                            }, () => {
                                                                                                // console.log(this.state.placeSelected)
                                                                                                console.log(e.name)
                                                                                                this.tog_center()
                                                                                            })
                                                                                        }}
                                                                                            data-toggle="modal"
                                                                                            data-target=".bs-example-modal-center">

                                                                                            <button type="button" className="btn" style={{ backgroundColor: 'rgba(255, 0, 0, -0.7)', borderColor: '#224AAC', color: "#224AAC", width: '100%' }}>
                                                                                                <i className="fa fa-plus-circle pr-1 " aria-hidden="true"></i>{this.props.t('Edit place')}
                                                                                            </button>

                                                                                        </Link>
                                                                                        {/* <Link to="#" onClick={() => {
                                                                                            this.setState({
                                                                                                placeSelected: e,
                                                                                                modalHeader: e.name,
                                                                                                modalphoto: e.photo,
                                                                                            }, () => {
                                                                                                // console.log(this.state.placeSelected)
                                                                                                console.log(e.name)
                                                                                                this.tog_center()
                                                                                            })
                                                                                        }}
                                                                                            data-toggle="modal"
                                                                                            data-target=".bs-example-modal-center" className="btn btn-outline-primary waves-effect waves-light">
                                                                                            <i className="fas fa-pencil-alt"></i> แก้ไขสถานที่</Link> */}
                                                                                    </Col>


                                                                                    <Modal
                                                                                        isOpen={this.state.modal_center}
                                                                                        toggle={this.tog_center}
                                                                                        centered={true}

                                                                                    >
                                                                                        {/* {console.log(e)} */}
                                                                                        <div className="modal-header">
                                                                                            <h5 style={{ textAlign: 'left' }} className="modal-title mt-0">แจ้งเตือน</h5>
                                                                                            <button
                                                                                                type="button"
                                                                                                onClick={() =>
                                                                                                    this.setState({ modal_center: false })
                                                                                                }
                                                                                                className="close"
                                                                                                data-dismiss="modal"
                                                                                                aria-label="Close"
                                                                                            >
                                                                                                <span aria-hidden="true">&times;</span>
                                                                                            </button>
                                                                                        </div>
                                                                                        <div className="modal-body">
                                                                                            <Row style={{ paddingTop: 20 }}>
                                                                                                <Col lg={5} style={{ textAlign: 'center', padding: '10px' }}>
                                                                                                    <img className="rounded mr-2 rounded-circle avatar-lg" alt="Skote" width="100" src={IMAGE_ENDPOINT + this.state.modalphoto.split(';')[0]} />
                                                                                                </Col>
                                                                                                <Col lg={7} style={{ alignSelf: 'center', justifyContent: 'center', textAlign: 'center', fontWeight: 'bold' }}>
                                                                                                    <h5 >คุณต้องการไป </h5>
                                                                                                    <h5> {this.state.modalHeader} </h5>
                                                                                                    <h5>ในช่วงเวลาไหนบ้าง</h5>
                                                                                                </Col>
                                                                                            </Row>
                                                                                            {/* <Row>
                                                                                            <Col style={{ textAlign: 'center', padding: '10px' }}>
                                                                                                <img className="rounded mr-2" alt="Skote" width="100" src={modalsave} />
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row style={{ alignSelf: 'center', justifyContent: 'center' }}>
                                                                                            <h6>คุณต้องการไป {this.state.modalHeader} ในช่วงเวลาไหนบ้าง</h6>
                                                                                        </Row> */}
                                                                                            <Row style={{ paddingTop: 40, alignSelf: 'center', justifyContent: 'center', paddingLeft: 25, fontSize: 15, fontWeight: 'bold' }}>
                                                                                                <Col lg={3} sm={3} md={3}>
                                                                                                    <div className="custom-control custom-checkbox mb-3" >
                                                                                                        <input type="checkbox" className="custom-control-input" id="CustomCheck1" onChange={() => false} checked={this.state.morning} />
                                                                                                        <label className="custom-control-label" onClick={() => { this.setState({ morning: !this.state.morning }) }} >ช่วงเช้า</label>
                                                                                                    </div>

                                                                                                </Col>
                                                                                                <Col lg={3} sm={3} md={3}>
                                                                                                    <div className="custom-control custom-checkbox mb-3" >
                                                                                                        <input type="checkbox" className="custom-control-input" id="CustomCheck2" onChange={() => false} checked={this.state.afternoon} />
                                                                                                        <label className="custom-control-label" onClick={() => { this.setState({ afternoon: !this.state.afternoon }) }} >ช่วงบ่าย</label>
                                                                                                    </div>

                                                                                                </Col>
                                                                                                <Col lg={3} sm={3} md={3}>
                                                                                                    <div className="custom-control custom-checkbox mb-3">
                                                                                                        <input type="checkbox" className="custom-control-input" id="CustomCheck3" onChange={() => false} checked={this.state.evening} />
                                                                                                        <label className="custom-control-label" onClick={() => { this.setState({ evening: !this.state.evening }) }} >ช่วงเย็น</label>
                                                                                                    </div>
                                                                                                </Col>
                                                                                                <Col lg={3} sm={3} md={3}>
                                                                                                    <div className="custom-control custom-checkbox mb-3" >
                                                                                                        <input type="checkbox" className="custom-control-input" id="CustomCheck3" onChange={() => false} checked={this.state.allday} />
                                                                                                        <label className="custom-control-label" onClick={() => { this.setState({ allday: !this.state.allday }, () => { this.setState({ morning: this.state.allday, afternoon: this.state.allday, evening: this.state.allday }) }) }} >ทั้งวัน</label>
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <div className="modal-footer">
                                                                                                <button
                                                                                                    type="reset"
                                                                                                    className="btn btn-light waves-effect waves-light"
                                                                                                    onClick={() =>
                                                                                                        this.setState({ modal_center: false })
                                                                                                    }

                                                                                                >
                                                                                                    ยกเลิก
                                                                                                </button>
                                                                                                <button
                                                                                                    type="submit"
                                                                                                    className="btn btn-primary waves-effect waves-light"
                                                                                                    onClick={() => {
                                                                                                        this.confirmAdd()
                                                                                                    }
                                                                                                        // this.setState({ modal_center: false })
                                                                                                    }
                                                                                                // onClick={() =>
                                                                                                //     this.saveplace()
                                                                                                // }

                                                                                                >
                                                                                                    โอเค
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Modal>

                                                                                </Row>
                                                                            </CardBody>
                                                                        </Card>
                                                                    </Col>



                                                                )
                                                            }
                                                            else {
                                                                return (
                                                                    <Col sm={6} md={6} lg={6} >
                                                                        <p>   ไม่พบข้อมูล !!!</p>

                                                                    </Col>
                                                                )
                                                            }

                                                        })}
                                                    </Row>
                                                </div>

                                                :


                                                <GridLoader
                                                    css={override}
                                                    size={15}
                                                    margin={2}
                                                    color={"#123abc"}
                                                    loading={this.state.isSearching}
                                                />

                                            }


                                            {this.state.pageSkip === 0 ?
                                                <Col md={12} lg={12} onClick={() => { this.pageControl('next') }} >
                                                    <p className="text-success" style={{ cursor: "pointer" }} >ถัดไป</p>
                                                </Col>
                                                :
                                                <div>
                                                    <Row>
                                                        <Col md={6} lg={6} onClick={() => { this.pageControl('back') }} style={{ textAlign: 'left' }}>

                                                            <p className="text-success" style={{ cursor: "pointer" }} >ย้อนกลับ</p>
                                                        </Col>
                                                        <Col md={6} lg={6} onClick={() => { this.pageControl('next') }} style={{ textAlign: 'right', padding: 10 }}>
                                                            <p className="text-success" style={{ cursor: "pointer" }}>ถัดไป</p>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }




                                        </Col>
                                    </Col>

                                </Row>

                            </TabPane>

                        </TabContent>



                    </Container>
                </div >

            </React.Fragment >
        );
    }
}



export default (withTranslation()(Tableplacedv));