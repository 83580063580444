import { LOGIN_USER, LOGIN_SUCCESS, LOGOUT_USER, LOGOUT_USER_SUCCESS, API_ERROR, SAVE_CAPACITY } from './actionTypes';

const initialState = {
    error: "...",
    isLoggedIn: false, // เพิ่มค่า isLoggedIn เพื่อเก็บสถานะการ login
    user: null, // เพิ่มตัวแปร userData เพื่อเก็บข้อมูลผู้ใช้ที่ login สำเร็จ
    loading: true,
    capacity: [],
};

const login = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_USER:
            state = {
                ...state,
                loading: true
            }
            break;
        case LOGIN_SUCCESS:
            state = {
                ...state,
                user: action.payload.user,
                isLoggedIn: action.payload.isLoggedIn,
            };
            break;
        case LOGOUT_USER:
            state = { ...state };
            break;
        case LOGOUT_USER_SUCCESS:
            state = { ...state };
            break;
        case API_ERROR:
            state = { ...state, error: action.payload, loading: false };
            break;
        case SAVE_CAPACITY:
            // console.log('inside reducer')
            // console.log(action.payload)
            state = {
                ...state, capacity: action.payload
            }
            console.log(state)
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default login;