import React, { Component } from 'react';
import Board from "@lourenci/react-kanban";
import CardTaskBox from "./card-task-box";
import RenderCardTitle from "./render-card-title";

// import Modaladd from "./Modal-add";
// import { View } from 'devextreme-react/scheduler';

class UncontrolledBoard extends Component {
    render() {
        const content = this.props.board;
        console.log(content)
        return (

            <React.Fragment>
                {/* <Row className="mb-2"> */}
                <Board
                    disableCardDrag
                    disableColumnDrag
                    // allowRemoveLane
                    // allowRenameColumn
                    // allowRemoveCard
                    // allowAddCard={{ on: "top" }}
                    // // onNewCardConfirm={draftCard => ({
                    // //     id: new Date().getTime(),
                    // //     ...draftCard
                    // // })}
                    initialBoard={content}
                    renderColumnHeader={({ title }) => (
                        <RenderCardTitle title={title} modalCallback={this.props.modalCallback} />
                    )
                    }
                    renderCard={({ content }, { dragging }) => (
                        <CardTaskBox data={content} dragging={dragging} >
                            {content}
                        </CardTaskBox>
                    )}
                />

                {/* </Row> */}
            </React.Fragment>
        );
    }
}

export default UncontrolledBoard;