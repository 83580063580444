import React, { Component } from "react";
import { GoogleApiWrapper } from "google-maps-react";
import { connect } from "react-redux";
// import Gallery from "react-photo-gallery";

import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  Input,
  Label,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
// import img1 from "../../assets/images/small/img-1.jpg";
// import img2 from "../../assets/images/small/img-2.jpg";
// import img3 from "../../assets/images/small/img-15.jpg";
// import img4 from "../../assets/images/small/img-16.jpg";
// import img5 from "../../assets/images/small/img-17.jpg";
// import img6 from "../../assets/images/small/img-18.jpg";
// import img7 from "../../assets/images/small/img-19.jpg";
// import img8 from "../../assets/images/small/img-20.jpeg";
// import img9 from "../../assets/images/small/img-21.jpg";
// import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";

import Cardtrip from "./components/Cardtrip";
// import { View } from "devextreme-react/scheduler";

import { API_ENDPOINT } from "../../assets/api";

import '../../assets/scss/custom.scss'
import * as loadingData from "../Joborder/men-component/loading.json";
import Lottie from "react-lottie";
import FadeIn from "react-fade-in";
// import UserType from "../Management/component/UserType";
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingData.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};
const LoadingContainer = (props) => <div>Loading...</div>;

class Trip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopupOpen: false,
      skip: 0,
      take: 10,
      images: this.props.images,
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      step1: true,
      step2: false,
      thumbContainer: {
        width: "100%",
        height: 400,
      },
      thumbnail: {
        flex: 1,
        width: undefined,
        height: undefined,
        resizeMode: "cover",
      },
      tripData: [],
      count: 0,
      isLoadingCategory: true,
      isLoading: true,
      category: [],
      categorySelected: [],
      regions: [],
      regionsSelected: [],
      search_name: "",
      isSearching: false,
    };

    this.pageControl = this.pageControl.bind(this);
    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.getTrips = this.getTrips.bind(this);
    this.getCategory = this.getCategory.bind(this);
    this.getRegions = this.getRegions.bind(this);
    this.search = this.search.bind(this);

    this.auth = JSON.parse(localStorage.getItem("authToken"));
    console.log(this.props);
    console.log(this.auth);
  }
  pageControl() {
    this.setState({}, () => {
      let take = this.state.take;

      this.setState(
        {
          take: parseInt(take) + 5,
        },
        () => {
          console.log(this.state);
          this.getTrips();
        }
      );
    });
  }

  // getAgencysLicenseDiffdays() {
  //   const authData = localStorage.getItem("authToken");
  //   const auth = authData ? JSON.parse(authData) : null;

  //   if (auth && auth.users) {
  //     if (
  //       auth.users.type_id === 3 &&
  //       auth.users.agencys_license_diffdays <= 15
  //     ) {
  //       this.setState({
  //         isPopupOpen: true,
  //       });
  //       return auth.users.agencys_license_diffdays;
  //     } else {
  //       return "N/A"; // หากไม่ตรงเงื่อนไขที่ auth.users.type_id === 3 หรือ agencys_license_diffdays มากกว่า 15 ให้แสดง "N/A" หรือข้อความที่คุณต้องการ
  //     }
  //   } else {
  //     this.setState({
  //       isPopupOpen: false,
  //     });
  //   }
  // }

  getAgencysLicenseDiffdays() {
    const authData = localStorage.getItem("authToken");
    const auth = authData ? JSON.parse(authData) : null;


    if (auth && auth.users && auth.users.agencys_license_diffdays) {
      return auth.users.agencys_license_diffdays;
    } else {
      return "N/A"; // หากไม่พบค่า agencys_license_diffdays ให้แสดง "N/A" หรือข้อความที่คุณต้องการ
    }

  }

  togglePopup = () => {
    // When the popup is closed, store the current timestamp
    localStorage.setItem("lastPopupClosed", Date.now().toString());
    this.setState({ isPopupOpen: false });
  };

  openPopup = () => {
    this.setState({ isPopupOpen: true });
  };

  async getRegions() {
    var response = await fetch(
      API_ENDPOINT + "api/v2/web/data/regions/get?lang=th",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: this.auth.data.type + " " + this.auth.data.token,
        },
      }
    );
    var responseJson = await response.json();
    console.log(responseJson);
    this.setState({
      regions: responseJson,
      isLoadingCategory: false,
    });
  }

  async getCategory() {
    var response = await fetch(
      API_ENDPOINT + "api/v2/web/place/category/get?lang=th",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: this.auth.data.type + " " + this.auth.data.token,
        },
      }
    );
    var responseJson = await response.json();
    console.log(responseJson);
    this.setState({
      category: responseJson,
      isLoadingCategory: false,
    });
  }

  async search(e) {
    console.log(e);
    let name = "";
    if (e !== undefined) {
      name = e.target.value;
      console.log(name);
    }
    this.setState(
      {
        isSearching: true,
      },
      async () => {
        await clearTimeout(this.searchenable);
        this.searchenable = setTimeout(async () => {
          // Do the ajax stuff
          console.log(this.state.categorySelected);
          console.log(
            JSON.stringify({
              skip: this.state.skip,
              take: this.state.take,
              categorys: this.state.categorySelected,
              lang: "th",
              name: name,
            })
          );
          var url = API_ENDPOINT + "api/v2/web/place/filter";
          // var dev_url =
          //   "https://drjotest-api.melivecode.com/api/v2/web/place/filter?skip=" +
          //   this.state.pageSkip +
          //   "&take=" +
          //   this.state.pageTake +
          //   "&categorys=[" +
          //   this.state.categorySelected +
          //   "]&name=" +
          //   name;
          var response = await fetch(url, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: this.auth.data.type + " " + this.auth.data.token,
            },
            body: JSON.stringify({
              skip: this.state.skip,
              take: this.state.take,
              categorys: JSON.stringify(this.state.categorySelected),
              lang: "th",
              name: this.state.search_name,
            }),
          });
          console.log(response);
          var responseJson = await response.json();
          console.log(responseJson);
          this.setState(
            {
              tripData: responseJson.tours,
              count: responseJson.count,
              isSearching: false,
            },
            () => {
              console.log(this.state.Orders);
              console.log(this.state.isSearching);
            }
          );
        }, 1000); // Will do the ajax stuff after 1000 ms, or 1 s
      }
    );
  }

  async getTrips() {
    var response = await fetch(API_ENDPOINT + "api/v2/web/tour/get", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.auth.data.type + " " + this.auth.data.token,
      },
      body: JSON.stringify({
        skip: this.state.skip,
        take: this.state.take,
        categorys: this.state.categorySelected,
        regions: this.state.regionsSelected,
        name: this.state.search_name,
      }),
    });
    var responseJson = await response.json();
    console.log(responseJson);
    if (responseJson.count > 0) {
      this.setState({
        tripData: responseJson.tours,
        count: responseJson.count,
        isLoading: false,
      });
    } else {
      this.setState({
        tripData: [],
        count: 0,
        isLoading: false,
      });
    }
  }

  componentDidMount() {
    const authData = localStorage.getItem("authToken");
    const auth = authData ? JSON.parse(authData) : null;
    // Check if the popup should be displayed
    const lastPopupClosed = localStorage.getItem("lastPopupClosed");
    if (
      !lastPopupClosed ||
      (auth.users.type_id === 3 &&
        Date.now() - parseInt(lastPopupClosed, 10) > 3600000 && //จะแสดงpopup 1ครั้ง ภายใน 1ชม. (3600000)
        auth.users.agencys_license_diffdays <= 30)
    ) {
      this.setState({ isPopupOpen: true });
    } else {
      this.setState({ isPopupOpen: false });
    }
    this.getTrips();
    this.getCategory();
    this.getRegions();
    localStorage.removeItem("tripInfo");
  }

  onMarkerClick(props, marker, e) {
    alert("You clicked in this marker");
  }

  checkBoxregions(i, id) {
    this.setState(
      {
        tripData: [],
        count: 0,
      },
      () => {
        console.log(id);
        let arr = this.state.regions,
          arrSelect = this.state.regionsSelected;
        if ("isCheck" in arr[i]) {
          console.log("found");
          if (arr[i].isCheck === true) {
            arr[i] = { ...arr[i], isCheck: !arr[i].isCheck };
            arrSelect.splice(arrSelect.indexOf(id), 1);

            this.setState(
              {
                regions: arr,
                regionsSelected: arrSelect,
              },
              () => {
                this.getTrips();
                console.log(this.state.regionsSelected);
              }
            );
          } else {
            arr[i] = { ...arr[i], isCheck: !arr[i].isCheck };
            arrSelect.push(id);
            this.setState(
              {
                regions: arr,
                regionsSelected: arrSelect,
              },
              () => {
                this.getTrips();
                console.log(this.state.regionsSelected);
              }
            );
          }
        } else {
          console.log("Notfound");
          arr[i] = { ...arr[i], isCheck: true };
          arrSelect.push(id);
          this.setState(
            {
              regions: arr,
              regionsSelected: arrSelect,
            },
            () => {
              this.getTrips();
              console.log(this.state.regions);
            }
          );
        }
      }
    );
  }

  checkBox(i, id) {
    this.setState(
      {
        tripData: [],
        count: 0,
      },
      () => {
        console.log(id);
        let arr = this.state.category,
          arrSelect = this.state.categorySelected;
        if ("isCheck" in arr[i]) {
          console.log("found");
          if (arr[i].isCheck === true) {
            arr[i] = { ...arr[i], isCheck: !arr[i].isCheck };
            arrSelect.splice(arrSelect.indexOf(id), 1);

            this.setState(
              {
                category: arr,
                categorySelected: arrSelect,
              },
              () => {
                this.getTrips();
                console.log(this.state.categorySelected);
              }
            );
          } else {
            arr[i] = { ...arr[i], isCheck: !arr[i].isCheck };
            arrSelect.push(id);
            this.setState(
              {
                category: arr,
                categorySelected: arrSelect,
              },
              () => {
                this.getTrips();
                console.log(this.state.categorySelected);
              }
            );
          }
        } else {
          console.log("Notfound");
          arr[i] = { ...arr[i], isCheck: true };
          arrSelect.push(id);
          this.setState(
            {
              category: arr,
              categorySelected: arrSelect,
            },
            () => {
              this.getTrips();
              console.log(this.state.category);
            }
          );
        }
      }
    );
  }

  async searching(value) {
    await clearTimeout(this.searchenable);
    this.searchenable = setTimeout(async () => {
      this.setState(
        {
          search_name: value,
          isLoading: true,
        },
        () => {
          this.getTrips();
        }
      );
    }, 1000); // Will do the ajax stuff after 1000 ms, or 1 s
  }

  render() {
    if (this.state.isLoading === true) {
      return (
        <FadeIn>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh", // Use full viewport height to center vertically
            }}
          >
            <Card>
              <CardBody>
                <Lottie
                  options={defaultOptions}
                  height={300}
                  width={300}
                />
                <div>
                  <h4
                    style={{
                      color: "black",
                      padding: 30,
                    }}
                  >
                    กำลังเตรียมข้อมูล กรุณารอสักครู่
                  </h4>
                </div>
              </CardBody>
            </Card>
          </div>
        </FadeIn>
      )
    } else {
      // const photos = [
      //     {
      //       src: img1,
      //       width: 4,
      //       height: 3,
      //     },
      //   ],
      //   photos1 = [
      //     {
      //       src: img2,
      //       width: 4,
      //       height: 5,
      //     },
      //   ],
      //   photos2 = [
      //     {
      //       src: img3,
      //       width: 4,
      //       height: 5,
      //     },
      //   ],
      //   photos3 = [
      //     {
      //       src: img4,
      //       width: 4,
      //       height: 5,
      //     },
      //   ],
      //   photos4 = [
      //     {
      //       src: img5,
      //       width: 4,
      //       height: 3,
      //     },
      //   ],
      //   photos5 = [
      //     {
      //       src: img6,
      //       width: 4,
      //       height: 3,
      //     },
      //   ],
      //   photos6 = [
      //     {
      //       src: img7,
      //       width: 4,
      //       height: 3,
      //     },
      //   ],
      //   photos7 = [
      //     {
      //       src: img8,
      //       width: 4,
      //       height: 5,
      //     },
      //   ],
      //   photos8 = [
      //     {
      //       src: img9,
      //       width: 4,
      //       height: 5,
      //     },
      //   ];

      return (
        <React.Fragment>
          <div className="page-content">
            <div className="container-fluid">
              <Row>
                <Col xs="12">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Breadcrumbs
                      title={"แผนการท่องเที่ยว"}
                      breadcrumbItem={this.props.t("Trip")}
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Link to={{ pathname: "/Trip/Addtrip/Selecttimeperiod" }}>
                        <button
                          type="button"
                          className="btn btn-primary waves-effect waves-light"
                          style={{
                            width: "100%",
                            backgroundColor: "#224AAC",
                            borderColor: "#224AAC",
                          }}
                        >
                          <i className="fas fa-plus-circle mr-3"></i>
                          <span className="font-style-16">
                            {this.props.t("Add New Trip")}
                          </span>
                        </button>
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="container-fluid">
              <Row style={{ marginTop: "1.5rem" }}>
                <Col xl={3} lg={3} sm={12} md={12} xs={12}>
                  <div
                    style={{
                      alignSelf: "center",
                      justifyContent: "center",
                    }}
                  >
                    {/* <Link to={{ pathname: "/Trip/Addtrip/Selecttimeperiod" }}>
                      <Button
                        className="mb-4 mt-4"
                        style={{
                          width: "100%",
                          backgroundColor: "#224AAC",
                          borderColor: "#224AAC",
                        }}
                      >
                        <i className="fas fa-plus-circle mr-3"></i>
                        {this.props.t("Add New Trip")}
                      </Button>
                    </Link> */}
                  </div>
                  <Card>
                    <CardBody>
                      <Form className="mb-3">
                        <FormGroup className="m-0">
                          <InputGroup>
                            <Input
                              type="text"
                              className="form-control"
                              placeholder={this.props.t(
                                "Search Place Name and Detail…"
                              )}
                              aria-label="Recipient's username"
                              value={this.state.search_name}
                              onChange={(e) => {
                                this.setState({ search_name: e.target.value });
                                this.searching(e.target.value);
                              }}
                            // onChange={(e) => {
                            //   this.searching(e.target.value);
                            // }}
                            />
                            <InputGroupAddon addonType="append">
                              <Button color="primary" type="submit">
                                <i className="mdi mdi-magnify"></i>
                              </Button>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Form>

                      {/* <Row>
                                                <form className="app-search" style={{ paddingRight: 15 }}>
                                                    <div className="position-relative">
                                                        <input type="text" className="form-control rounded chat-input" placeholder="ค้นหาชื่อและรายละเอียดสถานที่…." />
                                                        <span className="bx bx-search-alt"></span>
                                                    </div>
                                                </form>

                                                <Button style={{ backgroundColor: '#224AAC', height: 50 }}>  <i className="bx bx-search-alt search-icon fa-lg"></i></Button>
                                            </Row> */}
                      <p className="mb-3 font-style-18 cl-blue">
                        {this.props.t("Desired Region")}
                      </p>
                      {this.state.regions.map((e, i) => {
                        return (
                          <div key={i}>
                            <div
                              className="custom-control custom-checkbox mt-2"
                              onClick={() => {
                                this.checkBoxregions(i, e.id);
                              }}
                            >
                              <Input
                                type="checkbox"
                                className="custom-control-input"
                                checked={e.isCheck}
                              />
                              <Label
                                className="custom-control-label font-style-16 cl-light-black"
                                htmlFor="productdiscountCheck1"
                              >
                                {this.props.t(e.name)}
                              </Label>
                            </div>
                          </div>
                        );
                      })}

                      <hr></hr>

                      <div>
                        <p className="mb-3 font-style-18 cl-blue">
                          {this.props.t("หมวดหมู่สถานที่")}
                        </p>
                        {this.state.category.map((e, i) => {
                          return (
                            <div key={i}>
                              <div
                                className="custom-control custom-checkbox mt-2"
                                onClick={() => {
                                  this.checkBox(i, e.id);
                                }}
                              >
                                <Input
                                  type="checkbox"
                                  className="custom-control-input"
                                  checked={e.isCheck}
                                />
                                <Label
                                  className="custom-control-label font-style-16 cl-light-black"
                                  htmlFor="productdiscountCheck1"
                                >
                                  {this.props.t(e.name)}
                                </Label>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={9} lg={9} sm={12} md={12} xs={12}>
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "19px auto",
                    }}
                  >
                    {/* <Col
                      xl={9}
                      lg={9}
                      sm={9}
                      md={9}
                      xs={12}
                      style={{ alignSelf: "center", padding: 20 }}
                    ></Col> */}
                    {/* <Row> */}
                    {/* <Col xl={12} lg={12} sm={12} md={12} xs={12}> */}
                    <h5 className="font-style-16">
                      {this.props.t("Show")}{" "}
                      <span style={{ color: "rgb(34, 74, 172)" }}>
                        {this.state.tripData.length}
                      </span>{" "}
                      {this.props.t("of all")}{" "}
                      <span style={{ color: "rgb(34, 74, 172)" }}>
                        {this.state.count}
                      </span>{" "}
                      {this.props.t("the Trip")}{" "}
                      {this.props.t("Sort by date of Trip to")}{" "}
                      <span style={{ color: "rgb(34, 74, 172)" }}>
                        {this.props.t("วันที่สร้าง ใหม่ - เก่า")}
                      </span>{" "}
                    </h5>
                    {/* </Col> */}
                    {/* </Row> */}
                    {/* <Col
                      xl={3}
                      lg={3}
                      sm={3}
                      md={3}
                      xs={12}
                      style={{ padding: 10, textAlign: "end" }}
                    >
                      <div className="btn-group mb-2">
                        {" "} */}
                    {/* <ButtonDropdown
                          isOpen={this.state.drp_secondary}
                          toggle={() =>
                            this.setState({
                              drp_secondary: !this.state.drp_secondary,
                            })
                          }
                        >
                          <DropdownToggle
                            style={{
                              backgroundColor: "rgba(255, 0, 0, -0.7)",
                              borderColor: "#224AAC",
                            }}
                            caret
                            className="btn-sm"
                          >
                            <div>
                              <label
                                style={{
                                  cursor: "pointer",
                                  color: "#224AAC",
                                  marginTop: 6,
                                }}
                              >
                                {" "}
                                {this.props.t("Created Date")}
                                &nbsp; <i className="mdi mdi-chevron-down"></i>
                              </label>
                            </div>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem>
                              {this.props.t("Created Date")}
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem>
                              {this.props.t("Popular")}
                            </DropdownItem>
                          </DropdownMenu>
                        </ButtonDropdown> */}
                    {/* </div>{" "}
                    </Col> */}
                  </Row>
                  {this.state.tripData.map((e, i) => {
                    console.log(e);
                    return <Cardtrip key={i} data={e}></Cardtrip>;
                  })}
                  <div>
                    <button
                      onClick={() => {
                        this.pageControl();
                      }}
                      className="btn btn-primary waves-effect waves-light"
                      style={{
                        backgroundColor: "#224AAC",
                        borderColor: "#224AAC",
                        color: "white",
                        width: "100%",
                      }}
                    >
                      <i className="fas fa-plus-circle font-size-16 align-middle mr-2"></i>{" "}
                      {this.props.t("See More Trip")}
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <Modal
            isOpen={this.state.isPopupOpen}
            toggle={this.togglePopup}
            size="md"
          >
            <ModalHeader toggle={this.togglePopup}>
              แจ้งเตีอนวันหมดอายุของใบอนุญาต
            </ModalHeader>
            <ModalBody style={{ textAlign: "center" }}>
              <p style={{ fontSize: "20px" }}>
                ใบอนุญาตของคุณจะหมดในอีก{" "}
                <label style={{ fontSize: "24px", color: "red" }}>
                  {this.getAgencysLicenseDiffdays()}
                </label>{" "}
                วัน
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.togglePopup}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </React.Fragment>
      );
    }
  }
}
const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
    login: state.Login,
  };
};
export default connect(
  mapStatetoProps,
  {}
)(
  GoogleApiWrapper({
    apiKey: "AIzaSyD0AIarEA25PY-NvHNsZDAVqM3qa8krHfs",
    libraries: ["visualization"],
    LoadingContainer: LoadingContainer,
    v: "3",
  })(withTranslation()(Trip))
);
