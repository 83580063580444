import React, { Component } from 'react'
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';

class ReportAttractionTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            paginationModel: {
                pageSize: 10,
                page: 0,
            },
            rowCountState: 10,
            data_attraction: this.props.report.report_data,
        }
        console.log("props", props)
    }
    handlePaginationModelChange = (newModel) => {
        this.setState({ paginationModel: newModel });
        // You might want to fetch new data here based on the newModel's page and pageSize
    }

    render() {
        const isMonthReport = this.props.report.view === "month"
        const columns = [
            { field: 'no', headerName: 'ลำดับ', width: 200 },
            {
                field: 'name',
                headerName: 'ชื่อสถานที่',
                width: 500,
                align: 'left',
            },
            {
                field: isMonthReport ? 'year_month' : 'year',
                headerName: isMonthReport ? 'ปี-เดือน' : 'ปี',
                width: 200,
                align: 'left',
            },
            {
                field: 'count',
                headerName: 'จำนวนเข้าใช้งาน',
                width: 200,
                align: 'left',
            },
        ];
        if (this.state.isLoading === true) {
            return (
                <div></div>
            )
        }
        else {
            return (
                <React.Fragment>
                    <Box sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                            getRowId={(row) => row.no}
                            rows={this.state.data_attraction}
                            columns={columns}
                            // rowCount={this.state.rowCountState}
                            loading={this.state.isLoading}
                            pageSizeOptions={[10]}
                            // paginationModel={this.state.paginationModel}
                            // paginationMode="server"
                            onPaginationModelChange={this.handlePaginationModelChange}
                            // checkboxSelection
                            disableRowSelectionOnClick
                        />
                    </Box>
                </React.Fragment>
            )
        }

    }
}
export default (
    (withTranslation()(ReportAttractionTable))
);
