// import { View } from 'devextreme-react/scheduler';
import React, { Component } from 'react'
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

// import { useLayoutEffect, useState } from "react";
import { v4 as uuid } from 'uuid';
// import user from "../../../../assets/images/users/avatar-1.jpg"
// import temple from "../../../../assets/images/small/img-1.jpg"
// import icon from "../../../../assets/images/small/temple1.svg"
import { Col, Row, Card, CardBody, CardImg, Modal, Label, Input } from "reactstrap";
import { Link } from "react-router-dom";
// import sun from "../../../../assets/images/icon/sun.svg"
// import night from "../../../../assets/images/icon/night.svg"
// import cloudy from "../../../../assets/images/icon/cloudy.svg"
// import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
// import modalsave from "../../../../assets/images/small/modalsave.svg"
// import img1 from "../../../../assets/images/small/img-1.jpg";
// import tawnoi from "../../../../assets/images/small/tawnoi.jpg"
import { connect } from "react-redux";
import { API_ENDPOINT } from "../../../../assets/api";
import { IMAGE_ENDPOINT } from "../../../../assets/api";
import Swal from 'sweetalert2'
// import GridLoader from "react-spinners/GridLoader";
// import { css } from "@emotion/core";
// import pin from "../../../../assets/images/icon/pin-drop.svg"
// import file from "../../../../assets/images/icon/file-text.svg"
import "./styles.css";
import "../../../Joborder/component/kabanguide/modal.scss";
import { withTranslation } from 'react-i18next';
import * as loadingData from "../../../Joborder/men-component/loading.json";
import FadeIn from "react-fade-in";

import Lottie from "react-lottie";


const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};


// const override = css`
//   display: block;
//   margin: 0 auto;
//   border-color: red;
// `;

class kabannew extends Component {
    constructor(props) {
        super(props)
        this.state = {
            skip: 0,
            take: 2,
            windowWidth: window.innerWidth,
            columns: this.props.data,
            modal_xlarge: false,
            modal_center: false,
            morning: false,
            afternoon: false,
            evening: false,
            allday: false,
            toggleSwitch: true,
            columnsFromBackend: {
                morning: {
                    name: "ช่วงเช้า",
                    time: "06.00 น.",
                    icon: '/assets/images/icon/sun.svg',
                    items: [


                    ]
                }
                ,

                afternoon: {
                    name: "ช่วงบ่าย",
                    time: "12.01 - 18.00 น.",
                    icon: '/assets/images/icon/cloudy.svg',
                    items: [

                    ]
                }
                ,

                evening: {
                    name: "ช่วงเย็น",
                    time: "หลัง 18.00 น.",
                    icon: '/assets/images/icon/night.svg',
                    items: [

                    ]
                }



            },
            getPlaces: true,
            places_name_filter: '',
            Orders: [],
            regions: [],
            regionSelected: "",
            provinces: [],
            provinceSelected: "",
            isLoading: true,
            isLoadingCategory: true,
            isLoadingRegion: true,
            placeSelected: "",
            modalHeader: "",
            modalphoto: "",
            pageSkip: 0,
            pageTake: 4,
            search: {
                value: "",
                timer: 0
            },
            searchText: "",
            isSearching: false,
            category: [],
            categorySelected: [],
            count: 0

        }
        console.log(this.props)

        this.auth = JSON.parse(localStorage.getItem('authToken'))
        this.onDragEnd = this.onDragEnd.bind(this);
        this.getPlaces = this.getPlaces.bind(this);
        this.tog_xlarge = this.tog_xlarge.bind(this);
        this.tog_center = this.tog_center.bind(this);
        this.confirmAdd = this.confirmAdd.bind(this);
        this.delete = this.delete.bind(this);
        this.pageControl = this.pageControl.bind(this);
        this.search = this.search.bind(this);
        this.getCategory = this.getCategory.bind(this);
        this.getRegions = this.getRegions.bind(this);
        this.getProvinces = this.getProvinces.bind(this);
        this.checkBox = this.checkBox.bind(this);
        this.handleResize = this.handleResize.bind(this);
        // this.alert = this.alert.bind(this);

        this.searchenable = {}

    }
    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    };

    // async fetch_places(more = false) {
    //     this.setState({
    //         ...this.state,
    //         step_2: {
    //             ...this.state.step_2,
    //             fetch_places: true
    //         }
    //     }, async () => {
    //         var name = this.state.step_2.places_name_filter
    //         var places_selected_category = this.state.step_2.places_selected_category
    //         var auth = JSON.parse(await localStorage.getItem('authToken'))
    //         if (more) {
    //             var object = {
    //                 name: name,
    //                 take: 10,
    //                 skip: this.state.step_2.places.length,
    //                 places_categorys: places_selected_category
    //             }
    //         } else {
    //             var object = {
    //                 name: name,
    //                 take: 10,
    //                 skip: 0,
    //                 places_categorys: places_selected_category
    //             }
    //         }
    //         var response = await fetch(API_ENDPOINT + 'api/v2/web/joborder/place/get', {
    //             method: 'POST',
    //             headers: {
    //                 Accept: 'application/json',
    //                 'Content-Type': 'application/json',
    //                 Authorization: auth.data.type + ' ' + auth.data.token,

    //             },
    //             body: JSON.stringify(object)

    //         });
    //         var responseJson = await response.json();
    //         if (more) {
    //             var places = this.state.step_2.places
    //             places = places.concat(responseJson)
    //             console.log('in more')
    //             console.log(places)
    //             this.setState({
    //                 ...this.state,
    //                 step_2: {
    //                     ...this.state.step_2,
    //                     fetch_places: false,
    //                     places: places
    //                 }
    //             })
    //         } else {
    //             console.log('in more false ')
    //             this.setState({
    //                 ...this.state,
    //                 step_2: {
    //                     ...this.state.step_2,
    //                     fetch_places: false,
    //                     places: responseJson
    //                 }
    //             })
    //         }
    //     })

    // }

    checkBox(i, id) {
        console.log(id)
        let arr = this.state.category, arrSelect = this.state.categorySelected
        if ('isCheck' in arr[i]) {
            console.log('found')
            if (arr[i].isCheck === true) {
                arr[i] = { ...arr[i], isCheck: !arr[i].isCheck }
                arrSelect.splice(arrSelect.indexOf(id), 1)
                this.setState({
                    category: arr,
                    categorySelected: arrSelect
                }, () => {
                    this.search()
                    console.log(this.state.categorySelected)
                })
            }
            else {
                arr[i] = { ...arr[i], isCheck: !arr[i].isCheck }
                arrSelect.push(id)
                this.setState({
                    category: arr,
                    categorySelected: arrSelect
                }, () => {
                    this.search()
                    console.log(this.state.categorySelected)
                })
            }

        }
        else {
            arr[i] = { ...arr[i], isCheck: true }
            arrSelect.push(id)
            this.setState({
                category: arr,
                categorySelected: arrSelect
            }, () => {
                this.search()
                console.log(this.state.category)
            })
        }

    }

    async search(e) {
        console.log(e)
        let name = "";
        if (e !== undefined) {
            name = e.target.value;
            console.log(name);
        }
        this.setState({
            isSearching: true,
            pageSkip: 0,
            searchText: name
        }, async () => {
            await clearTimeout(this.searchenable);
            this.searchenable = setTimeout(async () => {
                // Do the ajax stuff
                console.log(this.state.categorySelected)
                var url = API_ENDPOINT + 'api/v2/web/place/filter?skip=' + this.state.pageSkip +
                    '&take=' + this.state.pageTake + '&categorys=[' + this.state.categorySelected + ']' +
                    '&name=' + name + '&province_id=' + this.state.provinceSelected + '&region_id=' + this.state.regionSelected + '&lang=th'
                var response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: this.auth.data.type + ' ' + this.auth.data.token,

                    }
                });
                console.log(response)

                var responseJson = await response.json();
                console.log(responseJson)
                this.setState({
                    Orders: responseJson,
                    isSearching: false
                }, () => {
                    console.log(this.state.Orders)
                    console.log(this.state.isSearching)
                })
            }, 1000); // Will do the ajax stuff after 1000 ms, or 1 s
        })
        console.log(name)




    }
    pageControl(action) {
        this.setState({
            // isLoading: true
        }, () => {
            let skip = this.state.pageSkip
            // take = this.state.pageTake
            console.log(action)
            this.setState({

                pageSkip: parseInt(skip) + 4,
            }, () => {
                console.log(this.state)
                this.getPlaces()
            })
        })

    }




    // pageControl(action) {
    //     this.setState({
    //         isLoading: true
    //     }, () => {
    //         let skip = this.state.pageSkip, take = this.state.pageTake
    //         console.log(action)
    //         if (action == 'next') {
    //             this.setState({
    //                 pageSkip: parseInt(skip) + 4,
    //                 pageTake: take
    //             }, () => {
    //                 console.log(this.state)
    //                 this.getPlaces()
    //             })
    //         }
    //         if (action == 'back') {
    //             this.setState({
    //                 pageSkip: parseInt(skip) - 4,
    //                 pageTake: take
    //             }, () => {
    //                 console.log(this.state)
    //                 this.getPlaces()
    //             })

    //         }
    //         else
    //             return
    //     })

    // }

    async getCategory() {
        // var auth = JSON.parse(await localStorage.getItem('authToken'))
        // var name = this.state.searchText;
        var url = API_ENDPOINT + 'api/v2/web/place/category/get?lang=th'
        var response = await fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: this.auth.data.type + ' ' + this.auth.data.token,

            }
        });
        var responseJson = await response.json();
        console.log(responseJson.length)
        this.setState({
            category: responseJson,
            isLoadingCategory: false
        })
    }

    async getRegions() {
        var response = await fetch(
            API_ENDPOINT + "api/v2/web/data/regions/get?lang=th",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: this.auth.data.type + " " + this.auth.data.token,
                },
            }
        );
        var responseJson = await response.json();
        console.log(responseJson);
        this.setState({
            regions: responseJson,
            isLoadingRegion: false,
        });
    }

    async getProvinces() {
        var token = localStorage.getItem('authToken');
        token = JSON.parse(token)
        var province_response = await fetch(API_ENDPOINT + 'api/v2/web/data/provinces/get', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.data.type + ' ' + token.data.token
            },
            body: JSON.stringify({
                lang: 'th',
                region_id: this.state.regionSelected
            }) // body data type must match "Content-Type" header
        });
        var responseJson = await province_response.json();
        console.log(responseJson);
        this.setState({
            provinces: responseJson
        });
    }

    selectRegion(value) {
        this.setState({
            regionSelected: value
        }, () => {
            this.search();
            this.getProvinces();
        });
    }

    selectProvince(value) {
        this.setState({
            provinceSelected: value
        }, () => {
            this.search();
        });
    }

    delete(itemId, columId, name) {
        console.log(columId)
        let value = 'undefine'
        if (columId === 'morning') {
            value = 'ช่วงเช้า'
        }
        if (columId === 'afternoon') {
            value = 'ช่วงบ่าย'
        }
        if (columId === 'evening') {
            value = 'ช่วงเย็น'
        }
        Swal.fire({
            title: 'คุณต้องการลบ ',
            text: name + " ออกจากแผนการท่องเที่ยวช่วง " + value + " หรือไม่ ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ต้องการ',
            cancelButtonText: 'ไม่ต้องการ'
        }).then((result) => {
            if (result.isConfirmed) {
                let arr = this.state.columnsFromBackend
                let index = arr[columId].items.map((e) => { return (e.id) }).indexOf(itemId)
                arr[columId].items.splice(index, 1)
                this.setState({
                    columnsFromBackend: arr
                }, () => {
                    console.log(this.state.columnsFromBackend)
                })
            }
            else {
                return
            }
        })


    }


    tog_xlarge() {
        this.setState(prevState => ({
            modal_xlarge: !prevState.modal_xlarge
        }));
        this.removeBodyCss();
    }
    tog_center() {
        this.setState(prevState => ({
            modal_center: !prevState.modal_center,
        }));
        this.removeBodyCss();
    }
    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    onDragEnd = (result, columns) => {
        console.log(result)
        if (!result.destination) return;
        let source = result.source;
        let destination = result.destination;
        if (source.droppableId !== destination.droppableId) {
            let columns_morning = columns.morning.items.filter((item) => item.id === result.draggableId)
            let columns_afternoon = columns.afternoon.items.filter((item) => item.id === result.draggableId)
            let columns_evening = columns.evening.items.filter((item) => item.id === result.draggableId)
            let drag_selected = undefined
            if (columns_morning.length > 0) {
                drag_selected = columns_morning[0]
            }
            if (columns_afternoon.length > 0) {
                drag_selected = columns_afternoon[0]
            }
            if (columns_evening.length > 0) {
                drag_selected = columns_evening[0]
            }
            if (result.destination === undefined || result.destination === null) return;
            console.log(drag_selected)
            let drag_selected_id = drag_selected.placesTmp.name.id
            if (drag_selected_id === undefined) {
                drag_selected_id = drag_selected.placesTmp.id
            }
            console.log(drag_selected_id)
            if (result.destination.droppableId === 'morning') {
                let columns_morning_selected = columns.morning.items.filter((item) => item.placesTmp.name.id === drag_selected_id)
                let columns_morning_added_selected = columns.morning.items.filter((item) => item.placesTmp.id === drag_selected_id)
                console.log(columns_morning_selected, columns_morning_added_selected)
                if (columns_morning_selected.length > 0 || columns_morning_added_selected.length > 0) {
                    Swal.fire({
                        title: 'ผิดพลาด ?',
                        text: "มีสถานที่นี้ในวันเดียวกันแล้ว !",
                        icon: 'warning',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'ตกลง',
                    })
                    return
                }
                else {
                    const sourceColumn = columns[source.droppableId];
                    const destColumn = columns[destination.droppableId];
                    const sourceItems = [...sourceColumn.items];
                    const destItems = [...destColumn.items];
                    const [removed] = sourceItems.splice(source.index, 1);
                    destItems.splice(destination.index, 0, removed);
                    this.setState(
                        prevState => {
                            let columnsFromBackend = Object.assign({}, prevState.columnsFromBackend);
                            columnsFromBackend[destination.droppableId].items = destItems;
                            columnsFromBackend[source.droppableId].items = sourceItems;
                            return { columnsFromBackend };

                        }, () => { console.log(this.state) });
                }
            }
            if (result.destination.droppableId === 'afternoon') {
                let columns_afternoon_selected = columns.afternoon.items.filter((item) => item.placesTmp.name.id === drag_selected_id)
                let columns_afternoon_added_selected = columns.afternoon.items.filter((item) => item.placesTmp.id === drag_selected_id)
                if (columns_afternoon_selected.length > 0 || columns_afternoon_added_selected.length > 0) {
                    Swal.fire({
                        title: 'ผิดพลาด ?',
                        text: "มีสถานที่นี้ในวันเดียวกันแล้ว !",
                        icon: 'warning',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'ตกลง',
                    })
                    return
                }
                else {
                    const sourceColumn = columns[source.droppableId];
                    const destColumn = columns[destination.droppableId];
                    const sourceItems = [...sourceColumn.items];
                    const destItems = [...destColumn.items];
                    const [removed] = sourceItems.splice(source.index, 1);
                    destItems.splice(destination.index, 0, removed);
                    this.setState(
                        prevState => {
                            let columnsFromBackend = Object.assign({}, prevState.columnsFromBackend);
                            columnsFromBackend[destination.droppableId].items = destItems;
                            columnsFromBackend[source.droppableId].items = sourceItems;
                            return { columnsFromBackend };

                        }, () => { console.log(this.state) });
                }
            }
            if (result.destination.droppableId === 'evening') {
                let columns_evening_selected = columns.evening.items.filter((item) => item.placesTmp.name.id === drag_selected_id)
                let columns_evening_added_selected = columns.evening.items.filter((item) => item.placesTmp.id === drag_selected_id)
                if (columns_evening_selected.length > 0 || columns_evening_added_selected.length > 0) {
                    Swal.fire({
                        title: 'ผิดพลาด ?',
                        text: "มีสถานที่นี้ในวันเดียวกันแล้ว !",
                        icon: 'warning',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'ตกลง',
                    })
                    return
                }
                else {
                    const sourceColumn = columns[source.droppableId];
                    const destColumn = columns[destination.droppableId];
                    const sourceItems = [...sourceColumn.items];
                    const destItems = [...destColumn.items];
                    const [removed] = sourceItems.splice(source.index, 1);
                    destItems.splice(destination.index, 0, removed);
                    this.setState(
                        prevState => {
                            let columnsFromBackend = Object.assign({}, prevState.columnsFromBackend);
                            columnsFromBackend[destination.droppableId].items = destItems;
                            columnsFromBackend[source.droppableId].items = sourceItems;
                            return { columnsFromBackend };

                        }, () => { console.log(this.state) });
                }

            }



        } else {
            const column = columns[source.droppableId];
            const copiedItems = [...column.items];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);
            this.setState(
                prevState => {
                    let columnsFromBackend = Object.assign({}, prevState.columnsFromBackend);
                    columnsFromBackend[destination.droppableId].items = copiedItems;
                    return { columnsFromBackend };

                }, () => { console.log(this.state) });
        }


    };


    async getPlaces() {
        var name = this.state.searchText;
        var url = API_ENDPOINT + 'api/v2/web/place/filter?skip=' + this.state.pageSkip +
            '&take=' + this.state.pageTake + '&categorys=[' + this.state.categorySelected + ']' +
            '&name=' + name + '&province_id=' + this.state.provinceSelected + '&lang=th'
        var response = await fetch(url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: this.auth.data.type + ' ' + this.auth.data.token,

            }
        });
        var responseJson = await response.json();
        var Orders = this.state.Orders
        Orders = Orders.concat(responseJson)
        console.log(responseJson)

        this.setState({
            // Orders: responseJson,
            isLoading: false,
            Orders: Orders

        }, () => {
            console.log(this.state.Orders)
        })
    }


    async getCount() {

        var response = await fetch(API_ENDPOINT + 'api/v2/web/place/count', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: this.auth.data.type + ' ' + this.auth.data.token,

            }
        });
        var responseJson = await response.json();
        this.setState({
            count: parseInt(responseJson.count)
        });
    }

    confirmAdd() {
        let arr = this.state.columnsFromBackend

        let morning_selected = arr.morning.items.filter((item) => item.placesTmp.name.id === this.state.placeSelected.id)
        let afternoon_selected = arr.afternoon.items.filter((item) => item.placesTmp.name.id === this.state.placeSelected.id)
        let evening_selected = arr.evening.items.filter((item) => item.placesTmp.name.id === this.state.placeSelected.id)
        let morning_selected_added = arr.morning.items.filter((item) => item.placesTmp.id === this.state.placeSelected.id)
        let afternoon_selected_added = arr.afternoon.items.filter((item) => item.placesTmp.id === this.state.placeSelected.id)
        let evening_selected_added = arr.evening.items.filter((item) => item.placesTmp.id === this.state.placeSelected.id)
        if (this.state.morning === true || this.state.allday === true) {
            if (morning_selected.length > 0 || morning_selected_added.length > 0) {
                Swal.fire({
                    title: 'ผิดพลาด ?',
                    text: "สถานที่นี้ในวันเดียวกันแล้ว !",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    // confirmButtonText: 'ต้องการ',
                    cancelButtonText: 'ไม่ต้องการ'
                })
                return
            }
            else {
                arr.morning.items.push({ id: uuid(), placesTmp: this.state.placeSelected })
            }

        }
        if (this.state.afternoon === true || this.state.allday === true) {
            if (afternoon_selected.length > 0 || afternoon_selected_added.length > 0) {
                Swal.fire({
                    title: 'ผิดพลาด ?',
                    text: "สถานที่นี้ในวันเดียวกันแล้ว !",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    // confirmButtonText: 'ต้องการ',
                    cancelButtonText: 'ไม่ต้องการ'
                })
                return
            }
            else {

                arr.afternoon.items.push({ id: uuid(), placesTmp: this.state.placeSelected })
            }
        }
        if (this.state.evening === true || this.state.allday === true) {
            if (evening_selected.length > 0 || evening_selected_added.length > 0) {
                Swal.fire({
                    title: 'ผิดพลาด ?',
                    text: "สถานที่นี้ในวันเดียวกันแล้ว !",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    // confirmButtonText: 'ต้องการ',
                    cancelButtonText: 'ไม่ต้องการ'
                })
                return
            }
            else {

                arr.evening.items.push({ id: uuid(), placesTmp: this.state.placeSelected })
            }
        }
        this.setState({
            modal_center: false,
            columnsFromBackend: arr,
            morning: false,
            afternoon: false,
            evening: false,
            allday: false,
            placeSelected: ""
        }, () => {
            console.log(this.state.columnsFromBackend)
            this.props.callbackKaban(this.state.columnsFromBackend, this.props.day, 'place')
        })
    }

    componentDidMount() {
        this.getPlaces()
        this.getCount()
        this.getCategory()
        this.getRegions()
        this.getProvinces()
        window.addEventListener("resize", this.handleResize);
    }

    render() {
        // const bg = {
        //     overlay: {
        //         background: "#FFFF00"
        //     }
        // };
        // const { windowWidth } = this.state;
        const columns = this.state.columnsFromBackend;
        if (this.state.isLoading === true || this.state.isLoadingCategory === true || this.state.isLoadingRegion === true) {
            return (
                <Row>
                    <Col>
                        <div className="text-center">
                            <FadeIn>
                                <div style={{ display: "flex", justifyContent: 'center' }}>
                                    <Card>
                                        <CardBody>
                                            <Lottie options={defaultOptions} height={300} width={300} />
                                            <div>
                                                <h4 style={{ color: 'black', padding: 30 }}>กำลังเตรียมข้อมูล กรุณารอสักครู่</h4>
                                            </div>
                                        </CardBody>
                                    </Card>

                                </div>
                            </FadeIn>
                        </div>
                    </Col>
                </Row>
            )
        }
        else {

            return (

                <div style={{ height: "100%" }}>
                    <Row>

                        <Col style={{ textAlign: 'end', margin: 10 }}>
                            <Link to={'#'}>
                                <button onClick={this.tog_xlarge}
                                    className="btn btn-primary waves-effect waves-light"
                                    data-toggle="modal"
                                    data-target=".bs-example-modal-lg" type="button" style={{ backgroundColor: '#42B600', borderColor: '#42B600', color: 'white' }}>
                                    <i className="fas fa-plus-circle font-size-16 align-middle mr-2"></i> {this.props.t('Add Travel Places')}
                                </button>
                            </Link>
                        </Col>

                    </Row>
                    {/* <div style={{
                        display: "flex", height: "500px", overflowX: 'scroll',
                        whiteSpace: 'nowrap'
                    }}> */}
                    <div style={{ flexDirection: 'row' }}>
                        <div style={{
                            display: "flex", width: '100%', justifyContent: 'center', overflowX: 'scroll',
                            position: 'relative', whiteSpace: 'nowrap'
                        }}>

                            <DragDropContext
                                onDragEnd={result => this.onDragEnd(result, columns)}
                            >
                                {Object.entries(columns).map(([columnId, column]) => {
                                    return (
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center"
                                            }}
                                            key={columnId}
                                        >
                                            <div style={{ margin: 5 }}>

                                                <Droppable droppableId={columnId} key={columnId}>
                                                    {(provided, snapshot) => {
                                                        return (

                                                            <div

                                                                {...provided.droppableProps}
                                                                ref={provided.innerRef}

                                                                style={{
                                                                    background: snapshot.isDraggingOver
                                                                        ? "#F4D006"
                                                                        : "#4162B6",
                                                                    padding: 2,

                                                                    width: (this.state.windowWidth * 0.78) / 3,
                                                                    minWidth: 400,

                                                                    minHeight: 500,
                                                                    borderRadius: 10,

                                                                }}
                                                            >

                                                                <Row style={{ margin: '2vh', borderBottom: "2px solid white", padding: '2vh' }}>
                                                                    <div style={{ paddingLeft: 10 }}>
                                                                        <img className="" alt="Skote" src={column.icon} />
                                                                    </div>
                                                                    <div style={{ paddingLeft: 20 }}>
                                                                        <h5 style={{ color: 'white' }}>{column.name}</h5>
                                                                    </div>
                                                                </Row>


                                                                <div >
                                                                    {column.items.map((item, index) => {
                                                                        console.log(item)
                                                                        return (

                                                                            <Draggable
                                                                                key={item.id}
                                                                                draggableId={item.id}
                                                                                index={index}
                                                                            >
                                                                                {(provided, snapshot) => {
                                                                                    return (
                                                                                        <div
                                                                                            ref={provided.innerRef}
                                                                                            {...provided.draggableProps}
                                                                                            {...provided.dragHandleProps}
                                                                                            style={{
                                                                                                userSelect: "none",

                                                                                                margin: "0 0 8px 0",
                                                                                                minHeight: "50px",
                                                                                                // backgroundColor: snapshot.isDragging
                                                                                                //     ? "#F4D006"
                                                                                                //     : "#F4D006",
                                                                                                color: "white",
                                                                                                justifyContent: 'center',
                                                                                                ...provided.draggableProps.style

                                                                                            }}
                                                                                        >
                                                                                            <Card style={{ borderRadius: 5, width: ((this.state.windowWidth * 0.71) / 3), minWidth: 353, margin: 20 }}>
                                                                                                <CardBody>
                                                                                                    <Row>
                                                                                                        <Col sm={3} md={3} lg={3} style={{ textAlign: 'center', alignSelf: 'center' }}>
                                                                                                            <img className="rounded-circle avatar-lg" style={{ height: 60, width: 60, objectFit: 'cover' }} alt="Skote" src={IMAGE_ENDPOINT + item.placesTmp.photo.split(';')[0]} />
                                                                                                        </Col>
                                                                                                        <Col sm={9} md={9} lg={9}>
                                                                                                            <Row>

                                                                                                                <h4 style={{ color: 'black', fontWeight: 'bold' }}>{item.placesTmp.name.substring(0, 20)}...</h4>

                                                                                                            </Row>
                                                                                                            <Row>
                                                                                                                <div>
                                                                                                                    <img
                                                                                                                        src={IMAGE_ENDPOINT + "/logo/" + item.placesTmp.category_icon + '.svg'}
                                                                                                                        alt=""
                                                                                                                        width={10}
                                                                                                                    />
                                                                                                                </div>
                                                                                                                <p style={{ marginLeft: 5, color: 'black', fontSize: '0.8rem', fontWeight: 'bold' }}>{item.placesTmp.category_name}</p>
                                                                                                            </Row>
                                                                                                            <Row >
                                                                                                                <i className="fas fa-map-marker-alt text-primary"></i>   <p style={{ marginLeft: 5, color: 'black', fontSize: 10, fontWeight: 'bold' }}>{item.placesTmp.address.substring(0, 40)}...</p>

                                                                                                            </Row>

                                                                                                            <Row style={{ borderTop: 'solid 1px rgba(0, 0, 0, 0.1)', alignItems: 'center' }}>

                                                                                                                <Col className="mt-1" xs={8} sm={8} md={8} lg={8} xl={8} >
                                                                                                                    <Row >
                                                                                                                        <div >
                                                                                                                            <img
                                                                                                                                src={'/assets/images/icon/file-text.svg'}
                                                                                                                                alt=""
                                                                                                                                width={20}
                                                                                                                                height={20}
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                        <div onClick={event => {
                                                                                                                            let place_id = item.placesTmp.name.id
                                                                                                                            if (place_id === undefined) {
                                                                                                                                place_id = item.placesTmp.id
                                                                                                                            }
                                                                                                                            window.open('/places/detail?placeid=' + place_id)
                                                                                                                        }
                                                                                                                        }
                                                                                                                        >


                                                                                                                            <h6 style={{ cursor: 'pointer' }} className="mt-1 ml-2" >คลิกดูรายละเอียดสถานที่</h6>

                                                                                                                        </div>
                                                                                                                    </Row>
                                                                                                                </Col>
                                                                                                                <Col className="mt-1" xs={4} sm={4} md={4} lg={4} xl={4} style={{ textAlign: 'end' }}>

                                                                                                                    <div onClick={() => { this.delete(item.id, columnId, item.placesTmp.name.name) }}>
                                                                                                                        <h6 className="mt-1" style={{ cursor: 'pointer', color: 'red', fontSize: 10 }} >ลบ <i className="far fa-trash-alt"></i></h6>
                                                                                                                    </div>

                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        </Col>


                                                                                                    </Row>
                                                                                                </CardBody>
                                                                                            </Card>
                                                                                        </div>
                                                                                    );
                                                                                }}
                                                                            </Draggable>
                                                                        );
                                                                    })}
                                                                </div>

                                                                {provided.placeholder}
                                                            </div>
                                                        );
                                                    }}
                                                </Droppable>
                                            </div>
                                        </div >
                                    );
                                })}
                            </DragDropContext>
                        </div>
                    </div>


                    <Modal
                        className="special_modal"
                        size="xl"
                        style={{ maxWidth: '1800px', width: '90%' }}
                        isOpen={this.state.modal_xlarge}

                        toggle={this.tog_xlarge}
                    >
                        <Row>
                            <Col xl={3} lg={12} md={12} sm={12} xs={12}>
                                <Card>
                                    <CardBody>
                                        {/* <Button style={{ width: '100%' }} type="button" color="primary" className="waves-effect waves-light" onClick={event => window.location.href = '/Joborderadd'}>
                                            <i className="fas fa-plus-circle font-size-16 align-middle mr-2"></i>
                                            {this.props.t('Add New Place')}
                                        </Button> */}


                                        <form className="app-search d-none d-lg-block">
                                            <div className="position-relative">
                                                <span className="bx bx-search-alt" style={{ marginTop: '12px' }}></span>
                                                <input type="text" className="form-control" placeholder={"Search ..."} onChange={(e) => { this.search(e) }} />

                                            </div>
                                        </form>
                                        <button className="btn btn-primary btn-block">{this.props.t("ค้นหา")}</button>

                                        <hr></hr>

                                        <div >
                                            <h5 className="font-size-14 mb-3 mb-category-text">{this.props.t("ภาค")}</h5>
                                            <select
                                                onChange={(e) => this.selectRegion(e.target.value)}
                                                value={this.state.regionSelected}
                                                className="form-control select2">
                                                <option value="0">{this.props.t('กรุณาเลือกภาค')}</option>
                                                {this.state.regions.map((element, i) => {
                                                    return <option key={i} value={element.id}>{element.name} </option>
                                                })}
                                            </select>
                                            <hr></hr>
                                            <h5 className="font-size-14 mb-3 mb-category-text">{this.props.t("จังหวัด")}</h5>
                                            <select
                                                onChange={(e) => this.selectProvince(e.target.value)}
                                                value={this.state.provinceSelected}
                                                className="form-control select2">
                                                <option value="0">{this.props.t('กรุณาเลือกจังหวัด')}</option>
                                                {this.state.provinces.map((element, i) => {
                                                    return <option key={i} value={element.id}>{element.name} </option>
                                                })}
                                            </select>
                                            {/* 
                                            {this.state.regions.map((e, i) => {

                                                return (
                                                    <div key={i}>
                                                    <div
                                                      className="custom-control custom-checkbox mt-2"
                                                      onClick={() => {
                                                        this.checkBoxregions(i, e.id);
                                                      }}
                                                    >
                                                      <Input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        checked={e.isCheck}
                                                      />
                                                      <Label
                                                        className="custom-control-label font-style-16 cl-light-black"
                                                        htmlFor="productdiscountCheck1"
                                                      >
                                                        {this.props.t(e.name)}
                                                      </Label>
                                                    </div>
                                                  </div>
                                                )
                                            })} */}
                                        </div>

                                        <hr></hr>

                                        <div >
                                            <h5 className="font-size-14 mb-3 mb-category-text">{this.props.t('Place Category')}</h5>
                                            {this.state.category.map((e, i) => {

                                                return (
                                                    <div key={i}>
                                                        <div className="custom-control custom-checkbox mt-2" onClick={() => { this.checkBox(i, e.id) }} >
                                                            <Input type="checkbox" className="custom-control-input" checked={e.isCheck} />
                                                            <Label className="custom-control-label" htmlFor="productdiscountCheck1" style={{ paddingTop: '4px' }}>{e.name}</Label>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>

                                        <hr></hr>

                                        <button className="btn btn-primary btn-block" style={{ backgroundColor: '#42B600', borderColor: '#42B600', color: 'white' }} onClick={() => window.open('/places/add')}>{this.props.t("เพิ่มสถานที่ท่องเที่ยว")}</button>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl={9} lg={12} md={12} sm={12} xs={12} style={{ overflowX: 'scroll' }} >
                                <div style={{ textAlign: 'end', paddingRight: 5, cursor: 'pointer' }}>
                                    <h3 onClick={() =>
                                        this.setState({ modal_xlarge: false })
                                    }>x</h3>
                                </div>
                                <div style={{ textAlign: 'center', marginTop: -20 }}>
                                    <h5><span style={{ color: 'rgb(34, 74, 172)' }}></span> {this.state.Orders.length} {this.props.t('Places in this category')} {this.props.t('from')} {this.state.count.toLocaleString()} {this.props.t('Places')}
                                    </h5>
                                </div>


                                {this.state.isSearching === false ?
                                    <div>
                                        <Row className="mt-2 mr-modal">
                                            {this.state.Orders.map((e, i) => {
                                                // let name = ''
                                                // if (e.name === undefined) {
                                                //     name = 'undefined'
                                                // }
                                                // else {
                                                //     if (e.name.name === undefined) {
                                                //         name = e.name
                                                //     }
                                                //     else {
                                                //         name = e.name.name
                                                //     }

                                                // }

                                                if (this.state.Orders.length > 0) {

                                                    return (
                                                        <Col xl={6} lg={12} md={12} sm={12} xs={12} >
                                                            <Card style={{ borderRadius: 5 }} key={i}>
                                                                <CardImg top src={IMAGE_ENDPOINT + e.photo.split(';')[0]} style={{ objectFit: 'cover', borderRadius: '5px 5px 0px 0px', width: 'auto', height: 150 }} />
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                            {e.name.length < 50 ?
                                                                                <h5 style={{ fontWeight: 'bold', color: 'black' }}>{e.name}</h5>
                                                                                :
                                                                                <h5 style={{ fontWeight: 'bold', color: 'black' }}>{e.name.substring(0, 50)}...</h5>}
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="justify-content-center mt-lg-1 row">
                                                                        <Col xs={12} sm={7} md={7} lg={7} xl={7}>
                                                                            <Row className="ml-0">
                                                                                <div>
                                                                                    <img
                                                                                        src={IMAGE_ENDPOINT + "/logo/" + e.category_icon + '.svg'}
                                                                                        alt=""
                                                                                        width={20}
                                                                                    />
                                                                                </div>
                                                                                {e.category_name !== undefined ? <div>
                                                                                    {e.category_name.length < 24 ?
                                                                                        <h6 className="mt-1 ml-2" style={{ fontWeight: 'bold', color: 'black' }}>{e.category_name}</h6>
                                                                                        :
                                                                                        <h6 className="mt-1 ml-2" style={{ fontWeight: 'bold', color: 'black' }}>{e.category_name.substring(0, 25)}...</h6>
                                                                                    }

                                                                                </div> : null}

                                                                            </Row>
                                                                        </Col>
                                                                        <Col xs={12} sm={5} md={5} lg={5} xl={5}>
                                                                            <Row className="ml-0">
                                                                                <div>
                                                                                    <img
                                                                                        src={'/assets/images/icon/pin-drop.svg'}
                                                                                        alt=""
                                                                                        width={20}
                                                                                        height={20}
                                                                                    />
                                                                                </div>
                                                                                <h6 className="mt-1 ml-2" style={{ fontWeight: 'bold', color: 'black' }}>จังหวัด{e.province_name}</h6>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                    <hr></hr>
                                                                    <Row className="justify-content-center mt-lg-1 row">

                                                                        <Col className="mt-1" xs={6} sm={6} md={6} lg={6} xl={6} >

                                                                            <Row className="ml-0" >
                                                                                <div >
                                                                                    <img
                                                                                        src={'/assets/images/icon/file-text.svg'}
                                                                                        alt=""
                                                                                        width={20}
                                                                                        height={20}
                                                                                    />
                                                                                </div>
                                                                                <div style={{ cursor: 'pointer' }} onClick={event => {

                                                                                    let place_id = e.id
                                                                                    if (place_id === undefined) {
                                                                                        place_id = e
                                                                                    }

                                                                                    window.open('/places/detail?placeid=' + place_id)
                                                                                }}>



                                                                                    <h6 className="mt-1 ml-2 t-detail-xs">{this.props.t('Click to view place details')}</h6>

                                                                                </div>
                                                                            </Row>
                                                                        </Col>

                                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "right" }} >
                                                                            <Link to="#" onClick={() => {
                                                                                this.setState({
                                                                                    placeSelected: e,
                                                                                    modalHeader: e.name,
                                                                                    modalphoto: e.photo,

                                                                                }, () => {
                                                                                    // console.log(this.state.placeSelected)
                                                                                    console.log(e.name)
                                                                                    this.tog_center()

                                                                                })
                                                                            }}
                                                                                data-toggle="modal"
                                                                                data-target=".bs-example-modal-center">

                                                                                <button type="button" className="btn" style={{ backgroundColor: 'rgba(255, 0, 0, -0.7)', borderColor: '#224AAC', color: "#224AAC" }}>
                                                                                    <i className="fa fa-plus-circle pr-1 mr-2 " aria-hidden="true"></i>{this.props.t('Choose a place')}
                                                                                </button>

                                                                            </Link>
                                                                        </Col>
                                                                        <Modal
                                                                            isOpen={this.state.modal_center}
                                                                            toggle={this.tog_center}
                                                                            centered={true}


                                                                        >
                                                                            {console.log(e)}
                                                                            <div className="modal-header">
                                                                                <h5 style={{ textAlign: 'left' }} className="modal-title mt-0">{this.props.t('Notification')}</h5>
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={() =>
                                                                                        this.setState({ modal_center: false })
                                                                                    }
                                                                                    className="close"
                                                                                    data-dismiss="modal"
                                                                                    aria-label="Close"
                                                                                >
                                                                                    <span aria-hidden="true">&times;</span>
                                                                                </button>
                                                                            </div>
                                                                            <div className="modal-body">
                                                                                <Row style={{ paddingTop: 15, paddingBottom: 15 }}>
                                                                                    <Col xs={5} sm={5} md={5} lg={5} xl={5} style={{ textAlign: 'center', padding: '10px' }}>
                                                                                        <img className="rounded mr-2 rounded-circle avatar-lg" alt="Skote" width="100" src={IMAGE_ENDPOINT + this.state.modalphoto.split(';')[0]} />
                                                                                    </Col>
                                                                                    <Col xs={7} sm={7} md={7} lg={7} xl={7} style={{ alignSelf: 'center', justifyContent: 'center', fontWeight: 'bold' }}>
                                                                                        <h4 style={{ fontWeight: 'bold', color: '#224AAC' }}> {this.state.modalHeader} </h4>
                                                                                        <h5 style={{ fontWeight: 'bold', color: 'black' }}>คุณต้องการไป ในช่วงเวลาใดบ้าง? </h5>
                                                                                    </Col>
                                                                                </Row>

                                                                                <Row style={{ paddingTop: 45, paddingBottom: 20, alignSelf: 'center', justifyContent: 'center', paddingLeft: 25, fontSize: 15, fontWeight: 'bold', borderTop: 'solid 1px rgba(0, 0, 0, 0.1)' }}>
                                                                                    <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                                                        <div className="custom-control custom-checkbox mb-3" >
                                                                                            <input type="checkbox" className="custom-control-input" id="CustomCheck1" onChange={() => false} checked={this.state.morning} />
                                                                                            <label className="custom-control-label" onClick={() => { this.setState({ morning: !this.state.morning }) }} ><h4 style={{ fontWeight: 'bold', color: 'black' }}>ช่วงเช้า</h4></label>
                                                                                        </div>

                                                                                    </Col>
                                                                                    <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                                                        <div className="custom-control custom-checkbox mb-3" >
                                                                                            <input type="checkbox" className="custom-control-input" id="CustomCheck2" onChange={() => false} checked={this.state.afternoon} />
                                                                                            <label className="custom-control-label" onClick={() => { this.setState({ afternoon: !this.state.afternoon }) }} ><h4 style={{ fontWeight: 'bold', color: 'black' }}>ช่วงบ่าย</h4></label>
                                                                                        </div>

                                                                                    </Col>
                                                                                    <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                                                        <div className="custom-control custom-checkbox mb-3">
                                                                                            <input type="checkbox" className="custom-control-input" id="CustomCheck3" onChange={() => false} checked={this.state.evening} />
                                                                                            <label className="custom-control-label" onClick={() => { this.setState({ evening: !this.state.evening }) }} ><h4 style={{ fontWeight: 'bold', color: 'black' }}>ช่วงเย็น</h4></label>
                                                                                        </div>
                                                                                    </Col>
                                                                                    <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                                                                        <div className="custom-control custom-checkbox mb-3" >
                                                                                            <input type="checkbox" className="custom-control-input" id="CustomCheck3" onChange={() => false} checked={this.state.allday} />
                                                                                            <label className="custom-control-label" onClick={() => { this.setState({ allday: !this.state.allday }, () => { this.setState({ morning: this.state.allday, afternoon: this.state.allday, evening: this.state.allday }) }) }} ><h4 style={{ fontWeight: 'bold', color: 'black' }}>ทั้งวัน</h4></label>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>

                                                                                <Row style={{ borderTop: 'solid 1px rgba(0, 0, 0, 0.1)' }}>
                                                                                    <Col xs={5} sm={5} md={5} lg={5} xl={5}></Col>
                                                                                    <Col style={{ textAlign: 'end', paddingTop: 20 }}>
                                                                                        <button style={{ width: 100 }}

                                                                                            type="reset"
                                                                                            className="btn btn-light waves-effect waves-light"
                                                                                            onClick={() =>
                                                                                                this.setState({ modal_center: false })
                                                                                            }

                                                                                        >
                                                                                            {this.props.t('Cancel')}
                                                                                        </button>
                                                                                    </Col>
                                                                                    <Col style={{ textAlign: 'end', paddingTop: 20 }}>
                                                                                        <button style={{ width: 100 }}
                                                                                            type="submit"
                                                                                            className="btn btn-primary waves-effect waves-light"

                                                                                            onClick={() => {
                                                                                                this.confirmAdd()
                                                                                                this.setState({
                                                                                                    modal_xlarge: false
                                                                                                })
                                                                                            }
                                                                                                // this.setState({ modal_center: false })
                                                                                            }
                                                                                        // onClick={() =>
                                                                                        //     this.saveplace()
                                                                                        // }

                                                                                        >
                                                                                            {this.props.t('Confirm')}
                                                                                        </button>
                                                                                    </Col>

                                                                                </Row>


                                                                            </div>
                                                                        </Modal>

                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>



                                                    )
                                                }
                                                else {
                                                    return (
                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6} >
                                                            <p>   ไม่พบข้อมูล !!!</p>

                                                        </Col>
                                                    )
                                                }

                                            })}
                                        </Row>
                                    </div>

                                    :


                                    <Row>
                                        <Col>
                                            <div className="text-center">
                                                <FadeIn>
                                                    <div style={{ display: "flex", justifyContent: 'center' }}>
                                                        <Card>
                                                            <CardBody>
                                                                <Lottie options={defaultOptions} height={300} width={300} />
                                                                <div>
                                                                    <h4 style={{ color: 'black', padding: 30 }}>กำลังเตรียมข้อมูล กรุณารอสักครู่</h4>
                                                                </div>
                                                            </CardBody>
                                                        </Card>

                                                    </div>
                                                </FadeIn>
                                            </div>
                                        </Col>
                                    </Row>

                                }
                                <Row style={{ alignSelf: 'center', }} onClick={() => { this.pageControl() }}>
                                    <Card style={{ width: '98%', backgroundColor: 'transparent', borderWidth: 1, borderColor: 'lightgray' }}>
                                        <CardBody style={{ textAlign: 'center', cursor: 'pointer' }}>
                                            <i className="fas fa-plus" style={{ cursor: 'pointer' }}></i>โหลดเพิ่ม
                                        </CardBody>
                                    </Card>
                                </Row>
                                {/* <div >
                                    <button onClick={() => { this.pageControl() }} className="btn btn-primary waves-effect waves-light" style={{ backgroundColor: '#224AAC', borderColor: '#224AAC', color: 'white', width: '100%' }}>
                                        <i className="fas fa-plus-circle font-size-16 align-middle mr-2"></i> {this.props.t('See More Trip')}
                                    </button>
                                </div> */}

                                {/* {this.state.pageSkip == 0 ?
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} onClick={() => { this.pageControl('next') }} style={{ paddingBottom: 20 }} >

                                        <button type="button" className="btn  waves-effect waves-light" style={{ backgroundColor: 'white', borderColor: '#224AAC', color: '#224AAC', width: 100 }}>
                                            {this.props.t('Next')}    <i className="mdi mdi-arrow-right font-size-16 "></i>
                                        </button>
                                    </Col>
                                    :
                                    <div>
                                        <Row style={{ paddingBottom: 20 }}>
                                            <Col xs={6} sm={6} md={6} lg={6} xl={6} onClick={() => { this.pageControl('back') }} style={{ textAlign: 'left' }}>

                                                <button type="button" className="btn  waves-effect waves-light" style={{ backgroundColor: 'white', borderColor: '#224AAC', color: '#224AAC', width: 100 }}>
                                                    <i className="mdi mdi-arrow-left font-size-16 "></i> {this.props.t('Back')}
                                                </button>
                                            </Col>
                                            <Col xs={6} sm={6} md={6} lg={6} xl={6} onClick={() => { this.pageControl('next') }} style={{ textAlign: 'end', paddingRight: 20 }}>
                                                <button type="button" className="btn  waves-effect waves-light" style={{ backgroundColor: 'white', borderColor: '#224AAC', color: '#224AAC', width: 100 }}>
                                                    {this.props.t('Next')}    <i className="mdi mdi-arrow-right font-size-16 "></i>
                                                </button>
                                            </Col>
                                        </Row>
                                    </div>
                                } */}




                            </Col>
                        </Row>

                    </Modal>


                </div >



            )
        }



    }
}
const mapStatetoProps = state => {
    return {
        ...state.Layout,
        login: state.Login
    };
};
export default connect(mapStatetoProps, {})(withTranslation()(kabannew)
);
