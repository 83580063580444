import React, { Component } from 'react'
// import classnames from 'classnames';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardImg,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Button,
    Input,
    FormGroup,
    Collapse,
    ModalFooter
} from "reactstrap";
import moment from 'moment-timezone';
import Swal from 'sweetalert2'
// import { Link } from "react-router-dom";
import { API_ENDPOINT } from "../../assets/api";
import PlacesKanban from "../Joborder/men-component/PlacesKanban";
// import Dropzone from 'react-dropzone';
// import sun from "../../../assets/images/icon/sun.svg"
// import night from "../../../assets/images/icon/night.svg"
// import cloudy from "../../../assets/images/icon/cloudy.svg"
// import pin from "../../../assets/images/icon/pin-drop.svg"
// import file from "../../../assets/images/icon/file-text.svg"
import { IMAGE_ENDPOINT } from "../../assets/api";
import * as loadingData from "../Joborder/men-component/loading.json";
import FadeIn from "react-fade-in";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Lottie from "react-lottie";
import { withTranslation } from 'react-i18next';
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};


class EditTrip extends Component {
    constructor(props) {
        super(props)

        this.state = {
            //initial 
            mode: "",
            joborder_code: "",
            joborder_id: -1,
            tour_id: 0,
            tour_fetch_ready: false,
            tour_detail: {},
            agency_license: "",
            agency_name: "",
            step: 2,
            progress: 25,
            step_1: {
                created_at: moment().format('YYYY-MM-DD'),
                external_agencys: '',
                external_agencys_code: '',
                joborder_start: '',
                joborder_stop: '',
                // flights_external: [],
                flights_internal: [],
                inbound_type: '',
                inbound_no: '',
                inbound_date: '',
                inbound_country: '',
                outbound_type: '',
                outbound_no: '',
                outbound_date: '',
                outbound_country: '',
            },
            step_2: {
                days: [],
                places_modal_is_open: false,
                places_period_modal_is_open: false,
                guides_modal_is_open: false,
                fetch_guides: false,
                fetch_places: true,
                name_filter: true,
                guides_fee: 0,
                guides: [],
                guides_skip: 0,
                guides_take: 10,
                guides_name_filter: '',
                guides_selected_day: 0,
                searching: false,
                places: [],
                places_name_filter: '',
                places_selected: {},
                places_selected_day: 0,
                places_selected_daypart: [],
                places_selected_category: [],
                places_categorys: [],
                pageSkip: 0,
                pageTake: 4,
            },
            summary: {},
            update_data: {
                tour_id: "",
                tour_name: "",
                tour_description: "",
                min_recommend: 1,
                max_recommend: 100,
                visible: "",
                tour_detail: []
            }

        }
        document.title = "เพิ่มใบสั่งงาน"
        console.log(this.state.step_2)

        console.log("========his.state.step_1")
        console.log(this.state.step_1)

        this.handleChangeVisible = this.handleChangeVisible.bind(this);
    }
    async componentDidMount() {
        let tour_id = this.props.match.params.tour_id
        this.get_tour_detail(tour_id)
    }

    handleChangeVisible(e) {
        console.log("Visible Selected!!" + e.target.value);
        // this.setState({ update_data: e.target.value });
        let obj = this.state.update_data
        obj.visible = e.target.value
        this.setState({
            update_data: obj
        });
    }

    async get_tour_detail(tour_id) {
        var auth = JSON.parse(await localStorage.getItem('authToken'))
        let obj = {
            tour_id: tour_id,
            lang: 'th'
        }

        var response = await fetch(API_ENDPOINT + 'api/v2/web/joborder/tour/get', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: auth.data.type + ' ' + auth.data.token,

            },
            body: JSON.stringify(obj)

        });
        var responseJson = await response.json();
        console.log(responseJson)
        for (var day of responseJson.detail.days) {
            day.collapse_open = false
        }
        responseJson.detail.days.sort((a, b) => (a.day > b.day) ? 1 : -1)
        // console.log(responseJson)
        for (const day of responseJson.detail.days) {
            day.dayparts.sort((a, b) => (a.daypart > b.daypart) ? 1 : -1)
            for (const daypart of day.dayparts) {
                daypart.places.sort((a, b) => (a.place_ordering > b.place_ordering) ? 1 : -1)
            }
        }

        // for(var )


        let update = this.state.update_data
        update.tour_id = responseJson.id
        update.tour_name = responseJson.name
        update.tour_description = responseJson.description
        update.min_recommend = responseJson.min_recommend
        update.max_recommend = responseJson.max_recommend
        update.visible = responseJson.visible
        this.setState({
            ...this.state,
            step_2: {
                ...this.state.step_2,
                days: responseJson.detail.days
            },
            update_data: update
        }, () => {
            // console.log(this.state)
        })
    }

    async get_places_category() {
        var auth = JSON.parse(await localStorage.getItem('authToken'))
        var lang = 'th'
        var response = await fetch(API_ENDPOINT + 'api/v2/web/place/category/get?lang=' + lang, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: auth.data.type + ' ' + auth.data.token,
            }

        });
        var responseJson = await response.json();
        var places_categorys = responseJson
        this.setState({
            ...this.state,
            step_2: {
                ...this.state.step_2,
                places_categorys: places_categorys
            }
        })
        // console.log(responseJson)
    }

    async get_agency_detail() {
        var auth = JSON.parse(await localStorage.getItem('authToken'))
        // var lang = 'th'
        var response = await fetch(API_ENDPOINT + 'api/v2/web/agency/get/' + auth.users.agency_id, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: auth.data.type + ' ' + auth.data.token,
            }
        });
        var responseJson = await response.json();
        var agency = responseJson
        this.setState({
            agency_license: agency.license,
            agency_name: agency.name
        })
        // console.log(responseJson)
    }



    validate_step_2() {
        var days = this.state.step_2.days
        var found_guides = false
        for (const day of days) {
            if (typeof day.guides != 'undefined' && day.guides.length > 0) {
                found_guides = true
            }
        }

        if (found_guides === false) {
            Swal.fire(
                'เตือน !',
                'ไม่พบข้อมูลไกด์ กรุณาเพิ่มไกด์อย่างน้อย 1 วัน',
                'error'
            )
            return false
        }

        for (const day of days) {
            var found_places = false
            for (const daypart of day.dayparts) {
                if (daypart.places.length > 0) {
                    found_places = true
                }
            }
            if (found_places === false) {
                Swal.fire(
                    'เตือน !',
                    'ไม่พบข้อมูลสถานที่ท่องเที่ยวในวันที่ ' + day.day + ' กรุณาเพิ่มสถานที่ท่องเที่ยว',
                    'error'
                )
                return false
            }
        }

        return true
    }
    set_step(step) {
        switch (step) {
            case 1:
                this.setState({
                    progress: 25,
                    step: step
                })
                break;
            case 2:
                if (this.validate_step_1()) {
                    this.setState({
                        progress: 50,
                        step: step
                    })
                }
                break;
            case 3:
                if (this.validate_step_2()) {
                    this.setState({
                        progress: 75,
                        step: step
                    })
                }
                break;
            case 4:
                if (this.validate_step_1() && this.validate_step_2() && this.validate_step_3()) {
                    let obj = this.state.step_3
                    obj.tourists_children = this.state.step_3.tourists.filter(item => moment().diff(item.date_of_birth, 'years') < 15).length
                    obj.tourists_adults = this.state.step_3.tourists.filter(item => moment().diff(item.date_of_birth, 'years') > 15).length
                    obj.tourists_all = this.state.step_3.tourists.length
                    this.setState({
                        progress: 100,
                        step: step
                    }, () => {

                    })
                }
                break;
            default:
                break;
        }
    }

    change_step(action) {
        var current_step = this.state.step
        var next_step = action === 1 ? current_step + 1 : current_step - 1
        switch (next_step) {
            case 1:
                this.setState({
                    progress: 25,
                    step: next_step
                })
                break;
            case 2:
                if (this.validate_step_1()) {
                    this.setState({
                        progress: 50,
                        step: next_step
                    })
                }
                break;
            case 3:
                if (this.validate_step_2()) {
                    this.setState({
                        progress: 75,
                        step: next_step
                    })
                }
                break;
            case 4:
                if (this.validate_step_1() && this.validate_step_2() && this.validate_step_3()) {
                    let obj = this.state.step_3
                    obj.tourists_children = this.state.step_3.tourists.filter(item => moment().diff(item.date_of_birth, 'years') < 15).length
                    obj.tourists_adults = this.state.step_3.tourists.filter(item => moment().diff(item.date_of_birth, 'years') > 15).length
                    obj.tourists_all = this.state.step_3.tourists.length
                    this.setState({
                        progress: 100,
                        step_3: obj,
                        step: next_step
                    }, () => {

                    })
                }
                break;
            default:
                break;
        }
    }
    handle_change(e, step, name) {
        // console.log(e, step, name)
        var state = this.state
        state[step][name] = e.target.value
        this.setState({
            ...state
        }, () => {
            // console.log(this.state)
        })
    }


    kanban_remove_places(day, daypart, place_index) {
        Swal.fire({
            title: 'ยืนยันหรือไม่ ?',
            text: "คุณต้องการลบรายการสถานที่ท่องเที่ยว ใช่หรือไม่",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: 'ยืนยัน!'
        }).then((result) => {
            if (result.isConfirmed) {
                let dayindex = this.state.step_2.days.map(e => e.day).indexOf(day)
                let daypartindex = this.state.step_2.days[dayindex].dayparts.map(e => e.daypart).indexOf(daypart)
                var days = this.state.step_2.days
                days[dayindex].dayparts[daypartindex].places.splice(place_index, 1)
                this.setState({
                    step_2: {
                        ...this.state.step_2,
                        days: days
                    }
                }, () => {
                    Swal.fire(
                        'ลบแล้ว !',
                        'ลบสถานที่ท่องเที่ยวออกจากรายการแล้ว',
                        'success'
                    )
                })
            }
        })
    }
    reordering_places(days) {
        var places_ordering = 1
        for (var day of days) {
            for (var daypart of day.dayparts) {
                for (var place of daypart.places) {
                    place.place_ordering = places_ordering
                    places_ordering++
                }
            }
        }
        return days
    }
    kanban_move_places(from, to) {
        var days = this.state.step_2.days
        let dayfromindex = days.map(e => e.day).indexOf(from.day)
        let daypartfromindex = days[dayfromindex].dayparts.map(e => e.daypart).indexOf(from.daypart)
        let daytoindex = days.map(e => e.day).indexOf(to.day)
        let dayparttoindex = days[dayfromindex].dayparts.map(e => e.daypart).indexOf(to.daypart)
        if (dayparttoindex === -1) {
            days[dayfromindex].dayparts.push({
                daypart: to.daypart,
                places: []
            })
            dayparttoindex = days[dayfromindex].dayparts.map(e => e.daypart).indexOf(to.daypart)
        }
        // console.log(dayparttoindex)
        var tmpplace = days[dayfromindex].dayparts[daypartfromindex].places[from.place_index]

        // console.log(to.daypart)

        // console.log(days[dayfromindex].dayparts)

        var existindex = days[daytoindex].dayparts[dayparttoindex].places.map(place => place.place_id).indexOf(tmpplace.place_id)


        if (existindex !== -1) {
            Swal.fire(
                'ล้มเหลว !',
                'ช่วงเวลาที่เลือกมีสถานที่ ที่เลือกอยู่แล้ว',
                'error'
            )
            return
        }


        days[dayfromindex].dayparts[daypartfromindex].places.splice(from.place_index, 1)
        var form_ordering = 1
        for (const place of days[dayfromindex].dayparts[daypartfromindex].places) {
            place.place_ordering = form_ordering
            form_ordering++
        }

        days[daytoindex].dayparts[dayparttoindex].places.splice(to.place_index, 0, tmpplace)
        var to_ordering = 1
        for (const place of days[daytoindex].dayparts[dayparttoindex].places) {
            place.place_ordering = to_ordering
            to_ordering++
        }
        this.setState({
            step_2: {
                ...this.state.step_2,
                days: days
            }
        })
    }


    async fetch_places(more = false) {
        this.setState({
            ...this.state,
            step_2: {
                ...this.state.step_2,
                fetch_places: true
            }
        }, async () => {
            var name = this.state.step_2.places_name_filter
            var places_selected_category = this.state.step_2.places_selected_category
            var auth = JSON.parse(await localStorage.getItem('authToken'))
            var object;
            if (more) {
                object = {
                    name: name,
                    take: 10,
                    skip: this.state.step_2.places.length,
                    places_categorys: places_selected_category
                }
            } else {
                object = {
                    name: name,
                    take: 10,
                    skip: 0,
                    places_categorys: places_selected_category
                }
            }
            var response = await fetch(API_ENDPOINT + 'api/v2/web/joborder/place/get', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: auth.data.type + ' ' + auth.data.token,

                },
                body: JSON.stringify(object)

            });
            var responseJson = await response.json();
            if (more) {
                var places = this.state.step_2.places
                places = places.concat(responseJson)
                // console.log('in more')
                // console.log(places)
                this.setState({
                    ...this.state,
                    step_2: {
                        ...this.state.step_2,
                        fetch_places: false,
                        places: places
                    }
                })
            } else {
                // console.log('in more false ')
                this.setState({
                    ...this.state,
                    step_2: {
                        ...this.state.step_2,
                        fetch_places: false,
                        places: responseJson
                    }
                })
            }
        })

    }

    add_places_to_daypart() {
        var days = this.state.step_2.days

        var daysindex = days.map(day => day.day).indexOf(this.state.step_2.places_selected_day)
        var places_selected_dayparts = this.state.step_2.places_selected_daypart
        var error = 0
        for (var places_selected_daypart of places_selected_dayparts) {
            var daypartindex = days[daysindex].dayparts.map(day => day.daypart).indexOf(places_selected_daypart)
            if (daypartindex === -1) {
                days[daysindex].dayparts.push({
                    daypart: places_selected_daypart,
                    places: [],


                })
                daypartindex = days[daysindex].dayparts.map(day => day.daypart).indexOf(places_selected_daypart)
            }
            var placesindex = days[daysindex].dayparts[daypartindex].places.map(place => place.place_id).indexOf(this.state.step_2.places_selected.id)
            // console.log(this.state.step_2.places_selected_category)
            if (placesindex === -1) {
                days[daysindex].dayparts[daypartindex].places.push({
                    place_id: this.state.step_2.places_selected.id,
                    place_detail: this.state.step_2.places_selected.detail,
                    place_img: this.state.step_2.places_selected.photo,
                    place_name: this.state.step_2.places_selected.name,
                    place_ordering: 0,

                })


            } else {
                error++
            }
        }
        if (error > 0) {
            Swal.fire(
                'ล้มเหลว !',
                'มีสถานที่ท่องเที่ยวในช่วงเวลาที่เลือกแล้วกรุณาเลือกใหม่อีกครั้ง',
                'error'
            )
        }
        days = this.reordering_places(days)
        this.setState({
            ...this.state,
            step_2: {
                ...this.state.step_2,
                days: days,
                places: [],
                places_name_filter: '',
                places_selected: {},
                places_selected_day: 0,
                places_selected_daypart: [],
                places_selected_category: [],
                fetch_places: false,
                places_modal_is_open: false,
                places_period_modal_is_open: false
            }
        }, () => {
            // console.log(this.state.step_2.days)
            Swal.fire(
                'สำเร็จ !',
                'เพิ่มสถานที่ท่องเที่ยวในวันที่เลือกแล้ว',
                'success'
            )
        })

    }


    radioHandle(value) {
        let obj = this.state.step_2
        obj.name_filter = value === 'name' ? true : false;
        this.setState({
            step_2: obj
        }, () => { console.log(obj.name_filter) })
    }



    async editTrip(value) {
        var auth = JSON.parse(await localStorage.getItem('authToken'))
        for (var i = 0; i < value.tour_detail.length; i++) {
            value.tour_detail[i].places = []
            for (var j = 0; j < value.tour_detail[i].dayparts.length; j++) {
                for (var k = 0; k < value.tour_detail[i].dayparts[j].places.length; k++) {
                    value.tour_detail[i].places.push({
                        place_id: value.tour_detail[i].dayparts[j].places[k].place_id,
                        daypart: value.tour_detail[i].dayparts[j].daypart,
                        placeordering: value.tour_detail[i].dayparts[j].places[k].place_ordering
                    })
                }
            }

        }
        var response = await fetch(API_ENDPOINT + 'api/v2/web/tour/save', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: auth.data.type + ' ' + auth.data.token,

            },
            body: JSON.stringify(value)

        });
        var responseJson = await response.json();
        console.log(responseJson)
        if (responseJson.status === 'success') {
            Swal.fire(
                'สำเร็จ !',
                'แก้ไขแผนการท่องเที่ยวสำเร็จ',
                'success'
            ).then(() => {
                // window.location = "/Tripmanagement"
            })
        }
        else {
            Swal.fire(
                'ผิดพลาด !',
                'เกิดความผิดพลาดในการแก้ไขข้อมูล',
                'error'
            )
        }
    }





    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Row>
                            <Col xs="12">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Breadcrumbs title="Edit Trip" breadcrumbItem={this.props.t("Edit Trip")} />
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <button type="button" className="btn btn-primary waves-effect waves-light"
                                            onClick={() => {
                                                let obj = this.state.update_data
                                                obj.tour_detail = this.state.step_2.days
                                                this.setState({
                                                    update_data: obj
                                                }, () => {
                                                    this.editTrip(this.state.update_data)
                                                })
                                            }}>
                                            <span className="font-style-16">แก้ไขข้อมูล</span>
                                        </button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                            <CardBody>
                                <Row>
                                    <Col lg={3} mg={3} sm={3} style={{ display: 'flex', alignItems: 'center', margin: "15px 0px" }}>
                                        <h5>ชื่อทัวร์ :</h5>
                                    </Col>
                                    <Col lg={9} mg={9} sm={9}>
                                        <Input type="text" className="form-control" id="tour_name" placeholder="ชื่อทัวร์" value={this.state.update_data.tour_name}
                                            onChange={(e) => {
                                                let obj = this.state.update_data
                                                obj.tour_name = e.target.value
                                                this.setState({
                                                    update_data: obj
                                                })
                                            }}
                                        />
                                    </Col>
                                    <Col lg={3} mg={3} sm={3} style={{ display: 'flex', alignItems: 'center', margin: "15px 0px" }}>
                                        <h5>รายละเอียดทัวร์ :</h5>
                                    </Col>
                                    <Col lg={9} mg={9} sm={9}>
                                        <Input type="text" className="form-control" id="tour_description" placeholder="ชื่อทัวร์" value={this.state.update_data.tour_description}
                                            onChange={(e) => {
                                                let obj = this.state.update_data
                                                obj.tour_description = e.target.value
                                                this.setState({
                                                    update_data: obj
                                                })
                                            }}
                                        />
                                    </Col>
                                    <Col lg={3} mg={3} sm={3} style={{ display: 'flex', alignItems: 'center', margin: "15px 0px" }}>
                                        <h5>การเผยแพร่</h5>
                                    </Col>
                                    <Col lg={9} mg={9} sm={9}>
                                        <FormGroup>
                                            <div >
                                                <select
                                                    className="custom-select"
                                                    style={{ color: "#28CA00" }}
                                                    value={this.state.update_data.visible} onChange={this.handleChangeVisible}
                                                >
                                                    <option defaultValue value="1">{this.props.t("ไม่สาธารณะ")}</option>
                                                    <option defaultValue value="2">{this.props.t("สาธารณะ")}</option>
                                                    {/* <option defaultValue>สาธารณะ</option> */}
                                                    {/* <option value="1">One</option>
                                                                                            <option value="2">Two</option>
                                                                                            <option value="3">Three</option> */}
                                                </select>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    {/* <Col lg={3} mg={3} sm={3} style={{ display: 'flex', alignItems: 'center', margin: "15px 0px" }}>
                                        <h5>จำนวนต่ำสุด :</h5>
                                    </Col>
                                    <Col lg={9} mg={9} sm={9}>
                                        <Input type="text" className="form-control" id="min_recommend" placeholder="ชื่อทัวร์" value={this.state.update_data.min_recommend}
                                            onChange={(e) => {
                                                let obj = this.state.update_data
                                                obj.min_recommend = e.target.value
                                                this.setState({
                                                    update_data: obj
                                                })
                                            }}
                                        />
                                    </Col>
                                    <Col lg={3} mg={3} sm={3} style={{ display: 'flex', alignItems: 'center', margin: "15px 0px" }}>
                                        <h5>จำนวนสูงสุด :</h5>
                                    </Col>
                                    <Col lg={9} mg={9} sm={9}>
                                        <Input type="text" className="form-control" id="max_recommend" placeholder="ชื่อทัวร์" value={this.state.update_data.max_recommend}
                                            onChange={(e) => {
                                                let obj = this.state.update_data
                                                obj.max_recommend = e.target.value
                                                this.setState({
                                                    update_data: obj
                                                })
                                            }}
                                        />
                                    </Col> */}
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className="card-shadow" style={{ marginTop: '1.5rem' }}>
                            <CardBody>
                                {this.state.step === 2 && (
                                    <div>
                                        <Row style={{ marginBottom: '2%' }}>
                                            <Col lg={12}>
                                                <div className="text-right">
                                                    <button onClick={() => {
                                                        var days = this.state.step_2.days
                                                        if (days.length === 0) {
                                                            days.push({
                                                                day: 1,
                                                                dayparts: [],
                                                                collapse_open: false
                                                            })
                                                        } else {
                                                            days.push({
                                                                day: days[days.length - 1].day + 1,
                                                                dayparts: [],
                                                                collapse_open: false
                                                            })
                                                        }
                                                        days = this.reordering_places(days)
                                                        var step_1 = this.state.step_1
                                                        console.log(step_1)
                                                        var new_stop = moment(step_1.joborder_start, "YYYY-MM-DD").add('days', days.length).format('YYYY-MM-DD');
                                                        step_1.joborder_stop = new_stop
                                                        this.setState({
                                                            ...this.state,
                                                            step_1: step_1,
                                                            step_2: {
                                                                ...this.state.step_2,
                                                                days: days
                                                            }
                                                        })
                                                    }} className="btn btn-primary">
                                                        เพิ่มวันท่องเที่ยว
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                        {this.state.step_2.days.map((element, i) => {
                                            console.log(element)
                                            return (
                                                <Card key={i} style={{ border: '1px solid #C8C8C8' }}>
                                                    <CardBody>
                                                        <Row>
                                                            <Col xs={10} lg={11}>
                                                                <div onClick={() => {
                                                                    let days = this.state.step_2.days
                                                                    days[i].collapse_open = !days[i].collapse_open
                                                                    this.setState({
                                                                        ...this.state,
                                                                        step_2: {
                                                                            ...this.state.step_2,
                                                                            days: days
                                                                        }
                                                                    })
                                                                }} style={{ cursor: "pointer", paddingRight: 10, alignSelf: 'center', fontSize: 20, color: 'black' }}>
                                                                    <i className="fas fa-angle-down" style={{ marginRight: 10, cursor: 'pointer' }}></i> วันที่ {element.day}</div>
                                                            </Col>
                                                            <Col style={{ textAlign: 'right', paddingTop: '0.3rem' }} xs={2} lg={1}>
                                                                <div onClick={() => {
                                                                    Swal.fire({
                                                                        title: 'ยืนยันหรือไม่ ?',
                                                                        text: "ต้องการลบวันที่ " + (i + 1) + " ข้อมูลสถานที่ท่องเที่ยวที่เลือกไว้จะทำการลบข้อมูลทั้งหมด ยืนยัน ใช่หรือไม่ ",
                                                                        icon: 'warning',
                                                                        showCancelButton: true,
                                                                        confirmButtonColor: '#3085d6',
                                                                        cancelButtonColor: '#d33',
                                                                        cancelButtonText: 'ยกเลิก',
                                                                        confirmButtonText: 'ยืนยัน!'
                                                                    }).then((result) => {
                                                                        if (result.isConfirmed) {
                                                                            var days = this.state.step_2.days
                                                                            days.splice(i, 1)
                                                                            this.setState({
                                                                                ...this.state,
                                                                                step_2: {
                                                                                    ...this.state.step_2,
                                                                                    days: days,
                                                                                }
                                                                            }, () => {
                                                                                Swal.fire(
                                                                                    'สำเร็จ !',
                                                                                    'ลบวันที่ที่เลือกเรียบร้อยแล้ว',
                                                                                    'success'
                                                                                )
                                                                            })
                                                                        }
                                                                    })
                                                                }}>  <i className="fas fa-trash-alt" style={{ color: '#e74242', cursor: 'pointer', }}></i></div>
                                                            </Col>
                                                        </Row>
                                                        <Collapse isOpen={element.collapse_open}>
                                                            {/* <Card>
                                                                <CardBody> */}
                                                            <Row >
                                                                <Col lg="12" className="text-right" style={{}}>
                                                                    <button onClick={() => {
                                                                        this.setState({
                                                                            ...this.state,
                                                                            step_2: {
                                                                                ...this.state.step_2,
                                                                                places_modal_is_open: !this.state.step_2.places_modal_is_open,
                                                                                fetch_places: true,
                                                                                places: [],
                                                                                places_selected_day: element.day,
                                                                            }
                                                                        }, () => {
                                                                            this.fetch_places()
                                                                        })
                                                                    }} className="btn btn-success" style={{ backgroundColor: '#42B600', borderColor: '#42B600', color: 'white' }}> <i className="fas fa-plus-circle font-size-16 align-middle mr-2"></i>เพิ่มสถานที่ท่องเที่ยว</button>
                                                                </Col>
                                                            </Row>
                                                            <div style={{ height: '100%' }}>
                                                                <div style={{ flexDirection: 'row' }}>
                                                                    <div className="board-style">
                                                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                            <PlacesKanban kanban_move_places={this.kanban_move_places.bind(this)} kanban_remove_places={this.kanban_remove_places.bind(this)} index={i} days={element} ></PlacesKanban>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <Row>
                                                                <Col lg="12">
                                                                    <PlacesKanban kanban_move_places={this.kanban_move_places.bind(this)} kanban_remove_places={this.kanban_remove_places.bind(this)} index={i} days={element} ></PlacesKanban>
                                                                </Col>
                                                            </Row> */}
                                                            {/* </CardBody>
                                                            </Card> */}
                                                        </Collapse>
                                                    </CardBody>
                                                </Card>
                                            )
                                        })}
                                        <Modal id="places_modal" size={'xl'} isOpen={this.state.step_2.places_modal_is_open} toggle={() => {
                                            this.setState({
                                                ...this.state,
                                                step_2: {
                                                    ...this.state.step_2,
                                                    places_modal_is_open: !this.state.step_2.places_modal_is_open
                                                }
                                            })
                                        }} className={''}>
                                            {/* <ModalHeader toggle={() => {
                                        this.setState({
                                            ...this.state,
                                            step_2: {
                                                ...this.state.step_2,
                                                places_modal_is_open: !this.state.step_2.places_modal_is_open
                                            }
                                        })
                                    }}>เพิ่มสถานที่ท่องเที่ยว</ModalHeader> */}
                                            <ModalBody>
                                                <Row>
                                                    <Col lg={3}>
                                                        <Row>
                                                            <Col lg={12}>
                                                                <FormGroup>
                                                                    <Button style={{ width: '100%' }} type="button" color="primary" className="waves-effect waves-light" onClick={event => window.location.href = '/Joborderadd'}>
                                                                        เพิ่มสถานที่
                                                                    </Button>
                                                                    <form className="app-search d-none d-lg-block">
                                                                        <div className="position-relative">
                                                                            <input type="text" onChange={(text) => {
                                                                                let places_name_filter = this.state.step_2.places_name_filter
                                                                                places_name_filter = text.target.value
                                                                                this.setState({
                                                                                    ...this.state,
                                                                                    step_2: {
                                                                                        ...this.state.step_2,
                                                                                        places_name_filter: places_name_filter
                                                                                    }
                                                                                }, () => { console.log(this.state.step_2.places_name_filter) })
                                                                            }} className="form-control" id="places_name" placeholder="ค้นหาสถานที่เที่ยว" />
                                                                            <span className="bx bx-search-alt"></span>
                                                                        </div>
                                                                    </form>
                                                                    <button onClick={() => { this.fetch_places() }} className="btn btn-primary btn-block">ค้นหา</button>
                                                                </FormGroup>
                                                                <FormGroup>
                                                                    <Label>หมวดหมู่สถานที่</Label>
                                                                    {this.state.step_2.places_categorys.map((places_element, i) => {
                                                                        return (
                                                                            <div className="custom-control custom-checkbox mt-2" key={i}> <Input style={{ marginTop: 5 }} value={places_element.id} onClick={(e) => {
                                                                                if (e.target.checked) {
                                                                                    let places_selected_category = this.state.step_2.places_selected_category
                                                                                    if (places_selected_category.indexOf(e.target.value) === -1) {
                                                                                        places_selected_category.push(e.target.value)
                                                                                        this.setState({
                                                                                            ...this.state,
                                                                                            step_2: {
                                                                                                ...this.state.step_2,
                                                                                                places_selected_category: places_selected_category
                                                                                            }
                                                                                        }, () => {
                                                                                            console.log(this.state.step_2)
                                                                                            this.fetch_places()
                                                                                        })
                                                                                    }
                                                                                } else {
                                                                                    let places_selected_category = this.state.step_2.places_selected_category
                                                                                    var places_selected_category_index = places_selected_category.indexOf(e.target.value)
                                                                                    if (places_selected_category_index > -1) {
                                                                                        places_selected_category.splice(places_selected_category_index, 1)
                                                                                        this.setState({
                                                                                            ...this.state,
                                                                                            step_2: {
                                                                                                ...this.state.step_2,
                                                                                                places_selected_category: places_selected_category
                                                                                            }
                                                                                        }, () => {
                                                                                            this.fetch_places()
                                                                                        })
                                                                                    }
                                                                                }
                                                                            }} id={"category-" + places_element.id} type="checkbox"></Input> {places_element.name}</div>
                                                                        )
                                                                    })}
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col lg={9}>
                                                        <div style={{ textAlign: 'end', paddingRight: 5, cursor: 'pointer', paddingBottom: 5 }}>
                                                            <h3 onClick={() =>
                                                                this.setState({
                                                                    ...this.state,
                                                                    step_2: {
                                                                        ...this.state.step_2,
                                                                        places_modal_is_open: !this.state.step_2.places_modal_is_open
                                                                    }
                                                                })
                                                            }>x</h3>
                                                        </div>
                                                        <div style={{ textAlign: 'center', marginTop: -35, paddingBottom: 10 }}>
                                                            <h5><span style={{ color: 'rgb(34, 74, 172)' }}></span> สถานที่ในหมวดหมู่นี้1111</h5>
                                                        </div>
                                                        {
                                                            this.state.step_2.fetch_places === true && (
                                                                <Row>
                                                                    <Col>
                                                                        <div className="text-center">
                                                                            <FadeIn>
                                                                                <div style={{ display: "flex", justifyContent: 'center' }}>
                                                                                    <Card>
                                                                                        <CardBody>
                                                                                            <Lottie options={defaultOptions} height={300} width={300} />
                                                                                            <div>
                                                                                                <h4 style={{ color: 'black', padding: 30 }}>กำลังเตรียมข้อมูล กรุณารอสักครู่</h4>
                                                                                            </div>
                                                                                        </CardBody>
                                                                                    </Card>
                                                                                </div>
                                                                            </FadeIn>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        }
                                                        {
                                                            this.state.step_2.fetch_places === false && (
                                                                <Row>
                                                                    {this.state.step_2.places.map((element, i) => {
                                                                        console.log(element)
                                                                        console.log(this.state.step_2.places)
                                                                        console.log(this.state.step_2.places_selected)
                                                                        console.log(this.state.step_2.places_selected.photo)
                                                                        var img = IMAGE_ENDPOINT + "/" + element.photo.split(';')[0]
                                                                        return (
                                                                            <Col key={i} md={12} lg={12} xl={6}>
                                                                                <Card style={{ boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.25)' }}>
                                                                                    <CardImg top src={img} style={{ objectFit: 'cover', borderRadius: '5px 5px 0px 0px', width: 'auto', height: 150 }} />
                                                                                    <CardBody>
                                                                                        <Row>
                                                                                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                                                {element.name.length < 50 ?
                                                                                                    <h5 style={{ fontWeight: 'bold', color: 'black' }}>{element.name}</h5>
                                                                                                    :
                                                                                                    <h5 style={{ fontWeight: 'bold', color: 'black' }}>{element.name.substring(0, 50)}...</h5>}
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row className="justify-content-center mt-lg-1 row">
                                                                                            <Col xs={12} sm={12} md={6} lg={7} xl={7}>
                                                                                                <Row className="ml-0">
                                                                                                    <div>
                                                                                                        <img
                                                                                                            src={IMAGE_ENDPOINT + "/logo/" + element.category_icon + '.svg'}
                                                                                                            // src={element.category_icon}
                                                                                                            alt=""
                                                                                                            width={20}
                                                                                                        />
                                                                                                    </div>
                                                                                                    {element.category_name !== undefined ? <div>
                                                                                                        {element.category_name.length < 24 ?
                                                                                                            <h6 className="mt-1 ml-2" style={{ fontWeight: 'bold', color: 'black' }}>{element.category_name}</h6>
                                                                                                            :
                                                                                                            <h6 className="mt-1 ml-2" style={{ fontWeight: 'bold', color: 'black' }}>{element.category_name.substring(0, 25)}...</h6>
                                                                                                        }
                                                                                                    </div> : null}
                                                                                                </Row>
                                                                                            </Col>
                                                                                            <Col xs={12} sm={12} md={6} lg={5} xl={5}>
                                                                                                <Row className="ml-0">
                                                                                                    <div>
                                                                                                        <img
                                                                                                            src={'/assets/images/icon/pin-drop.svg'}
                                                                                                            alt=""
                                                                                                            width={20}
                                                                                                            height={20}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <h6 className="mt-1 ml-2" style={{ fontWeight: 'bold', color: 'black' }}>จังหวัด{element.province_name}</h6>
                                                                                                </Row>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row className="justify-content-center mt-lg-1 row" style={{ borderTop: 'solid 1px rgba(0, 0, 0, 0.1)', alignItems: 'center' }}>
                                                                                            <Col className="mt-2" xs={6} sm={6} md={6} lg={6} xl={6} >
                                                                                                <Row className="ml-0" >
                                                                                                    <div>
                                                                                                        <img
                                                                                                            src={'/assets/images/icon/file-text.svg'}
                                                                                                            alt=""
                                                                                                            width={20}
                                                                                                            height={20}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div style={{ cursor: 'pointer' }} onClick={event => {
                                                                                                        let place_id = element.id
                                                                                                        if (place_id === undefined) {
                                                                                                            place_id = element.id
                                                                                                        }
                                                                                                        window.open('/places/detail?placeid=' + place_id)
                                                                                                    }
                                                                                                    }>
                                                                                                        <h6 className="mt-1 ml-2">คลิกดูรายละเอียดสถานที่</h6>
                                                                                                    </div>
                                                                                                </Row>
                                                                                            </Col>
                                                                                            <Col className="mt-2" xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "right" }} >
                                                                                                <button onClick={() => {
                                                                                                    this.setState({
                                                                                                        ...this.state,
                                                                                                        step_2: {
                                                                                                            ...this.state.step_2,
                                                                                                            places_modal_is_open: false,
                                                                                                            places_period_modal_is_open: true,
                                                                                                            places_selected: element
                                                                                                        }
                                                                                                    }, () => {
                                                                                                        console.log(this.state.step_2.places_selected)
                                                                                                    })
                                                                                                }} type="button" className="btn" style={{ backgroundColor: 'rgba(255, 0, 0, -0.7)', borderColor: '#224AAC', color: "#224AAC" }}>
                                                                                                    <i className="fa fa-plus-circle pr-1 mr-2 " aria-hidden="true"></i>เลือกสถานที่
                                                                                                </button>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </CardBody>
                                                                                </Card>
                                                                            </Col>
                                                                        )
                                                                    })}
                                                                </Row>
                                                            )
                                                        }
                                                        <Row style={{ alignSelf: 'center', justifyContent: 'center' }} onClick={() => { this.fetch_places(true) }}>
                                                            <Card style={{ width: '100%', backgroundColor: 'transparent', borderWidth: 1, borderColor: 'lightgray' }}>
                                                                <CardBody style={{ textAlign: 'center', cursor: 'pointer' }}>
                                                                    <i className="fas fa-plus" style={{ marginRight: 10, cursor: 'pointer' }}></i>โหลดเพิ่ม
                                                                </CardBody>
                                                            </Card>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </ModalBody>
                                            <ModalFooter>
                                            </ModalFooter>
                                        </Modal>
                                        <Modal id="places_period_modal" size={'lg'} isOpen={this.state.step_2.places_period_modal_is_open} toggle={() => {
                                            // let img = card.places.place_img.split(';')[0]
                                            console.log(this.state.step_2)
                                            this.setState({
                                                ...this.state,
                                                step_2: {
                                                    ...this.state.step_2,
                                                    places_selected: {},
                                                    places_period_modal_is_open: !this.state.step_2.places_period_modal_is_open,
                                                    places_modal_is_open: true,
                                                    places_selected_daypart: []
                                                }
                                            })
                                        }} className={''}>
                                            <ModalHeader toggle={() => {
                                                console.log(this.state.step_2)
                                                this.setState({
                                                    ...this.state,
                                                    step_2: {
                                                        ...this.state.step_2,
                                                        places_selected: {},
                                                        places_period_modal_is_open: !this.state.step_2.places_period_modal_is_open,
                                                        places_modal_is_open: true,
                                                        places_selected_daypart: []
                                                    }
                                                }, () => {
                                                    console.log(this.state.step_2)
                                                })
                                            }}>แจ้งเตือน</ModalHeader>
                                            <ModalBody>
                                                <Row style={{ paddingTop: 15, paddingBottom: 15 }}>
                                                    <Col xs={5} sm={5} md={5} lg={5} xl={5} style={{ textAlign: 'center', padding: '10px' }}>
                                                        {typeof this.state.step_2.places_selected.photo != 'undefined' ? <img className="rounded mr-2 rounded-circle avatar-lg" alt="Skote" width="100" src={IMAGE_ENDPOINT + this.state.step_2.places_selected.photo.split(';')[0]} /> : ''}
                                                    </Col>
                                                    <Col xs={7} sm={7} md={7} lg={7} xl={7} style={{ alignSelf: 'center', justifyContent: 'center', fontWeight: 'bold' }}>
                                                        <h4 style={{ fontWeight: 'bold', color: '#224AAC' }}> {this.state.step_2.places_selected.name} </h4>
                                                        <h5 style={{ fontWeight: 'bold', color: 'black' }}>คุณต้องการไป ในช่วงเวลาใดบ้าง? </h5>
                                                    </Col>
                                                </Row>
                                                <Row style={{ borderTop: 'solid 1px rgba(0, 0, 0, 0.1)', paddingTop: 40, paddingBottom: 40 }}>
                                                    <Col lg="4" className="text-center"><input onClick={(e) => {
                                                        var places_selected_daypart = this.state.step_2.places_selected_daypart
                                                        console.log(this.state.step_2.places_selected)
                                                        if (e.target.checked) {
                                                            let places_selected_daypart_index = places_selected_daypart.indexOf(1)
                                                            if (places_selected_daypart_index === -1) {
                                                                places_selected_daypart.push(1)
                                                            }
                                                        } else {
                                                            let places_selected_daypart_index = places_selected_daypart.indexOf(1)
                                                            if (places_selected_daypart_index > -1) {
                                                                places_selected_daypart.splice(places_selected_daypart_index, 1);
                                                            }
                                                        }
                                                        this.setState({
                                                            ...this.state,
                                                            step_2: {
                                                                ...this.state.step_2,
                                                                places_selected_daypart: places_selected_daypart
                                                            }
                                                        }, () => {
                                                            console.log(this.state.step_2)

                                                        })
                                                    }} type="checkbox" name="dayperiod"></input> ช่วงเช้า </Col>
                                                    <Col lg="4" className="text-center"><input onClick={(e) => {
                                                        var places_selected_daypart = this.state.step_2.places_selected_daypart
                                                        if (e.target.checked) {
                                                            let places_selected_daypart_index = places_selected_daypart.indexOf(2)
                                                            if (places_selected_daypart_index === -1) {
                                                                places_selected_daypart.push(2)
                                                            }
                                                        } else {
                                                            let places_selected_daypart_index = places_selected_daypart.indexOf(2)
                                                            if (places_selected_daypart_index > -1) {
                                                                places_selected_daypart.splice(places_selected_daypart_index, 1);
                                                            }
                                                        }
                                                        this.setState({
                                                            ...this.state,
                                                            step_2: {
                                                                ...this.state.step_2,
                                                                places_selected_daypart: places_selected_daypart
                                                            }
                                                        }, () => {
                                                            console.log(this.state.step_2)
                                                        })
                                                    }} type="checkbox" name="dayperiod"></input> ช่วงบ่าย </Col>
                                                    <Col lg="4" className="text-center"><input onClick={(e) => {
                                                        var places_selected_daypart = this.state.step_2.places_selected_daypart
                                                        if (e.target.checked) {
                                                            let places_selected_daypart_index = places_selected_daypart.indexOf(3)
                                                            if (places_selected_daypart_index === -1) {
                                                                places_selected_daypart.push(3)
                                                            }
                                                        } else {
                                                            let places_selected_daypart_index = places_selected_daypart.indexOf(3)
                                                            if (places_selected_daypart_index > -1) {
                                                                places_selected_daypart.splice(places_selected_daypart_index, 1);
                                                            }
                                                        }
                                                        this.setState({
                                                            ...this.state,
                                                            step_2: {
                                                                ...this.state.step_2,
                                                                places_selected_daypart: places_selected_daypart
                                                            }
                                                        }, () => {
                                                        })
                                                    }} type="checkbox" name="dayperiod"></input> ช่วงเย็น</Col>
                                                </Row>
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button color="secondary" onClick={() => {
                                                    this.setState({
                                                        ...this.state,
                                                        step_2: {
                                                            ...this.state.step_2,
                                                            places_selected: {},
                                                            places_period_modal_is_open: !this.state.step_2.places_period_modal_is_open,
                                                            places_modal_is_open: true,
                                                            places_selected_daypart: 0
                                                        }
                                                    })
                                                }}>ยกเลิก</Button>
                                                <Button color="primary" onClick={() => {
                                                    this.setState({
                                                        ...this.state,
                                                        step_2: {
                                                            ...this.state.step_2,
                                                            places_period_modal_is_open: false,
                                                            places_modal_is_open: false,
                                                        }
                                                    }, () => {
                                                        this.add_places_to_daypart()
                                                    })
                                                }}>ยืนยัน</Button>
                                            </ModalFooter>
                                        </Modal>
                                    </div>
                                )}
                            </CardBody>
                        </Card>
                    </Container>
                </div >
            </React.Fragment >
        )
    }
}
export default (withTranslation()(EditTrip))