// ChartApex.js

import React, { Component } from "react";
import Chart from "react-apexcharts";
import { withTranslation } from 'react-i18next';

class ChartApex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: {
                chart: {
                    id: "basic-bar"
                },
                xaxis: {
                    categories: this.props.report.series
                }
            },
            series: [
                {
                    name: this.props.t('จำนวนข้อมูล'),
                    data: this.props.report.data
                }
            ]
        };
        console.log("props chart", props)
    }
    componentDidUpdate(prevProps) {
        if (prevProps.report !== this.props.report) {
            // อัพเดตข้อมูลใน this.state.report และ options
            this.setState(prevState => ({
                options: {
                    chart: {
                        id: "basic-bar"
                    },
                    xaxis: {
                        categories: this.props.report.series
                    }
                },
                series: [
                    {
                        name: this.props.t('จำนวนข้อมูล'),
                        data: this.props.report.data
                    }
                ]
            }));
        }
    }
    render() {
        // มีข้อมูลใน series และ data
        return (
            <div className="mixed-chart">
                <Chart
                    options={this.state.options}
                    series={this.state.series}
                    type="bar"
                    width={'100%'}
                    height={400}
                />
            </div>
        );
    }
}


export default withTranslation()(ChartApex);
